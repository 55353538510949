import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import Content from 'components/Dashboard/Content';
import TemplatesNew from 'components/Dashboard/Settings/Templates/New';

import Template from 'models/Template';
import api from 'models/API';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    this.template = new Template(); 
    this.template.assign({ id: -1 });
    this.template.editor.root.addChild('Row', {});
    this.template.editor.root.children[0].children[0].setContent('');
    this.template.editor.root.select();
    this.template.saveOldBody();


    api.get('/home/account').then(data => {
      this.template.previewEmail = data.email;
    });
  }


  render() {
    return (
      <Content disableMaxWidth={true}>
        <TemplatesNew template={this.template} />
      </Content>
    );
  }
}
