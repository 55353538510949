import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Doughnut from './Charts/Doughnut';

import styles from 'styles/Visualizations';

const CenterText = (props) => {
  const healthy = props.healthy;
  const unhealthy = props.unhealthy;

  const total = healthy + unhealthy;
  const percent = Math.round(healthy / total * 100);

  if (total > 0) {
    return <span>{percent}%</span>
  }

  return <span style={{ color: styles.tint2 }}>N/A</span>
}

const createGradient = (chart, colorStart, colorEnd) => {
  const ctx = chart.ctx;
  const area = chart.chartArea;

  const centerX = area.width / 2;
  const centerY = area.height / 2;
  const r = area.height * 0.666;

  const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

export function Health(props) {
  const { healthyText, healthyLink, unhealthyText, unhealthyLink, widget, options } = props;
  const healthy = widget.data.datasets[0].data[0];
  const unhealthy = widget.data.datasets[0].data[1];

// outside, middle
  const healthyColor = ['#2afa8d', '#2afa8d'];
  const unhealthyColor = ['#ff4058', '#ff4058']; // dark light

  // remove tooltip since we display legend
  options.plugins.tooltip.enabled = false
  options.cutout = '66%';
  options.borderWidth = 0;
  options.hoverBorderWidth = 0;

  const chartData = {
    ...widget.data,
    datasets: widget.data.datasets.map(dataset => ({
      ...dataset,
    })),
  };

  useEffect(() => {
    const chart = props.chartRef.current;
    const dataset = widget.data.datasets[0];

    if (chart) {
      if (!dataset.backgroundColor) {
        if (dataset.data[0] === 0 && dataset.data[1] == 0) {
          const color = createGradient(chart, styles.tint1, styles.tint2);
          // push new
          widget.data.datasets.push({
            data: [1],
            backgroundColor: color,
            borderColor: color,
          })

          dataset.hidden = true;
          // for our logic check abovee...
          dataset.backgroundColor = 'placeholder';
        }
        else {
          const colors = [
            createGradient(chart, healthyColor[0], healthyColor[1]),
            createGradient(chart, unhealthyColor[0], unhealthyColor[1])
          ];

          dataset.backgroundColor = colors;
          dataset.borderColor = colors;
        }
      }
    }
  });


  return (
    <div className={classnames(styles.Health, 'row', 'h-100')}>
      <div className='col-6 d-flex align-self-center'>
        <ul>
          <li><span style={{ background: `radial-gradient(circle at center, ${healthyColor[0]}, ${healthyColor[1]})` }}>{healthy}</span>{healthyText}</li>
          <li><span style={{ background: `radial-gradient(circle at center, ${unhealthyColor[0]}, ${unhealthyColor[1]})` }}>{unhealthy}</span>{unhealthyText}</li>
        </ul>
      </div>
      <div className='col-6 position-relative'>
        <div className={styles.CenterText}>
          <CenterText healthy={healthy} unhealthy={unhealthy} />
        </div>
        <Doughnut id={widget.id} data={chartData} options={options} chartRef={props.chartRef} />
      </div>
    </div>
  );
}

export default observer(Health);
