import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LineBar from './Charts/LineBar';

import styles from 'styles/Visualizations';

const baseStyles = {
  borderWidth: 1.5,
  pointRadius: 1.5,
  pointHoverRadius: 3,
  tension: 0,
}

export function DailyEvents(props) {
  const { widget, options } = props;

  options.plugins.tooltip.itemSort = (a, b) => {
    return a.datasetIndex - b.datasetIndex
  }

  options.plugins.tooltip.bodyFont.lineHeight = 0.8;
  options.plugins.tooltip.bodyFont.size = 10;
  options.plugins.tooltip.titleFont.size = 11;
  options.plugins.tooltip.titleFont.lineHeight = 0.9;

  const chartData = {
    ...widget.data,
    datasets: widget.data.datasets.map(dataset => ({
      ...dataset,
    })),
  };

  useEffect(() => {
    const chart = props.chartRef.current;

    if (chart) {
      if (!widget.data.datasets[0].backgroundColor) {
        // sends
        const sendStyles = {
          borderWidth: 1,
          borderRadius: 2,
          //barThickness: (window.innerWidth / widget.data.labels.length),
          barPercentage: 0.9,
          categoryPercentage: 1.0,
          backgroundColor: styles.colorSecondaryGrey,
          hoverBackgroundColor: styles.tint3,
          borderColor: 'transparent', // #ddedff, darken(tint15, 3%)
          pointBackgroundColor: 'transparent',
          order: 100,
        }

        widget.data.datasets[0] = {
          ...widget.data.datasets[0],
          ...baseStyles,
          ...sendStyles,
        };

        const colors = [
          styles.colorPrimaryPurple, // opens
          styles.colorSecondaryGreen, // clicks
          styles.colorSecondaryOrange, // rejections
          styles.tint7, // bounces
          '#ff4058', // spam
          '#FFF859', // unsubs
          '#73D0FF' // inbounds
        ];

        let order = 0;

        widget.data.datasets.slice(1, widget.data.datasets.length).forEach((dataset, i) => {
          const styles = {
            backgroundColor: colors[i],
            borderColor: colors[i],
            pointBackgrounColor: colors[i],
            order: order,
          }

          widget.data.datasets[i+1] = {
            ...widget.data.datasets[i+1],
            ...baseStyles,
            ...styles
          };

          order += 5;
        });
      }
    }
  });

  return (
    <div className={classnames(styles.DailyEvents, 'row', 'h-100')}>
      <div className='col-12 d-flex'>
        <LineBar id={widget.id} data={chartData} options={options} chartRef={props.chartRef} />
      </div>
    </div>
  );
}

export default observer(DailyEvents);
