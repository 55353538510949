import { observable, computed, action } from 'mobx';

export default class Monitor {
  @observable id;
  @observable type;
  @observable detection_method;
  @observable detection_type;
  @observable alert_conditions = [];

  @observable last_trigger_alerted_at;
  @observable last_triggered_object;
  @observable last_triggered_object_status;

  @observable notifications;
  @observable created_at;

  @observable is_posting_test = false;

  constructor(args={}) {
    this.assign(args);
  }

  // saved configurations as user switches between
  // types, detection_types, detection_methods, alert_conditions
  @observable saved_monitor = {
    DomainReputationMonitor: {
      detection_method: this.config['DomainReputationMonitor']['default_detection_method'],
      detection_types: {
        change: this.config['DomainReputationMonitor']['detection_methods']['change']['default_detection_type'],
        threshold: this.config['DomainReputationMonitor']['detection_methods']['threshold']['default_detection_type'],
        anomaly: this.config['DomainReputationMonitor']['detection_methods']['anomaly']['default_detection_type'],
      },
      alert_conditions: {
        change: {
          blocklist_event: observable.array([observable.object(this.config['DomainReputationMonitor']['detection_methods']['change']['blocklist_event']['default_alert_condition'])])
        },
        threshold: {
          bounce_rate: observable.array([observable.object(this.config['DomainReputationMonitor']['detection_methods']['threshold']['bounce_rate']['default_alert_condition'])]),
          spam_rate: observable.array([observable.object(this.config['DomainReputationMonitor']['detection_methods']['threshold']['spam_rate']['default_alert_condition'])]),
        },
        anomaly: {
          bounce_rate: observable.array([observable.object(this.config['DomainReputationMonitor']['detection_methods']['anomaly']['bounce_rate']['default_alert_condition'])]),
          spam_rate: observable.array([observable.object(this.config['DomainReputationMonitor']['detection_methods']['anomaly']['spam_rate']['default_alert_condition'])]),
          send_rate: observable.array([observable.object(this.config['DomainReputationMonitor']['detection_methods']['anomaly']['send_rate']['default_alert_condition'])]),
        }
      }
    },
    IpReputationMonitor: {
      detection_method: this.config['IpReputationMonitor']['default_detection_method'],
      detection_types: {
        change: this.config['IpReputationMonitor']['detection_methods']['change']['default_detection_type'],
        threshold: this.config['IpReputationMonitor']['detection_methods']['threshold']['default_detection_type'],
        anomaly: this.config['IpReputationMonitor']['detection_methods']['anomaly']['default_detection_type'],
      },
      alert_conditions: {
        change: {
          blocklist_event: observable.array([observable.object(this.config['IpReputationMonitor']['detection_methods']['change']['blocklist_event']['default_alert_condition'])])
        },
        threshold: {
          bounce_rate: observable.array([observable.object(this.config['IpReputationMonitor']['detection_methods']['threshold']['bounce_rate']['default_alert_condition'])]),
          spam_rate: observable.array([observable.object(this.config['IpReputationMonitor']['detection_methods']['threshold']['spam_rate']['default_alert_condition'])]),
        },
        anomaly: {
          bounce_rate: observable.array([observable.object(this.config['IpReputationMonitor']['detection_methods']['anomaly']['bounce_rate']['default_alert_condition'])]),
          spam_rate: observable.array([observable.object(this.config['IpReputationMonitor']['detection_methods']['anomaly']['spam_rate']['default_alert_condition'])]),
          send_rate: observable.array([observable.object(this.config['IpReputationMonitor']['detection_methods']['anomaly']['send_rate']['default_alert_condition'])]),
        }
      }
    },
    UnsubscribeMonitor: {
      detection_method: this.config['UnsubscribeMonitor']['default_detection_method'],
      detection_types: {
        change: this.config['UnsubscribeMonitor']['detection_methods']['change']['default_detection_type'],
        anomaly: this.config['UnsubscribeMonitor']['detection_methods']['anomaly']['default_detection_type'],
      },
      alert_conditions: {
        change: {
          unsubscribe_event: observable.array([observable.object(this.config['UnsubscribeMonitor']['detection_methods']['change']['unsubscribe_event']['default_alert_condition'])])
        },
        anomaly: {
          unsubscribe_rate: observable.array([observable.object(this.config['UnsubscribeMonitor']['detection_methods']['anomaly']['unsubscribe_rate']['default_alert_condition'])]),
        }
      }
    }
  };

  assign(args) {
    this.name = args.name || this.name;
    this.type = args.type || this.type;
    this.detection_method = args.detection_method || this.detection_method;
    this.detection_type = args.detection_type || this.detection_type;

    this.last_trigger_alerted_at = args.last_trigger_alerted_at || this.last_trigger_alerted_at;
    this.last_triggered_object = args.last_triggered_object || this.last_triggered_object;
    this.last_triggered_object_status = args.last_triggered_object_status || this.last_triggered_object_status;

    this.notifications = args.notifications || this.notifications;

    this.created_at = args.created_at || this.created_at;

    if (this.type && this.detection_method && this.detection_type && args.alert_conditions) {
      this.alert_conditions = this.saved_monitor[this.type]['alert_conditions'][this.detection_method][this.detection_type]
      this.alert_conditions.clear();
      args.alert_conditions?.forEach(alert_condition => {
        this.alert_conditions.push(observable.object(alert_condition));
      });
    }

    this.id = args.id;
  }

  percent_to_decimal(value) {
    const match = value?.match(/^(\d+)?(\.)?(\d+)?$/);

    if (!match) {
      return value;
    }

    match.shift();
    const groups = match.filter(el => { return !!el });

    switch (groups.length) {
      case 1:
        return parseInt(groups[0]) / 100;
      case 2:
        return parseInt(groups[0]) / 100;
      case 3:
        return parseFloat(value) / 100;
    }

    return value;
  }

  decimal_to_percent(value) {
    value = (value || '').trim();

    if (!value.match(/^\d+\.?\d*$/) && !value.match(/^\.\d+$/)) {
      return '?';
    }

    const parsed = parseFloat(value) * 100;
    const sig_figs = value.replace(/^(0|\.)*/, '').replace(/(0|\.)*$/, '').length;

    return parseFloat(parsed.toPrecision(sig_figs > 0 ? sig_figs : 1));
  }


  get listMethods() {
    try {
      return Object.keys(this.config[this.type]['detection_methods']);
    }
    catch {
      return [];
    }
  }

  get max_alert_conditions() {
      return this.config[this.type]['detection_methods'][this.detection_method]['max_alert_conditions'];
  }

  get type_formatted() {
    switch (this.type) {
    case 'DomainReputationMonitor':
      return 'Domain Reputation';
    case 'IpReputationMonitor':
      return 'IP Reputation';
    case 'UnsubscribeMonitor':
      return 'Unsubscribe';
    }
  }

  get last_triggered_object_name() {
    return this.objectName(this.last_triggered_object);
  }

  objectName(object) {
    if (!object) {
      return;
    }

    switch (this.type) {
    case 'DomainReputationMonitor':
      if (object.verified_domain !== undefined) {
        return object.verified_domain.name;
      }

      return object.name;

    case 'IpReputationMonitor':
      if (object.ip_address !== undefined) {
        return object.ip_address.public_ip;
      }

      return object.name;

    case 'UnsubscribeMonitor':
      if (object.organization_user !== undefined) {
        return object.organization_user.email;
      }
      
      return object.name;
    }
  }

  config = {
    DomainReputationMonitor: {
      default_detection_method: 'change',
      detection_methods: {
        change: {
          default_detection_type: 'blocklist_event',
          max_alert_conditions: 1,
          blocklist_event: {
            default_alert_condition: { value: 'blocked', logic: '?==', trigger_duration: 1800 }
          }
        },
        threshold: {
          default_detection_type: 'bounce_rate',
          max_alert_conditions: 2,
          bounce_rate: {
            default_alert_condition: { value: '0.05', logic: '?>', trigger_duration: 1800 }
          },
          spam_rate: {
            default_alert_condition: { value: '0.001', logic: '?>', trigger_duration: 1800 }
          },
        },
        anomaly: {
          default_detection_type: 'bounce_rate',
          max_alert_conditions: 2,
          bounce_rate: {
            default_alert_condition: { value: '0.5', logic: '?>', trigger_duration: 1800 }
          },
          spam_rate: {
            default_alert_condition: { value: '0.5', logic: '?>', trigger_duration: 1800 }
          },
          send_rate: {
            default_alert_condition: { value: '0.5', logic: '?>', trigger_duration: 1800 }
          }
        }
      },
    },
    IpReputationMonitor: {
      default_detection_method: 'change',
      detection_methods: {
        change: {
          default_detection_type: 'blocklist_event',
          max_alert_conditions: 1,
          blocklist_event: {
            default_alert_condition: { value: 'blocked', logic: '?==', trigger_duration: 1800 }
          }
        },
        threshold: {
          default_detection_type: 'bounce_rate',
          max_alert_conditions: 2,
          bounce_rate: {
            default_alert_condition: { value: '0.05', logic: '?>', trigger_duration: 1800 }
          },
          spam_rate: {
            default_alert_condition: { value: '0.001', logic: '?>', trigger_duration: 1800 }
          },
        },
        anomaly: {
          default_detection_type: 'bounce_rate',
          max_alert_conditions: 2,
          bounce_rate: {
            default_alert_condition: { value: '0.5', logic: '?>', trigger_duration: 1800 }
          },
          spam_rate: {
            default_alert_condition: { value: '0.5', logic: '?>', trigger_duration: 1800 }
          },
          send_rate: {
            default_alert_condition: { value: '0.5', logic: '?>', trigger_duration: 1800 }
          }
        }
      }
    },
    UnsubscribeMonitor: {
      default_detection_method: 'change',
      default_detection_type: 'unsubscribe_event',
      detection_methods: {
        change: {
          default_detection_type: 'unsubscribe_event',
          max_alert_conditions: 1,
          unsubscribe_event: {
            default_alert_condition: { value: 'unsubscribed', logic: '?==', trigger_duration: 1800 }
          }
        },
        anomaly: {
          default_detection_type: 'unsubscribe_rate',
          max_alert_conditions: 2,
          unsubscribe_rate: {
            default_alert_condition: { value: '0.5', logic: '?>', trigger_duration: 1800 }
          }
        }
      }
    }
  };

  @action
  add_default_alert_condition() {
    // observable will copy object + make it observable
    this.alert_conditions.push(observable.object(this.config[this.type]['detection_methods'][this.detection_method][this.detection_type]['default_alert_condition']));
  }

  delete_alert_condition(index) {
    const el = this.alert_conditions[index];
    this.alert_conditions.remove(el);
  }

  update_type(value) {
    this.save_state();

    this.type = value;
    this.detection_method = this.saved_monitor[this.type]['detection_method'];
    this.detection_type = this.saved_monitor[this.type]['detection_types'][this.detection_method];
    this.update_alert_conditions();

    return value;
  }

  update_detection_method(value) {
    this.save_state();

    this.detection_method = value;
    this.detection_type = this.saved_monitor[this.type]['detection_types'][this.detection_method];
    this.update_alert_conditions();

    return value;
  }

  update_detection_type(value) {
    this.save_state();
    this.detection_type = value;
    this.update_alert_conditions();
  }

  update_alert_conditions() {
    this.alert_conditions = this.saved_monitor[this.type]['alert_conditions'][this.detection_method][this.detection_type];
  }

  save_state() {
    if (this.detection_method !== undefined && this.detection_type !== undefined) {
      this.saved_monitor[this.type]['detection_method']                                             = this.detection_method;
      this.saved_monitor[this.type]['detection_types'][this.detection_method]                       = this.detection_type;
      this.saved_monitor[this.type]['alert_conditions'][this.detection_method][this.detection_type] = this.alert_conditions;

      return true;
    }

    return false;
  }

  humanize_detection_type(detection_type) {
    return {
      'blocklist_event': 'Blocklist Event',
      'bounce_rate': 'Bounce Rate',
      'send_rate': 'Send Rate',
      'spam_rate': 'Spam Rate',
      'unsubscribe_event': 'Unsubscribe Event',
      'unsubscribe_rate': 'Unsubscribe Rate'
    }[detection_type];
  }


  @computed
  get loaded() {
    return this.id !== undefined;
  }
}
