import { observable, computed, action } from 'mobx';

export default class Collection {
  @observable objects = [];
  @observable loaded = false;

  get isEmpty() {
    return this.objects.length === 0;
  }
}
