import React, { Component } from 'react';
import classnames from 'classnames';

// Orbs
import Section1 from './Section1';

export default class Show extends Component {
  render() {
    return (
      <React.Fragment>
        <Section1 />
      </React.Fragment>
    );
  }
}
