import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';

import Table from '../../Table';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  ipPoolSize = (row) => {
    return row.ip_addresses.length;
  }

  ipPoolStatus = (row) => {
    const value = row['status'];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    return (
      <span className={classnames(value === 'healthy' ? styles.Healthy : styles.Unhealthy)}>
        {capitalizedValue}
      </span>
    );
  }

  render() {
    const headers = [
      {
        key: 'name',
        value: 'IP Pool',
      },
      {
        key: 'ip_addresses',
        value: 'IP Addresses',
        callback: this.ipPoolSize,
        width: '120',
        className: 'text-center',
        sortable: false,
      },
      {
        key: 'status',
        value: 'Status',
        callback: this.ipPoolStatus,
        width: '150',
        className: 'text-end',
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: '220',
        className: 'text-end',
      }
    ]

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>IP Pools</h3>
        <Table path='/api/v1/settings/ip_pools' hrefBase='/home/settings/ip_pools' hrefKey='id' headers={headers}>
          <div className='text-end'>
            <a href='/home/settings/ip_pools/new'><Button><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button></a>
          </div>
        </Table>
      </div>
    );
  }
}
