import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import MonitorsNew from 'components/Dashboard/Monitors/New';

import Monitor from 'models/Monitor';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    this.monitor = new Monitor({ type: 'DomainReputationMonitor', detection_method: 'change', detection_type: 'blocklist_event' });
    this.monitor.update_alert_conditions();
  }

  render() {
    return (
      <Content>
        <MonitorsNew monitor={this.monitor} />
      </Content>
    );
  }
}
