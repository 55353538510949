import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import UnsubscribeGroupsShow from 'components/Dashboard/Settings/UnsubscribeGroups/Show';

import api from 'models/API';
import UnsubscribeGroup from 'models/UnsubscribeGroup';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.unsubscribeGroup = new UnsubscribeGroup();
    const id = props.match.params.id;

    api.get(`/api/v1/settings/unsubscribe_groups/${id}`).then(data => {
      this.unsubscribeGroup.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <UnsubscribeGroupsShow unsubscribeGroup={this.unsubscribeGroup} />
      </Content>
    );
  }
}
