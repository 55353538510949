import React, { Component } from 'react';
import classnames from 'classnames';

import Delete from 'components/Util/Icons/Delete';

import alertStore from 'models/Alert';
import api from 'models/API';

import styles from 'styles/Dashboard/Base';

export default class MediumDeleteSquareButton extends Component {
  componentDidMount = () => {
    const closeAlert     = this.closeAlert;
    const deleteResource = this.deleteResource;
    const store = this.props.store;

    window.addEventListener('click', function(event) {
      switch (event.target.id) {
        case 'delete_button_cancel':
          closeAlert();
          break;
        case 'delete_button_delete':
          deleteResource(event, store);
          break;
      }
    })
  }

  closeAlert = () => {
    alertStore.setDisplay(false);
  }

  deleteResource = (event, store) => {
    const closeAlert = this.closeAlert;
    const afterDelete = this.props.afterDelete;

    const button = event.target;
    button.disabled = true;

    store?.disableForm();

    api.del(`${this.props.action}`).then(data => {
      button.disabled = false;

      const errors = data !== undefined ? (data['errors'] || []) : [];
      store?.clearErrors();
      store?.clearSuccesses();

      if (errors.length > 0) {
        store?.enableForm();

        if (errors[0].message !== 'not authorized') {
          // add errors
          for (const error of errors) {
            const name    = error['field'];
            const message = error['message'];

            store?.addError(name, message);
            closeAlert();
          }
        }
      }
      else {
        afterDelete(data);
      }
    });
  }

  onClick = (e) => {
    e.preventDefault();

    alertStore.setContents(
      <React.Fragment>
        <h4 className={classnames(styles.h4, 'text-center')}>Delete this resource?</h4>
        <p className={classnames(styles.p2, styles.Tint5, 'mb-2', 'text-center')}>This action cannot be undone.</p>

        <p className={classnames(styles.p2, 'mb-0', 'text-center')}>
          <span
            id='delete_button_cancel'
            className={classnames(styles.p2, 'fw-bold', 'text-decoration-underline', 'cursor-pointer', 'me-2')}>

            Cancel
          </span>

          <button
            id='delete_button_delete'
            style={{ padding: '4px 8px' }}
            className={classnames(styles.LightBackgroundMediumDeleteSquare)}>

            Yes, delete this resource
          </button>
        </p>
      </React.Fragment>
    );

    alertStore.setDisplay(true);
  }

  render() {
    const { afterDelete, className, children, ...params } = this.props;

    return (
      <button
        {...params}
        onClick={this.onClick}
        className={classnames(styles.LightBackgroundMediumDeleteSquare, className)}>

        <Delete />

        {children}
      </button>
    )
  }
}
