import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import SelectM from 'components/Util/Inputs/SelectM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import styles from 'styles/Dashboard/Base';

@observer
export default class Unsubscribe extends Component {
  render() {
    const { subscription, store } = this.props;

    return (
      <div>
        <p className={classnames(styles.p2)}>You are subscribed to the <span className={classnames(styles.ColorPrimaryPurple, 'fst-italic', 'fw-bold')}>{subscription.unsubscribe_group?.name}</span> mailing list. Please let us know why you are unsubscribing so we can improve the emails we send.</p>
        <SelectM
          className={classnames(styles.InputWidth, 'mb-2')}
          store={store}
          placeholder='Unsubscribe Reason'
          name='reason'>

          { subscription.unsubscribe_reasons?.map((reason, index) =>
            <option key={index} value={reason}>{reason}</option>
          )}
        </SelectM>

        <div><Button>Unsubscribe</Button></div>
      </div>
    );
  }
}
