import React, { Component } from 'react';
import { observable } from 'mobx';

import DashboardCollection from 'models/DashboardCollection';

import Content from 'components/Dashboard/Content';
import ShowRoot from 'components/Dashboard/Root/Show';

export default class Show extends Component {
  dashboardCollection = new DashboardCollection(['devops', 'product', 'security']);

  constructor(props) {
    super(props);
    this.dashboardCollection.load();
  }

  render() {
    return (
      <Content>
        <ShowRoot dashboardCollection={this.dashboardCollection} />
      </Content>
    );
  }
}
