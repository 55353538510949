/*
 * Taken from https://github.com/kiruh/ckeditor5-placeholder
 * We are keeping our own version to prevent multiple copies of CKEditor being
 * loaded. This could be removed if ckeditor5-placeholder supports a version of
 * CKEditor compatible with the version we use.
 */

import Placeholder from "./placeholder";

export default Placeholder;
