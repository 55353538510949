import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import TopNav from './Editor/TopNav';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Editor extends Component {
  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeUnload);
  }

  beforeUnload = (e) => {
    const { model, store } = this.props;

    if (!model || !store) {
      return;
    }

    if (model.isStale(store)) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  render() {
    const { model, navMiddle, navRight, editorLeft, editorRight, editorMiddle, className, ...params } = this.props;

    return (
      <div className={classnames(styles.Editor, className)}>
        { model.loaded ? (
          <React.Fragment>
            <TopNav {...params} model={model} middle={navMiddle} right={navRight} />

            <div className='d-flex'>
              { this.props.override ? this.props.children : (
                <React.Fragment>
                  <div id='js-editor-left' className={classnames(styles.Left)}>{editorLeft}</div>
                  <div id='js-editor-middle' className={classnames(styles.Center)}>{editorMiddle}</div>
                  <div id='js-editor-right' className={classnames(styles.Right)}>{editorRight}</div>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className='py-2'>
            <div className={classnames(styles.ColorPrimaryPurple, 'd-block', 'mx-auto', 'spinner-border')} role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
