import React from 'react';
import qs from 'qs';
import classnames from 'classnames';

import alertStore from 'models/Alert';

import styles from 'styles/Dashboard/Base';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

class APIException extends Error {
  constructor(response, text) {
    super('An error occurred while making an API request');
    this.status = response.status;
    this.text = text || response.statusText;
  }
}

class ValidationException extends Error {
  constructor(errors) {
    super('A validation error occurred while making an API request');
    this.errors = errors;
  }
}

class ResourceNotFoundException extends Error {
  constructor(errors) {
    super('The resource was not found while making an API request');
    this.errors = errors;
  }
}

async function checkResponse(response) {
  if (response.status === 204) {
    return;
  }
  else if (response.redirected) {
    return response.url;
  }
  else if (response.status === 401) {
    // unauthenticated (most likely logged out)
    return window.location.reload();
  }
  else if (response.status === 403) {
    // 403 unauthorized (RBAC/lacking permission)

    alertStore.setContents(
      <React.Fragment>
        <h4 className={classnames(styles.h4, 'text-center')}>Authorization Error</h4>
        <p className={classnames(styles.p2, styles.Tint5, 'mb-0', 'text-center')}>Access to this resource is restricted by policies within your organization. Please contact your administrator for more information.</p>
      </React.Fragment>
    );

    alertStore.setDisplay(true);

    // return error json
    return response.json();
  }
  else if (response.status === 404) {
    const json = await response.json();
    throw new ResourceNotFoundException(json.errors);
  }
  else if (response.status === 422) {
    return response.json();
    //const json = await response.json();
    //throw new ValidationException(json.errors);
  }
  else if (response.status < 200 || response.status >= 300) {
    // For any other non-2xx response, throw a new error with the response text
    const text = await response.text();
    throw new APIException(response, text);
  }

  return response.json();
}

export async function get(path, params, options = {}) {
  const headers = { ...DEFAULT_HEADERS, ...options.headers };

  let fullPath = path;
  if (params && Object.entries(params).length > 0) {
    const serializedParams = qs.stringify(params, {
      encodeValuesOnly: true,
      arrayFormat: 'brackets'
    });
    fullPath = `${fullPath}?${serializedParams}`
  }

  const response = await fetch(fullPath, {
    credentials: 'same-origin',
    headers
  });

  return checkResponse(response);
}

export async function post(path, body, options = {}) {
  const headers = { ...DEFAULT_HEADERS, ...options.headers };

  let parsedBody;
  if (body instanceof FormData) {
    delete headers['Content-Type'];
    parsedBody = body;
  } else {
    parsedBody = JSON.stringify(body);
  }

  const response = await fetch(path, {
    body: parsedBody,
    credentials: 'same-origin',
    headers,
    method: 'POST',
  });

  return checkResponse(response);
}

export async function put(path, body, options = {}) {
  const headers = { ...DEFAULT_HEADERS, ...options.headers };

  let parsedBody;
  if (body instanceof FormData) {
    delete headers['Content-Type'];
    parsedBody = body;
  } else {
    parsedBody = JSON.stringify(body);
  }

  const response = await fetch(path, {
    body: parsedBody,
    credentials: 'same-origin',
    headers,
    method: 'PUT',
  });

  return checkResponse(response);
}

export async function del(path, options = {}) {
  const headers = { ...DEFAULT_HEADERS, ...options.headers };

  const response = await fetch(path, {
    credentials: 'same-origin',
    headers,
    method: 'DELETE',
  });

  return checkResponse(response);
}
