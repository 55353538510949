import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { onScroll } from '../../Table/Helpers/Base.js';

import ChevronRight from 'components/Util/Icons/ChevronRight';
import KeyboardArrowDown from 'components/Util/Icons/KeyboardArrowDown';
import Terminal from 'components/Util/Icons/Terminal';
import StackedBarChart from 'components/Util/Icons/StackedBarChart';
import QueryStats from 'components/Util/Icons/ManageSearch';
import Add from 'components/Util/Icons/Add';
import Source from 'components/Util/Icons/Source';

import Tooltip from 'components/Util/Misc/Tooltip';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Queries extends Component {
  jumpRef = React.createRef();

  componentDidMount() {
    const { queries } = this.props;
    const left = document.getElementById('js-editor-left');
    left.addEventListener('scroll', () => onScroll(queries));

    const y = this.jumpRef.current.getBoundingClientRect().y;
    const windowHeight = window.innerHeight;
    if (y > windowHeight / 2) {
      left.scrollTop = y - 48 - 12; // hacky.  y - height of nav + padding
    }
  }

  render() {
    const { queries, sql } = this.props;

    const sortedResults = queries.results.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
    });

    return (
      <div className={classnames(styles.Queries, 'py-2', 'position-relative')} style={{ zIndex: 1 }}>
        <div className='float-end d-inline-block mx-1'>
          <Tooltip content='New' place='left'>
            <a className={classnames(styles.New)} href='/home/analytics/searches/sql/new'><Add /></a>
          </Tooltip>
        </div>
        <p className={classnames(styles.Header, 'px-2')}><Source />Queries</p>
        <ul>
          { sortedResults.map((query, index) =>
            <li key={index} className={classnames(query.id === sql.id && styles.Active)}>
              { sql.id === query.id ? (
                <React.Fragment>
                  <span ref={this.jumpRef}><KeyboardArrowDown /><Terminal />{query.name}</span>
                  <ul>
                    <li onClick={() => sql.setView('data')} className={classnames(sql.isDataView && styles.ActiveView)}><QueryStats />Data</li>
                    <li onClick={() => sql.setView('chart')} className={classnames(sql.isChartView && styles.ActiveView)}><StackedBarChart />Chart</li>
                  </ul>
                </React.Fragment>
              ) : (
                <a href={`/home/analytics/searches/sql/${query.id}`}><ChevronRight /><Terminal />{query.name}</a>
              )}
            </li>
          )}
        </ul>
      </div>
    );
  }
}
