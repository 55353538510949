import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Marketing/Divider';

export default class DividerInverse extends Component {
  render() {
    const { className, ...params } = this.props;

    return (
      <div {...params} className={classnames(styles.ContainerInverse, className)}>

        <svg width="1400" height="491" preserveAspectRatio="none" viewBox="0 0 1400 544" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V397.844L186.836 529.336C198.32 537.418 211.73 542.335 225.716 543.593C239.703 544.85 253.774 542.404 266.516 536.499L1400 11.207V0H0Z" fill={styles.tint7}/>
        </svg>

        <div className={classnames(styles.LeftLight)} />
        <div className={classnames(styles.LeftDark)} />
        <div className={classnames(styles.RightLight)} />
        <div className={classnames(styles.RightDark)} />

      </div>
    );
  }
}
