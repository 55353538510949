import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import DateRange from './DateRange';

import styles from 'styles/Dashboard/Root/TopNav';

@observer
export default class TopNav extends Component {
  updatePersona = (persona) => {
    this.props.dashboardCollection.active = persona;
  }

  render() {
    const { dashboardCollection } = this.props;
    const activePersona = dashboardCollection.active;

    return (
      <div className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'h-100')}>
          <div className='d-flex align-items-center'>
            <button onClick={() => this.updatePersona('devops')} className={classnames(styles.Persona, activePersona === 'devops' ? styles.Active : '')}>DevOps</button>
            <button onClick={() => this.updatePersona('product')} className={classnames(styles.Persona, activePersona === 'product' ? styles.Active : '')}>Product</button>
            <button onClick={() => this.updatePersona('security')} className={classnames(styles.Persona, activePersona === 'security' ? styles.Active : '')}>Security</button>
          </div>

          <div className={classnames(styles.Right, 'ms-auto')}>
            <div className='h-100'>
              <DateRange dashboardCollection={dashboardCollection} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
