import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import IpPoolsNew from 'components/Dashboard/Settings/IpPools/New';

import api from 'models/API';
import IpPool from 'models/IpPool';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    this.ipPool = new IpPool();

    // TODO: FIX TO ENSURE WE GET ALL IP'S
    api.get(`/api/v1/settings/ip_addresses`).then(data => {
      this.ipPool.assign({ id: -1, all_ip_addresses: data });
    })
  }

  render() {
    return (
      <Content>
        <IpPoolsNew ipPool={this.ipPool} />
      </Content>
    );
  }
}
