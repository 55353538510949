import View from '@ckeditor/ckeditor5-ui/src/view';

import LabeledFieldView from '@ckeditor/ckeditor5-ui/src/labeledfield/labeledfieldview';

import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';
import LabeledInputView from '@ckeditor/ckeditor5-ui/src/labeledinput/labeledinputview';

import { createLabeledInputText } from '@ckeditor/ckeditor5-ui/src/labeledfield/utils';

import InputView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import submitHandler from '@ckeditor/ckeditor5-ui/src/bindings/submithandler';

export default class HtmlButtonView extends View {
	constructor(locale) {
		super(locale);

		const bind = this.bindTemplate;

		this.buttonTextView = this._createButtonTextView();
		this.buttonHrefView = this._createButtonHrefView();
		this.buttonAlignView = this._createButtonAlignView();
		this.buttonColorView = this._createButtonColorView();
		this.buttonBackgroundView = this._createButtonBackgroundView();
		this.buttonBorderView = this._createButtonBorderView();
		this.buttonBorderRadiusView = this._createButtonBorderRadiusView();
		this.buttonCustomStylesView = this._createButtonCustomStylesView();

		this.setTemplate({
			tag: 'form',
			attributes: {
				class: [
					'ck',
					'ck-responsive-form'
				],
			},
			children: [
				this.buttonTextView,
        this.buttonHrefView,
        document.createElement('br'),
        this.buttonAlignView,
				this.buttonColorView,
				this.buttonBackgroundView,
        document.createElement('br'),
        this.buttonBorderView,
        this.buttonBorderRadiusView,
        document.createElement('br'),
        this.buttonCustomStylesView,
        document.createElement('br'),
			]
		});
	}

  _createButtonTextView() {
		const t = this.locale.t;

    const input = new LabeledInputView(this.locale, InputTextView);
    input.label = 'Button Text';
    input.inputView.placeholder = 'Text';
    input.inputView.extendTemplate({
      attributes: {
        style: 'width: 100%;'
      }
    });

		return input;
  }

  _createButtonHrefView() {
    const t = this.locale.t;

    const input = new LabeledInputView(this.locale, InputTextView);
    input.extendTemplate({
      attributes: {
        style: 'margin: 0; width: 100%; display: inline-block;'
      }
    });
    input.label = 'Link';
    input.inputView.placeholder = 'Link Href';
    input.inputView.extendTemplate({
      attributes: {
        style: 'min-width: 1px; width: 100%;'
      }
    });

    return input;
  }

  _createButtonAlignView() {
    const t = this.locale.t;

    // LABEL
    const label = new View;
    label.setTemplate({
      tag: 'p',
      children: [{ text: 'Align' }]
    });

    const left = new View;
    left.setTemplate({
      tag: 'option',
      attributes: {
        value: 'left'
      },
      children: [{ text: 'Left' }],
    });

    const center = new View;
    center.setTemplate({
      tag: 'option',
      attributes: {
        value: 'center'
      },
      children: [{ text: 'Center' }],
    });

    const right = new View;
    right.setTemplate({
      tag: 'option',
      attributes: {
        value: 'right'
      },
      children: [{ text: 'Right' }],
    });

    const select = new View;
    select.setTemplate({
      tag: 'select',
      attributes: {
        class: 'w-100 ck ck-input ck-input-text',
      },
      children: [left, center, right]
    });

    const align = new View;
    align.setTemplate({
      tag: 'div',
      attributes: {
        class: 'm-0 amply-ck-button-container'
      },
      children: [
        label,
        select,
      ]
    });

    align.selectView = select;

    return align;
  }

  _createButtonColorView() {
    const t = this.locale.t;

    const input = new LabeledInputView(this.locale, InputTextView);
    input.extendTemplate({
      attributes: {
        style: 'margin: 0; width: 50%; display: inline-block; padding-right:2px;'
      }
    });
    input.label = 'Color';
    input.inputView.placeholder = 'Color';
    input.inputView.extendTemplate({
      attributes: {
        style: 'min-width: 1px; width: 100%;'
      }
    });

    return input;
  }


  _createButtonBackgroundView() {
    const t = this.locale.t;

    const input = new LabeledInputView(this.locale, InputTextView);
    input.extendTemplate({
      attributes: {
        style: 'margin: 0; width: 50%; display: inline-block;padding-left: 2px;'
      }
    });
    input.label = 'Background';
    input.inputView.placeholder = 'Background';
    input.inputView.extendTemplate({
      attributes: {
        style: 'min-width: 1px; width: 100%;'
      }
    });

    return input;
  }

  _createButtonBorderView() {
    const t = this.locale.t;

    const input = new LabeledInputView(this.locale, InputTextView);
    input.extendTemplate({
      attributes: {
        style: 'margin: 0; width: 50%; display: inline-block; padding-right:2px;'
      }
    });
    input.label = 'Border';
    input.inputView.placeholder = 'Border';
    input.inputView.extendTemplate({
      attributes: {
        style: 'min-width: 1px; width: 100%;'
      }
    });

    return input;
  }

  _createButtonBorderRadiusView() {
    const t = this.locale.t;

    const input = new LabeledInputView(this.locale, InputTextView);
    input.extendTemplate({
      attributes: {
        style: 'margin: 0; width: 50%; display: inline-block; padding-left:2px;'
      }
    });
    input.label = 'Border Radius';
    input.inputView.placeholder = 'Border Radius';
    input.inputView.extendTemplate({
      attributes: {
        style: 'min-width: 1px; width: 100%;'
      }
    });

    return input;
  }

  _createButtonCustomStylesView() {
    const t = this.locale.t;

    const input = new LabeledInputView(this.locale, InputTextView);
    input.extendTemplate({
      attributes: {
        style: 'margin: 0; display: inline-block; width: 100%;'
      }
    });
    input.label = 'Custom Styles';
    input.inputView.placeholder = 'Custom Styles';
    input.inputView.extendTemplate({
      attributes: {
        style: 'min-width: 1px; width: 100%;'
      }
    });

    return input;
  }

	render() {
		super.render();
		submitHandler({
			view: this
		});
	}
}
