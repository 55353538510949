import React, { Component } from 'react';
import classnames from 'classnames';

import Code from './Code';

import styles from 'styles/Marketing/Root/Section7';

export default class Section7 extends Component {
  render() {
    const curl = `
curl https://sendamply.com/api/v1/email \\
    -H "Authorization: Bearer YOUR_ACCESS_TOKEN" \\
    -H 'Content-Type: application/json' \\
    --data-binary @- << EOF
    {
      "subject": "Hello, from Amply!",
      "from": {
        "email": "donotreply@sendamply.com",
        "name": "Amply"
      },
      "content": [
        {
          "type": "text/plain",
          "value": "Hi there!"
        }
      ],
      "personalizations": [
        {
          "to": [
            {
              "email": "john.smith@example.com",
              "name": "John Smith"
            }
          ]
        }
      ]
    }
EOF
`.trim();

    const python = `
import amply
import os

# https://github.com/sendamply/amply-python/

amply.set_access_token(os.environ.get('AMPLY_ACCESS_TOKEN'))
amply.email.create({
    'to': 'test@example.com',
    'from': 'test@verifieddomain.com',
    'subject': 'My first Amply email!',
    'text': 'This is easy',
    'html': '<strong>and fun :)</strong>'
})
`.trim();

    const javascript = `
// https://github.com/sendamply/amply-js/

const amply = require ('amply.js');
amply.setAccessToken(process.env.AMPLY_ACCESS_TOKEN);

amply.email.create({
  to: 'test@example.com',
  from: 'test@verifieddomain.com',
  subject: 'My first Amply email!',
  text: 'This is easy',
  html: '<strong>and fun :)</strong>'
}).then(msg => console.log(msg))
.catch(err => console.log(err.response));
`.trim();

    const ruby = `
require 'amply'

# https://github.com/sendamply/amply-ruby/

Amply.set_access_token(ENV['AMPLY_ACCESS_TOKEN'])

Amply::Email.create(to: 'test@example.com',
                    from: 'test@verifieddomain.com',
                    subject: 'My first Amply email!',
                    text: 'This is easy',
                    html: '<strong>and fun :)</strong>')
`.trim();

    const php = `
<?php
# https://github.com/sendamply/amply-php/

$amply = new Amply(getenv('AMPLY_ACCESS_TOKEN'));

$amply->email->create(array(
    'to' => 'test@example.com',
    'from' => 'test@verifieddomain.com',
    'subject' => 'My first Amply email!',
    'text' => 'This is easy',
    'html' => '<strong>and fun :)</strong>'
));
`.trim();
    const go = `
package main
import (
        "fmt"
        "os"
        "github.com/sendamply/amply-go"
)
// https://github.com/sendamply/amply-go/
func main() {
        amply.SetAccessToken(os.Getenv("AMPLY_ACCESS_TOKEN"))

        resp, err := amply.Email.Create(amply.EmailData{
                To: "test@example.com",
                From: "test@verifieddomain.com",
                Subject: "My first Amply email!",
                Text: "This is easy",
                Html: "<strong>and fun :)</strong>",
        })
}
`.trim();

    const code = {
      python: {
        title: 'Python',
        code: python
      },
      javascript: {
        title: 'Javascript',
        code: javascript
      },
      ruby: {
        title: 'Ruby',
        code: ruby
      },
      php: {
        title: 'PHP',
        code: php
      },
      go: {
        title: 'Go',
        code: go
      },
      curl: {
        title: 'cURL',
        code: curl
      },
    };

    return (
      <div className={classnames(styles.Container, 'py-5')}>
        <div className={classnames(styles.MaxWidth, 'text-center', 'mx-auto')}>
          <h3 className={classnames(styles.h3, styles.ColorPrimaryWhite)}>Start sending in minutes.</h3>
          <p className={classnames(styles.p1, styles.Text, styles.ColorPrimaryWhite, 'mx-auto', 'mb-3')}>Amply automates the process of switching to our platform by intelligently load-balancing emails so that reputation isn't impacted.</p>

          <div className='mx-2'>
            <Code code={code} />
          </div>
        </div>
      </div>
    );
  }
}
