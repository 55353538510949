import { observable, computed, action } from 'mobx';

import FormElement from './FormElement';

export default class Form {
  @observable formElements = [];
  @observable disabled = false;

  get(name) {
    for (const formElement of this.formElements) {
      if (formElement.name?.replace('[]', '') == name) {
        return formElement;
      }
    }

    return undefined;
  }

  getRef(name) {
    return this.get(name)?.ref || undefined;
  }

  @computed
  get isDisabled() {
    return this.disabled;
  }

  get hasErrors() {
    let hasErrors = false;

    this.formElements.forEach((element) => {
      if (element.hasError) {
        hasErrors = true;
      }
    });

    return hasErrors;
  }

  @action
  addFormElement(args) {
    const formElement = new FormElement(args);

    // delete non array elements of the same name
    for (const [index, element] of Object.entries(this.formElements)) {
      if (element.name === args?.name && !args?.name?.match(/\[\]$/)) {
        this.formElements.splice(index, 1);
      }
    }

    this.formElements.push(formElement);

    return formElement;
  }

  clearErrors() {
    this.formElements.forEach((element) => {
      element.setError(undefined);
    });
  }

  clearSuccesses() {
    this.formElements.forEach((element) => {
      element.setSuccess(undefined);
    });
  }

  addError(name, message) {
    this.get(name)?.setError(message);
  }

  addSuccess(name, message) {
    this.get(name)?.setSuccess(message);
  }

  disableForm() {
    this.disabled = true;
  }

  enableForm() {
    this.disabled = false;
  }
}
