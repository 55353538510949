import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Tick from 'components/Util/Icons/Tick';

import BaseInput from './BaseInput';

import styles from 'styles/Base';

@observer
export default class CheckboxL extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
       <label className={classnames(styles.Checkbox, className)}>
        <BaseInput type='checkbox' {...this.props}>
          <span></span>
          <Tick color={styles.colorPrimaryWhite} />
          <p>{children}</p>
        </BaseInput>
      </label>
    );
  }
}
