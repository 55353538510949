import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Change from './DetectionTypes/Change';
import Threshold from './DetectionTypes/Threshold';
import Anomaly from './DetectionTypes/Anomaly';

@observer
export default class DetectionTypes extends Component {
  render() {
    const { monitor, store } = { ...this.props }

    return (
      <React.Fragment>
        { monitor.detection_method === 'change' && <Change monitor={monitor} store={store} /> }
        { monitor.detection_method === 'threshold' && <Threshold monitor={monitor} store={store} /> }
        { monitor.detection_method === 'anomaly' && <Anomaly monitor={monitor} store={store} /> }
      </React.Fragment>
    );
  }
}
