import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Share from 'components/Util/Icons/Share';
import Fingerprint from 'components/Util/Icons/Fingerprint';
import Favorite from 'components/Util/Icons/Favorite';
import Cloud from 'components/Util/Icons/Cloud';

import Config from './Config';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  afterDelete = () => {
    window.location = '/home/settings/ip_pools';
  }

  ipAddressList = () => {
    const { ipPool } = this.props;
    const links = [];

    if (!ipPool.loaded) {
      return;
    }

    if (ipPool.ip_addresses.length == 0) {
      return 'N/A';
    }

    ipPool.ip_addresses.forEach((ip_address, index) => {
      links.push(<a key={index} href={`/home/settings/ip_addresses/${ip_address.id}`}>{ip_address.public_ip}</a>);
    })

    return links.reduce((prev, curr) => [prev, ', ', curr]);
  }

  render() {
    const { ipPool } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/ip_pools' className='me-1'>
            <BackButton />
          </a>

          Update IP Pool

          <Delete action={`/api/v1/settings/ip_pools/${ipPool.id}`} store={this.store} afterDelete={this.afterDelete} className='float-end mb-2'>Delete</Delete>
        </h3>

        <LoadableCard className='mb-2' loadState={ipPool.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>IP Pool</h5>

          <Attribute icon={Share} name='Name' value={ipPool.name} className='mb-1' />
          <Attribute icon={Fingerprint} name='UUID' value={ipPool.uuid} className='mb-1' />
          <Attribute icon={Favorite} name='Status' value={ipPool.statusFormatted} className='mb-1' />
          <Attribute icon={Cloud} name='IP Addresses' value={this.ipAddressList()} className='mb-1' />
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={ipPool.loaded}>
          <Config method='put' action={`/api/v1/settings/ip_pools/${ipPool.id}`} store={this.store} ipPool={ipPool} />
        </LoadableCard>
      </div>
    );
  }
}
