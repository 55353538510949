import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Scale extends Component {
  render() {
    const src = `
<svg width="150" height="151" viewBox="0 0 150 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Asset 33 1" clip-path="url(#clip0_124_669)">
<g id="ICONS">
<g id="Group">
<path id="Vector" d="M74.75 85.2C80.2728 85.2 84.75 80.7228 84.75 75.2C84.75 69.6771 80.2728 65.2 74.75 65.2C69.2272 65.2 64.75 69.6771 64.75 75.2C64.75 80.7228 69.2272 85.2 74.75 85.2Z" fill="url(#paint0_linear_124_669)"/>
<path id="Vector_2" d="M74.75 5C76.1307 5 77.25 3.88071 77.25 2.5C77.25 1.11929 76.1307 0 74.75 0C73.3693 0 72.25 1.11929 72.25 2.5C72.25 3.88071 73.3693 5 74.75 5Z" fill="url(#paint1_linear_124_669)"/>
<path id="Vector_3" d="M74.75 150.4C76.1307 150.4 77.25 149.281 77.25 147.9C77.25 146.519 76.1307 145.4 74.75 145.4C73.3693 145.4 72.25 146.519 72.25 147.9C72.25 149.281 73.3693 150.4 74.75 150.4Z" fill="url(#paint2_linear_124_669)"/>
<path id="Vector_4" d="M74.75 55.12C78.8921 55.12 82.25 51.7621 82.25 47.62C82.25 43.4779 78.8921 40.12 74.75 40.12C70.6079 40.12 67.25 43.4779 67.25 47.62C67.25 51.7621 70.6079 55.12 74.75 55.12Z" fill="url(#paint3_linear_124_669)"/>
<path id="Vector_5" d="M74.75 31.66C78.8921 31.66 82.25 28.3021 82.25 24.16C82.25 20.0179 78.8921 16.66 74.75 16.66C70.6079 16.66 67.25 20.0179 67.25 24.16C67.25 28.3021 70.6079 31.66 74.75 31.66Z" fill="url(#paint4_linear_124_669)"/>
<path id="Vector_6" d="M74.75 132.93C78.8921 132.93 82.25 129.572 82.25 125.43C82.25 121.288 78.8921 117.93 74.75 117.93C70.6079 117.93 67.25 121.288 67.25 125.43C67.25 129.572 70.6079 132.93 74.75 132.93Z" fill="url(#paint5_linear_124_669)"/>
<path id="Vector_7" d="M74.75 109.47C78.8921 109.47 82.25 106.112 82.25 101.97C82.25 97.8279 78.8921 94.47 74.75 94.47C70.6079 94.47 67.25 97.8279 67.25 101.97C67.25 106.112 70.6079 109.47 74.75 109.47Z" fill="url(#paint6_linear_124_669)"/>
<path id="Vector_8" d="M102.93 82.7C107.072 82.7 110.43 79.3421 110.43 75.2C110.43 71.0579 107.072 67.7 102.93 67.7C98.7879 67.7 95.43 71.0579 95.43 75.2C95.43 79.3421 98.7879 82.7 102.93 82.7Z" fill="url(#paint7_linear_124_669)"/>
<path id="Vector_9" d="M102.93 4.5C104.035 4.5 104.93 3.60457 104.93 2.5C104.93 1.39543 104.035 0.5 102.93 0.5C101.825 0.5 100.93 1.39543 100.93 2.5C100.93 3.60457 101.825 4.5 102.93 4.5Z" fill="url(#paint8_linear_124_669)"/>
<path id="Vector_10" d="M102.93 150.2C105.139 150.2 106.93 148.409 106.93 146.2C106.93 143.991 105.139 142.2 102.93 142.2C100.721 142.2 98.93 143.991 98.93 146.2C98.93 148.409 100.721 150.2 102.93 150.2Z" fill="url(#paint9_linear_124_669)"/>
<path id="Vector_11" d="M102.93 52.62C105.691 52.62 107.93 50.3814 107.93 47.62C107.93 44.8586 105.691 42.62 102.93 42.62C100.169 42.62 97.93 44.8586 97.93 47.62C97.93 50.3814 100.169 52.62 102.93 52.62Z" fill="url(#paint10_linear_124_669)"/>
<path id="Vector_12" d="M102.93 28.16C105.139 28.16 106.93 26.3691 106.93 24.16C106.93 21.9509 105.139 20.16 102.93 20.16C100.721 20.16 98.93 21.9509 98.93 24.16C98.93 26.3691 100.721 28.16 102.93 28.16Z" fill="url(#paint11_linear_124_669)"/>
<path id="Vector_13" d="M102.93 129.43C105.139 129.43 106.93 127.639 106.93 125.43C106.93 123.221 105.139 121.43 102.93 121.43C100.721 121.43 98.93 123.221 98.93 125.43C98.93 127.639 100.721 129.43 102.93 129.43Z" fill="url(#paint12_linear_124_669)"/>
<path id="Vector_14" d="M102.93 106.97C105.691 106.97 107.93 104.731 107.93 101.97C107.93 99.2086 105.691 96.97 102.93 96.97C100.169 96.97 97.93 99.2086 97.93 101.97C97.93 104.731 100.169 106.97 102.93 106.97Z" fill="url(#paint13_linear_124_669)"/>
<path id="Vector_15" d="M128.84 80.2C131.601 80.2 133.84 77.9614 133.84 75.2C133.84 72.4386 131.601 70.2 128.84 70.2C126.079 70.2 123.84 72.4386 123.84 75.2C123.84 77.9614 126.079 80.2 128.84 80.2Z" fill="url(#paint14_linear_124_669)"/>
<path id="Vector_16" d="M128.84 3.5C129.392 3.5 129.84 3.05228 129.84 2.5C129.84 1.94772 129.392 1.5 128.84 1.5C128.288 1.5 127.84 1.94772 127.84 2.5C127.84 3.05228 128.288 3.5 128.84 3.5Z" fill="url(#paint15_linear_124_669)"/>
<path id="Vector_17" d="M128.84 150.2C131.049 150.2 132.84 148.409 132.84 146.2C132.84 143.991 131.049 142.2 128.84 142.2C126.631 142.2 124.84 143.991 124.84 146.2C124.84 148.409 126.631 150.2 128.84 150.2Z" fill="url(#paint16_linear_124_669)"/>
<path id="Vector_18" d="M128.84 51.62C131.049 51.62 132.84 49.8291 132.84 47.62C132.84 45.4109 131.049 43.62 128.84 43.62C126.631 43.62 124.84 45.4109 124.84 47.62C124.84 49.8291 126.631 51.62 128.84 51.62Z" fill="url(#paint17_linear_124_669)"/>
<path id="Vector_19" d="M128.84 26.66C130.221 26.66 131.34 25.5407 131.34 24.16C131.34 22.7793 130.221 21.66 128.84 21.66C127.459 21.66 126.34 22.7793 126.34 24.16C126.34 25.5407 127.459 26.66 128.84 26.66Z" fill="url(#paint18_linear_124_669)"/>
<path id="Vector_20" d="M128.84 127.93C130.221 127.93 131.34 126.811 131.34 125.43C131.34 124.049 130.221 122.93 128.84 122.93C127.459 122.93 126.34 124.049 126.34 125.43C126.34 126.811 127.459 127.93 128.84 127.93Z" fill="url(#paint19_linear_124_669)"/>
<path id="Vector_21" d="M128.84 105.97C131.049 105.97 132.84 104.179 132.84 101.97C132.84 99.7609 131.049 97.97 128.84 97.97C126.631 97.97 124.84 99.7609 124.84 101.97C124.84 104.179 126.631 105.97 128.84 105.97Z" fill="url(#paint20_linear_124_669)"/>
<path id="Vector_22" d="M147.25 77.7C148.631 77.7 149.75 76.5807 149.75 75.2C149.75 73.8193 148.631 72.7 147.25 72.7C145.869 72.7 144.75 73.8193 144.75 75.2C144.75 76.5807 145.869 77.7 147.25 77.7Z" fill="url(#paint21_linear_124_669)"/>
<path id="Vector_23" d="M147.25 49.62C148.355 49.62 149.25 48.7246 149.25 47.62C149.25 46.5154 148.355 45.62 147.25 45.62C146.145 45.62 145.25 46.5154 145.25 47.62C145.25 48.7246 146.145 49.62 147.25 49.62Z" fill="url(#paint22_linear_124_669)"/>
<path id="Vector_24" d="M147.25 25.16C147.802 25.16 148.25 24.7123 148.25 24.16C148.25 23.6077 147.802 23.16 147.25 23.16C146.698 23.16 146.25 23.6077 146.25 24.16C146.25 24.7123 146.698 25.16 147.25 25.16Z" fill="url(#paint23_linear_124_669)"/>
<path id="Vector_25" d="M147.25 126.43C147.802 126.43 148.25 125.982 148.25 125.43C148.25 124.878 147.802 124.43 147.25 124.43C146.698 124.43 146.25 124.878 146.25 125.43C146.25 125.982 146.698 126.43 147.25 126.43Z" fill="url(#paint24_linear_124_669)"/>
<path id="Vector_26" d="M147.25 103.97C148.355 103.97 149.25 103.075 149.25 101.97C149.25 100.865 148.355 99.97 147.25 99.97C146.145 99.97 145.25 100.865 145.25 101.97C145.25 103.075 146.145 103.97 147.25 103.97Z" fill="url(#paint25_linear_124_669)"/>
<g id="Group_2">
<path id="Vector_27" d="M46.82 83C50.9621 83 54.32 79.6421 54.32 75.5C54.32 71.3579 50.9621 68 46.82 68C42.6779 68 39.32 71.3579 39.32 75.5C39.32 79.6421 42.6779 83 46.82 83Z" fill="url(#paint26_linear_124_669)"/>
<path id="Vector_28" d="M46.82 150.2C47.9246 150.2 48.82 149.305 48.82 148.2C48.82 147.095 47.9246 146.2 46.82 146.2C45.7154 146.2 44.82 147.095 44.82 148.2C44.82 149.305 45.7154 150.2 46.82 150.2Z" fill="url(#paint27_linear_124_669)"/>
<path id="Vector_29" d="M46.82 8.5C49.0291 8.5 50.82 6.70914 50.82 4.5C50.82 2.29086 49.0291 0.5 46.82 0.5C44.6109 0.5 42.82 2.29086 42.82 4.5C42.82 6.70914 44.6109 8.5 46.82 8.5Z" fill="url(#paint28_linear_124_669)"/>
<path id="Vector_30" d="M46.82 108.08C49.5814 108.08 51.82 105.841 51.82 103.08C51.82 100.319 49.5814 98.08 46.82 98.08C44.0586 98.08 41.82 100.319 41.82 103.08C41.82 105.841 44.0586 108.08 46.82 108.08Z" fill="url(#paint29_linear_124_669)"/>
<path id="Vector_31" d="M46.82 130.54C49.0291 130.54 50.82 128.749 50.82 126.54C50.82 124.331 49.0291 122.54 46.82 122.54C44.6109 122.54 42.82 124.331 42.82 126.54C42.82 128.749 44.6109 130.54 46.82 130.54Z" fill="url(#paint30_linear_124_669)"/>
<path id="Vector_32" d="M46.82 29.27C49.0291 29.27 50.82 27.4791 50.82 25.27C50.82 23.0609 49.0291 21.27 46.82 21.27C44.6109 21.27 42.82 23.0609 42.82 25.27C42.82 27.4791 44.6109 29.27 46.82 29.27Z" fill="url(#paint31_linear_124_669)"/>
<path id="Vector_33" d="M46.82 53.73C49.5814 53.73 51.82 51.4914 51.82 48.73C51.82 45.9686 49.5814 43.73 46.82 43.73C44.0586 43.73 41.82 45.9686 41.82 48.73C41.82 51.4914 44.0586 53.73 46.82 53.73Z" fill="url(#paint32_linear_124_669)"/>
<path id="Vector_34" d="M20.91 80.5C23.6714 80.5 25.91 78.2614 25.91 75.5C25.91 72.7386 23.6714 70.5 20.91 70.5C18.1486 70.5 15.91 72.7386 15.91 75.5C15.91 78.2614 18.1486 80.5 20.91 80.5Z" fill="url(#paint33_linear_124_669)"/>
<path id="Vector_35" d="M20.91 149.2C21.4623 149.2 21.91 148.752 21.91 148.2C21.91 147.648 21.4623 147.2 20.91 147.2C20.3577 147.2 19.91 147.648 19.91 148.2C19.91 148.752 20.3577 149.2 20.91 149.2Z" fill="url(#paint34_linear_124_669)"/>
<path id="Vector_36" d="M20.91 8.5C23.1191 8.5 24.91 6.70914 24.91 4.5C24.91 2.29086 23.1191 0.5 20.91 0.5C18.7009 0.5 16.91 2.29086 16.91 4.5C16.91 6.70914 18.7009 8.5 20.91 8.5Z" fill="url(#paint35_linear_124_669)"/>
<path id="Vector_37" d="M20.91 107.08C23.1191 107.08 24.91 105.289 24.91 103.08C24.91 100.871 23.1191 99.08 20.91 99.08C18.7009 99.08 16.91 100.871 16.91 103.08C16.91 105.289 18.7009 107.08 20.91 107.08Z" fill="url(#paint36_linear_124_669)"/>
<path id="Vector_38" d="M20.91 129.04C22.2907 129.04 23.41 127.921 23.41 126.54C23.41 125.159 22.2907 124.04 20.91 124.04C19.5293 124.04 18.41 125.159 18.41 126.54C18.41 127.921 19.5293 129.04 20.91 129.04Z" fill="url(#paint37_linear_124_669)"/>
<path id="Vector_39" d="M20.91 27.77C22.2907 27.77 23.41 26.6507 23.41 25.27C23.41 23.8893 22.2907 22.77 20.91 22.77C19.5293 22.77 18.41 23.8893 18.41 25.27C18.41 26.6507 19.5293 27.77 20.91 27.77Z" fill="url(#paint38_linear_124_669)"/>
<path id="Vector_40" d="M20.91 52.73C23.1191 52.73 24.91 50.9391 24.91 48.73C24.91 46.5209 23.1191 44.73 20.91 44.73C18.7009 44.73 16.91 46.5209 16.91 48.73C16.91 50.9391 18.7009 52.73 20.91 52.73Z" fill="url(#paint39_linear_124_669)"/>
<path id="Vector_41" d="M2.5 78C3.88071 78 5 76.8807 5 75.5C5 74.1193 3.88071 73 2.5 73C1.11929 73 0 74.1193 0 75.5C0 76.8807 1.11929 78 2.5 78Z" fill="url(#paint40_linear_124_669)"/>
<path id="Vector_42" d="M2.5 105.08C3.60457 105.08 4.5 104.185 4.5 103.08C4.5 101.975 3.60457 101.08 2.5 101.08C1.39543 101.08 0.5 101.975 0.5 103.08C0.5 104.185 1.39543 105.08 2.5 105.08Z" fill="url(#paint41_linear_124_669)"/>
<path id="Vector_43" d="M2.5 127.54C3.05228 127.54 3.5 127.092 3.5 126.54C3.5 125.988 3.05228 125.54 2.5 125.54C1.94772 125.54 1.5 125.988 1.5 126.54C1.5 127.092 1.94772 127.54 2.5 127.54Z" fill="url(#paint42_linear_124_669)"/>
<path id="Vector_44" d="M2.5 26.27C3.05228 26.27 3.5 25.8223 3.5 25.27C3.5 24.7177 3.05228 24.27 2.5 24.27C1.94772 24.27 1.5 24.7177 1.5 25.27C1.5 25.8223 1.94772 26.27 2.5 26.27Z" fill="url(#paint43_linear_124_669)"/>
<path id="Vector_45" d="M2.5 50.73C3.60457 50.73 4.5 49.8346 4.5 48.73C4.5 47.6254 3.60457 46.73 2.5 46.73C1.39543 46.73 0.5 47.6254 0.5 48.73C0.5 49.8346 1.39543 50.73 2.5 50.73Z" fill="url(#paint44_linear_124_669)"/>
</g>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_124_669" x1="64.75" y1="75.2" x2="84.75" y2="75.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint1_linear_124_669" x1="72.25" y1="2.5" x2="77.25" y2="2.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint2_linear_124_669" x1="72.25" y1="147.9" x2="77.25" y2="147.9" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint3_linear_124_669" x1="67.25" y1="47.62" x2="82.25" y2="47.62" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint4_linear_124_669" x1="67.25" y1="24.16" x2="82.25" y2="24.16" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint5_linear_124_669" x1="67.25" y1="125.43" x2="82.25" y2="125.43" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint6_linear_124_669" x1="67.25" y1="101.97" x2="82.25" y2="101.97" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint7_linear_124_669" x1="95.43" y1="-2.86102e-06" x2="110.43" y2="-2.86102e-06" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint8_linear_124_669" x1="100.93" y1="2.5" x2="104.93" y2="2.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint9_linear_124_669" x1="98.93" y1="146.2" x2="106.93" y2="146.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint10_linear_124_669" x1="97.93" y1="47.62" x2="107.93" y2="47.62" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint11_linear_124_669" x1="98.93" y1="24.16" x2="106.93" y2="24.16" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint12_linear_124_669" x1="98.93" y1="125.43" x2="106.93" y2="125.43" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint13_linear_124_669" x1="97.93" y1="101.97" x2="107.93" y2="101.97" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint14_linear_124_669" x1="123.84" y1="75.2" x2="133.84" y2="75.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint15_linear_124_669" x1="127.84" y1="2.5" x2="129.84" y2="2.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint16_linear_124_669" x1="124.84" y1="146.2" x2="132.84" y2="146.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint17_linear_124_669" x1="124.84" y1="47.62" x2="132.84" y2="47.62" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint18_linear_124_669" x1="126.34" y1="24.16" x2="131.34" y2="24.16" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint19_linear_124_669" x1="126.34" y1="125.43" x2="131.34" y2="125.43" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint20_linear_124_669" x1="124.84" y1="101.97" x2="132.84" y2="101.97" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint21_linear_124_669" x1="144.75" y1="-2.86102e-06" x2="149.75" y2="-2.86102e-06" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint22_linear_124_669" x1="145.25" y1="47.62" x2="149.25" y2="47.62" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint23_linear_124_669" x1="146.25" y1="24.16" x2="148.25" y2="24.16" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint24_linear_124_669" x1="146.25" y1="125.43" x2="148.25" y2="125.43" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint25_linear_124_669" x1="145.25" y1="101.97" x2="149.25" y2="101.97" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint26_linear_124_669" x1="54.32" y1="75.5" x2="39.32" y2="75.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint27_linear_124_669" x1="48.82" y1="148.19" x2="44.82" y2="148.19" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint28_linear_124_669" x1="50.82" y1="4.5" x2="42.82" y2="4.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint29_linear_124_669" x1="51.82" y1="103.07" x2="41.82" y2="103.07" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint30_linear_124_669" x1="50.82" y1="126.53" x2="42.82" y2="126.53" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint31_linear_124_669" x1="50.82" y1="25.26" x2="42.82" y2="25.26" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint32_linear_124_669" x1="51.82" y1="48.73" x2="41.82" y2="48.73" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint33_linear_124_669" x1="25.91" y1="75.5" x2="15.91" y2="75.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint34_linear_124_669" x1="21.91" y1="148.19" x2="19.91" y2="148.19" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint35_linear_124_669" x1="24.91" y1="4.5" x2="16.91" y2="4.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint36_linear_124_669" x1="24.91" y1="103.07" x2="16.91" y2="103.07" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint37_linear_124_669" x1="23.41" y1="126.53" x2="18.41" y2="126.53" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint38_linear_124_669" x1="23.41" y1="25.26" x2="18.41" y2="25.26" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint39_linear_124_669" x1="24.91" y1="48.73" x2="16.91" y2="48.73" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint40_linear_124_669" x1="5" y1="75.5" x2="0" y2="75.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint41_linear_124_669" x1="4.5" y1="103.07" x2="0.5" y2="103.07" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint42_linear_124_669" x1="3.5" y1="126.53" x2="1.5" y2="126.53" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint43_linear_124_669" x1="3.5" y1="25.26" x2="1.5" y2="25.26" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint44_linear_124_669" x1="4.5" y1="48.73" x2="0.5" y2="48.73" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<clipPath id="clip0_124_669">
<rect width="149.75" height="150.4" fill="white"/>
</clipPath>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
