import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Retention extends Component {
  render() {
    const src = `
<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Asset 32 1" clip-path="url(#clip0_124_718)">
<g id="ICONS">
<g id="Group">
<path id="Vector" d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z" fill="url(#paint0_linear_124_718)"/>
<path id="Vector_2" d="M142.5 15C146.642 15 150 11.6421 150 7.5C150 3.35786 146.642 0 142.5 0C138.358 0 135 3.35786 135 7.5C135 11.6421 138.358 15 142.5 15Z" fill="url(#paint1_linear_124_718)"/>
<path id="Vector_3" d="M74.97 9.96C77.1791 9.96 78.97 8.16914 78.97 5.96C78.97 3.75086 77.1791 1.96 74.97 1.96C72.7609 1.96 70.97 3.75086 70.97 5.96C70.97 8.16914 72.7609 9.96 74.97 9.96Z" fill="url(#paint2_linear_124_718)"/>
<path id="Vector_4" d="M7.5 150C11.6421 150 15 146.642 15 142.5C15 138.358 11.6421 135 7.5 135C3.35786 135 0 138.358 0 142.5C0 146.642 3.35786 150 7.5 150Z" fill="url(#paint3_linear_124_718)"/>
<path id="Vector_5" d="M142.5 150C146.642 150 150 146.642 150 142.5C150 138.358 146.642 135 142.5 135C138.358 135 135 138.358 135 142.5C135 146.642 138.358 150 142.5 150Z" fill="url(#paint4_linear_124_718)"/>
<path id="Vector_6" d="M74.97 146.5C77.1791 146.5 78.97 144.709 78.97 142.5C78.97 140.291 77.1791 138.5 74.97 138.5C72.7609 138.5 70.97 140.291 70.97 142.5C70.97 144.709 72.7609 146.5 74.97 146.5Z" fill="url(#paint5_linear_124_718)"/>
<path id="Vector_7" d="M143.24 78.23C145.449 78.23 147.24 76.4391 147.24 74.23C147.24 72.0209 145.449 70.23 143.24 70.23C141.031 70.23 139.24 72.0209 139.24 74.23C139.24 76.4391 141.031 78.23 143.24 78.23Z" fill="url(#paint6_linear_124_718)"/>
<path id="Vector_8" d="M6.7 78.23C8.90914 78.23 10.7 76.4391 10.7 74.23C10.7 72.0209 8.90914 70.23 6.7 70.23C4.49086 70.23 2.7 72.0209 2.7 74.23C2.7 76.4391 4.49086 78.23 6.7 78.23Z" fill="url(#paint7_linear_124_718)"/>
<path id="Vector_9" d="M52.96 99.51C54.37 96.23 56.62 93.19 59.71 90.4C62.79 87.62 67.39 84.25 73.5 80.28C76.85 78.13 79.6 76.19 81.75 74.45C83.9 72.71 85.74 70.62 87.29 68.21C88.83 65.79 89.6 63.11 89.6 60.16C89.6 55.93 88.27 52.61 85.62 50.19C82.97 47.77 79.5 46.57 75.2 46.57C70.64 46.57 67.01 47.76 64.33 50.14C61.65 52.52 60.17 55.87 59.9 60.16H49.83C50.1 53.45 52.46 48.08 56.93 44.06C61.39 40.03 67.48 38.02 75.2 38.02C80.23 38.02 84.6 38.98 88.29 40.9C91.98 42.81 94.8 45.39 96.75 48.65C98.7 51.9 99.67 55.58 99.67 59.67C99.67 63.5 99.07 66.84 97.86 69.69C96.65 72.54 94.77 75.21 92.22 77.69C89.67 80.17 86.11 82.89 81.55 85.84L76.61 88.96C73.46 90.97 71.08 92.56 69.47 93.74C67.86 94.91 66.43 96.25 65.19 97.76C63.95 99.28 63.06 101 62.52 102.94H100.16V112H50.84C50.84 106.97 51.54 102.81 52.95 99.52L52.96 99.51Z" fill="url(#paint8_linear_124_718)"/>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_124_718" x1="0" y1="7.5" x2="15" y2="7.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint1_linear_124_718" x1="135" y1="0" x2="150" y2="0" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint2_linear_124_718" x1="72.1467" y1="3.12743" x2="77.8036" y2="8.78429" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint3_linear_124_718" x1="0" y1="142.5" x2="1" y2="142.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint4_linear_124_718" x1="135" y1="142.5" x2="150" y2="142.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint5_linear_124_718" x1="72.1467" y1="139.67" x2="77.8036" y2="145.327" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint6_linear_124_718" x1="146.065" y1="71.3995" x2="140.408" y2="77.0563" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint7_linear_124_718" x1="9.53006" y1="71.4065" x2="3.87321" y2="77.0634" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint8_linear_124_718" x1="49.84" y1="75" x2="100.16" y2="75" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<clipPath id="clip0_124_718">
<rect width="150" height="150" fill="white"/>
</clipPath>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
