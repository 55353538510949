import React, { Component } from 'react';

import Widget from 'models/Widget';

import ShowWidget from 'components/Visualizations/Widgets/Show';

export default class Show extends Component {
  widget = new Widget(widget);

  render() {
    return (
      <ShowWidget widget={this.widget}/>
    );
  }
}
