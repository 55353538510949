import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import moment from 'moment-timezone';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';
import SecondaryButton from 'components/Util/Buttons/LightBackground/MediumSecondarySquareButton';

import Share from 'components/Util/Icons/Share';
import Fingerprint from 'components/Util/Icons/Fingerprint';
import Favorite from 'components/Util/Icons/Favorite';
import Person from 'components/Util/Icons/Person';
import Schedule from 'components/Util/Icons/Schedule';

import Config from './Config';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  afterDelete = () => {
    window.location = '/home/settings/unsubscribe_groups';
  }

  last = (iso8601) => {
    if (iso8601 === undefined) {
      return 'N/A';
    }
    const time = moment(iso8601);
    const tz = moment.tz.guess();

    return time.tz(tz).format('MM/DD/YYYY h:mm A z');
  }

  render() {
    const { unsubscribeGroup } = this.props;
    const totalUsersFormatted = unsubscribeGroup.total_users?.toLocaleString();
    const quarantinedUsersFormatted = unsubscribeGroup.quarantine?.total_users?.toLocaleString();

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/unsubscribe_groups' className='me-1'>
            <BackButton />
          </a>

          Update Unsubscribe Group

          <div className='d-inline-block float-end'>
            { unsubscribeGroup.loaded && (
              <React.Fragment>
                { unsubscribeGroup.isGlobalUnsubscribe && unsubscribeGroup.isQuarantineEnabled && (
                  <a href={`/home/settings/unsubscribe_groups/${unsubscribeGroup.id}/quarantined_users`}><SecondaryButton className='me-1 mb-2'>Quarantined Users</SecondaryButton></a>
                )}

                <a href={`/home/settings/unsubscribe_groups/${unsubscribeGroup.id}/users`}><SecondaryButton className='me-1 mb-2'>Unsubscribed Users</SecondaryButton></a>

                { !unsubscribeGroup.isGlobalUnsubscribe && (
                  <Delete action={`/api/v1/settings/unsubscribe_groups/${unsubscribeGroup.id}`} store={this.store} afterDelete={this.afterDelete} className='mb-2'>Delete</Delete>
                )}
              </React.Fragment>
            )}
          </div>
        </h3>

        <LoadableCard className='mb-2' loadState={unsubscribeGroup.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Unsubscribe Group</h5>

          <Attribute icon={Share} name='Name' value={unsubscribeGroup.name} className='mb-1' />
          <Attribute icon={Fingerprint} name='UUID' value={unsubscribeGroup.uuid} className='mb-1' />
          <Attribute icon={Person} name='Unsubscribed Users' value={totalUsersFormatted} className='mb-1' />
          <Attribute icon={Schedule} name='Last Unsubscribed' value={this.last(unsubscribeGroup.last_unsubscribed_at)} className='mb-1' />

          { unsubscribeGroup.isGlobalUnsubscribe && unsubscribeGroup.isQuarantineEnabled && (
            <React.Fragment>
              <Attribute icon={Person} name='Quarantined Users' value={quarantinedUsersFormatted} className='mb-1' />
              <Attribute icon={Schedule} name='Last Quarantined' value={this.last(unsubscribeGroup.quarantine.last_quarantined_at)} className='mb-1' />
            </React.Fragment>
          )}
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={unsubscribeGroup.loaded}>
          <Config method='put' action={`/api/v1/settings/unsubscribe_groups/${unsubscribeGroup.id}`} store={this.store} unsubscribeGroup={unsubscribeGroup} />
        </LoadableCard>
      </div>
    );
  }
}
