import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from "mobx-react"
import classnames from 'classnames';

import Email from 'components/Util/Icons/Dialogs';

import Background from './Elements/Background';
import Padding from './Elements/Padding';
import CustomStyles from './Elements/CustomStyles';
import Spacer from './Elements/Spacer';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Base extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired, // TODO use shape
  }

  Icon() {
    return <Email />;
  }

  renderForm = () => {
    const { node } = this.props;

    return (
      <React.Fragment>
        <Background node={node} />
        <Spacer />
        <Padding node={node} />
        <Spacer />
        <div className='mb-2'>
          <CustomStyles node={node} />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { node } = this.props;

    return (
      <React.Fragment>
        <p className={classnames(styles.Header, styles.ColorPrimaryPurple, 'mx-2', 'mt-2')}>
          <this.Icon />
          {node.title}
        </p>

        <div className={classnames(styles.Config, 'mx-2')}>
          {this.renderForm()}
        </div>
      </React.Fragment>
    );
  }
}
