import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LineBar from 'components/Visualizations/Widgets/Charts/LineBar';

import styles from 'styles/Dashboard/Editor';

@observer
export default class RenderChart extends Component {
  render() {
    const { chartRef, sql } = this.props;
    const chartType = sql.chartSettings.type;
    const options = {
      responsive: true,
      animation: {
        duration: 0,
      },
      //maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: true,
          bodyFont: {
            family: 'Inter'
          },
        },
        legend: {
          display: false,
        }
      },
      scales: {
        x: {
          border: {
            display: true,
            color: styles.tint2,
          },
        },
        y: {
          min: undefined,
          suggestedMin: 0,
          border: {
            display: true,
            color: styles.tint2,
          },
          grid: {
            display: true,
            color: styles.tint2,
          },
        }
      }
    }

    return (
      <div className='px-1'>
        { chartType === 'line' && (
          <LineBar
            id='amply-chart'
            data={{
              labels: sql.chartXValues,
              datasets: [
                {
                  type: 'line',
                  label: sql.chartSettings.yAxis,
                  data: sql.chartYValues,
                  borderColor: styles.colorPrimaryPurple,
                  backgroundColor: styles.colorPrimaryPurple,
                }
              ]
            }}
            options={options}
            ref={chartRef} />
        )}

        { chartType === 'bar' && (
          <LineBar
            id='amply-chart'
            data={{
              labels: sql.chartXValues,
              datasets: [ 
                { 
                  type: 'bar',
                  label: sql.chartSettings.yAxis,
                  data: sql.chartYValues,
                  borderColor: styles.colorPrimaryPurple,
                  backgroundColor: styles.colorPrimaryPurple,
                }
              ]
            }}
            options={options}
            ref={chartRef} />
        )}
      </div>
    );
  }
}
