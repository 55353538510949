import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';
import Plus from 'components/Util/Icons/Plus';

import LargeSecondaryButton from 'components/Util/Buttons/DarkBackground/LargeSecondaryButton';
import LongArrow from 'components/Util/Icons/LongArrow';

import styles from 'styles/Marketing/Root/Section1';

export default class Section1 extends Component {
  onClick = () => {
    document.getElementById('js-amply-cta').scrollIntoView();
  }

  render() {
    const orbs = [
      // PURPLE ORBS
      // top left
      {
        width: '600px',
        height: '600px',
        top: '-200px',
        left: 'calc(50% - 750px)',
        background: styles.colorPrimaryPurple,
      },
      // bottom right
      { 
        width: '700px',
        height: '700px',
        bottom: '-400px',
        right: 'calc(50% - 900px)',
        background: styles.colorPrimaryPurple,
      },
      // bottom left
      {
        width: '50px',
        height: '50px',
        bottom: '125px',
        left: 'calc(50% - 525px)',
        background: styles.colorPrimaryPurple,
      },
      // center
      {
        width: '50px',
        height: '50px',
        top: '278px',
        left: 'calc(50% - 170px)',
        background: styles.colorPrimaryPurple,
      },

      // ORANGE ORB
      { 
        width: '200px',
        height: '200px',
        bottom: '175px',
        right: 'calc(50% - 560px)',
        background: styles.colorSecondaryOrange,
      },
      // LIGHT BLUE ORB
      {
        width: '75px',
        height: '75px',
        top: '85px',
        right: 'calc(50% - 570px)',
        background: '#18cefe'
      },
    ];

    const plusColor = `rgba(255,255,255, 0.9)`;

    return (
      <div className='position-relative'>
        <Orbs orbs={orbs} blurEffect={true}>
          <div className='px-0 px-lg-4 position-relative'>
            <div className={classnames(styles.Content, styles.Fold, 'position-relative', 'text-center', 'text-lg-start', )}>
              <div>
                <h1 className={classnames(styles.h1, styles.ColorPrimaryWhite, 'mb-3')}>Unleash Data Insights <br className='d-none d-lg-block' />at the Heart of <br className='d-none d-lg-block'/>Transactional Email</h1>
                <p className={classnames(styles.h5, styles.ColorPrimaryWhite, 'mb-3')}>Transactional email powers the mission-critical events of your application. Amply turns this raw programmatic email data into insights that speed up your product development and elevate your product experience.</p>

                <div className={classnames(styles.CTA, styles.MaxWidth, 'mx-auto', 'mb-3')}>
                  <LargeSecondaryButton onClick={this.onClick}>
                    Get&nbsp;Started Free <LongArrow color={styles.colorPrimaryWhite} />
                  </LargeSecondaryButton>
                </div>
              </div>
            </div>
          </div>
        </Orbs>

        <div className={classnames(styles.Aura, styles.MaxWidth, 'mx-auto')} />
      </div>
    );
  }
}
