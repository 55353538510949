import { observable, computed, action } from 'mobx';
import Dashboard from './Dashboard';

export default class DashboardCollection {
  @observable active = 'devops';
  @observable filters = observable.object({
    created_at: observable.object({})
  });
  @observable dashboards = observable.array([]);
  @observable dashboardIDs = observable.array([]);

  constructor(dashboardIDs) {
    this.dashboardIDs = dashboardIDs;

    const endDate = new Date();
    endDate.setHours(23,59, 59, 999)
  }

  @action
  load() {
    // eventually support custom dashboards
    // this.dashboardIDs.forEach((id) => {
    // });

    // DevOps
    this.dashboards.push(new Dashboard({
      id: 'devops',
      name: 'DevOps'
    }));
    

    // Product
    this.dashboards.push(new Dashboard({
      id: 'product',
      name: 'Product'
    }));

    // Security
    this.dashboards.push(new Dashboard({
      id: 'security',
      name: 'Security'
    }));
  }
}
