import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import InputM from 'components/Util/Inputs/InputM';

import Delete from './Delete';
import Duration from './Duration';
import Label from './Label';

import styles from 'styles/Dashboard/Base';

@observer
export default class Change extends Component {
  render() {
    const { monitor, store } = { ...this.props };

    return (
      <div className='mb-2'>
        <Label monitor={monitor} />

        <ol className={classnames(styles.p2, 'position-relative')} style={{ left: '-16px' }}>
          { monitor.alert_conditions.map((alertCondition, index) =>
            <li key={index} className='mb-1'>
               <InputM
                 value={alertCondition.logic}
                 type='hidden'
                 name={`alert_conditions[${index}][logic]`}
                 className='align-middle'
                 store={store} />

               <InputM
                 value={alertCondition.value}
                 type='hidden'
                 name={`alert_conditions[${index}][value]`}
                 className='align-middle'
                 store={store} />

              { monitor.type == 'DomainReputationMonitor' && (
                <span>Trigger an alert when the status changes to blocked for at least</span>
              )}

              { monitor.type == 'IpReputationMonitor' && (
                <span>Trigger an alert when the status changes to blocked for at least</span>
              )}

              { monitor.type == 'UnsubscribeMonitor' && (
                <span>Trigger an alert when the status changes to unsubscribed for at least</span>
              )}

              <Duration
                monitor={monitor}
                index={index}
                value={alertCondition.trigger_duration}
                className='mx-1 align-middle'
                store={store} />

              <Delete monitor={monitor} index={index} />
            </li>
          )}
        </ol>
      </div>
    );
  }
}

