import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Metrics extends Component {
  render() {
    const src = `
<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Asset 29 1" clip-path="url(#clip0_124_735)">
<g id="ICONS">
<g id="Group">
<path id="Vector" d="M75 150C33.64 150 0 116.36 0 75C0 33.64 33.64 0 75 0C116.36 0 150 33.64 150 75H119.96C119.96 50.21 99.79 30.04 75 30.04C50.21 30.04 30.04 50.21 30.04 75C30.04 99.79 50.21 119.96 75 119.96V150Z" fill="url(#paint0_linear_124_735)"/>
<path id="Vector_2" d="M37.56 75C37.56 54.29 54.35 37.5 75.06 37.5C95.77 37.5 112.56 54.29 112.56 75C112.56 95.71 95.77 112.5 75.06 112.5V75H37.56Z" fill="url(#paint1_linear_124_735)"/>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_124_735" x1="0" y1="75" x2="150" y2="75" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint1_linear_124_735" x1="112.56" y1="75" x2="37.56" y2="75" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<clipPath id="clip0_124_735">
<rect width="150" height="150" fill="white"/>
</clipPath>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
