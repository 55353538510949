import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import classnames from 'classnames';

import SelectM from 'components/Util/Inputs/SelectM';

import RenderChart from './RenderChart';
import { hasErrors } from './Helpers/Chart';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Chart extends Component {
  run = (e) => {
    const { store, sql } = this.props;
    sql.chartSettings.display = false;

    if (sql.chartSettings?.type?.length > 0  &&
        sql.chartSettings?.xAxis?.length > 0 &&
        sql.chartSettings?.yAxis?.length > 0) {

      if (hasErrors(store, sql)) {
        return;
      }

      sql.chartSettings.display = true;
    }
  }

  onChartTypeChange = (values) => {
    const { sql } = this.props;
    sql.chartSettings.type = values[0];
    this.run();
  }

  onXAxisChange = (values) => {
    const { sql } = this.props;
    sql.chartSettings.xAxis = values[0];
    this.run();
  }

  onYAxisChange = (values) => {
    const { sql } = this.props;
    sql.chartSettings.yAxis = values[0];
    this.run();
  }

  render() {
    const { chartRef, store, sql } = this.props;

    const xAxisIndex = sql.columns.indexOf(sql.chartSettings.yAxis);
    const xAxisOptions = toJS(sql.columns);

    if (xAxisIndex > -1) {
      xAxisOptions.splice(xAxisIndex, 1);
    }

    const yAxisIndex = sql.columns.indexOf(sql.chartSettings.xAxis);
    const yAxisOptions = toJS(sql.columns);

    if (yAxisIndex > -1) {
      yAxisOptions.splice(yAxisIndex, 1);
    }

    return (
      <React.Fragment>
        <div className={classnames(styles.ChartOptions, 'mx-1',  'mt-1')}>
          <table className='w-100' style={{ maxWidth: '750px' }}>
            <tbody>
              <tr>
                <td>
                  <label className={classnames(styles.Label, 'w-100')}>
                    <p>Chart Type</p>
                    <SelectM
                      onChange={this.onChartTypeChange}
                      value={sql.chartSettings.type}
                      className={classnames('w-100', 'mb-2')}
                      placeholder='Type of chart'
                      name='chartSettings[type]'
                      store={store}>

                      <option value='line'>Line</option>
                      <option value='bar'>Bar</option>
                    </SelectM>
                  </label>
                </td>

                <td>
                  <label className={classnames(styles.Label, 'w-100')}>
                    <p>X-Axis</p>
                    <SelectM
                      onChange={this.onXAxisChange}
                      value={sql.chartSettings.xAxis}
                      className={classnames('w-100', 'mb-2')}
                      placeholder='X-Axis'
                      name='chartSettings[xAxis]'
                      store={store}>

                      { xAxisOptions.map((value, index) =>
                        <option value={value} key={index}>{value}</option>
                      )}
                    </SelectM>
                  </label>
                </td>

                <td>
                  <label className={classnames(styles.Label, 'w-100')}>
                    <p>Y-Axis</p>
                    <SelectM
                      onChange={this.onYAxisChange}
                      value={sql.chartSettings.yAxis}
                      className={classnames('w-100', 'mb-2')}
                      placeholder='Y-Axis'
                      name='chartSettings[yAxis]'
                      store={store}>

                      { yAxisOptions.map((value, index) =>
                        <option value={value} key={index}>{value}</option>
                      )}
                    </SelectM>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        { sql.chartSettings.display && (
          <RenderChart sql={sql} chartRef={chartRef} />
        )}
      </React.Fragment>
    );
  }
}
