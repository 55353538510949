import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from 'styles/Marketing/Root/Section7';

@observer
export default class Code extends Component {
  c = observable.object({ language: 'python' });

  render() {
    const code = this.props.code;
    const languages = Object.keys(code);
    const c = this.c;
    let newLines = '';

    const lineCount = code[c.language].code.split("\n").length
    if (lineCount < 14) {
      newLines = "\n ".repeat(14 - lineCount);
    }

    return (
      <div className={classnames(styles.Code)}>
        <ul className={classnames('mb-0')}>
          { languages.map((language, index) =>
            <li key={index}
              className={classnames(c.language == language ? styles.Active : '')}
              onClick={() => c.language = language}>{code[language].title}</li>
          )}
        </ul>
        <div data-nosnippet>
          <SyntaxHighlighter language={c.language} showLineNumbers={true} style={a11yDark}>
            {code[c.language].code + newLines}
          </SyntaxHighlighter>
        </div>
      </div>
    );
  }
}
