import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import UnsubscribeShow from 'components/Users/Unsubscribe/Show';

import api from 'models/API';
import Subscription from 'models/Subscription';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.subscription = new Subscription;

    api.get(window.location.pathname).then(data => {
      this.subscription.assign(data);
    });
  }

  render() {
    return (
      <UnsubscribeShow subscription={this.subscription} />
    );
  }
}
