import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class InputAlertIcon extends Component {
  render() {
    const color = this.props.color;

    const src = `
<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.9" d="M3.99838 6C4.12909 6 4.2392 5.95578 4.3287 5.86736C4.41821 5.77896 4.46296 5.66937 4.46296 5.53867C4.46296 5.40796 4.41875 5.29785 4.33032 5.20834C4.2419 5.11882 4.13234 5.07407 4.00162 5.07407C3.87091 5.07407 3.76081 5.11829 3.6713 5.20671C3.58178 5.29514 3.53704 5.40471 3.53704 5.53543C3.53704 5.66614 3.58125 5.77625 3.66967 5.86575C3.75809 5.95525 3.86766 6 3.99838 6ZM3.51852 4.35185H4.5V2.07407H3.51852V4.35185ZM4 8C3.44667 8 2.92667 7.89502 2.43999 7.685C1.95333 7.47501 1.52999 7.19002 1.17 6.83C0.809998 6.47002 0.525002 6.04668 0.314999 5.56C0.105 5.07333 0 4.55334 0 4C0 3.4451 0.105183 2.92361 0.315548 2.43555C0.525906 1.94748 0.811401 1.52295 1.17202 1.16191C1.53265 0.800903 1.95598 0.516998 2.44201 0.310211C2.92805 0.103394 3.44738 0 4 0C4.55485 0 5.07628 0.103394 5.56428 0.310211C6.05229 0.516998 6.47685 0.800934 6.83796 1.16203C7.19908 1.52313 7.48302 1.9478 7.68982 2.43596C7.89661 2.92415 8 3.44572 8 4.00078C8 4.55582 7.89661 5.07562 7.68982 5.56021C7.48302 6.04475 7.19912 6.46736 6.83809 6.82797C6.47706 7.1886 6.05252 7.47409 5.56445 7.68448C5.0764 7.89482 4.55492 8 4 8ZM4 7.01852C4.84267 7.01852 5.55643 6.7261 6.14126 6.14127C6.7261 5.55644 7.01852 4.84268 7.01852 4C7.01852 3.15732 6.7261 2.44357 6.14126 1.85873C5.55643 1.2739 4.84267 0.981491 4 0.981491C3.15733 0.981491 2.44357 1.2739 1.85874 1.85873C1.2739 2.44357 0.981483 3.15732 0.981483 4C0.981483 4.84268 1.2739 5.55644 1.85874 6.14127C2.44357 6.7261 3.15733 7.01852 4 7.01852Z" fill="${color}"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
