import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import FontFamilyIcon from 'components/Util/Icons/Title';
import FontSizeIcon from 'components/Util/Icons/FormatSize';
import FontSpacingIcon from 'components/Util/Icons/FormatLetterSpacing';
import LineHeightIcon from 'components/Util/Icons/FormatLineSpacing';
import TextIndentIcon from 'components/Util/Icons/FormatIndentIncrease';

import TextAlignCenterIcon from 'components/Util/Icons/FormatAlignCenter';
import TextAlignLeftIcon from 'components/Util/Icons/FormatAlignLeft';
import TextAlignRightIcon from 'components/Util/Icons/FormatAlignRight';
import TextAlignJustifyIcon from 'components/Util/Icons/FormatAlignJustify';

import SelectM from 'components/Util/Inputs/SelectM';
import InputM from 'components/Util/Inputs/InputM';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Editor';
import inputStyles from 'styles/Dashboard/Editor';

@observer
export default class TypeSettings extends Component {
  store = new Store();

  onFontFamilyChange = (values) => {
    this.props.node.setFontFamily(values[0]);
  }

  onFontSizeChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      this.props.node.setFontSize(`${value}px`);
    }
  }

  onLetterSpacingChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      this.props.node.setLetterSpacing(`${value}px`);
    }
  }

  onLineHeightChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      this.props.node.setLineHeight(`${value}px`);
    }
  }

  onTextIndentChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      this.props.node.setTextIndent(`${value}px`);
    }
  }

  onTextAlignChange = (align) => {
    this.props.node.setTextAlign(align);
  }

  render() {
    const { node } = this.props;
    const activeColor = styles.colorPrimaryPurple;
    const colPaddingStart = { paddingRight: '2px' }
    const colPaddingEnd = { paddingLeft: '2px' }

    return (
      <React.Fragment>
        <label className={classnames(styles.Label)}>
          <p>Type Settings</p>
        </label>

        <div className='row'>
          <div className='col-12 mb-1'>
            <ul className={classnames(styles.TextAlign, 'mb-0')}>
              <li onClick={() => this.onTextAlignChange('left')}>
                <TextAlignLeftIcon
                  className={classnames(styles.SVG)}
                  style={node.textAlign === 'left' ? { fill: activeColor} : {}} />
              </li>

              <li onClick={() => this.onTextAlignChange('center')}>
                <TextAlignCenterIcon
                  className={classnames(styles.SVG)}
                  style={node.textAlign === 'center' ? { fill: activeColor} : {}} />
              </li>

              <li onClick={() => this.onTextAlignChange('right')}>
                <TextAlignRightIcon
                  className={classnames(styles.SVG)}
                  style={node.textAlign === 'right' ? { fill: activeColor} : {}} />
              </li>

              <li onClick={() => this.onTextAlignChange('justify')}>
                <TextAlignJustifyIcon
                  className={classnames(styles.SVG)}
                  style={node.textAlign === 'justify' ? { fill: activeColor} : {}} />
              </li>
            </ul>
          </div>

          <div className='col-12 mb-1'>
            <FontFamilyIcon alt='Font Family' width='13' className={classnames(styles.SVG, 'mr-1')} />
            <SelectM
              style={{ fontFamily: node.fontFamily }}
              value={node.fontFamily}
              onChange={this.onFontFamilyChange}
              store={this.store}
              className={classnames(styles.IconInput)}>

              <option value='Arial, Helvetica, sans-serif' style={{fontFamily: 'Arial'}}>Arial</option>
              <option value='"Brush Script MT", cursive' style={{fontFamily: '"Brush Script MT"'}}>Brush Script MT</option>
              <option value='"Courier New", Courier, monospace' style={{fontFamily: '"Courier New"'}}>Courier New</option>
              <option value='Garamond' style={{fontFamily: 'Garamond'}}>Garamond</option>
              <option value='Georgia, serif' style={{fontFamily: 'Georgia'}}>Georgia</option>
              <option value='Helvetica' style={{fontFamily: 'Helvetica'}}>Helvetica</option>
              <option value='"Lucida Sans Unicode", "Lucida Grande", sans-serif' style={{fontFamily: '"Lucida Sans Unicode"'}}>Lucida Sans Unicode</option>
              <option value='Tahoma, Geneva, sans-serif' style={{fontFamily: 'Tahoma'}}>Tahoma</option>
              <option value='"Times New Roman", Times, serif' style={{fontFamily: '"Times New Roman"'}}>Times New Roman</option>
              <option value='"Trebuchet MS", Helvetica, sans-serif' style={{fontFamily: '"Trebuchet MS"'}}>Trebuchet MS</option>
              <option value='Verdana, Geneva, sans-serif' style={{fontFamily: 'Verdana'}}>Verdana</option>
            </SelectM>
          </div>

          <div className='col-6 mb-1' style={colPaddingStart}>
            <FontSizeIcon width='13' className={classnames(styles.SVG, 'mr-1')} />
            <InputM
              value={parseInt(node.fontSize)}
              store={this.store}
              min='1'
              max='120'
              type='number'
              onChange={this.onFontSizeChange}
              className={classnames(styles.IconInput)} />
          </div>

          <div className='col-6 mb-1' style={colPaddingEnd}>
            <FontSpacingIcon width='13' className={classnames(styles.SVG, 'mr-1')} />
            <InputM
              value={parseInt(node.letterSpacing)}
              store={this.store}
              min='0'
              max='20'
              type='number'
              onChange={this.onLetterSpacingChange}
              className={classnames(inputStyles.IconInput)} />
          </div>

          <div className='col-6 mb-1' style={colPaddingStart}>
            <LineHeightIcon width='13' className={classnames(styles.SVG, 'mr-1')} />

            <InputM
              value={parseInt(node.lineHeight)}
              store={this.store}
              min='0'
              max='200'
              type='number'
              onChange={this.onLineHeightChange}
              className={classnames(inputStyles.IconInput)}/>
          </div>

          <div className='col-6 mb-1' style={colPaddingEnd}>
            <TextIndentIcon width='13' className={classnames(styles.SVG, 'mr-1')} />

            <InputM
              value={parseInt(node.textIndent)}
              store={this.store}
              type='number'
              onChange={this.onTextIndentChange}
              className={classnames(inputStyles.IconInput)}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
