import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import RunQueryBtn from './RunQueryBtn';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Placeholder extends Component {

  render() {
    const { sql, store } = this.props;

    return (
      <React.Fragment>
        <p className={classnames(styles.p2, 'px-1', 'mt-1', 'mb-0')} style={{ fontSize: '13px' }}>
          Click <RunQueryBtn className='ms-0' sql={sql} store={store} /> to execute your SQL query.
        </p>

        { sql.hasErrors && (
          <ul className={classnames(styles.Errors, 'mt-1')}>
            { sql.queryErrors.map((error, index) =>
              <li key={index}>{error.message}</li>
            )}
          </ul>
        )}
      </React.Fragment>
    );
  }
}
