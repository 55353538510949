import React, { Component } from 'react';
import classnames from 'classnames';

import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Filler, LineController, BarController } from 'chart.js';

import { Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

import { deepMerge } from './Helpers';

import styles from 'styles/Base';

export default class LineBar extends Component {
  suggestedYMax = 10;

  componentDidMount() {
    ChartJS.register(Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Filler, LineController, BarController, annotationPlugin);
  }

  compactNumberFormatter() {
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
    });
  }

  renderTicks = (value) => {
    if (isNaN(value)) {
      // don't format non numbers
      return value;
    }

    if (!Number.isInteger(value) && value < this.suggestedYMax && value > -this.suggestedYMax) {
      // don't format small floats
      return value;
    }

    // floats > 1 and any integer
    return this.compactNumberFormatter().format(value, 1)
  }

  render() {
    const { options, chartRef, ...params } = this.props;
    const defaults = {};

    defaults.interaction = {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    };

    defaults.scales = {
      x: {
        border: {
          display: true,
          color: styles.tint1,
        },
        grid: {
          display: false,
          color: styles.tint1,
        },
        ticks: {
          color: styles.tint3,
          font: {
            family: "'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            size: 11,
          },
          display: true,
          maxTicksLimit: 30,
          minRotation: '0',
        }
      },
      y: {
        border: {
          display: true,
          color: styles.tint1,
        },
        grid: {
          display: true,
          color: styles.tint1,
        },
        min: 0,
        suggestedMax: this.suggestedYMax,
        ticks: {
          color: styles.tint3,
          font: {
            family: "'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            size: 11,
          },
          display: true,
          maxTicksLimit: 8,
          callback: this.renderTicks,
        }
      }
    };

    const mergedOptions = deepMerge(defaults, options);

    return (
      <Chart {...params} options={mergedOptions} ref={chartRef} />
    );
  }
}
