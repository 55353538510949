import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LoadableCard from '../LoadableCard';

import styles from 'styles/Dashboard/Root/Show';

@observer
export default class Product extends Component {
  totalActiveUsersRef = React.createRef();
  dailyActiveUsersRef = React.createRef();
  totalNewUsersRef = React.createRef();
  dailyNewUsersRef = React.createRef();
  averageEventsPerUserRef = React.createRef();
  averageEventsPerDayRef = React.createRef();
  emailEngagementRef = React.createRef();

  render() {
    const { dashboardCollection } = this.props;
    const search = window.location.search;

    return (
      <div className='row'>
        <div className='col-lg-3 mb-2 px-1'>
          <LoadableCard iframeRef={this.totalActiveUsersRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'text-center', 'mb-1', 'fw-bold')}>Total Active Users</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/total_active_users${search}`} ref={this.totalActiveUsersRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-9 mb-2 px-1'>
          <LoadableCard iframeRef={this.dailyActiveUsersRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-1', 'fw-bold')}>Daily Active Users</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/daily_active_users${search}`} ref={this.dailyActiveUsersRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-3 mb-2 px-1'>
          <LoadableCard iframeRef={this.totalNewUsersRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'text-center', 'mb-1', 'fw-bold')}>Total New Users</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/total_new_users${search}`} ref={this.totalNewUsersRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-9 mb-2 px-1'>
          <LoadableCard iframeRef={this.dailyNewUsersRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-1', 'fw-bold')}>Daily New Users</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/daily_new_users${search}`} ref={this.dailyNewUsersRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-4 mb-lg-0 mb-2 px-1'>
          <LoadableCard iframeRef={this.averageEventsPerUserRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'text-center', 'mb-1', 'fw-bold')}>Average Events Per User</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/average_events_per_user${search}`} ref={this.averageEventsPerUserRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-4 mb-lg-0 mb-2 px-1'>
          <LoadableCard iframeRef={this.averageEventsPerDayRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'text-center', 'mb-1', 'fw-bold')}>Average Events Per Day</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/average_events_per_day${search}`} ref={this.averageEventsPerDayRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-4 px-1'>
          <LoadableCard iframeRef={this.emailEngagementRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'text-center', 'mb-1', 'fw-bold')}>Email Engagement Rate</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/email_engagement_rate${search}`} ref={this.emailEngagementRef} />
          </LoadableCard>
        </div>
      </div>
    );
  }
}
