import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Organization extends Component {
  store = new Store;

  onSuccess = () => {
    window.location.reload();
  }

  render() {
    const { organizationAccount } = this.props;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Organization</h5>

        <Form method='put' action='/home/account/general' onSuccess={this.onSuccess} store={this.store}>
          <label className={classnames(styles.Label)}>
            <p>Account Owner</p>
            <InputM
              className={classnames(styles.InputWidth, 'mb-2')}
              defaultValue={organizationAccount.current_organization.owner_email}
              name='owner_email'
              placeholder='Owner Email'
              disabled={true}
              store={this.store} />
          </label>

          <label className={classnames(styles.Label)}>
            <p>Account Name</p>
            <InputM
              className={classnames(styles.InputWidth, 'mb-2')}
              defaultValue={organizationAccount.current_organization.name}
              name='name'
              placeholder='Name'
              store={this.store} />
          </label>

          <div>
            <Button>Update</Button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
