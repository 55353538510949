import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import Form from 'components/Util/Inputs/Form';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Details extends Component {
  store = new Store();

  onSuccess = () => {
    window.location.reload();
  }

  render() {
    const { plan, organizationAccount } = this.props;
    const confirmationMessage = `Click submit to activate production mode. Your account will be charged ${plan.prorated_base_price_formatted} for the prorated month.`;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Plan</h5>

        { plan.created_at === undefined ? (
          <p className={classnames(styles.p2, styles.Tint5, 'mb-0')}>No plan found.</p>
        ) : (
          <React.Fragment>
            <table className={classnames(styles.BasicTableH)}>
              <tbody>
                <tr>
                  <td className={classnames(styles.BodySBold, 'pr-1')}>
                    Mode
                  </td>
                  <td>{organizationAccount?.current_organization?.mode === 'sandbox' ? 'Sandbox' : 'Production'}</td>
                </tr>
                <tr>
                  <td className={classnames(styles.BodySBold, 'pr-1')}>Base Rate</td>
                  <td>{plan.base_price_formatted} / first {plan.tier_volume_base_formatted} emails / month</td>
                </tr>
                <tr>
                  <td className={classnames(styles.BodySBold, 'pr-1')}>Additional Volume<br/>Rate</td>
                  <td>{plan.tier_volume_price_formatted} / additional {plan.tier_volume_interval_formatted} emails / month</td>
                </tr>
                { organizationAccount.isSandboxMode ? (
                <tr>
                  <td className={classnames(styles.BodySBold, 'pt-2', 'pr-1')}>Pay Now <br/>Production Mode</td>
                  <td className='pt-2'>{plan.prorated_base_price_formatted}</td>
                </tr>
                ) : (
                <tr>
                  <td className={classnames(styles.BodySBold, 'pt-2', 'pr-1')}>Month Estimate</td>
                  <td className='pt-2'>{plan.month_estimate_formatted}</td>
                </tr>
                )}
              </tbody>
            </table>

            { organizationAccount.isSandboxMode && (
              <Form
                action='/home/account/plan'
                method='put'
                store={this.store}
                onSuccess={this.onSuccess}
                confirmation={true}
                confirmationMessage={confirmationMessage}>

                <Button className='mt-2'>Activate Production Mode</Button>
              </Form>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
