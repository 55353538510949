import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import SelectM from 'components/Util/Inputs/SelectM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Config extends Component {
  onSuccess = (data) => {
    window.location = `/home/settings/ip_pools/${data.id}`;
  }

  render() {
    const { ipPool, action, store, method } = this.props;
    const ipAddresses = ipPool.ip_addresses?.map(ipAddress => ipAddress.id);

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>
        <Form method={method} action={action} onSuccess={this.onSuccess} store={store}>
          <label className={classnames(styles.Label)}>
            <p>Name</p>
            <InputM
              defaultValue={ipPool.name}
              className={classnames(styles.InputWidth, 'mb-2')}
              name='name'
              placeholder='Name'
              store={store} />
          </label>

          <label className={classnames(styles.Label)}>
            <p>IP Addresses</p>
            <p className={classnames(styles.InputDescription, 'mb-1')}>The IP addresses to associate with this IP pool.</p>
            <SelectM
              multi={true}
              defaultValue={ipAddresses}
              placeholder='IP Addresses'
              className={classnames(styles.InputWidth, 'mb-2')}
              name='ip_address_ids'
              store={store}>

              { ipPool.all_ip_addresses.map((ipAddress, index) =>
                <option key={index} value={ipAddress.id}>{ipAddress.public_ip}</option>
              )}
            </SelectM>
          </label>

          <div>
            <Button>{ method === 'put' ? 'Update' : 'Create' }</Button>
            <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={ipPool.id} store={store} />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
