import React, { Component } from 'react';

import Content from 'components/Dashboard/Content';
import TemplatesIndex from 'components/Dashboard/Settings/Templates/Index';

export default class Index extends Component {
  render() {
    return (
      <Content>
        <TemplatesIndex />
      </Content>
    );
  }
}
