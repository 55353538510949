import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import SSOConnectionsShow from 'components/Dashboard/SSOConnections/Show';

import api from 'models/API';
import SSOConnection from 'models/SSOConnection';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.ssoConnection = new SSOConnection();
    const id = props.match.params.id;

    api.get(`/api/v1/sso_connections/${id}`).then(data => {
      this.ssoConnection.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <SSOConnectionsShow ssoConnection={this.ssoConnection} />
      </Content>
    );
  }
}
