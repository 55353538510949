import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Two extends Component {
  render() {
    const src = `
<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 15C0 6.71573 6.71573 0 15 0H47C55.2843 0 62 6.71573 62 15V47C62 55.2843 55.2843 62 47 62H15C6.71573 62 0 55.2843 0 47V15Z" fill="url(#paint0_linear_1_1738)"/>
<path d="M1 16C1 7.71573 7.71573 1 16 1H46C54.2843 1 61 7.71573 61 16V46C61 54.2843 54.2843 61 46 61H16C7.71573 61 1 54.2843 1 46V16Z" fill="url(#paint1_linear_1_1738)"/>
<path d="M21.2 47.0001C21.2 45.0001 21.48 43.3468 22.04 42.0401C22.6 40.7334 23.4934 39.5268 24.72 38.4201C25.9467 37.3134 27.7734 35.9734 30.2 34.4001C31.5334 33.5468 32.6267 32.7734 33.48 32.0801C34.3334 31.3868 35.0667 30.5601 35.68 29.6001C36.2934 28.6401 36.6 27.5734 36.6 26.4001C36.6 24.7201 36.0734 23.4001 35.02 22.4401C33.9667 21.4801 32.5867 21.0001 30.88 21.0001C29.0667 21.0001 27.6267 21.4734 26.56 22.4201C25.4934 23.3668 24.9067 24.6934 24.8 26.4001H20.8C20.9067 23.7334 21.8467 21.6001 23.62 20.0001C25.3934 18.4001 27.8134 17.6001 30.88 17.6001C32.88 17.6001 34.6134 17.9801 36.08 18.7401C37.5467 19.5001 38.6667 20.5268 39.44 21.8201C40.2134 23.1134 40.6 24.5734 40.6 26.2001C40.6 27.7201 40.3601 29.0468 39.8801 30.1801C39.4001 31.3134 38.6534 32.3734 37.64 33.3601C36.6267 34.3468 35.2134 35.4268 33.4 36.6001L31.44 37.8401C30.1867 38.6401 29.24 39.2734 28.6 39.7401C27.96 40.2068 27.3934 40.7401 26.9 41.3401C26.4067 41.9401 26.0534 42.6268 25.84 43.4001H40.8V47.0001H21.2Z" fill="url(#paint2_linear_1_1738)"/>
<defs>
<linearGradient id="paint0_linear_1_1738" x1="28.4258" y1="66.3438" x2="31" y2="66.3438" gradientUnits="userSpaceOnUse">
<stop stop-color="#4141FF"/>
<stop stop-color="#6834FF"/>
<stop offset="0.536946" stop-color="#5535FE"/>
<stop offset="1" stop-color="#C400FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1_1738" x1="3.57617" y1="4.54492" x2="58.2793" y2="4.54492" gradientUnits="userSpaceOnUse">
<stop stop-color="#0B151E"/>
<stop offset="1" stop-color="#303A57"/>
</linearGradient>
<linearGradient id="paint2_linear_1_1738" x1="40.8" y1="47.0001" x2="30.8" y2="47.0001" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#36FFF7"/>
</linearGradient>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
