import React, { Component } from 'react';
import queryString from 'query-string'                                                   

import Content from 'components/Marketing/Content';
import NewConfirmation from 'components/Marketing/Organizations/Confirmations/New';

export default class New extends Component {
  render() {
    const tokenExpired = queryString.parse(window.location.search)['token_expired'];

    return (
        <Content setFooterBorder={true}>
          <NewConfirmation tokenExpired={tokenExpired} />
        </Content>
    );
  }
}
