import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import SelectM from 'components/Util/Inputs/SelectM';

import styles from 'styles/Dashboard/Base';

@observer
export default class Change extends Component {
  render() {
    const { monitor, store } = { ...this.props };

    return (
      <label className={classnames(styles.Label, 'mb-2')}>
        <p>Detection Type</p>
        <p className={classnames(styles.InputDescription, 'mb-1')}>
          { monitor.type == 'DomainReputationMonitor' && monitor.detection_type == 'blocklist_event' && (
            <React.Fragment>A blocklist event occurs when a domain is added to a blocklist.</React.Fragment>
          )}
          { monitor.type == 'IpReputationMonitor' && monitor.detection_type == 'blocklist_event' && (
            <React.Fragment>A blocklist event occurs when an IP address is added to a blocklist.</React.Fragment>
          )}
          { monitor.type == 'UnsubscribeMonitor' && monitor.detection_type == 'unsubscribe_event' && (
            <React.Fragment>An unsubscribe event occurs when a user is added to an unsubscribe list..</React.Fragment>
          )}
        </p>

        { monitor.detection_type == 'blocklist_event' && (
          <SelectM
            disabled={true}
            className={classnames(styles.InputWidth)}
            placeholder='Detection Type'
            name='detection_type'
            value={monitor.detection_type}
            store={store}>

            <option value='blocklist_event'>Blocklist Event</option>
          </SelectM>
        )}

        { monitor.detection_type == 'unsubscribe_event' && (
          <SelectM
            disabled={true}
            className={classnames(styles.InputWidth)}
            placeholder='Detection Type'
            name='detection_type'
            value={monitor.detection_type}
            store={store}>
            
            <option value='unsubscribe_event'>Unsubscribe Event</option>
          </SelectM>
        )}
      </label>
    );
  }
}
