import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import Content from 'components/Dashboard/Content';
import SQLShow from 'components/Dashboard/Analytics/SQL/Show';

import Sql from 'models/SQL';
import Collection from 'models/TableAPI';
import api from 'models/API';

@observer
export default class Show extends Component {
  sql = new Sql();
  queries = new Collection({ path: '/api/v1/searches/sql' });

  constructor(props) {
    super(props);

    const id = props.match.params.id;

    api.get(`/api/v1/searches/sql/${id}`).then(data => {
      this.sql.assign(data);

      this.queries.fetch().then(() => {
        // must wait until initial queries have loaded, then push the loaded query
        // the collection will remove duplicates if already loaded
        this.queries.pushUniqueData([this.sql]);
        this.sql.loaded = true;
      });
    });
  }

  render() {
    return (
      <Content disableMaxWidth={true}>
        <SQLShow sql={this.sql} queries={this.queries} />
      </Content>
    );
  }
}
