import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getRoot } from 'mobx-state-tree';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from 'styles/Dashboard/Editor';

@observer
export default class Node extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired, // TODO use shape
  }

  onMouseOver = (event) => {
    const { node } = this.props;

    if (getRoot(node).draggingNode) {
      return;
    }

    event.stopPropagation();
    node.hover();
  }

  onMouseOut = (event) => {
    const { node } = this.props;

    if (getRoot(node).draggingNode) {
      return;
    }

    event.stopPropagation();
    node.blur();
  }

  onFocus = (event) => {
    event.stopPropagation();
    this.props.node.select();
  }

  onClick = (event) => {
    const { node } = this.props;

    if (getRoot(node).draggingNode) {
      return;
    }

    event.stopPropagation();
    node.select();
  }

  onKeyPress = (event) => {
  }
}
