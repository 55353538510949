import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Minus from 'components/Util/Icons/Minus'

import styles from 'styles/Dashboard/Base';

@observer
export default class Delete extends Component {
  render() {
    const { monitor, index } = { ...this.props }
    const style = {
      borderRadius: '50%',
      height: '16px',
      width: '16px',
      background: '#ff5c64', // rgba(styles.colorError, 0.75)
      border: 'none',
      fontSize: '12px',
      color: styles.colorPrimaryWhite,
      top: '2px',
    };

    return (
      <React.Fragment>
        { monitor.alert_conditions.length > 1 &&
          <span
            className='d-inline-block text-center cursor-pointer position-relative'
            style={style}
            onClick={() => monitor.delete_alert_condition(index) }>

            <Minus className='position-relative' style={{width: '8px', bottom: '4px' }} color={styles.colorPrimaryWhite} />
          </span>
        }
      </React.Fragment>
    );
  }
}
