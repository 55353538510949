import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Cohorts';

export default class Section4 extends Component {
  render() {
    return (
      <div className={classnames(styles.Section4)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2')}>
          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple, 'text-center')}>You'll also receive:</h2>

          <div className={classnames(styles.Row, styles.Card2, 'row', 'mb-5')}>
            <div className='col-lg-6'>
              <p className={classnames(styles.p2)}>Premium shared IP, free <br className='d-none d-lg-block' /> dedicated IPs</p>
            </div>

            <div className='col-lg-6'>
              <p className={classnames(styles.p2)}>Unique dashboards covering delivery health, <br className='d-none d-lg-block' /> product usage, and security status</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6'>
              <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple)}>Capitalize on the missing <br className='d-none d-lg-block' /> piece of your data stack <br className='d-none d-lg-block' /> and unleash transactional <br className='d-none d-lg-block' /> email insights that fuel <br className='d-none d-lg-block' /> growth.</h2>
            </div>

            <div className='col-lg-6'>
              <p className={classnames(styles.p1, 'mb-0', 'fw-bold')}>DevOps</p>
              <p className={classnames(styles.p1, 'mb-3')}>Maintain a healthier infrastructure and proactively stop outages with monitoring and real-time metrics</p>

              <p className={classnames(styles.p1, 'mb-0', 'fw-bold')}>Product</p>
              <p className={classnames(styles.p1, 'mb-3')}>Understand driving engagement factors with your most important product features</p>

              <p className={classnames(styles.p1, 'mb-0', 'fw-bold')}>Security</p>
              <p className={classnames(styles.p1, 'mb-3')}>Access real-time sensitive account activity to stay informed of security issues</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
