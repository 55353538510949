import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import OrganizationUsersShow from 'components/Dashboard/Analytics/Users/Show';

import api from 'models/API';
import tableAPI from 'models/TableAPI';
import OrganizationUser from 'models/OrganizationUser';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.organizationUser = new OrganizationUser();
    const email = props.match.params.email;

    this.tableAPI = new tableAPI({ path: `/api/v1/analytics/users/${email}/feed` });
    this.tableAPI.fetch();

    api.get(`/api/v1/analytics/users/${email}`).then(data => {
      this.organizationUser.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <OrganizationUsersShow organizationUser={this.organizationUser} tableAPI={this.tableAPI} />
      </Content>
    );
  }
}
