import React, { Component } from 'react';

import Content from 'components/Marketing/Content';
import NewSession from 'components/Marketing/Organizations/Sessions/New';

export default class New extends Component {
  render() {
    return (
        <Content setFooterBorder={true}>
          <NewSession />
        </Content>
    );
  }
}
