import React, { Component } from 'react';
import classnames from 'classnames';

// Orbs
import Section1 from './Section1';
// Screenshots
import Section2 from './Section2';
// Personas
import Section3 from './Section3';
// Enterprise Powerhouse
import Section4 from './Section4';
// Features
import Section5 from './Section5';
// More Features
import Section6 from './Section6';
// Code
import Section7 from './Section7';
// Integration
import Section8 from './Section8';
// CTA
import CTA from '../CTA';


export default class Show extends Component {
  render() {
    return (
      <React.Fragment>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <CTA />
      </React.Fragment>
    );
  }
}
