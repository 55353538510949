import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Table';

@observer
export default class Data extends Component {

  render() {
    const { sql, store } = this.props;

    return (
      <table className={classnames(styles.Sql)}>
        <thead>
          <tr>
            <th></th>
            { sql.columns.map((column, index) =>
              <th key={index}>{column}</th>
            )}
          </tr>
        </thead>
        <tbody>
          { sql.rows.map((row, rowIndex) =>
            <tr key={rowIndex}>
              <td>{rowIndex + 1}</td>
              { row.map((data, cellIndex) =>
                <td key={cellIndex}>{data}</td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}
