import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Line2 extends Component {
  render() {
    const src = `
<svg width="1177" height="585" viewBox="0 0 1177 585" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1244.13 0.257324L1183.28 180.994C1150.02 279.751 1050.35 340.088 947.425 323.764L349.82 228.984C246.898 212.658 0.791992 208.715 0.791992 538.182V584.359" stroke="#4836FF"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
