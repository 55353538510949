import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { useStripe, useElements, Elements, CardElement, StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import InputM from './InputM';

import styles from 'styles/Dashboard/Base';

@observer
export default class Card extends Component {
  componentDidMount() {
    const { options, card, height } = this.props;

    loadStripe(card.publishable_key).then(function(stripe) {
      const elements = stripe.elements({fonts: [{cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap'}]});
      const parsedOptions  = options || {
        style: {
          base: {
            fontSize: '13px',
            fontFamily: 'Inter',
            fontWeight: '400',
            lineHeight: height || '40px',
            color: styles.colorPrimaryBlack,
            '::placeholder': {
              color: styles.tint4,
            },
          },
          invalid: {
            color: styles.colorPrimaryBlack,
          },
        }
      };

      // create card number
      const cardElement = elements.create('card', parsedOptions);
      cardElement.mount('#card-element');

      card.stripe = stripe;
      card.cardElement = cardElement;

      return;
    });
  }

  render() {
    const { store, className } = this.props;

    return (
      <div {...this.props} className={classnames(className, 'position-relative')}>
        <div
          id='card-element'
          className={classnames(styles.Card, 'd-inline-block')} />

        <InputM
          type='hidden'
          name='token'
          className={classnames(styles.InputWidth, styles.ShowInputMessage, 'position-absolute', 'start-0')}
          store={store} />
      </div>
    );
  }
}
