import React, { Component } from 'react';
import { observable } from 'mobx';

import Content from 'components/Dashboard/Content';
import ShowIndex from 'components/Dashboard/Analytics/Users/Index';

export default class Index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Content>
        <ShowIndex />
      </Content>
    );
  }
}
