import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import styles from 'styles/Marketing/Pricing/Section2';

@observer
export default class FeatureButton extends Component {
  button = observable.object({ active: false });

  constructor(props) {
    super(props);

    if (props.active === 'true') {
      this.button.active = true;
    }
  }

  onClick = (e) => {
    if (this.props.disabled) {
      return;
    }

    this.button.active = !this.button.active;
  }

  render() {
    const { className, children, ...params } = this.props;
    const klasses = [styles.FeatureButton, className, 'me-2'];

    if (this.button.active) {
      klasses.push(styles.Active);
    }

    return (
      <button {...params} className={classnames(klasses)} onClick={this.onClick}>{children}</button>
    );
  }
}
