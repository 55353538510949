import { observable, computed, action } from 'mobx';

export default class Webhook {
  @observable id;
  @observable type = 'inbound_parse';
  @observable destination_url;

  // needed for inbounds
  @observable sending_method = 'default';
  @observable subdomain;
  @observable verified_domain_id;

  @observable verified_domains;

  @observable isSendingTestRequest = false;

  assign(params) {
    this.type = params.type || this.type;
    this.destination_url = params.destination_url || this.destination_url;
    this.sending_method = params.sending_method || this.sending_method;
    this.subdomain = params.subdomain || this.subdomain;
    this.verified_domain_id = params.verified_domain_id || this.verified_domain_id;
    this.verified_domains = params.verified_domains || this.verified_domains;

    this.id = params.id || this.id;
  }

  @computed
  get loaded() {
    return this.id !== undefined && this.verified_domains !== undefined;
  }

  get formattedType() {
    switch (this.type) {
      case 'inbound_parse':
        return 'Inbound Parse';
      case 'email_activity':
        return 'Email Activity';
      default:
        return '';
    }
  }
}
