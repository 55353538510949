import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

import alertStore from 'models/Alert';

@observer
export default class Authentication extends Component {
  store = new Store;

  onSuccess = () => {
    const curEmail = this.props.organizationAccount.email;
    const newEmail = this.store.get('email').value || '';
    const passwordSet = (this.store.get('password').value || '').length > 0;

    if (curEmail !== newEmail || passwordSet) {
      alertStore.setContents(
        <React.Fragment>
          <h4 className={classnames(styles.h4, 'text-center')}>Your Authentication has been updated!</h4>

          { curEmail !== newEmail &&
            <p className={classnames(styles.p2, 'text-center')}>We've sent a confirmation email to <strong>{newEmail}</strong>. We will update your account once you have confirmed your new email.</p>
          }
          { passwordSet &&
            <p className={classnames(styles.p2, 'text-center')}>Your password has been successfully changed.</p>
          }
        </React.Fragment>
      );
      alertStore.persistAlert();
    }

    window.location.reload();
  }

  render() {
    const { organizationAccount } = this.props;
    const password = this.store.get('password')?.value || '';
    const newPassword = this.store.get('password_confirmation')?.value || '';

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Authentication</h5>

        <Form method='put' action='/home/account/authentication' onSuccess={this.onSuccess} store={this.store}>
          <label className={classnames(styles.Label)}>
            <p>Email</p>
            <InputM
              className={classnames(styles.InputWidth, 'mb-2')}
              defaultValue={organizationAccount.email}
              name='email'
              placeholder='Email'
              store={this.store} />
          </label>

          <label className={classnames(styles.Label)}>
            <p>New Password</p>
            <InputM
              className={classnames(styles.InputWidth, styles.ShowInputMessage, 'mb-2')}
              defaultValue=''
              type='password'
              name='password'
              placeholder='Password'
              store={this.store} />
          </label>

          <label className={classnames(styles.Label)}>
            <p>Confirm New Password</p>
            <InputM
              className={classnames(styles.InputWidth, 'mb-2')}
              defaultValue=''
              type='password'
              name='password_confirmation'
              placeholder='Password Confirmation'
              store={this.store} />
          </label>

          {(password.length > 0 || newPassword.length > 0) && (
            <label className={classnames(styles.Label)}>
              <p>Verify Current Password</p>
              <InputM
                className={classnames(styles.InputWidth, 'mb-2')}
                defaultValue=''
                type='password'
                name='current_password'
                placeholder='Current Password'
                store={this.store} />
            </label>
          )}

          <div>
            <Button>Update</Button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
