import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import Table from '../../Table';

import OrganizationUser from 'models/OrganizationUser';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  openRate = (row) => {
    const ou = new OrganizationUser();
    ou.assign({ opens: row['opens'], sends: row['sends']});
    return ou.open_rate;
  }

  clickRate = (row) => {
    const ou = new OrganizationUser();
    ou.assign({ clicks: row['clicks'], sends: row['sends']});
    return ou.click_rate;
  }

  render() {
    const headers = [
      {
        key: 'email',
        value: 'Email',
      },
      {
        key: 'opens',
        value: 'Open Rate',
        callback: this.openRate,
        className: 'text-center',
        width: '150',
        sortable: false,
      },
      {
        key: 'clicks',
        value: 'Click Rate',
        callback: this.clickRate,
        className: 'text-center',
        width: '150',
        sortable: false,
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: '250',
        className: 'text-end',
      }
    ]

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Users</h3>
        <Table path='/api/v1/analytics/users' hrefBase='/home/analytics/users' hrefKey='email' headers={headers} />
      </div>
    );
  }
}
