import React, { Component, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import classnames from 'classnames';
import { toJS } from 'mobx';

import { Chart as ChartJS, Tooltip} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import * as ChartGeo from 'chartjs-chart-geo';

import styles from 'styles/Base';

const createGradient = (chart, colorStart, colorEnd) => {
  const ctx = chart.ctx;
  const area = chart.chartArea;

  const gradient = ctx.createLinearGradient(0, 0, 0, area.height * 2);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const addGeoData = (dataset, locations) => {
  const data = dataset.data;

  const presentLocations = getPresentLocations(data, locations);
  const missingLocations = locations.filter(s => !presentLocations.includes(s))

  data.forEach(d => {
    const feature = locations.filter(s => s.properties.name === d.feature)[0];
    d.feature = feature;
  });

  missingLocations.forEach(location => {
    data.push({
      feature: location,
      value: 0,
    });
  });

  dataset.data = data.sort((a, b) => {
    const aName = a?.feature?.properties?.name;
    const bName = b?.feature?.properties?.name;

    if ( aName < bName ) {
      return -1;
    }

    else if ( aName > bName ) {
      return 1;
    }

    return 0;
  });

  return dataset;
}
const formatLabel = (object) => {
  const raw = object.raw;

  const value = (raw.value || 0).toLocaleString();
  const accountValue = (raw.account_value || 0).toLocaleString();
  const financialValue = (raw.financial_value || 0).toLocaleString();

  return [
    raw?.feature?.properties?.name,
    `Total Sensitive Events: ${value}`,
    `Account Events: ${accountValue}`,
    `Financial Events: ${financialValue}`,
  ];
}

const getLabels = (locations) => {
  return locations.map(s => s.properties.name).sort();
}

const getPresentLocations = (data, locations) => {
  const present = [];
  const presentLocationNames = [];

  data.forEach(d => {
    presentLocationNames.push(d.feature);
  });

  return locations.filter(location => presentLocationNames.includes(location.properties.name));
}

const round = (number, step) => {
  return Math.ceil(number / step) * step;
}

export default function Map(props) {
  ChartJS.register(Tooltip,
                   ChartGeo.ChoroplethController,
                   ChartGeo.ProjectionScale,
                   ChartGeo.ColorScale,
                   ChartGeo.GeoFeature);

  const { data, options, topology, chartRef, projection, ...params } = props;

  // before chartData modifies datasets
  const isEmptyData= data?.datasets?.every(dataset => dataset.data.length === 0);

  const chartData = {
    ...data,
    labels: getLabels(topology),
    datasets: data.datasets.map(dataset => ({
      ...addGeoData(dataset, topology),
    })),
  };

  options.interaction = {
    mode: 'nearest',
    intersect: true,
  };

  chartData.datasets[0].borderColor = styles.colorSecondaryGrey;
  chartData.datasets[0].hoverBackgroundColor = styles.colorPrimaryPurple;
  chartData.datasets[0].hoverBorderColor = styles.colorPrimaryPurple;


  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.scales.color.display = false;
      chart.options.scales.projection.projection = projection;

      chart.data.datasets[0].tooltip = {
        callbacks: {
          label: formatLabel 
        }
      }

      chart.options.scales.projection.projection = projection;

      // background (tint1 // some shade of brand purple
      chart.options.scales.color.interpolate = (v) => {
        if (v === 0 || isEmptyData) {
          return styles.tint1;
        }

        const max = 0.55;
        const roundV = round(v * 100, 10) / 100;
        const alpha = max + max * roundV;

        return `rgba(72, 54, 255, ${alpha})`;
      }
    }

    chart.update();
});

  return (
    <Chart {...params} data={chartData} options={options} ref={chartRef} type='choropleth' />
  );
}
