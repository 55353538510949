import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { types } from 'mobx-state-tree';
import { Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { placeholderColor } from '../variables';

import Add from 'components/Util/Icons/Add';

import styles from 'styles/Dashboard/Editor';

@observer
export default class InsertPlaceholder extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired, // TODO use shape
    insertBefore: PropTypes.object, // TODO use shape
  }

  onClick = (event) => {
    event.stopPropagation();
    const { node, insertBefore } = this.props;
    node.editor.addRow(insertBefore);
  }

  render() {
    return (
      <Row>
        <Col className="align-items-center">
          <Button className={classnames(styles.Add)} variant="m-0 p-0" size="xs" onClick={this.onClick}>
            <Add />Add Row
           </Button>
        </Col>
      </Row>
    );
  }
}
