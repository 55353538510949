import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import Base from './Base';
import { Button } from 'react-bootstrap';

import LayerName from './Elements/LayerName';
import Background from './Elements/Background';
import TypeSettings from './Elements/TypeSettings';
import Padding from './Elements/Padding';
import Spacer from './Elements/Spacer';

import ColumnIcon from 'components/Util/Icons/ViewColumn';

@observer
export default class Col extends Base {
  Icon() {
    return <ColumnIcon />;
  }

  setName = (event) => {
    this.props.node.setName(event.target.value);
  }

  setBackground = (event) => {
    this.props.node.setBackground(event.target.value);
  }

  renderForm = () => {
    const { node } = this.props;
    return (
      <React.Fragment>
        <LayerName node={node} />
        <Spacer />
        <Background node={node} />
        <Spacer />
        <TypeSettings node={node} />
        <Spacer />

        <div className='mb-2'>
          <Padding node={node} />
        </div>
      </React.Fragment>
    );
  }
}



