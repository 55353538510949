import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import BaseInput from './BaseInput';

import styles from 'styles/Base';

@observer
export default class SelectExpandM extends Component {
  select = observable.object({});

  constructor(props) {
    super(props);
    this.select.value = this.props.value || this.props.defaultValue;;
  }

  componentDidUpdate = () => {
    this.select.value = this.props.value || this.props.defaultValue;

    if (this.props.onChange) {
      this.props.onChange(this.select.value);
    }
  }

  updateSelectedValue = (e, val) => {
    const li = e.target;

    if (li.hasAttribute('disabled')) {
      return;
    }

    // set value to be submitted
    this.select.value = val;

    if (this.props.onChange) {
      this.props.onChange(val);
    }
  }

  render() {
    const { children, name, store, ...params } = this.props;
    const select = this.select;

    return (
      <div className={classnames(styles.SelectExpandM, this.props.className)}>
        <ul>
          { children.map(child =>
            <li
              className={classnames(select.value === child.props.value && styles.Active)}
              disabled={child.props.disabled}
              key={child.key}
              onClick={(e) => this.updateSelectedValue(e, child.props.value)}>

              {child.props.children}
            </li>
          )}
        </ul>

        <BaseInput
          className={classnames(styles.ShowInputMessage, 'float-start')}
          type='hidden'
          name={name}
          value={select.value}
          store={store} />
      </div>
    );
  }
}
