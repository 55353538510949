import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import TextareaM from 'components/Util/Inputs/TextareaM';
import SelectExpandM from 'components/Util/Inputs/SelectExpandM';
import InputM from 'components/Util/Inputs/InputM';
import SelectM from 'components/Util/Inputs/SelectM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import DetectionTypes from './DetectionTypes';
import AlertConditions from './AlertConditions';
import Test from './Test';

import styles from 'styles/Dashboard/Base';

@observer
export default class Config extends Component {
  updateDetectionType = (values) => {
    this.props.monitor.update_type(values[0]);
  }

  updateDetectionMethod = (value) => {
    this.props.monitor.update_detection_method(value);
  }

  onSuccess = (data) => {
    window.location = `/home/monitors/${data.id}`;
  }

  render() {
    const { monitor, action, method, store } = this.props;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>

        <Form method={method} action={action} onSuccess={this.onSuccess} store={store}>
          <label className={classnames(styles.Label)}>
            <p>Name</p>
            <InputM
              defaultValue={monitor.name}
              className={classnames(styles.InputWidth, 'mb-2')}
              name='name'
              placeholder='Name'
              store={store} />
          </label>

          <label className={classnames(styles.Label)}>
            <p>Type</p>
            <SelectM
              className={classnames(styles.InputWidth, 'mb-2')}
              placeholder='Type'
              onChange={this.updateDetectionType}
              value={monitor.type}
              name='type'
              store={store}>

              <option value='DomainReputationMonitor'>Domain Reputation</option>
              <option value='IpReputationMonitor'>IP Reputation</option>
              <option value='UnsubscribeMonitor'>Unsubscribe</option>
            </SelectM>
          </label>

          <label className={classnames(styles.Label)}>
            <p>Detection Method</p>
            <SelectExpandM
              className='mb-2'
              value={monitor.detection_method}
              onChange={this.updateDetectionMethod}
              name='detection_method'
              store={store}>

              <option key='change' value='change' disabled={!monitor.listMethods.includes('change')}>Change Alert</option>
              <option key='threshold' value='threshold' disabled={!monitor.listMethods.includes('threshold')}>Threshold Alert</option>
              <option key='anomaly' value='anomaly' disabled={!monitor.listMethods.includes('anomaly')}>Anomaly Detection</option>
            </SelectExpandM>
          </label>

          <DetectionTypes monitor={monitor} store={store} />
          <AlertConditions monitor={monitor} store={store} />

          <label className={classnames(styles.Label)}>
            <p>Notifications</p>
            <p className={classnames(styles.InputDescription, 'mb-1')}>A comma or whitespace delimited list of email addresses and webhook URLs to send the alert to.</p>

            <TextareaM
              name='notifications'
              defaultValue={monitor.notifications}
              store={store}
              className='mb-0 w-100'
              style={{maxWidth: '500px'}}
              placeholder="ops@example.com, https://example.com/webhook, ..." />

          </label>

          <Test monitor={monitor} store={store} />

          <div>
            <Button>{ method === 'put' ? 'Update' : 'Create' }</Button>
            <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={monitor.id} store={store} />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
