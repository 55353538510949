import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-css';

import styles from 'styles/Dashboard/Editor';

@observer
export default class CustomStyles extends Component {
  onChange = (code) => {
    this.props.node.setCustomStyles(code);
  }

  render() {
    const { node } = this.props;

    const style = {
      height: '150px',
      overflow: 'auto',
      border: `1px solid ${styles.tint2}`,
      borderRadius: '8px',
      background: styles.colorPrimaryWhite,
      scrollbarColor: `${styles.tint3} ${styles.colorPrimaryWhite}`,
      scrollbarWidth: 'thin',
    }

    return (
      <React.Fragment>
        <label className={classnames(styles.Label, 'w-100')}>
          <p>Custom Styles</p>

          <div style={style}>
            <Editor
              value={node.customStyles || ''}
              onValueChange={code => this.onChange(code)}
              highlight={code => highlight(code, languages.css)}
              placeholder={`* { color: ${styles.colorPrimaryPurple}; }`}
              padding={8}
              style={{
                minHeight: '148px',
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
              }} />
          </div>
        </label>
      </React.Fragment>
    );
  }
}



