import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';

import Person from 'components/Util/Icons/Person';
import Send from 'components/Util/Icons/Send';
import Visibility from 'components/Util/Icons/Visibility';
import LeftClick from 'components/Util/Icons/LeftClick';
import ErrorOutline from 'components/Util/Icons/ErrorOutline';
import BugReport from 'components/Util/Icons/BugReport';
import Warning from 'components/Util/Icons/Warning';
import Unsubscribe from 'components/Util/Icons/Unsubscribe';
import Webhook from 'components/Util/Icons/Webhook';

import Feed from '../Feed';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  formatNumber = (number) => {
    return number?.toLocaleString() || 0;
  }

  render() {
    const { organizationUser, tableAPI } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/analytics/users' className='me-1'>
            <BackButton />
          </a>

          User Profile
        </h3>

        <LoadableCard className='mb-2' loadState={organizationUser.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold', 'cursor-text')}>
            User Profile
            <label style={{ marginLeft: '4px' }} className={classnames(styles.Label, 'd-inline-block', 'cursor-text')}><p>(30 Day Activity)</p></label>
          </h5>

          <Attribute icon={Person} name='Email' value={organizationUser.email} className='mb-1' />
          <Attribute icon={Send} name='Sends' value={this.formatNumber(organizationUser.sends)} className='mb-1' />
          <Attribute icon={Visibility} name='Opens' value={this.formatNumber(organizationUser.opens)} className='mb-1' />
          <Attribute icon={LeftClick} name='Clicks' value={this.formatNumber(organizationUser.clicks)} className='mb-1' />
          <Attribute icon={ErrorOutline} name='Bounces' value={this.formatNumber(organizationUser.bounces)} className='mb-1' />
          <Attribute icon={BugReport} name='Spam Reports' value={this.formatNumber(organizationUser.spam_reports)} className='mb-1' />
          <Attribute icon={Warning} name='Rejections' value={this.formatNumber(organizationUser.rejections)} className='mb-1' />
          <Attribute icon={Unsubscribe} name='Unsubscribes' value={this.formatNumber(organizationUser.unsubscribes)} className='mb-1' />
          <Attribute icon={Webhook} name='Inbounds' value={this.formatNumber(organizationUser.inbounds)} className='mb-1' />
        </LoadableCard>

        <Feed type='user' tableAPI={tableAPI} />
      </div>
    );
  }
}
