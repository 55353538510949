import React, { Component } from 'react';
import classnames from 'classnames';

import CTAForm from './CTAForm';

import styles from 'styles/Marketing/CTA';

export default class CTA extends Component {
  render() {
    return (
      <div id='js-amply-cta' className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto')}>
          <div className='row py-5 px-2 px-lg-4 w-100'>
            <div className='col-lg-6'>
              <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple)}>Break free from email <br className='d-lg-block d-none' />purgatory and switch <br className='d-lg-block d-none' />providers fearlessly.</h2>
              <p className={classnames(styles.h5)} style={{ fontSize: '20px' }}>Amply makes switching to our platform <br className='d-lg-block d-none' />frictionless by intelligently load-balancing emails <br className='d-lg-block d-none' />so that reputation and delivery stay high.</p>
              <p className={classnames(styles.p2)}>Save your team thousands of dollars in resources and <br className='d-lg-block d-none' /> engineering time!</p>
            </div>
            <div className='col-lg-6'>
              <h4 className={classnames(styles.h4)}>Amply is now available for <br className='d-lg-block d-none' /> data-obsessed devs.</h4>
              <p className={classnames(styles.p1)} style={{ fontWeight: '600' }}>Get your team the data they need to succeed.</p>

              <CTAForm
                onSuccess={this.onSuccess}
                action='/plans/request_access'
                cta='Try Amply Today' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
