import { toJS, observable, computed, action } from 'mobx';

export default class IpAddress {
  @observable id;
  @observable public_ip;
  @observable rdns;
  @observable rdns_subdomain;
  @observable rdns_verified_domain_id;
  @observable uuid;
  @observable status;
  @observable blocklists = [];
  @observable ip_pools = [];
  @observable enabled;
  @observable created_at;
  @observable default_rdns;
  @observable verified_domains;

  assign(params) {
    this.public_ip = params.public_ip || this.public_ip;
    this.rdns = params.rdns || this.rdns;
    this.rdns_subdomain = params.rdns_subdomain || this.rdns_subdomain;
    this.rdns_verified_domain_id = params.rdns_verified_domain?.id || this.rdns_verified_domain_id;
    this.uuid = params.uuid || this.uuid;
    this.status = params.status || this.status;
    this.blocklists = params.blocklists || this.blocklists;
    this.ip_pools = params.ip_pools || this.ip_pools;
    this.enabled = params.enabled !== undefined ? params.enabled : this.enabled;
    this.created_at = params.created_at || this.created_at;
    this.default_rdns = params.default_rdns || this.default_rdns;
    this.id = params.id || this.id;
    this.verified_domains = params.verified_domains || this.verified_domains;
  }

  @computed
  get verifiedDomains() {
    return toJS(this.verified_domains);
  }

  get statusFormatted() {
    return this.status?.charAt(0)?.toUpperCase() + this.status?.slice(1);
  }

  get defaultRdnsSubdomain() {
    return this.default_rdns.split('.')[0];
  }

  get loaded() {
    return this.id !== undefined && this.verified_domains !== undefined && this.default_rdns !== undefined;
  }
}
