import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Editor';

export default class Controls extends Component {
  onEdit = (event) => {
    event.stopPropagation();

    const { node, store } = this.props;
    const child = node.addChild('Text');
    store.setSelectedNode(child);
  }

  onAdd = (event) => {
    event.stopPropagation();
  }

  onDelete = (event) => {
    event.stopPropagation();

    const { node, store } = this.props;
    node.parent.removeChild(node);
  }

  render() {
    const { node, store } = this.props;

    return (
      <div className={styles.nodeControls}>
        <div className={styles.nodeControlsTop}>
          TOP
        </div>

        <div className={styles.nodeControlsMiddle}>
          MIDDLE
        </div>

        <div className={styles.nodeControlsBottom}>
          BOTTOM
        </div>

      </div>
    );
  }
}
