import React, { Component } from 'react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputL from 'components/Util/Inputs/InputL';

import Button from 'components/Util/Buttons/LightBackground/LargePrimarySquareButton';

import Store from 'models/Form';

import styles from 'styles/Marketing/CTA';

export default class CTA extends Component {
  store = new Store();
  thankYou = React.createRef();

  onSuccess = () => {
    const transitionDuration = styles.fadeTransitionDuration;
    const form = this.store.getRef('form').current;
    const thankYou = this.thankYou.current;

    form.classList.add(styles.FadeOut);

    setTimeout(() => {
      form.classList.add('d-none');
      thankYou.classList.remove('d-none');
      thankYou.classList.add(styles.FadeIn);
    }, parseFloat(transitionDuration) * 1000);
  }

  render() {
    const { action, cta } = this.props;

    return (
      <div className={classnames(styles.CTAForm)}>
        <Form name='form' method='post' action={action} onSuccess={this.onSuccess} store={this.store}>
          <div className='row w-100'>
            <div className='col-lg-4 col-6'>
              <InputL className='w-100 mb-2' name='first_name' placeholder='First Name*' store={this.store} />
            </div>

            <div className='col-lg-8 col-6'>
              <InputL className='w-100 mb-2' name='last_name' placeholder='Last Name*' store={this.store} />
            </div>

            <div className='col-lg-12'>
              <InputL className='w-100 mb-2' name='organization' placeholder='Organization' store={this.store} />
            </div>

            <div className='col-lg-12'>
              <InputL className='w-100 mb-2' name='email' placeholder='Email*' store={this.store} />
            </div>
          </div>

          <Button className='d-block'>{cta}</Button>
        </Form>

        <p className={classnames(styles.p2, 'd-none', 'opacity-0')} ref={this.thankYou}>Thank you for your interest in Amply. We will be in touch soon!</p>
      </div>
    );
  }
}
