import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import DailyEvents from './DailyEvents';
import DomainHealth from './DomainHealth';
import IpAddressHealth from './IpAddressHealth';
import IpPoolHealth from './IpPoolHealth';
import BounceSpamRate from './BounceSpamRate';
import Monitors from './Monitors';
import ValueChanged from './ValueChanged';
import Daily from './Daily';
import DailyWithStickiness from './DailyWithStickiness';
import EmailEngagementRate from './EmailEngagementRate';
import SensitiveGeoEvents from './SensitiveGeoEvents';

import tooltip from 'models/Tooltip';
import tooltipStyles from 'styles/Dashboard/Content';

@observer
export default class Show extends Component {
  chartRef = React.createRef();

  render() {
    const { widget } = this.props;

    const defaultOptions = {
      responsive: true,
      animation: {
        duration: 0,
      },
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: true,
          bodyFont: {
            family: 'Inter'
          },
          titleFont: {
            family: 'Inter'
          },
        },
        legend: {
          display: false,
        }
      },
    };

    return (
      <div className='vw-100 vh-100'>
        { widget.id === 'daily_events' && <DailyEvents widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'domain_health' && <DomainHealth widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'ip_address_health' && <IpAddressHealth widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'ip_pool_health' && <IpPoolHealth widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'bounce_spam_rate' && <BounceSpamRate widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'monitors' && <Monitors widget={widget} /> }

        { widget.id === 'total_active_users' && <ValueChanged widget={widget} /> }
        { widget.id === 'daily_active_users' && <DailyWithStickiness widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'total_new_users' && <ValueChanged widget={widget} /> }
        { widget.id === 'daily_new_users' && <Daily widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'average_events_per_user' &&  <ValueChanged widget={widget} /> }
        { widget.id === 'average_events_per_day' &&  <ValueChanged widget={widget} /> }
        { widget.id === 'email_engagement_rate' &&  <EmailEngagementRate widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }

        { widget.id === 'sensitive_geo_events' && <SensitiveGeoEvents widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'total_account_events' &&  <ValueChanged widget={widget} /> }
        { widget.id === 'daily_account_events' && <Daily widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }
        { widget.id === 'total_financial_events' &&  <ValueChanged widget={widget} /> }
        { widget.id === 'daily_financial_events' && <Daily widget={widget} options={defaultOptions} chartRef={this.chartRef} /> }

        <ReactTooltip
          id='amply-tooltip'
          place={tooltip.place}
          effect='solid'
          event={undefined}
          multiline={true}
          className={classnames(tooltipStyles.Tooltip)} />
      </div>
    );
  }
}
