import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Orbs from 'components/Util/Misc/Orbs';
import Line1 from './Images/Section2/Line1';
import Devops from './Images/Section2/Devops';
import Button from 'components/Util/Buttons/DarkBackground/LargeSecondaryIconButton';

import styles from 'styles/Marketing/Platform/Section2';

@observer
export default class Section2 extends Component {
  render() {
    const orbs = [
      // ORANGE ORB
      {
        width: '200px',
        height: '200px',
        top: '420px',
        right: 'calc(50% - 700px)',
        background: styles.colorSecondaryOrange,
      },
    ];

    const activeBreakpoint = this.props.activeBreakpoint;

    return (
      <Orbs className={styles.Orbs} orbs={orbs} blurEffect={false}>
        <div className={classnames(styles.Container)}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4')}>
        <div className={classnames(styles.Line1)}>
          <div className={classnames(styles.LineBg)} />
          <div className={classnames(styles.Breakpoint1, activeBreakpoint?.index == 0 ? styles.Active : '')} />
          <div className={classnames(styles.Breakpoint2, activeBreakpoint?.index == 1 ? styles.Active : '')} />
          <Line1 />
        </div>

            <div className='pt-3'>
              <h2 className={classnames(styles.h2, styles.ColorPrimaryWhite)}>
                Intelligent Infrastructure, <br />
                <span className={classnames(styles.ColorPrimaryPurple)}>Empowering DevOps.</span>
              </h2>

              <p className={classnames(styles.h5, styles.ColorPrimaryWhite, 'mb-3', 'mb-lg-5')}>
                Conventional transactional email providers focus on deliverability while missing the mark on data. <span className={classnames(styles.Tint4)}>Amply is changing this decade-old approach with a scalable data solution that provides DevOps teams with the highly available infrastructure they need to operate at peak efficiency.</span>
              </p>
            </div>

            <div className={classnames(styles.Screenshot, 'w-100')}>
              <Devops />
            </div>
          </div>
        </div>
      </Orbs>
    );
  }
}
