import { observable, computed, action } from 'mobx';

export default class AccessToken {
  @observable id;
  @observable token;
  @observable description;

  assign(params) {
    this.token = params.token || this.token;
    this.description = params.description || this.description;
    this.id = params.id || this.id;
  }

  get loaded() {
    return this.id !== undefined;
  }
}
