import { toJS, observable, computed, action } from 'mobx';
import Editor from './Template/Editor';
import api from './API';

export default class Template {
  @observable id;
  @observable name = 'New Template';
  @observable uuid;
  @observable old_body;
  @observable editor;
  @observable view = 'editor';
  @observable previewHTML = '';
  @observable previewEmail = ''; // recipient of preview email

  @observable isEditingName = false;
  @observable isSendingPreview = false;

  constructor(params={}) {
    this.assign(params);
  }

  assign(params) {
    this.name = params.name || this.name;
    this.uuid = params.uuid || this.uuid;
    this.editor = params.editor || this.editor || Editor.create({ id: 'templateEditor' });

    if (params.body) {
      this.editor.reset();
      this.editor.load(params.body);
      this.saveOldBody();
    }

    this.id = params.id || this.id; // must be last
  }

  saveOldBody() {
    this.old_body = JSON.stringify(toJS(this.editor.root.JSON));
  }

  setView(value) {
    this.view = value;
  }

  generatePreview() {
    const post = {
      body: this.editor.root.JSON,
    };

    const promise = api.post('/api/v1/settings/templates/preview', post);

    promise.then(data => {
      this.previewHTML = data.html;
    });

    return promise;
  }

  @computed
  get loaded() {
    return this.id !== undefined;
  }

  isStale(store) {
    const old_body = this.old_body;
    const body = JSON.stringify(this.editor.root.JSON);

    if (this.name !== store.get('name')?.value ||
        old_body !== body) {
      return true;
    }

    return false;
  }

  get isEditorView() {
    return this.view === 'editor';
  }

  get isDesktopView() {
    return this.view === 'desktop';
  }

  get isMobileView() {
    return this.view === 'mobile';
  }

  get isSettingsView() {
    return this.view === 'settings';
  }
}
