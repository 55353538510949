import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import classnames from 'classnames';
import { ResizableBox } from 'react-resizable';
import Editor from 'react-simple-code-editor';

import Prism from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';

import InputM from 'components/Util/Inputs/InputM';
import Download from 'components/Util/Icons/Download';

import Data from './Data';
import Chart from './Chart';
import Placeholder from './Placeholder';
import Loading from './Loading';
import { hasErrors } from './Helpers/Chart';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Console extends Component {
  chartRef = React.createRef();

  onChange = (value) => {
    const { store } = this.props;
    store.get('body[query]').setValue(value);
  }

  maxHeight = () => {
    let mh = window.innerHeight;

    mh -= parseInt(styles.topNavHeight);

    if (window.innerWidth < 992) {
      // lg breakpoint
      mh -= parseInt(styles.navShrinkHeight);
    }

    return mh;
  }

  exportSQL = (e) => {
    const { sql, store } = this.props;
    sql.errors.clear();

    if (!sql.hasRun || !sql.resultsLoaded) {
      sql.errors.clear();
      sql.errors.push({ field: 'body[query]', message: 'query must be executed before exporting' });
      return;
    }

    if (sql.isDataView) {
      const data = new Array(toJS(sql.columns), ...toJS(sql.rows));
      const csv = this.arrayToCSV(data);
      this.download(csv, sql.exportFilename, 'text/csv;charset=utf-8;');
    }
    else if (sql.isChartView) {
      if (!hasErrors(store, sql)) {
        const id = this.chartRef.current.props.id
        document.getElementById(id).toBlob(blob => {
          this.download(blob, sql.exportFilename, 'image/png');
        }, 'image/png', 1);
      }
    }
  }

  output() {
    const { sql, store } = this.props;

    if (sql.hasRun) {
      if (sql.resultsLoaded) {
        if (!sql.hasErrors) {
          if (sql.isDataView) {
            return <Data sql={sql} store={store} />
          }
          else if ( sql.isChartView) {
            return <Chart sql={sql} store={store} chartRef={this.chartRef} />
          }
        }
      }
      else {
        return <Loading />
      }
    }

    return <Placeholder sql={sql} store={store} />
  }

  arrayToCSV(data){
    return data.map(row =>
      row
      .map(String)  // convert every value to String
      .map(v => v.replaceAll('"', '""'))  // escape double colons
      .map(v => `"${v}"`)  // quote it
      .join(',')  // comma-separated
    ).join('\r\n');  // rows starting on new lines
  }

  download(content, filename, contentType) {
    const blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);

    // Create a link to download it
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
    link.remove();
  }

  render() {
    const { sql, store } = this.props;
    const maxHeight = this.maxHeight() - 100;
    const queryValue = store?.get('body[query]')?.value;

    return (
      <div className='d-flex h-100 flex-column'>
        <div className='position-relative'>
          <ResizableBox
            height={maxHeight / 3}
            axis='y'
            draggableOpts={{grid: [50, 50]}}
            handle={<div className={classnames(styles.ResizeHandle)} />}
            minConstraints={[0, 100]} maxConstraints={[Infinity, maxHeight]} />

          <div className='position-absolute start-0 mx-1 my-2 w-50' style={{ height: '0', bottom: '-12px' }}>
            <InputM
              style={{ background: 'transparent', border: 'none', overflow: 'hidden', padding: '0' }}
              className='h-100 w-100'
              name='body[query]'
              defaultValue={sql.query}
              store={store} />
          </div>

          <div className={classnames(styles.Console)}>
            { queryValue !== undefined && <Editor
              value={queryValue}
              onValueChange={this.onChange}
              highlight={code => Prism.highlight(code, Prism.languages.sql)}
              padding={8} />
            }
          </div>

        </div>

        <div className={classnames(styles.Output, 'd-flex', 'flex-column', 'flex-grow-1', 'overflow-hidden')}>
          <div className='p-1 px-0 pb-0'>
            <ul className={classnames(styles.UpdateView, 'mb-0', 'px-1')}>
              <li onClick={() => sql.setView('data')} className={classnames(sql.isDataView && styles.Active)}>Data</li>
              <li onClick={() => sql.setView('chart')} className={classnames(sql.isChartView && styles.Active)}>Chart</li>
              <li onClick={this.exportSQL} className={classnames('float-end', 'text-end', 'pe-0', styles.Export)}><Download /> <span>Export</span></li>
            </ul>
          </div>

          <div className='h-100 overflow-auto' >
            { this.output() }
          </div>
        </div>
      </div>
    );
  }
}
