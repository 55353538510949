import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import SelectM from 'components/Util/Inputs/SelectM';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';

import Fingerprint from 'components/Util/Icons/Fingerprint';
import Share from 'components/Util/Icons/Share';
import Favorite from 'components/Util/Icons/Favorite';
import ErrorOutline from 'components/Util/Icons/ErrorOutline';
import Cloud from 'components/Util/Icons/Cloud';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store;

  onSuccess = () => {
    window.location.reload();
  }

  afterDelete = () => {
    window.location = '/home/settings/ip_addresses';
  }

  onChange = (value, subdomain) => {
    const ref = this.store.getRef('rdns_subdomain')?.current;
    const ipAddress = this.props.ipAddress;

    if (parseInt(value || 0) > 0) {
      ref.disabled = false;
    }
    else {
      ref.disabled = true;
      ref.value = ipAddress.defaultRdnsSubdomain;
    }
  }

  isSubdomainDisabled = () => {
    const ref = this.store.getRef('rdns_verified_domain_id')?.current;
    const store = this.store;

    if (!ref) {
      return;
    }

    if (ref.value === '') {
      return true;
    }

    return false;
  }

  subdomainFromIp = () => {
    const ref = this.store.getRef('rdns_verified_domain_id')?.current;

    if (!ref) {
      return;
    }

    const { ipAddress } = this.props;
    const verifiedDomainId = ref?.value;

    if (parseInt(verifiedDomainId) > 0) {
      return ipAddress.rdns_subdomain;
    }
    else if (verifiedDomainId === undefined && ipAddress.rdns_verified_domain_id > 0) {
      return ipAddress.rdns_subdomain
    }
    
    return ipAddress.defaultRdnsSubdomain;
  }

  VerifiedDomainOptions = () => {
    const { ipAddress } = this.props;

    if (!ipAddress.loaded) {
      return <React.Fragment />;
    }

    const verifiedDomains = ipAddress.verifiedDomains.filter(vd => {
      return vd.verified_at !== null;
    });

    if (verifiedDomains.length > 0) {
      return verifiedDomains.map((vd, index) =>
        <option key={index} value={vd.id}>{vd.name}</option>
      );
    }

    return <option disabled={true} value={10002}>You haven't verified any domains yet</option>
  }

  ipPoolList = () => {
    const { ipAddress } = this.props;
    const links = [];

    if (ipAddress.ip_pools.length == 0) {
      return 'N/A';
    }

    ipAddress.ip_pools.forEach((ip_pool, index) => {
      links.push(<a key={index} href={`/home/settings/ip_pools/${ip_pool.id}`}>{ip_pool.name}</a>);
    })

    return links.reduce((prev, curr) => [prev, ', ', curr]);
  }

  blocklistList = () => {
    const { ipAddress } = this.props;
    const links = [];

    if (ipAddress.blocklists.length == 0) {
      return 'No blocklists detected';
    }

    ipAddress.blocklists.forEach((blocklist, index) => {
      links.push(<a key={index} href={blocklist.url}>{blocklist.name}</a>);
    })

    return links.reduce((prev, curr) => [prev, ', ', curr]);
  }


  render() {
    const { ipAddress } = this.props;
    const subdomain = this.subdomainFromIp(ipAddress);
    const action = `/api/v1/settings/ip_addresses/${ipAddress.id}`;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/ip_addresses' className='me-1'>
            <BackButton />
          </a>

          Update IP Address

          <Delete action={action} store={this.store} afterDelete={this.afterDelete} className='float-end mb-2'>Delete</Delete>
        </h3>

        <LoadableCard className='mb-2' loadState={ipAddress.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>IP Address</h5>

          <Attribute icon={Share} name='Public IP' value={ipAddress.public_ip} className='mb-1' />
          <Attribute icon={Fingerprint} name='UUID' value={ipAddress.uuid}  className='mb-1' />
          <Attribute icon={Favorite} name='Status' value={ipAddress.statusFormatted} className='mb-1' />
          <Attribute icon={ErrorOutline} name='Blocklists' value={this.blocklistList()} className='mb-0' />
          <Attribute icon={Cloud} name='IP Pools' value={this.ipPoolList()} className='mb-0' />
        </LoadableCard>

        <LoadableCard loadState={ipAddress.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>

          <Form method='put' action={action} onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label)}>
              <p>Enabled</p>
              <SelectM
                className={classnames(styles.InputWidth, 'mb-2')}
                defaultValue={ipAddress.enabled}
                name='enabled' store={this.store}>

                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </SelectM>
            </label>

            <label className={classnames(styles.Label, 'd-inline-block')}>
              <p>rDNS</p>
              <InputM
                name='rdns_subdomain'
                placeholder='Subdomain (optional)'
                store={this.store}
                defaultValue={subdomain}
                disabled={this.isSubdomainDisabled()}
                className={classnames(styles.InputWidth)} />
            </label>
            <span className={classnames(styles.Tint5, 'position-relative')} style={{ margin: '0 4px', top: '8px' }}>.</span>

            <SelectM
              onChange={(value) => this.onChange(value, subdomain)}
              defaultValue={ipAddress.rdns_verified_domain_id || ''}
              name='rdns_verified_domain_id'
              className={classnames(styles.InputWidth, 'mb-2')}
              store={this.store}>

              <option value=''>smtp.sendamply.net</option>
              {this.VerifiedDomainOptions()}
            </SelectM>

            <div>
              <Button>Update</Button>
              <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={ipAddress.id} store={this.store} />
            </div>

          </Form>
        </LoadableCard>
      </div>
    );
  }
}
