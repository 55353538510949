import React, { Component } from 'react';
import classnames from 'classnames';

import One from './Images/Section3/One';
import Two from './Images/Section3/Two';
import Three from './Images/Section3/Three';

import styles from 'styles/Marketing/Pricing/Section3';

export default class Section3 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'pb-5')}>
        <div className={classnames(styles.MaxWidth, 'px-lg-4', 'px-2', 'mx-auto', 'text-center')}>
          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple, 'mb-3')}>Getting started is easy:</h2>

            <div className={classnames('row', 'px-0', 'px-lg-5')}>

              <div className='col-lg-4 mb-2'>
                <div className={classnames(styles.Card2, 'px-3', 'py-5', 'h-100')}>
                  <One className='mb-2' />
                  <h5 className={classnames(styles.h5Span)}>Create an <br className='d-lg-block d-none' /> Amply account</h5>
                  <p className={classnames(styles.p2, 'mb-0')}>Sign in to your Amply account to get an access token — no credit card required.</p>
                </div>
              </div>

              <div className='col-lg-4 mb-2'>
                <div className={classnames(styles.Card2, 'px-3', 'py-5', 'h-100')}>
                  <Two className='mb-2' />
                  <h5 className={classnames(styles.h5Span)}>Verify your domain <br className='d-lg-block d-none' /> to start sending email</h5>
                  <p className={classnames(styles.p2)}>Authenticate your account by configuring your DNS records.</p>
                </div>
              </div>

              <div className='col-lg-4 mb-2'>
                <div className={classnames(styles.Card2, 'px-3', 'py-5', 'h-100')}>
                  <Three className='mb-2' />
                  <h5 className={classnames(styles.h5Span)}>Connect with users <br className='d-lg-block d-none' /> and get valuable insights</h5>
                  <p className={classnames(styles.p2)}>Send emails in minutes and get insight into your infrastructure, product, and security health.</p>
                </div>
              </div>
            </div>

        </div>
      </div>
    );
  }
}
