export function setIframeYDimensions(container, svg, iframe, percent, offset) {
  // top, must be before height
  const svgRect = svg?.getBoundingClientRect();

  const iframeTop = svgRect.height * percent;
  iframe.style.top = `${iframeTop}px`;

  // height
  const iframeRect  = iframe?.getBoundingClientRect();
  const containerRect = container?.getBoundingClientRect();
  let height;

  if (containerRect.height < svgRect.height) {
    height = containerRect.height - iframeTop;
  }
  else {
    height = svgRect.height - iframeTop + offset; // + padding
  }

  iframe.style.height = `${height}px`;
}
