import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Computer from 'components/Util/Icons/Computer';
import Smartphone from 'components/Util/Icons/Smartphone';
import AutoAwesome from 'components/Util/Icons/AutoAwesome';
import Settings from 'components/Util/Icons/SettingsSuggest';

import styles from 'styles/Dashboard/Editor';

@observer
export default class NavMiddle extends Component {
  render() {
    const { template } = this.props;

    return (
      <ul className={classnames(styles.NavMiddle)}>
        <li
          onClick={() => template.setView('editor') }
          className={classnames(template.isEditorView && styles.Active)}>

          <AutoAwesome className={classnames(styles.SVG)} />
          Editor
        </li>
        <li
          onClick={() => template.setView('desktop') }
          className={classnames(template.isDesktopView && styles.Active)}>

          <Computer className={classnames(styles.SVG)} />
          View Desktop
        </li>
        <li
          onClick={() => template.setView('mobile') }
          className={classnames(template.isMobileView && styles.Active)}>

          <Smartphone className={classnames(styles.SVG)} />
          View Mobile
        </li>
        { template.id > 0 && (
          <li
            onClick={() => template.setView('settings') }
            className={classnames(template.isSettingsView && styles.Active)}>

            <Settings className={classnames(styles.SVG)} />
            Settings
          </li>
        )}
      </ul>
    );
  }
}
