import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class ErrorOutline extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="${color}"><path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
