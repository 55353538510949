import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import AccessTokenShow from 'components/Dashboard/Settings/AccessTokens/Show';

import api from 'models/API';
import AccessToken from 'models/AccessToken';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.accessToken = new AccessToken();
    const id = props.match.params.id;

    api.get(`/api/v1/settings/access_tokens/${id}`).then(data => {
      this.accessToken.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <AccessTokenShow accessToken={this.accessToken} />
      </Content>
    );
  }
}
