import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';
import Plane from './Images/Section1/Plane';

import styles from 'styles/Marketing/Pricing/Section1';

export default class Section1 extends Component {
  render() {
    const orbs = [
      // ORANGE ORB
      // right
      {
        width: '400px',
        height: '500px',
        top: '-250px',
        right: 'calc(50% - 750px)',
        background: styles.colorSecondaryOrange,
      },
      // PURPLE ORBS
      // top left
      {
        width: '600px',
        height: '600px',
        top: '-200px',
        left: 'calc(50% - 850px)',
        background: styles.colorPrimaryPurple,
      },
      // top right
      { 
        width: '250px',
        height: '250px',
        top: '150px',
        right: 'calc(50% - 650px)',
        background: styles.colorPrimaryPurple,
      },
      // LIGHT BLUE ORB
      {
        width: '100px',
        height: '100px',
        top: '200px',
        right: 'calc(50% - 505px)',
        background: '#18cefe'
      },
    ];

    return (
      <Orbs orbs={orbs} blurEffect={true}>
        <div className={classnames(styles.Container, styles.Fold, 'mx-auto', 'text-center')}>
          <div className='mx-auto'>
            <div className={classnames(styles.Plane)}>
              <Plane />
            </div>

            <h1 className={classnames(styles.h1, styles.ColorPrimaryWhite, 'mb-3')}>What you send is <br/>what you pay for.</h1>
            <div className={classnames(styles.Line, 'mb-3')} />
            <p className={classnames(styles.h5, styles.ColorPrimaryWhite, 'mb-0', 'mx-auto')}>Simple, transparent pricing with</p>
            <p className={classnames(styles.h5, styles.ColorPrimaryWhite, styles.Base, 'mb-3', 'mx-auto')}>no overage fees or unexpected bills.</p>
          </div>
        </div>
      </Orbs>
    );
  }
}
