import { observable, computed, action } from 'mobx';

export default class Charge {
  @observable amount_cents;
  @observable description;
  @observable created_at;
  @observable updated_at;

  constructor(args={}) {
    this.assign(args);
  }

  assign(params) {
    this.description = params.description;
    this.created_at = params.created_at;
    this.updated_at = params.updated_at;
    this.amount_cents = params.amount_cents;
  }

  @computed
  get amount() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(this.amount_cents / 100.0);
  }

  get date() {
    const date = new Date(this.created_at);
    return date.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
  }

  get loaded() {
    return this.amount_cents !== undefined;
  }
}
