import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Base';

// Orbs
import Section1 from './Section1';
// Calculator
import Section2 from './Section2';
// Getting Started
import Section3 from './Section3';
// Differentiors
import Section4 from './Section4';
// CTA
import Section5 from './Section5';

export default class Show extends Component {
  render() {
    return (
      <React.Fragment>
        <Section1 />

        <div className='px-3' style={{background: `linear-gradient(to bottom, ${styles.colorPrimaryGrey}, ${styles.tint1})`}}>
          <Section2 pricing={this.props.pricing} />
          <Section3 />
          <Section4 />
        </div>

        <Section5 />
      </React.Fragment>
    );
  }
}
