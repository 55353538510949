import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Base';

export default class LargePrimaryButton extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
      <button
        {...params}
        className={classnames(styles.DarkBackgroundLargePrimary, className)}>

        {children}
      </button>
    )
  }
}
