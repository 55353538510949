import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';

import Table from '../../Table';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  totalUsers = (row) => {
    return row['total_users'].toLocaleString();
  }

  render() {
    const headers = [
      {
        key: 'name',
        value: 'Name',
      },
      {
        key: 'total_users',
        value: 'Users',
        callback: this.totalUsers,
        width: '150',
        className: 'text-center',
        sortable: false,
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: '220',
        className: 'text-end',
      }
    ];

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Unsubscribe Groups</h3>
        <Table path='/api/v1/settings/unsubscribe_groups' hrefBase='/home/settings/unsubscribe_groups' hrefKey='id' headers={headers}>
          <div className='text-end'>
            <a href='/home/settings/unsubscribe_groups/new'><Button><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button></a>
          </div>
        </Table>
      </div>
    );
  }
}
