import { observable, computed, action } from 'mobx';

export default class GeneralSettings {
  @observable smtp_username;
  @observable max_email_ip_addresses;
  @observable clicktracking_enabled;
  @observable email_activity_webhook_alert;

  assign(params) {
    this.max_email_ip_addresses = params.ref || this.max_email_ip_addresses;
    this.opentracking_enabled = params.opentracking_enabled !== undefined ? params.opentracking_enabled : this.opentracking_enabled;
    this.clicktracking_enabled = params.clicktracking_enabled !== undefined ? params.clicktracking_enabled : this.clicktracking_enabled;
    this.email_activity_webhook_alert = params.email_activity_webhook_alert !== undefined ? params.email_activity_webhook_alert : this.email_activity_webhook_alert;

    this.smtp_username = params.smtp_username || this.smtp_username;
  }

  @computed

  get loaded() {
    return this.smtp_username !== undefined;
  }
}
