import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Slider, { Range } from 'rc-slider';
import FeatureButton from './FeatureButton';

import styles from 'styles/Marketing/Pricing/Section2';

@observer
export default class Section2 extends Component {
  pricingSteps = [
    10000, 50000, 100000, 250000,
    500000, 1000000, 1500000, 2000000,
    2500000, 3000000, 3500000, 4000000,
    4500000, 5000000, 5500000, 6000000,
    6500000, 7000000, 7500000, 8000000,
    8500000, 9000000, 9500000, 10000000,
    11000000, 12000000, 13000000, 14000000,
    15000000, 16000000, 17000000, 18000000,
    19000000, 20000000
  ]

  constructor(props) {
    super(props);
    props.pricing.num_emails = this.pricingSteps[0];
  }

  setHandle = (text) => {
    const el = document.getElementsByClassName('rc-slider-handle');

    if (el.length > 0) {
      el[0].innerHTML = `${text}/month`;
    }
  }

  onChange = (step) => {
    this.props.pricing.num_emails = this.pricingSteps[step];
  }

  render() {
    const { pricing } = this.props;
    const plan = pricing.plan;
    const maxVolume = this.pricingSteps.slice(-1)[0];
    this.setHandle(pricing.price_without_vas_short);

    return (
      <div className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4', 'py-5', 'text-center')}>
          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple)}>Simple Pricing Calculator</h2>
          <h4 className={classnames(styles.h4, 'mb-2')}>Send {plan.tier_volume_base?.toLocaleString()} emails for only {plan.base_price_formatted}/month</h4>
          <h5 className={classnames(styles.p1, styles.Tint5, 'mb-3')}>And {plan.tier_volume_price_formatted} for each {plan.tier_volume_base_formatted} after that.</h5>

          <div className={classnames(styles.Card2, 'p-3', 'px-2', 'px-lg-4')}>
            <h4 className={classnames(styles.h4, styles.Volume, 'mb-4')}>Monthly Emails: <span className={classnames(styles.ColorPrimaryPurple)}>{pricing.num_emails_short}</span></h4>
            { maxVolume === pricing.num_emails && (
              <p className={classnames(styles.HighVolume)}>
                Sending more than 20M emails? <a href='/contact'>Contact us</a> for discounted pricing.
              </p>
            )}

            <div className='mb-2 mx-5 position-relative'>
              <Slider
                min={0}
                max={this.pricingSteps.length - 1}
                step={1}
                onChange={this.onChange} />

                <p className={classnames(styles.SliderInterval1)}>10K</p>
                <p className={classnames(styles.SliderInterval2)}>7M</p>
                <p className={classnames(styles.SliderInterval3)}>20M</p>
            </div>

            <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mb-1')}>Level up your team with more data-driven insights</h5>
            <p className={classnames(styles.p1, 'mb-2')} style={{ fontFamily: 'FKDisplayAlt' }}>Select the insights you need:</p>

            <FeatureButton active='true' disabled={true} value='intelligent_routing' className='me-2'>Intelligent Routing (Free)</FeatureButton>
            <FeatureButton value='eids' disabled={true} className='me-2'>Email-based IDS (Coming Soon)</FeatureButton>

            <div className={classnames(styles.Divider, 'mt-3', 'mb-1')} />

            <div className={classnames(styles.Total, 'text-end')}>
              <span className='me-1'>{pricing.price}</span>
              <span>per month</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
