import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Base';

export default class MediumPrimarySquareButton extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
      <button
        {...params}
        className={classnames(styles.LightBackgroundMediumPrimarySquare, 'px-2', 'py-1', className)}>

        {children}
      </button>
    )
  }
}
