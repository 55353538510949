import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Create from './Create';
import Table from '../../Table';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  ipStatus = (row) => {
    const value = row['status'];
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);

    return (
      <span className={classnames(value === 'healthy' ? styles.Healthy : styles.Unhealthy)}>
        {capitalizedValue}
      </span>
    );
  }
//          <Create />

  render() {
    const headers = [
      {
        key: 'public_ip',
        value: 'IP Address',
        width: '200',
      },
      {
        key: 'rdns',
        value: 'rDNS',
      },
      {
        key: 'status',
        value: 'Status',
        callback: this.ipStatus,
        width: '150',
        className: 'text-end',
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: '220',
        className: 'text-end',
      }
    ]

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>IP Addresses</h3>
        <Table path='/api/v1/settings/ip_addresses' hrefBase='/home/settings/ip_addresses' hrefKey='id' headers={headers}>
        </Table>
      </div>
    );
  }
}
