import Plugin from "@ckeditor/ckeditor5-core/src/plugin";

import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

import InsertButtonCommand from "./buttoncommand";

export default class ButtonEditing extends Plugin {
  static get requires() {
    return [ Widget ];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add('insertButton', new InsertButtonCommand(this.editor));
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register('button', {
      isObject: true,
      isLimit: true,
      allowWhere: '$block',
      allowAttributes: ['buttonData'],
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'a',
        classes: 'btn'
      },
      model: (viewElement, conversionApi) => {
        const modelWriter = conversionApi.writer;
        const buttonData = JSON.parse(atob(viewElement.getAttribute('data-amply-source')));
        viewElement._attrs.delete('href');

        const button = modelWriter.createElement('button', { buttonData });
        modelWriter.insertText(buttonData.text, button);

        const buttonText = button.getChild(0);

        return button;
      }
    });

    conversion.for( 'editingDowncast' ).elementToElement( {
      model: 'button',
      view: (modelElement, { writer: viewWriter }) => {
        const buttonData = modelElement.getAttribute('buttonData');

        const button = viewWriter.createContainerElement( 'button', {
          style: `${getAlignStylesEditing(buttonData)}; display: block; ${getStylesFromButtonData(buttonData)}`,
          type: 'button',
        });

        return toWidget(button, viewWriter, {});
      }
    });

    conversion.for( 'dataDowncast' ).elementToElement( {
      model: 'button',
      view: (modelElement, { writer: viewWriter }) => {
        const buttonData = modelElement.getAttribute('buttonData');
        const {
          href, align, color,
          background, border,
          borderRadius, customStyles
        } = buttonData;

        // this works because of bootstrap-email magic
        // https://github.com/bootstrap-email/bootstrap-email/blob/f6ec92842d16ca3adcc661d3954c1945019cf184/lib/bootstrap-email.rb#L83
        const button = viewWriter.createContainerElement( 'a', {
          'data-amply-source': btoa(JSON.stringify(buttonData)),
          href: buttonData.href || '#',
          target: '_blank',
          class: 'btn w-100',
          style: `${getAlignStylesData(buttonData)}; ${getStylesFromButtonData(buttonData)}`,
        });

        return button;
      }
    });

  }
}

function getStylesFromButtonData(buttonData) {
  let styleStr = '';

  const {
    align, color, background, border,
    borderRadius, customStyles
  } = buttonData;

  const style = {
    color: color,
    background: background,
    border: border,
    'border-radius': borderRadius,
    'line-height': 'revert',
  };

  Object.entries(style).forEach(val => {
    if (val[1] !== undefined) {
      styleStr += `${val[0]}: ${val[1]};`
    }
  });

  styleStr += customStyles;

  return styleStr;
}

function getAlignStylesEditing(buttonData) {
  switch (buttonData.align) {
    case 'right':
      return 'margin-right: 0 !important; margin-left: auto !important';
    case 'left':
      return 'margin-left: 0 !important; margin-right: auto !important';
  }

  return 'margin-left: auto !important; margin-right: auto !important;';
}

function getAlignStylesData(buttonData) {
  switch (buttonData.align) {
    case 'right':
      return 'float: right';
    case 'left':
      return 'float: left';
  }

  return '';
}

