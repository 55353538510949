import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';

import Table from '../../../Table';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  totalUsers = (value) => {
    return value.toLocaleString();
  }

  render() {
    const { unsubscribeGroup, unsubscribeGroupId } = this.props;
    const headers = [
      {
        key: 'email',
        value: 'Email',
      },
    ];

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href={`/home/settings/unsubscribe_groups/${unsubscribeGroupId}`} className='me-1'>
            <BackButton />
          </a>

          Unsubscribed Users

          { unsubscribeGroup.loaded && (
            <span>&nbsp;of <i className={classnames(styles.ColorPrimaryPurple)}>{unsubscribeGroup.name}</i></span>
          )}
        </h3>

        <Table path={`/api/v1/settings/unsubscribe_groups/${unsubscribeGroupId}/users`} hrefBase={`/home/settings/unsubscribe_groups/${unsubscribeGroupId}/users`} hrefKey='email' headers={headers}>
          <div className='text-end'>
            <a href={`/home/settings/unsubscribe_groups/${unsubscribeGroupId}/users/new`}><Button><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button></a>
          </div>
        </Table>
      </div>
    );
  }
}
