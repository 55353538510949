import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import InputM from './InputM';
import BaseSelect from './BaseSelect';

import styles from 'styles/Base';

@observer
export default class SelectM extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
      <BaseSelect {...params} inputComponent={InputM} className={classnames(styles.SelectM, className)}>{children}</BaseSelect>
    );
  }
}
