import React, { Component } from 'react';
import classnames from 'classnames';

import Icon from 'components/Util/Icons/ButtonIcon';

import styles from 'styles/Base';

export default class LargeSecondaryIconButton extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
      <button
        {...params}
        className={classnames(styles.DarkBackgroundLargeSecondary, className)}>

        {children}

        <Icon className='ms-1' color={styles.colorPrimaryWhite} />
      </button>
    )
  }
}
