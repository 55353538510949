import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Deliverability extends Component {
  render() {
    const src = `
<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Asset 31 1" clip-path="url(#clip0_124_730)">
<g id="ICONS">
<g id="Group">
<path id="Vector" d="M75 0C33.58 0 0 33.58 0 75H75V0Z" fill="url(#paint0_linear_124_730)"/>
<path id="Vector_2" d="M75 150C116.42 150 150 116.42 150 75H75V150Z" fill="url(#paint1_linear_124_730)"/>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_124_730" x1="0" y1="37.5" x2="75" y2="37.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint1_linear_124_730" x1="75" y1="112.5" x2="150" y2="112.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<clipPath id="clip0_124_730">
<rect width="150" height="150" fill="white"/>
</clipPath>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
