import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import WebhooksShow from 'components/Dashboard/Settings/Webhooks/Show';

import api from 'models/API';
import Webhook from 'models/Webhook';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.webhook = new Webhook();
    const id = props.match.params.id;
    const type = props.match.params.type;

    let apiUrl;

    switch(type) {
      case 'inbound_parses':
        apiUrl = `/api/v1/settings/webhooks/inbound_parses/${id}`;
        break;
      case 'email_activities':
        apiUrl = `/api/v1/settings/webhooks/email_activities/${id}`;
        break;
    }

    api.get(apiUrl).then(data => {
      this.webhook.assign(data);
    });

    // TODO: make sure we get all verified domains...
    api.get('/api/v1/settings/verified_domains').then(data => {
      this.webhook.assign({ verified_domains: data });
    });
  }

  render() {
    return (
      <Content>
        <WebhooksShow webhook={this.webhook} />
      </Content>
    );
  }
}
