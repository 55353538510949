import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Icon from 'components/Util/Icons/InputAlertIcon';

import styles from 'styles/Base';

@observer
export default class BaseInput extends Component {
  constructor(props) {
    super(props);
    const { name, placeholder, defaultValue, value } = { ...this.props };
    this.ref = React.createRef();
    this.initialValue = this.getPropValue();
    this.formElement = props.store?.addFormElement({name: name, placeholder: placeholder, value: this.initialValue, ref: this.ref });
  }

  componentDidUpdate() {
    const { defaultValue, value } = this.props;

    if (this.initialValue !== this.getPropValue()) {
      // we updated the value of this component...
      this.initialValue = this.getPropValue();
      this.formElement.value = this.getPropValue();
    }
  }

  getPropValue = () => {
    return this.props.defaultValue || this.props.value;
  }

  onChange = (e, onChange) => {
    if (typeof onChange !== 'undefined') {
      onChange(e);
    }

    this.formElement.value = e.target.value;
  }

  onFocus = (e) => {
    this.formElement.active = true;

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur = (e) => {
    this.formElement.active = false;

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  render() {
    const { className, containerStyle, inputClassName, onChange, onFocus, onBlur, children, placeholder, tag, ...params } = this.props;
    const Tag = tag || 'input';
    const ContainerStyle = containerStyle || {};
    const klasses = [];

    if (this.formElement?.hasError) {
      klasses.push(styles.BgError);
    }

    if (this.formElement?.hasSuccess) {
      klasses.push(styles.BgSuccess);
    }

    return (
      <div className={classnames('d-inline-block', 'position-relative', klasses, className)} style={containerStyle}>
        { this.formElement?.active &&
          <div className={classnames(styles.Placeholder, 'd-inline-block')}>{this.formElement?.placeholder}</div>
        }

        <Tag
          {...params}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          className={classnames(inputClassName)}
          placeholder={this.formElement?.active ? '' : placeholder}
          onChange={(e) => this.onChange(e, onChange)}
          ref={this.ref} />

        {children}

        { this.formElement?.hasError &&
          <div className={classnames(styles.Error, 'd-inline-block')}>
            <Icon color={classnames(styles.colorError)} />
            <span className='ml-2'>{this.formElement?.error}</span>
          </div>
        }
        { this.formElement?.hasSuccess &&
          <div className={classnames(styles.Success, 'd-inline-block')}>
            <Icon color={classnames(styles.colorSuccess)} />
            <span className='ml-2'>{this.formElement?.success}</span>
          </div>
        }
      </div>
    );
  }
}
