import React, { Component } from 'react';
import classnames from 'classnames';

import ArrowBack from 'components/Util/Icons/ArrowBack';

import styles from 'styles/Dashboard/Base';

export default class BackButton extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
      <button
        {...params}
        className={classnames(styles.LightBackgroundBack, className)}>

        <ArrowBack />
      </button>
    )
  }
}
