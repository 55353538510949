import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';

import Table from '../../Table';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  formatType = (row) => {
    switch (row['type']) {
      case 'inbound_parse':
        return 'Inbound Parse';
      case 'email_activity':
        return 'Email Activity';
      default:
        return '';
    }
  }

  formatReceivingDomain = (row) => {
    return row['receiving_domain'] || 'N/A';
  }

  hrefBase = (row) => {
    switch(row.type) {
      case 'inbound_parse':
        return `/home/settings/webhooks/inbound_parses/${row.id}`;
      case 'email_activity':
        return `/home/settings/webhooks/email_activities/${row.id}`;
      default:
        return '/home/settings/webhooks';
    }
  }

  render() {
    const headers = [
      {
        key: 'type',
        value: 'Type',
        callback: this.formatType,
        width: 150,
      },
      {
        key: 'destination_url',
        value: 'Destination URL',
      },
      {
        key: 'receiving_domain',
        value: 'Receiving Domain',
        callback: this.formatReceivingDomain,
        width: 200,
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: 220,
        className: 'text-end',
      }
    ];

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Webhooks</h3>
        <Table path='/api/v1/settings/webhooks' hrefBase={this.hrefBase} hrefKey='id' headers={headers}>
          <div className='text-end'>
            <a href='/home/settings/webhooks/new'><Button><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button></a>
          </div>
        </Table>
      </div>
    );
  }
}
