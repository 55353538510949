import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Base';

export default class Links extends Component {
  render() {
    const links = this.props.links || [];

    return (
      <React.Fragment>
        { links.includes('sign_up') && (
          <p className={classnames(styles.p2, styles.Tint5, 'mb-1')}>No account? <a href='/?getstarted' className={classnames(styles.ColorPrimaryPurple, 'fw-bold')}>Sign Up</a></p>
        )}

        { links.includes('confirmation') && (
          <p className={classnames(styles.p2, styles.Tint5, 'mb-1')}>Didn't receive <a href='/organizations/confirmation/new' className={classnames(styles.ColorPrimaryPurple, 'fw-bold')}>confirmation?</a></p>
        )}
      </React.Fragment>
    );
  }
}
