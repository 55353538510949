import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

import styles from 'styles/Dashboard/LoadableCard';

import api from 'models/API';

@observer
export default class LoadableCard extends Component {
  state = observable.object({ loaded: false });

  componentDidMount() {
    const { loadState, iframeRef } = this.props;
    const state = this.state;
    state.loaded = this.props.loadState || false;

    if (iframeRef) {
      iframeRef.current.contentWindow.addEventListener('load', () => {
        state.loaded = true;
      });
    }
  }

  componentDidUpdate() {
    if (this.props.loadState) {
      this.state.loaded = true;
    }

  }

  onLoad = () => {
    this.state.loaded = true;
  }

  render() {
    const { loadState, iframeRef, cardClassName, children, className, ...params } = this.props;
    let displayXhrChildren = true;

    if (iframeRef === undefined && !this.state.loaded) {
      // dont display non iframe requests until loaded
      displayXhrChildren = false;
    }

    return (
      <div {...params} className={classnames(cardClassName || styles.Card2, styles.LoadableCard, this.state.loaded ? styles.LoadableCardLoaded : styles.LoadableCardLoading, className)}>
        { displayXhrChildren && children }

        <div className={classnames(this.state.loaded ? 'd-none' : 'text-center position-absolute top-50 start-50')} style={{transform: 'translate(-50%, -50%)'}}>
          <div className={classnames(styles.ColorPrimaryPurple, 'spinner-border')} role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}
