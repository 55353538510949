import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LoadableCard from 'components/Dashboard/LoadableCard';

import Navigation from './Navigation';
import Payment from './Billing/Payment';
import Promos from './Billing/Promos';
import Invoices from './Billing/Invoices';

import styles from 'styles/Dashboard/Base';

@observer
export default class Billing extends Component {
  render() {
    const { card, promos, charges, organizationAccount } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold', 'mb-1')}>Account Details</h3>

        <Navigation organizationAccount={organizationAccount} />

        <LoadableCard className='mb-2' loadState={card.loaded}>
          <Payment card={card} />
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={promos.loaded}>
          <Promos promos={promos} />
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={charges.loaded}>
          <Invoices charges={charges} />
        </LoadableCard>
      </div>
    );
  }
}
