import React, { Component } from 'react';
import classnames from 'classnames';

import Base from '../Base';
import Links from '../Links';

import Form from 'components/Util/Inputs/Form';
import InputL from 'components/Util/Inputs/InputL';
import Button from 'components/Util/Buttons/LightBackground/LargePrimarySquareButton';

import Store from 'models/Form';
import alertStore from 'models/Alert';

import styles from 'styles/Marketing/Organizations/Sessions/New';

export default class New extends Component {
  store = new Store;

  onSuccess = () => {
    alertStore.setContents(
      <React.Fragment>
        <h3 className={classnames(styles.h3, 'text-center')}>Password Updated!</h3>
        <p className={classnames(styles.p2, 'mb-0', 'text-center')}>Your password has been updated. Please use the form to sign in.</p>
      </React.Fragment>
    );

    alertStore.persistAlert();

    window.location = '/organizations/sign_in';
  }

  render() {
    return (
      <Base>
        <h3 className={classnames(styles.h3, 'text-center')}>Update Password</h3>
        <Form method='put' action='/organizations/password' onSuccess={this.onSuccess} store={this.store}>
          <InputL className='d-none' defaultValue={this.props.token} name='organization_account[reset_password_token]' type='hidden' store={this.store} />
          <InputL className='mb-2 w-100' name='organization_account[password]' placeholder='Password*' type='password' store={this.store} />
          <InputL className='mb-2 w-100' name='organization_account[password_confirmation]' placeholder='Password Confirmation*' type='password' store={this.store} />
          <Button className='w-100 mb-3'>Update Password</Button>
        </Form>

        <div className='text-center'>
          <Links links={['sign_up', 'confirmation']} />
        </div>
      </Base>
    );
  }
}
