import { observable, computed, action } from 'mobx';

export default class Dashboard {
  @observable id;
  @observable name;
  @observable widgets = observable.array([]);

  constructor(id) {
    this.id = id;
  }
}
