import { observable, computed, action } from 'mobx';

export default class Subscription {
  @observable user;
  @observable unsubscribe_group;
  @observable unsubscribe_reasons;
  @observable is_unsubscribed;

  assign(params) {
    this.is_unsubscribed = params.is_unsubscribed !== undefined ? params.is_unsubscribed : this.is_unsubscribed
    this.user = params.user || this.user;
    this.unsubscribe_group = params.unsubscribe_group || this.unsubscribe_group;
    this.unsubscribe_reasons = params.unsubscribe_reasons || this.unsubscribe_reasons;
  }

  @computed
  get loaded() {
    return this.is_unsubscribed !== undefined;
  }
}
