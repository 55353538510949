import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import UnsubscribeGroupsNew from 'components/Dashboard/Settings/UnsubscribeGroups/New';

import UnsubscribeGroup from 'models/UnsubscribeGroup';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    this.unsubscribeGroup = new UnsubscribeGroup(); 
    this.unsubscribeGroup.assign({ id: -1 });
  }


  render() {
    return (
      <Content>
        <UnsubscribeGroupsNew unsubscribeGroup={this.unsubscribeGroup} />
      </Content>
    );
  }
}
