import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Plane extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryPurple;

    const src = `
<svg width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.209 23.5787L23.5425 6.22325C23.598 6.10879 23.4501 6.00523 23.3605 6.09476L9.8353 19.7977L9.84069 19.8026L0 12.8769L27.7857 0L25.0442 30.5007L15.209 23.5787Z" fill="${color}"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
