import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Visualizations';

function compactNumberFormatter() {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });
}

export function ValueChanged(props) {
  const { widget, options } = props;

  // useEffect(() => {
  // });

  const value = widget.data.value;
  const percentChange = parseInt(widget.data.percent_change);
  let prefix, klass;

  if (percentChange == 0) {
    prefix = '+';
    klass = styles.Neutral;
  }
  else if (percentChange > 0) {
    prefix = '+';
    klass = styles.Increase;
  }
  else {
    prefix = ''; // we already have the minus sign...
    klass = styles.Decrease;
  }

  const formattedValue = compactNumberFormatter().format(value, 1);

  return (
    <div className={classnames(styles.ValueChanged, 'd-flex', 'align-items-center', 'w-100', 'h-100')}>
      <div className='mx-auto' className={classnames(klass)}>
        <div>
          <p>{formattedValue}</p>
          <p>{prefix}{percentChange}%</p>
        </div>
      </div>
    </div>
  );
}

export default observer(ValueChanged);
