import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Daily from './Daily';

@observer
export default class DailyWithStickiness extends Component {
  formatLabel = (object) => {
    const stickinessTotal = object.dataset.stickiness_total;
    let stickiness = 0;

    if (stickinessTotal > 0) {
      const ratio = object.raw / stickinessTotal;
      stickiness = (Math.round((ratio + Number.EPSILON) * 100) / 100) || 0;
    }

    return [
      `${object.dataset.label}: ${object.raw.toLocaleString()}`,
      `Stickiness: ${stickiness.toLocaleString()}`
    ];
  }

  render() {
    const { widget, options, chartRef } = this.props;

    widget.data.datasets[0].tooltip = {
      callbacks: {
        label: this.formatLabel
      }
    }

    return (
      <Daily widget={widget} options={options} chartRef={chartRef} />
    );
  }
}
