import React, { Component } from 'react';
import classnames from 'classnames';
import { observable } from 'mobx';

// Orbs
import Section1 from './Section1';
// DevOps
import Section2 from './Section2';
// DevOps
import Section3 from './Section3';
// Product
import Section4 from './Section4';
// Product
import Section5 from './Section5';
// Security
import Section6 from './Section6';
// CTA
import CTA from '../CTA';

export default class Show extends Component {
  activeBreakpoint = observable.object({ index: undefined });

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  onScroll = (e) => {
    const scrollY = window.scrollY;

    // ELEMENT.getBoundingClientRect().top + document.documentElement.scrollTop - 500px

    if (scrollY > 100 && scrollY <= 500) {
      this.activeBreakpoint.index = 0;
    }
    else if (scrollY > 500 && scrollY <= 1500) {
      this.activeBreakpoint.index = 1;
    }
    else if (scrollY > 1500 && scrollY <= 1730) {
      this.activeBreakpoint.index = 2;
    }
    else if (scrollY > 1730 && scrollY <= 1930) {
      this.activeBreakpoint.index = 3;
    }
    else if (scrollY > 1930 && scrollY <= 3650) {
      this.activeBreakpoint.index = 4;
    }
    else if (scrollY > 3650 && scrollY <= 3960) {
      this.activeBreakpoint.index = 5;
    }
    else if (scrollY > 3960) {
      this.activeBreakpoint.index = 6;
    }
    else {
      this.activeBreakpoint.index = undefined;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Section1 />
        <Section2 activeBreakpoint={this.activeBreakpoint} />
        <Section3 activeBreakpoint={this.activeBreakpoint} />
        <Section4 />
        <Section5 />
        <Section6 activeBreakpoint={this.activeBreakpoint} />
        <CTA />
      </React.Fragment>
    );
  }
}
