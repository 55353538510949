import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Alerts extends Component {
  render() {
    const src = `
<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_124_740)">
<path d="M50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25Z" fill="url(#paint0_linear_124_740)"/>
<path d="M100 50H50V100H100V50Z" fill="url(#paint1_linear_124_740)"/>
<path d="M150 0H100V50H150V0Z" fill="url(#paint2_linear_124_740)"/>
<path d="M50 100H0V150H50V100Z" fill="url(#paint3_linear_124_740)"/>
<path d="M150 100H100V150H150V100Z" fill="url(#paint4_linear_124_740)"/>
</g>
<defs>
<linearGradient id="paint0_linear_124_740" x1="0" y1="25" x2="50" y2="25" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint1_linear_124_740" x1="50" y1="75" x2="100" y2="75" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint2_linear_124_740" x1="100" y1="0" x2="150" y2="0" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint3_linear_124_740" x1="0" y1="125" x2="1" y2="125" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint4_linear_124_740" x1="100" y1="125" x2="150" y2="125" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<clipPath id="clip0_124_740">
<rect width="150" height="150" fill="white"/>
</clipPath>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
