import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Spacer extends Component {
  render() {
    const style = {
      position: 'absolute',
      left: '-16px',
      width: 'calc(100% + 32px)',
      height: '1px',
      backgroundColor: styles.tint2,
      boxShadow: `-15px 0 15px -15px #{styles.tint2} inset`,
    };

    return (
      <div className='position-relative w-100 py-2'>
        <div style={style} />
      </div>
    );
  }
}



