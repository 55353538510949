import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class StarSupport extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.89999 18.4L11.5 14.8925L16.1 18.4L14.26 12.7362L18.86 9.48749H13.2538L11.5 3.65125L9.7175 9.48749H4.11125L8.71124 12.7362L6.89999 18.4ZM11.5 23C9.92833 23 8.44292 22.6981 7.04375 22.0944C5.64459 21.4906 4.4227 20.6665 3.37813 19.6219C2.33354 18.5773 1.50937 17.3554 0.905624 15.9562C0.30188 14.5571 0 13.0717 0 11.5C0 9.90915 0.30188 8.41415 0.905624 7.01498C1.50937 5.61581 2.33354 4.39874 3.37813 3.36374C4.4227 2.32877 5.64459 1.50937 7.04375 0.905609C8.44292 0.30188 9.92833 0 11.5 0C13.0908 0 14.5858 0.30188 15.985 0.905609C17.3842 1.50937 18.6013 2.32877 19.6362 3.36374C20.6712 4.39874 21.4906 5.61581 22.0944 7.01498C22.6981 8.41415 23 9.90915 23 11.5C23 13.0717 22.6981 14.5571 22.0944 15.9562C21.4906 17.3554 20.6712 18.5773 19.6362 19.6219C18.6013 20.6665 17.3842 21.4906 15.985 22.0944C14.5858 22.6981 13.0908 23 11.5 23ZM11.5 21.275C14.2217 21.275 16.5312 20.3214 18.4287 18.4144C20.3262 16.5073 21.275 14.2025 21.275 11.5C21.275 8.77832 20.3262 6.46875 18.4287 4.57126C16.5312 2.67374 14.2217 1.72501 11.5 1.72501C8.7975 1.72501 6.49271 2.67374 4.58563 4.57126C2.67854 6.46875 1.72501 8.77832 1.72501 11.5C1.72501 14.2025 2.67854 16.5073 4.58563 18.4144C6.49271 20.3214 8.7975 21.275 11.5 21.275Z" fill="#4836FF"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
