import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';

import Config from './Config';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class New extends Component {
  store = new Store();

  action = () => {
    const type = this.store.get('type')?.value;

    switch(type) {
      case 'inbound_parse':
        return `/api/v1/settings/webhooks/inbound_parses`;
      case 'email_activity':
        return `/api/v1/settings/webhooks/email_activities`;
    }
  }

  render() {
    const { webhook } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/webhooks' className='me-1'>
            <BackButton />
          </a>

          New Webhook
        </h3>

        <LoadableCard className='mb-2' loadState={webhook.loaded}>
          <Config method='post' action={this.action()} store={this.store} webhook={webhook} />
        </LoadableCard>
      </div>
    );
  }
}
