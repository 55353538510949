import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import WebhooksNew from 'components/Dashboard/Settings/Webhooks/New';

import api from 'models/API';
import Webhook from 'models/Webhook';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    this.webhook = new Webhook();
    this.webhook.assign({ id: -1 });

    // TODO: make sure we get all verified domains...
    api.get('/api/v1/settings/verified_domains').then(data => {
      this.webhook.assign({ verified_domains: data });
    });
  }


  render() {
    return (
      <Content>
        <WebhooksNew webhook={this.webhook} />
      </Content>
    );
  }
}
