import { observable, computed, action } from 'mobx';
import { get } from 'helpers/apiHelper';

export default class Plan {
  @observable mode;
  @observable base_price;
  @observable prorated_base_price;
  @observable tier_volume_price;
  @observable tier_volume_interval;
  @observable tier_volume_base;
  @observable month_estimate;
  @observable created_at;
  @observable updated_at;
  @observable loaded;

  assign(params) {
    this.mode = params.mode;
    this.base_price = params.base_price;
    this.prorated_base_price = params.prorated_base_price;
    this.tier_volume_price = params.tier_volume_price;
    this.tier_volume_interval = params.tier_volume_interval;
    this.tier_volume_base = params.tier_volume_base;
    this.month_estimate = params.month_estimate;
    this.created_at = params.created_at;
    this.updated_at = params.updated_at;
  }

  currency_formatter(d) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: d,
      maximumFractionDigits: d,
    });
  }

  compact_number_formatter(n, d) {
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
    });
  }

  fetch() {
  }

  fetch_new_plan() {
    const plan = this;

    get('/plans/new').then(data => {
      plan.assign(data);
      plan.loaded = true;
    });
  }

  @computed
  get base_price_formatted() {
    return this.currency_formatter(0).format(this.base_price);
  }

  get prorated_base_price_formatted() {
    return this.currency_formatter(2).format(this.prorated_base_price);
  }

  get month_estimate_formatted() {
    return this.currency_formatter(2).format(this.month_estimate);
  }

  get tier_volume_price_formatted() {
    return this.currency_formatter(0).format(this.tier_volume_price);
  }

  get tier_volume_base_formatted() {
    return this.compact_number_formatter().format(this.tier_volume_base, 0);
  }

  get tier_volume_interval_formatted() {
    return this.compact_number_formatter().format(this.tier_volume_interval, 0);
  }
}
