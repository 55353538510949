import { observable, computed, action } from 'mobx';

export default class SSOConnection {
  @observable id;
  @observable uuid;
  @observable name;
  @observable idp_metadata;
  @observable sso_url;
  @observable audience_uri;
  @observable created_at;

  assign(params) {
    this.uuid = params.uuid || this.uuid;
    this.name = params.name || this.name;
    this.idp_metadata = params.idp_metadata || this.idp_metadata;
    this.sso_url = params.sso_url || this.sso_url;
    this.audience_uri = params.audience_uri || this.audience_uri;
    this.created_at = params.created_at || this.created_at;

    this.id = params.id || this.id;
  }

  get loaded() {
    return this.id !== undefined;
  }
}
