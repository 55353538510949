import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class ExploreData extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.28068 16.492L0 15.5511L4.91364 7.71022L8.03878 11.3693L12.2057 4.62613L15.0545 8.86021C14.7758 8.89507 14.5057 8.95169 14.2443 9.03011C13.983 9.10852 13.7216 9.2 13.4602 9.30453L12.2841 7.50113L8.2968 14.0091L5.12273 10.3239L1.28068 16.492ZM21.8761 21.9545L18.3739 18.4523C18.0079 18.7136 17.6115 18.914 17.1847 19.0534C16.7578 19.1928 16.3178 19.2625 15.8648 19.2625C14.6306 19.2625 13.5815 18.8302 12.7175 17.9657C11.8536 17.1011 11.4216 16.0513 11.4216 14.8162C11.4216 13.5812 11.8539 12.5324 12.7184 11.6699C13.583 10.8074 14.6328 10.3761 15.8679 10.3761C17.1029 10.3761 18.1517 10.8081 19.0142 11.672C19.8767 12.536 20.308 13.5851 20.308 14.8193C20.308 15.2723 20.2339 15.7123 20.0858 16.1392C19.9377 16.5661 19.7417 16.9751 19.4977 17.3662L23 20.8307L21.8761 21.9545ZM15.8611 17.6943C16.6651 17.6943 17.3458 17.4167 17.9034 16.8616C18.461 16.3065 18.7398 15.627 18.7398 14.823C18.7398 14.019 18.4622 13.3383 17.9071 12.7807C17.352 12.2231 16.6724 11.9443 15.8685 11.9443C15.0645 11.9443 14.3837 12.2219 13.8261 12.777C13.2686 13.3321 12.9898 14.0117 12.9898 14.8156C12.9898 15.6196 13.2673 16.3004 13.8224 16.8579C14.3776 17.4155 15.0571 17.6943 15.8611 17.6943ZM17.7989 9.12158C17.5375 9.01704 17.2674 8.94734 16.9886 8.91249C16.7098 8.87764 16.4223 8.8428 16.1261 8.80795L21.7193 0L23 0.940903L17.7989 9.12158Z" fill="#4836FF"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
