import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import VerifiedDomainShow from 'components/Dashboard/Settings/VerifiedDomains/Show';

import api from 'models/API';
import VerifiedDomain from 'models/VerifiedDomain';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.verifiedDomain = new VerifiedDomain();
    const id = props.match.params.id;

    api.get(`/api/v1/settings/verified_domains/${id}/dns`).then(data => {
      this.verifiedDomain.assign({ dns: data });
    })

    api.get(`/api/v1/settings/verified_domains/${id}`).then(data => {
      this.verifiedDomain.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <VerifiedDomainShow verifiedDomain={this.verifiedDomain} />
      </Content>
    );
  }
}
