import moment from 'moment-timezone';

export const formatCell = (row, key, callback) => {
  if (callback) {
    return callback(row, key);
  }

  return formatTime(row[key]);
}

export const formatTime = (string) => {
  string = String(string || '');

  if (string.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)) {
    const time = moment(string);

    if (time.isValid()) {
      const tz = moment.tz.guess();
      return time.tz(tz).format('MM/DD/YYYY h:mm A z');
    }

    return '';
  }

  return string;
}
