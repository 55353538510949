import { toJS, observable, computed, action } from 'mobx';

export default class VerifiedDomain {
  @observable id;
  @observable name;
  @observable status;
  @observable deliverability_strategy;
  @observable blocklists = [];

  @observable custom_deliverability_strategy_smtp_host;
  @observable custom_deliverability_strategy_smtp_port;
  @observable custom_deliverability_strategy_username;
  @observable decrypted_custom_deliverability_strategy_password;
  @observable custom_deliverability_strategy_percent;

  @observable verified_at;
  @observable created_at;

  @observable is_verifying = false;

  @observable dns = {};
  @observable errors = [];

  assign(params) {
    this.name = params.name || this.name;
    this.status = params.status || this.status;
    this.deliverability_strategy = params.deliverability_strategy || this.deliverability_strategy;
    this.dns = params.dns || this.dns;

    this.custom_deliverability_strategy_smtp_host = params.custom_deliverability_strategy_smtp_host || this.custom_deliverability_strategy_smtp_host;
    this.custom_deliverability_strategy_smtp_port = params.custom_deliverability_strategy_smtp_port || this.custom_deliverability_strategy_smtp_port;
    this.custom_deliverability_strategy_username = params.custom_deliverability_strategy_username || this.custom_deliverability_strategy_username;
    this.decrypted_custom_deliverability_strategy_password = params.decrypted_custom_deliverability_strategy_password || this.decrypted_custom_deliverability_strategy_password;
    this.custom_deliverability_strategy_percent = params.custom_deliverability_strategy_percent || this.custom_deliverability_strategy_percent;

    this.verified_at = params.verified_at || this.verified_at;
    this.created_at = params.created_at || this.created_at;
    this.id = params.id || this.id;
  }

  @computed
  get namePlaceholder() {
    const name = this.name || '';

    if (name.length > 0) {
      return name;
    }

    return 'example.com';
  }

  get statusFormatted() {
    switch (this.status) {
      case 'healthy':
        return 'Healthy';
      case 'unhealthy':
        return 'Unhealthy';
      case 'pending_verification':
        return 'Pending verification';
    }
  }

  get isHighlyAvailable() {
    if (this.deliverability_strategy === 'auto') {
      return 'Enabled';
    }

    else if (this.deliverability_strategy === 'custom' &&
             this.custom_deliverability_strategy_smtp_host !== null &&
             this.custom_deliverability_strategy_smtp_port !== null &&
             this.custom_deliverability_strategy_username !== null &&
             this.decrypted_custom_deliverability_strategy_password !== null) {

      return 'Enabled';
    }

    return 'Disabled';
  }

  get spfHost() {
    return `amp.${this.name}`;
  }

  get dkimHost() {
    return `amp._domainkey.${this.name}`;
  }

  get whitelabelHost() {
    return `c.amp.${this.name}`;
  }

  get sharedHost() {
    return this.name;
  }

  get loaded() {
    return this.id !== undefined;
  }
}
