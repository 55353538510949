import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import Content from 'components/Dashboard/Content';
import OrganizationAccountsSettingsShow from 'components/Dashboard/Users/Settings/Show';

import api from 'models/API';
import Role from 'models/Role';
import OrganizationAccount from 'models/OrganizationAccount';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.organizationAccount = new OrganizationAccount();
    this.roles = observable.array([]);

    api.get(`/api/v1/users/settings`).then(data => {
      this.organizationAccount.assign({ id: -1, settings: data });
    });

    api.get(`/api/v1/users/roles`).then(data => {
      if (Array.isArray(data)) {
        for (const role of data) {
          const r = new Role(role);
          this.roles.push(r);
        }
      }
    });
  }

  render() {
    return (
      <Content>
        <OrganizationAccountsSettingsShow organizationAccount={this.organizationAccount} roles={this.roles} />
      </Content>
    );
  }
}
