import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';

import Store from 'models/Form';
import api from 'models/API';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Settings extends Component {
  store = new Store(); // use new store
  errors = observable.array([]);

  getNewName() {
    const { store, template } = this.props;
    const name = store.get('name')?.value || template.name || 'New Template';
    const group = name.match(/\((\d*)\)$/);
    let newName;

    if (group) {
      // duplicating a duplicate
      const index = parseInt(group[1] || 0) + 1;
      newName = name.replace(/\((\d*)\)$/, `(${index})`);
    }
    else {
      newName = name + ' (1)';
    }

    return newName;
  }

  onClick = () => {
    const { template } = this.props;
    const name = this.getNewName();

    const data = {
      name: name,
      body: template.editor.root.JSON,
    }

    this.errors.clear();

    api.post('/api/v1/settings/templates', data).then(data => {
      if (data.errors?.length > 0) {
        for (const error of data.errors) {
          this.errors.push(error.message);
        }
      }
      else {
        window.location = `/home/settings/templates/${data.id}`;
      }
    });
  }

  render() {
    const { store, template } = this.props;
    const errorStyle = {
      fontSize: '11px',
      color: styles.colorError,
    }

    return (
      <div className={classnames(styles.MaxWidth, 'mx-auto', 'p-2')}>
        <div className={classnames(styles.Card2, 'p-2')}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Settings</h5>

          <label className={classnames(styles.Label, 'mb-2')}>
            <p>UUID</p>
            <p className={classnames(styles.InputDescription, 'mb-1')}>The universally unique identifier of your template.</p>
            <InputM
              style={{ width: '325px'}}
              name='uuid'
              value={template.uuid}
              disabled={true}
              store={this.store} />
          </label>

          <label className={classnames(styles.Label, 'mb-1')}>
            <p className='mb-1'>Duplicate</p>
            <Button type='button' onClick={this.onClick} style={{ fontSize: '13px' }}>Make a copy!</Button>
          </label>

          { this.errors.map((error, index) =>
            <p key={index} className='mb-0' style={errorStyle}>{error}</p>
          )}
        </div>
      </div>
    );
  }
}
