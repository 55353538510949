import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import Button from 'components/Util/Buttons/DarkBackground/LargeSecondaryIconButton';

import styles from 'styles/Marketing/Platform/Section1';

export default class Section1 extends Component {
  render() {
    const orbs = [
      // LIGH BLUE ORGB
      {
        width: '200px',
        height: '200px',
        bottom: '0px',
        right: 'calc(50% - 250px)',
        background: '#18cefe'
      },
      // PURPLE ORBS
      // bottom left
      {
        width: '600px',
        height: '600px',
        bottom: '-250px',
        left: 'calc(50% - 1050px)',
        background: styles.colorPrimaryPurple,
      },
      // bottom center
      { 
        width: '800px',
        height: '800px',
        bottom: '-705px',
        right: 'calc(50% - 400px)',
        background: styles.colorPrimaryPurple,
      },
      // bottom left
      { 
        width: '100px',
        height: '100px',
        bottom: '60px',
        left: 'calc(50% - 250px)',
        background: styles.colorPrimaryPurple,
      },
      // bottom left
      {
        width: '300px',
        height: '300px',
        top: '80px',
        right: 'calc(50% - 550px)',
        background: styles.colorPrimaryPurple,
      },
    ];

    return (
      <Orbs orbs={orbs} blurEffect={true}>
        <div className={classnames(styles.Container, styles.Fold, 'mx-auto', 'text-center')}>
          <div className='mx-auto'>
            <h1 className={classnames(styles.h1, styles.ColorPrimaryWhite, 'mb-3')}>One platform. <br/>Limitless possibilities.</h1>
            <p className={classnames(styles.h5, styles.Tint4, 'mb-3', 'mx-auto')}>Amply brings tangible business value to transactional emails with data-driven features to ease the burden on DevOps, improve the customer experience, and heighten security.</p>
            <a href='/?getstarted'><Button className='mb-3'>Try Amply Today</Button></a>
          </div>
        </div>
      </Orbs>
    );
  }
}
