import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import DividerInverse from '../DividerInverse';

import Line2 from './Images/Section3/Line2';

import styles from 'styles/Marketing/Platform/Section3';

@observer
export default class Section3 extends Component {
  render() {
    const activeBreakpoint = this.props.activeBreakpoint;

    return (
      <div className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'px-2', 'px-lg-4', 'mx-auto', 'position-relative')}>
          <div className={classnames(styles.Planet)} />

          <div className={classnames(styles.Line2, 'mx-auto', 'mb-1')}>
            <Line2 />
            <div className={classnames(styles.Line2Bg)} />
            <div className={classnames(styles.Breakpoint3, activeBreakpoint?.index == 2 ? styles.Active : '')} />
            <div className={classnames(styles.Breakpoint4, activeBreakpoint?.index == 3 ? styles.Active : '')} />
            <div className={classnames(styles.Breakpoint5, activeBreakpoint?.index == 4 ? styles.Active : '')} />
          </div>

          <div className='row mx-lg-4 mx-0 mb-0'>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card4, 'h-100', 'p-2')}>
                <h5 className={classnames(styles.h5, styles.ColorPrimaryWhite)}>Automate <br/>deliverability with <br/>intelligent routing.<br/>&nbsp;</h5>
                <p className={classnames(styles.p2, styles.ColorPrimaryWhite)}>Essential deliverability decisions like which IP addresses to send from and how many to use are typically set-and-forget upon configuration. This approach leads to deliverability challenges since each use case is unique and business needs are always evolving.</p>

                <p className={classnames(styles.p2, styles.ColorPrimaryWhite, 'mb-1')}>Amply intelligently routes your email the best path to reach your customer’s primary inbox and avoid bounces. Free your DevOps team from the challenges of unpredictable and poor deliverability with a highly available email infrastructure.</p>
              </div>
            </div>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card4, 'h-100', 'p-2')}>
                <h5 className={classnames(styles.h5, styles.ColorPrimaryWhite)}>Prevent sending <br/>outages that lead to <br/>customer churn. <br/>Stay in the know.</h5>
                <p className={classnames(styles.p2, styles.ColorPrimaryWhite)}>Don’t wait for your customers to tell you about sending outages. Be the first to know about blocklists, IP and domain reputation dings, unhealthy bounce rates, spam reports, and more with customizable alerts.</p>

                <p className={classnames(styles.p2, styles.ColorPrimaryWhite, 'mb-1')}>Amply’s monitoring enables DevOps teams to maintain a healthier infrastructure and proactively stop outages before they happen.</p>
              </div>
            </div>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card4, 'h-100', 'p-2')}>
                <h5 className={classnames(styles.h5, styles.ColorPrimaryWhite)}>Improve your <br/>infrastructure health. <br/>See the full scope of <br/>events.</h5>
                <p className={classnames(styles.p2, styles.ColorPrimaryWhite, 'mb-1')}>Access all of your critical delivery events with comprehensive user profiles. Amply saves up to 2 years of email event logs and provides powerful SQL search capabilities so your team can see everything they need to maintain a healthy infrastructure.</p>
              </div>
            </div>
          </div>
        </div>

        <DividerInverse style={{ bottom: '-490px' }} />
      </div>
    );
  }
}
