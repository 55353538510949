import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import UnsubscribeGroupsUsersShow from 'components/Dashboard/Settings/UnsubscribeGroups/Users/Show';

import UnsubscribeGroup from 'models/UnsubscribeGroup';
import OrganizationUser from 'models/OrganizationUser';
import api from 'models/API';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    const unsubscribeGroupId = this.props.match.params.id;
    const email = this.props.match.params.email;

    this.unsubscribeGroup = new UnsubscribeGroup(); 
    this.organizationUser = new OrganizationUser(); 

    api.get(`/api/v1/settings/unsubscribe_groups/${unsubscribeGroupId}`).then(data => {
      this.unsubscribeGroup.assign(data);
    });

    api.get(`/api/v1/analytics/users/${email}`).then(data => {
      this.organizationUser.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <UnsubscribeGroupsUsersShow unsubscribeGroup={this.unsubscribeGroup} organizationUser={this.organizationUser} />
      </Content>
    );
  }
}
