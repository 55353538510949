import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Util/Misc/Orbs';

export default class Orbs extends Component {
  blurElement = React.createRef();

  componentDidMount() {
    if (this.props.blurEffect) {
      window.addEventListener('mousemove', this.onMouseMove);
    }
  }

  onMouseMove = (e) => {
    this.blurElement.current.style.clipPath = this.makeCircleHoleClipPathRule(48, e.clientX, e.clientY);
  }

  makeCircleHoleClipPathRule = (radius, mouseX, mouseY) => {
    const inner_path = [];
    const circumference = Math.PI * radius;
    const step = Math.PI * 2 / circumference;

    const bounding = this.blurElement.current.getBoundingClientRect();

    // we are coming from top-left corner
    const start_step = circumference * (5 / 8);
    for( let i = start_step; i < circumference + start_step; i++ ) {
      const angle = step * i;
      const x = radius * Math.cos( angle );
      const y = radius * Math.sin( angle );
      const str = `calc( ${mouseX}px + ${ x }px ) calc( ${mouseY - bounding.top}px + ${ y }px )`;
      inner_path.push( str );
    }

    // avoid rounding issues
    inner_path.push( inner_path[ 0 ] );

    return `polygon( evenodd,
      /* outer rect */
      0 0,       /* top - left */
      100% 0,    /* top - right */
      100% 100%, /* bottom - right */
      0% 100%,   /* bottom - left */
      0 0,       /* and top - left again */
      ${ inner_path.join( "," ) }
     )`;
  }

  render() {
    const { className, blurEffect, ...params } = this.props;

    return (
      <div {...params} className={classnames(styles.Section, className)}>
        { this.props.orbs.map((style, index) =>
          <div key={index} style={style} className={classnames(styles.Orb)} />
        )}
        <div className={classnames(styles.Content)}>{this.props.children}</div>
        <div className={classnames(styles.Blur)} ref={this.blurElement} />
      </div>
    );
  }
}
