import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LineBar from './Charts/LineBar';

import styles from 'styles/Visualizations';

const createGradient = (chart, colorStart, colorEnd) => {
  const ctx = chart.ctx;
  const area = chart.chartArea;

  const centerX = area.width / 2;
  const centerY = area.height / 2;

  const gradient = ctx.createLinearGradient(0, area.height, 0, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const baseStyles = {
  borderWidth: 1.5,
  pointRadius: 1.5,
  pointHoverRadius: 3,
  tension: 0,
}

export function BounceSpamRate(props) {
  const { widget, options } = props;

  options.plugins.tooltip.itemSort = (a, b) => {
    return a.datasetIndex - b.datasetIndex
  }

  const chartData = {
    ...widget.data,
    datasets: widget.data.datasets.map(dataset => ({
      ...dataset,
    })),
  };

  useEffect(() => {
    const chart = props.chartRef.current;

    if (chart) {
      if (!widget.data.datasets[0].backgroundColor) {
        const bounceColor = styles.tint7;
        const bounceBorderColor = styles.tint7;

        const bounceStyles = {
          borderColor: bounceBorderColor,
          pointBackgroundColor: bounceColor,
          fill: true,
          backgroundColor: 'rgba(11,21,30, 0.3)',
          order: 100,
        }

        widget.data.datasets[0] = {
          ...widget.data.datasets[0],
          ...bounceStyles,
          ...baseStyles,
        };

        const spamColor = '#ff4058';
        const spamBorderColor = '#ff4058';

        const spamStyles = {
          borderColor: spamBorderColor,
          pointBackgroundColor: spamColor,
          fill: true,
          backgroundColor: 'rgba(255,64,88, 0.3)',
          order: 95,
        }


        options.scales = {
          y: {
            suggestedMax: 0.1,
          }
        }

        options.plugins.annotation = {
          annotations: {
            bounceLine: {
              type: 'line',
              yMin: 0.05,
              yMax: 0.05,
              borderDash: [12, 8], // lineWidth, spacing
              borderWidth: 1,
              borderColor: bounceBorderColor,
            },
            spamLine: {
              type: 'line',
              yMin: 0.01,
              yMax: 0.01,
              borderDash: [12, 8],
              borderWidth: 1,
              borderColor: spamBorderColor,
            }
          }
        }

        widget.data.datasets[1] = {
          ...widget.data.datasets[1],
          ...baseStyles,
          ...spamStyles,
        };
      }
    }
  });

  return (
    <div className={classnames(styles.BounceSpamRate, 'row', 'h-100')}>
      <div className='col-12 d-flex'>
        <LineBar id={widget.id} data={chartData} options={options} chartRef={props.chartRef} />
      </div>
    </div>
  );
}

export default observer(BounceSpamRate);
