import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class GDPR extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="78" height="77" viewBox="0 0 78 77" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="19" height="19" transform="translate(29.5 18)" fill="transparent"></rect>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.625 21.9583C36.625 20.6467 37.6883 19.5833 39 19.5833C40.3117 19.5833 41.375 20.6467 41.375 21.9583L41.375 24.3333L36.625 24.3333V21.9583ZM42.9583 24.3333V21.9583C42.9583 19.7722 41.1861 18 39 18C36.8139 18 35.0417 19.7722 35.0417 21.9583L35.0417 24.3333H33.4583C32.5839 24.3333 31.875 25.0422 31.875 25.9167V33.8333C31.875 34.7078 32.5839 35.4167 33.4583 35.4167H44.5417C45.4161 35.4167 46.125 34.7078 46.125 33.8333L46.125 25.9167C46.125 25.0422 45.4161 24.3333 44.5417 24.3333H42.9583Z" fill="${color}"></path>
<path d="M39 0.75L40.1588 3.0975L42.75 3.47625L40.875 5.3025L41.3175 7.8825L39 6.66375L36.6825 7.8825L37.125 5.3025L35.25 3.47625L37.8412 3.0975L39 0.75Z" fill="${color}"></path>
<path d="M39 68.75L40.1588 71.0975L42.75 71.4763L40.875 73.3025L41.3175 75.8825L39 74.6638L36.6825 75.8825L37.125 73.3025L35.25 71.4763L37.8412 71.0975L39 68.75Z" fill="${color}"></path>
<path d="M73 34.75L74.1588 37.0975L76.75 37.4763L74.875 39.3025L75.3175 41.8825L73 40.6638L70.6825 41.8825L71.125 39.3025L69.25 37.4763L71.8412 37.0975L73 34.75Z" fill="${color}"></path>
<path d="M5 34.75L6.15875 37.0975L8.75 37.4763L6.875 39.3025L7.3175 41.8825L5 40.6638L2.6825 41.8825L3.125 39.3025L1.25 37.4763L3.84125 37.0975L5 34.75Z" fill="${color}"></path>
<path d="M22 5.75L23.1588 8.0975L25.75 8.47625L23.875 10.3025L24.3175 12.8825L22 11.6638L19.6825 12.8825L20.125 10.3025L18.25 8.47625L20.8412 8.0975L22 5.75Z" fill="${color}"></path>
<path d="M56 5.75L57.1588 8.0975L59.75 8.47625L57.875 10.3025L58.3175 12.8825L56 11.6638L53.6825 12.8825L54.125 10.3025L52.25 8.47625L54.8412 8.0975L56 5.75Z" fill="${color}"></path>
<path d="M56 63.75L57.1588 66.0975L59.75 66.4763L57.875 68.3025L58.3175 70.8825L56 69.6638L53.6825 70.8825L54.125 68.3025L52.25 66.4763L54.8412 66.0975L56 63.75Z" fill="${color}"></path>
<path d="M22 63.75L23.1588 66.0975L25.75 66.4763L23.875 68.3025L24.3175 70.8825L22 69.6638L19.6825 70.8825L20.125 68.3025L18.25 66.4763L20.8412 66.0975L22 63.75Z" fill="${color}"></path>
<path d="M10 17.75L11.1588 20.0975L13.75 20.4763L11.875 22.3025L12.3175 24.8825L10 23.6638L7.6825 24.8825L8.125 22.3025L6.25 20.4763L8.84125 20.0975L10 17.75Z" fill="${color}"></path>
<path d="M67 17.75L68.1588 20.0975L70.75 20.4763L68.875 22.3025L69.3175 24.8825L67 23.6638L64.6825 24.8825L65.125 22.3025L63.25 20.4763L65.8412 20.0975L67 17.75Z" fill="${color}"></path>
<path d="M67 50.75L68.1588 53.0975L70.75 53.4763L68.875 55.3025L69.3175 57.8825L67 56.6638L64.6825 57.8825L65.125 55.3025L63.25 53.4763L65.8412 53.0975L67 50.75Z" fill="${color}"></path>
<path d="M10 50.75L11.1588 53.0975L13.75 53.4763L11.875 55.3025L12.3175 57.8825L10 56.6638L7.6825 57.8825L8.125 55.3025L6.25 53.4763L8.84125 53.0975L10 50.75Z" fill="${color}"></path>
<path d="M26.8137 45.6074C26.7402 45.353 26.6371 45.1282 26.5041 44.933C26.3712 44.7343 26.2086 44.567 26.0162 44.4311C25.8273 44.2917 25.6105 44.1854 25.3656 44.1122C25.1243 44.039 24.8567 44.0024 24.5629 44.0024C24.0138 44.0024 23.5311 44.1383 23.1149 44.4102C22.7021 44.682 22.3804 45.0776 22.1495 45.597C21.9187 46.1128 21.8032 46.7437 21.8032 47.4895C21.8032 48.2354 21.9169 48.8698 22.1443 49.3926C22.3716 49.9154 22.6934 50.3145 23.1096 50.5898C23.5259 50.8617 24.0173 50.9976 24.5839 50.9976C25.0981 50.9976 25.537 50.907 25.9008 50.7258C26.2681 50.541 26.5479 50.2814 26.7402 49.9468C26.9361 49.6122 27.034 49.2166 27.034 48.76L27.4957 48.8279H24.7256V47.1236H29.2219V48.4724C29.2219 49.4135 29.0225 50.2221 28.6238 50.8983C28.225 51.571 27.6759 52.0903 26.9763 52.4563C26.2768 52.8188 25.4758 53 24.5734 53C23.5661 53 22.6812 52.7787 21.9187 52.336C21.1562 51.8899 20.5616 51.2573 20.1348 50.4382C19.7116 49.6157 19.5 48.6397 19.5 47.5105C19.5 46.6426 19.6259 45.8688 19.8778 45.1892C20.1331 44.506 20.4898 43.9274 20.948 43.4534C21.4062 42.9794 21.9396 42.6187 22.5482 42.3712C23.1568 42.1237 23.8162 42 24.5262 42C25.1348 42 25.7014 42.0889 26.2261 42.2666C26.7507 42.4409 27.2159 42.6884 27.6217 43.009C28.0309 43.3297 28.3649 43.7113 28.6238 44.154C28.8826 44.5932 29.0487 45.0776 29.1222 45.6074H26.8137Z" fill="${color}"></path>
<path d="M33.9986 52.8536H30.1896V42.1464H34.0301C35.1109 42.1464 36.0412 42.3607 36.8212 42.7894C37.6012 43.2147 38.2011 43.8264 38.6208 44.6245C39.044 45.4227 39.2556 46.3777 39.2556 47.4895C39.2556 48.6049 39.044 49.5634 38.6208 50.365C38.2011 51.1667 37.5977 51.7818 36.8107 52.2106C36.0273 52.6393 35.0899 52.8536 33.9986 52.8536ZM32.4613 50.914H33.9042C34.5757 50.914 35.1406 50.7955 35.5988 50.5585C36.0605 50.318 36.4068 49.9468 36.6376 49.4449C36.872 48.9395 36.9891 48.2877 36.9891 47.4895C36.9891 46.6984 36.872 46.0518 36.6376 45.5499C36.4068 45.048 36.0622 44.6785 35.604 44.4415C35.1458 44.2045 34.581 44.086 33.9094 44.086H32.4613V50.914Z" fill="${color}"></path>
<path d="M40.1998 52.8536V42.1464H44.439C45.2539 42.1464 45.9482 42.3015 46.5218 42.6117C47.0955 42.9184 47.5327 43.3454 47.8335 43.8926C48.1378 44.4363 48.2899 45.0637 48.2899 45.7747C48.2899 46.4857 48.136 47.1131 47.8282 47.6568C47.5204 48.2006 47.0745 48.6241 46.4904 48.9273C45.9097 49.2305 45.2067 49.3821 44.3813 49.3821H41.6793V47.568H44.014C44.4512 47.568 44.8115 47.493 45.0948 47.3432C45.3816 47.1898 45.595 46.9789 45.7349 46.7106C45.8783 46.4387 45.95 46.1267 45.95 45.7747C45.95 45.4192 45.8783 45.109 45.7349 44.8441C45.595 44.5757 45.3816 44.3683 45.0948 44.222C44.808 44.0721 44.4442 43.9971 44.0035 43.9971H42.4715V52.8536H40.1998Z" fill="${color}"></path>
<path d="M49.0268 52.8536V42.1464H53.266C54.0775 42.1464 54.77 42.291 55.3437 42.5803C55.9208 42.8661 56.3597 43.2722 56.6605 43.7985C56.9648 44.3213 57.117 44.9365 57.117 45.644C57.117 46.355 56.9631 46.9667 56.6553 47.4791C56.3475 47.988 55.9015 48.3783 55.3174 48.6502C54.7368 48.9221 54.0338 49.058 53.2083 49.058H50.3699V47.2386H52.8411C53.2748 47.2386 53.635 47.1793 53.9218 47.0608C54.2087 46.9423 54.422 46.7646 54.5619 46.5276C54.7053 46.2906 54.777 45.996 54.777 45.644C54.777 45.2885 54.7053 44.9888 54.5619 44.7448C54.422 44.5008 54.2069 44.3161 53.9166 44.1906C53.6298 44.0616 53.2678 43.9971 52.8306 43.9971H51.2986V52.8536H49.0268ZM54.8295 47.981L57.5 52.8536H54.9921L52.3794 47.981H54.8295Z" fill="${color}"></path>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
