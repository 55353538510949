import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

import ChevronRight from 'components/Util/Icons/ChevronRight';
import KeyboardArrowDown from 'components/Util/Icons/KeyboardArrowDown';
import Database from 'components/Util/Icons/TableRows';

import TableChart from 'components/Util/Icons/TableChart';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Schema extends Component {
  schema = observable.object({ active: 'activity_type_id' });

  setActive = (name) => {
    if (name === this.schema.active) {
      this.schema.active = '';
      return;
    }

    this.schema.active = name;
  }

  render() {
    const { sql } = this.props;

    const schema = [
      {
        name: 'message_id',
        description: 'The unique message ID of the email.'
      },
      {
        name: 'subject',
        description: 'The subject of the email.'
      },
      {
        name: 'recipient',
        description: 'The email address of the user receiving the email.'
      },
      {
        name: 'href',
        description: 'The href of the engaged link. Only applicable for :click events.'
      },
      {
        name: 'city',
        description: 'The city detected from the recipient\'s IP address.'
      },
      {
        name: 'state',
        description: 'The state detected from the recipient\'s IP address.'
      },
      {
        name: 'country',
        description: 'The country detected from the recipient\'s IP address.'
      },
      {
        name: 'longitude',
        description: 'The longitude coordinate detected from the recipient\'s IP address.'
      },
      {
        name: 'latitude',
        description: 'The latitude detected from the recipient\'s IP address.'
      },
      {
        name: 'browser',
        description: 'The browser detected from the recipient\'s user agent.'
      },
      {
        name: 'platform',
        description: 'The platform detected from the recipient\'s user agent.'
      },
      {
        name: 'email_client',
        description: 'The email client detected from the recipient\'s user agent and referer.'
      },
      {
        name: 'activity_type_id',
        description: 'Type of event. Values are :send, :open, :click, :bounce, :spam_report, :unsubscribe, :inbound.'
      },
      {
        name: 'ip_address',
        description: 'The IP address of the recipient.'
      },
      {
        name: 'outbound_ip_address',
        description: 'The IP address used to send the email. This is only applicable for :send events.'
      },
      {
        name: 'from',
        description: 'The email and name (if applicable) taken from the From header of the email.'
      },
      {
        name: 'error',
        description: 'The error message. This is only applicable for :bounce and :spam_report events.'
      },
      {
        name: 'created_at',
        description: 'The ISO 8601 timestamp of the event.'
      },
    ].sort((a, b) => {
      return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)
    });

    return (
      <div className={classnames(styles.Schema, 'py-2', 'position-relative')}>
        <p className={classnames(styles.Header, 'px-2')}><Database />Schema</p>
        <p className={classnames(styles.TableName, 'px-2', 'mb-0')}>email_activities</p>
        <ul>
          { schema.map((column, index) =>
            <li
              key={index}
              onClick={() => this.setActive(column.name)}
              className={classnames(this.schema.active === column.name && styles.Active)}>

              <div>

                { this.schema.active === column.name ? <KeyboardArrowDown /> : <ChevronRight /> }
                <TableChart />
                {column.name}

                <p>
                  {column.description}
                </p>
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  }
}
