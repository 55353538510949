import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import SelectM from 'components/Util/Inputs/SelectM';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import InboundParse from './InboundParse';
import EmailActivity from './EmailActivity';
import TestEvent from './TestEvent';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Config extends Component {
  onSuccess = (data) => {

    const { webhook, store } = this.props;
    const type = store.get('type')?.value || webhook.type;

    switch(type) {
      case 'inbound_parse':
        window.location = `/home/settings/webhooks/inbound_parses/${data.id}`;
        return;
      case 'email_activity':
        window.location = `/home/settings/webhooks/email_activities/${data.id}`;
        return;
      default:
        window.location = '/home/settings/webhooks';
    }
  }

  render() {
    const { webhook, store, action, method } = this.props;
    const type = store.get('type')?.value || webhook.type;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>
        <Form method={method} action={action} onSuccess={this.onSuccess} store={store}>
          { parseInt(webhook.id) <= 0 && (
            <label className={classnames(styles.Label)}>
              <p>Type</p>
              <SelectM
                defaultValue={webhook.type}
                className={classnames(styles.InputWidth, 'mb-2')}
                name='type'
                placeholder='Type'
                store={store}>

                <option value='inbound_parse'>Inbound Parse</option>
                <option value='email_activity'>Email Activity</option>
              </SelectM>
            </label>
          )}

          { type === 'inbound_parse' && <InboundParse {...this.props} /> }
          { type === 'email_activity' && <EmailActivity {...this.props} /> }

          <TestEvent webhook={webhook} store={store} />

          <div>
            <Button>{ method === 'put' ? 'Update' : 'Create' }</Button>
            <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={webhook.id} store={store} />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
