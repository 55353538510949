import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import NewButton from 'components/Util/Buttons/DarkBackground/MediumPrimaryButton';
import SaveButton from 'components/Util/Buttons/DarkBackground/MediumSecondaryButton';
import Add from 'components/Util/Icons/Add';
import Save from 'components/Util/Icons/Save';
import Send from 'components/Util/Icons/Send';
import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Tooltip from 'components/Util/Misc/Tooltip';

import Layout from './Layout';
import Content from './Content';
import Attributes from './Attributes';

import NavMiddle from './NavMiddle';
import Preview from './Preview';

import DesktopView from './Views/Desktop';
import MobileView from './Views/Mobile';
import SettingsView from './Views/Settings';

import Editor from '../../Editor';

import Store from 'models/Form';
import alertStore from 'models/Alert';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Config extends Component {
  store = new Store();
  previewID = 'js-send-preview';

  onSuccess = (data) => {
    const { template } = this.props;
    template.assign(data);
    window.location = `/home/settings/templates/${data.id}`
  }

  afterDelete = (data) => {
    const { template } = this.props;
    template.assign(data);
    window.location = '/home/settings/templates';
  }

  onError = (data) => {
    const { template } = this.props;

    if (this.store.get('name').error?.length > 0) {
      template.isEditingName = true;
    }
  }

  sendPreview = () => {
    alertStore.setContentsFromId(this.previewID);
    alertStore.setDisplay(true);
  }

  render() {
    const { action, method, template } = this.props;

    const navRight = (
      <div className='text-end'>
        <Tooltip content='Send Preview Email'>
          <button
            type='button'
            style={{ fontSize: '11px', padding: '0 8px' }}
            onClick={this.sendPreview}
            className={classnames(styles.CTA, 'me-1')}>

            <Send />Preview
          </button>
        </Tooltip>

        { template.id > 0 && (
          <Tooltip content='Delete'>
            <Delete
              action={`/api/v1/settings/templates/${template.id}`}
              store={this.store}
              afterDelete={this.afterDelete}
              className={classnames(styles.Delete, 'align-middle', 'me-1')} />
          </Tooltip>
        )}

        <Tooltip content='Save'>
          <SaveButton
            className={classnames(styles.Save, 'me-1', 'align-middle')}>

            <Save /></SaveButton>
        </Tooltip>

        <Tooltip content='New'>
          <a href='/home/settings/templates/new'>
            <NewButton
              type='button'
              className={classnames(styles.New, 'position-relative', 'align-middle')}
              style={{ width: '32px', height: '32px' }}>

              <Add className='position-relative' />
            </NewButton>
          </a>
        </Tooltip>
      </div>
    );

    return (
      <Form
        action={action}
        method={method}
        store={this.store}
        onSuccess={this.onSuccess}
        onError={this.onError}>

        <Editor
          className={classnames(styles.TemplateEditor)}
          model={template}
          store={this.store}
          override={!template.isEditorView}
          navMiddle={<NavMiddle template={template} />}
          navRight={navRight}
          editorLeft={<Layout store={template.editor} />}
          editorRight={<Attributes store={template.editor} />}
          editorMiddle={<Content store={template.editor} />}>

          <div className={classnames(styles.Center, 'overflow-visible', 'mh-100', 'mb-2')} style={{ background: styles.tint1 }}>
            { template.isDesktopView && <DesktopView template={template} /> }
            { template.isMobileView && <MobileView template={template} /> }
            { template.isSettingsView && <SettingsView store={this.store} template={template} /> }
          </div>
        </Editor>

        <Preview template={template} id={this.previewID} />

        <InputM
          name='body'
          store={this.store}
          value={JSON.stringify(template.editor.root.JSON)}
          className='d-none'
          type='hidden' />
      </Form>
    );
  }
}
