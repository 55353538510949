import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import UsersNew from 'components/Dashboard/Users/New';

import OrganizationAccount from 'models/OrganizationAccount';

@observer
export default class New extends Component {
   organizationAccount = new OrganizationAccount();

  render() {
    return (
      <Content>
        <UsersNew organizationAccount={this.organizationAccount} />
      </Content>
    );
  }
}
