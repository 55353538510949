import React, { Component } from 'react';
import { observable } from 'mobx';

import Content from 'components/Dashboard/Content';
import AccessTokensIndex from 'components/Dashboard/Settings/AccessTokens/Index';

export default class Index extends Component {
  render() {
    return (
      <Content>
        <AccessTokensIndex />
      </Content>
    );
  }
}
