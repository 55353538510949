import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import Content from 'components/Dashboard/Content';
import BillingShow from 'components/Dashboard/Account/Billing';

import api from 'models/API';
import Card from 'models/Card';
import Charge from 'models/Charge';
import Promo from 'models/Promo';
import Collection from 'models/Collection';
import OrganizationAccount from 'models/OrganizationAccount';

@observer
export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.organizationAccount = new OrganizationAccount();
    this.organizationAccount.assign({ settings: {} });

    this.card = new Card();
    this.charges = new Collection();
    this.promos = new Collection();

    api.get('/home/account').then(data => {
      this.organizationAccount.assign(data);
    });

    api.get('/home/account/card').then(data => {
      this.card.assign(data);
      this.card.loaded = true;
    });

    api.get('/home/account/charges').then(charges => {
      if (Array.isArray(charges)) {
        charges.forEach(charge => {
          this.charges.objects.push(new Charge(charge));
        });
      }

      this.charges.loaded = true;
    });

    api.get('/home/account/promos').then(promos => {
      if (Array.isArray(promos)) {
        promos.forEach(promo => {
          this.promos.objects.push(new Promo(promo));
        });
      }

      this.promos.loaded = true;
    });
  }

  render() {
    return (
      <Content>
        <BillingShow
          organizationAccount={this.organizationAccount}
          card={this.card}
          charges={this.charges}
          promos={this.promos} />
      </Content>
    );
  }
}
