import React, { Component } from 'react';
import classnames from 'classnames';

import Logo from 'components/Util/Misc/Logo';

import styles from 'styles/Marketing/Footer';

export default class Footer extends Component {
  render() {
    const borderColor = this.props.setBorder ? styles.tint5 : 'transparent';
    const year = new Date().getFullYear();

    return (
      <div className={classnames(styles.Footer)} style={{ background: styles.tint7 }}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4')}>
          <div className='py-3' style={{ borderTop: `1px solid ${borderColor}` }}>
            <div className='row'>
              <div className='col-lg-2 mb-lg-0 mb-2'>
                <a href='/'><Logo color={styles.colorPrimaryWhite} style={{ width: '88px' }} /></a>
              </div>

              <div className='col-lg-2 offset-lg-1 mb-lg-0 mb-2'>
                <p className={classnames(styles.h5Span, styles.ColorPrimaryWhite, 'mb-1')}>General</p>
                <p className='mb-1'><a className={classnames(styles.p2, styles.Tint3)} href='/platform'>Platform</a></p>
                <p className='mb-1'><a className={classnames(styles.p2, styles.Tint3)} href='/pricing'>Pricing</a></p>
                <p className='mb-1'><a className={classnames(styles.p2, styles.Tint3)} href='https://docs.sendamply.com'>Resources</a></p>
                <p className='mb-0'><a className={classnames(styles.p2, styles.Tint3)} href='/organizations/sign_in'>Sign In</a></p>
              </div>

              <div className='col-lg-2 mb-lg-0 mb-2'>
                <p className={classnames(styles.h5Span, styles.ColorPrimaryWhite, 'mb-1')}>Legal</p>
                <p className='mb-1'><a className={classnames(styles.p2, styles.Tint3)} href='/legal/privacy'>Privacy Policy</a></p>
                <p className='mb-1'><a className={classnames(styles.p2, styles.Tint3)} href='/legal/terms'>Terms of Service</a></p>
                <p className='mb-1'><a className={classnames(styles.p2, styles.Tint3)} href='/legal/subprocessors'>Subprocessors</a></p>
                <p className='mb-0'><a className={classnames(styles.p2, styles.Tint3)} href='/legal/security'>Security</a></p>
              </div>

              <div className='col-lg-2 mb-lg-0 mb-2'>
                <p className={classnames(styles.h5Span, styles.ColorPrimaryWhite, 'mb-1')}>Contact</p>
                <p className='mb-1'><a className={classnames(styles.p2, styles.Tint3)} href='/contact'>Support</a></p>
                <p className='mb-0'><a className={classnames(styles.p2, styles.Tint3, 'fw-bold')} href='mailto:support@sendamply.com'>@ Support</a></p>
              </div>

              <div className='col-lg-2'>
                <p className={classnames(styles.p2, styles.Tint5)}>©&nbsp;{year}&nbsp;Send&nbsp;Amply&nbsp;Inc. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
