import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import GeneralSettingsShow from 'components/Dashboard/Settings/General/Show';

import api from 'models/API';
import GeneralSettings from 'models/GeneralSettings';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.generalSettings = new GeneralSettings();

    api.get('/api/v1/settings/general').then(data => {
      this.generalSettings.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <GeneralSettingsShow generalSettings={this.generalSettings} />
      </Content>
    );
  }
}
