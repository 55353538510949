import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Editor';

@observer
export default class DeleteRow extends Component {
  onClick = (event) => {
    const { node } = this.props;
    node.editor.root.select();
    node.parent.removeChild(node);
  }

  render() {
    return (
      <p className='text-center'>
        <button
          text='Delete Row'
          onClick={this.onClick}
          className={classnames(styles.DeleteRow)}>

          Delete Row
        </button>
      </p>
    );
  }
}



