import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

import LoadableCard from 'components/Dashboard/LoadableCard';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Feed from '../Feed';

import Store from 'models/Form';
import tableAPI from 'models/TableAPI';

import styles from 'styles/Dashboard/Base';

@observer
export default class New extends Component {
  store = new Store();
  tableAPI = new tableAPI();
  feed =  observable.object({ display: false });

  onSubmit = (e, defaultSubmit) => {
    this.tableAPI.loaded = false;
    this.tableAPI.results.clear();
    this.feed.display = true;
    defaultSubmit(e.target);
  }

  onSuccess = (data) => {
    this.tableAPI.loaded = true;
    this.tableAPI.isFullyLoaded = true;
    this.store.enableForm();

    const cols = data?.results?.columns || [];
    const rows = data?.results?.rows || [];

    const formattedRows = rows.map(row => {
      return Object.fromEntries(cols.map((col, index) => [col, row[index]]));
    });

    this.tableAPI.results.push(...formattedRows);
  }

  onError = (data) => {
    if (data.errors.length > 0)
      this.feed.display = false;
  }

  render() {
    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Search</h3>

        <LoadableCard className='mb-2' loadState={true}>
          <h5 className={classnames(styles.p2, 'fw-bold', 'cursor-text')}>Search Events</h5>

          <Form action='/api/v1/analytics/search/basic' method='get' onSubmit={this.onSubmit} onSuccess={this.onSuccess} onError={this.onError} store={this.store}>
            <label className={classnames(styles.Label)}>
              <p>Keyword</p>
              <p className={classnames(styles.InputDescription, 'mb-1')}>Search event activity by user, location, platform, IP address, href, and more.</p>
              <InputM
                className={classnames(styles.InputWidth, 'mb-2')}
                defaultValue=''
                name='body[query]'
                placeholder='Keyword'
                store={this.store} />
            </label>

            <div>
              <Button>Search</Button>
            </div>
          </Form>
        </LoadableCard>

        { this.feed.display && <Feed type='search' tableAPI={this.tableAPI} /> }
      </div>
    );
  }
}
