import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import tooltip from 'models/Tooltip';

import styles from 'styles/Dashboard/Content';

@observer
export default class TooltipCore extends Component {
  render() {
    return (
      <ReactTooltip
        id='amply-tooltip'
        place={tooltip.place}
        effect='solid'
        event={undefined}
        multiline={true}
        className={classnames(styles.Tooltip)} />
    );
  }
}
