import React, { Component } from 'react';
import classnames from 'classnames';

import Plus from 'components/Util/Icons/Plus';
import Puzzle from 'components/Util/Icons/Puzzle';
import ExploreData from 'components/Util/Icons/ExploreData';
import StarSupport from 'components/Util/Icons/StarSupport';

import Product from './Images/Section5/Product';

import styles from 'styles/Marketing/Platform/Section5';

export default class Section4 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'pb-5')}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4')} style={{ borderRadius: '20px' }}>
          <div className='row mx-lg-4 mx-0 d-flex flex-lg-row-reverse'>
            <div className='col-lg-6'>
              <table className={classnames(styles.Table)}>
                <tbody>
                  <tr>
                    <td><Puzzle color={styles.colorPrimaryPurple} /></td>
                    <td>
                      <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mb-1')}>Analyze product usage from the <br/>customer’s perspective</h5>
                      <p className={classnames(styles.p2, 'mb-3')}>Customize and filter through Amply’s dashboard to precisely measure user engagement with your most important product features. With more granular data on each segment of the customer journey, you can identify the key drivers of user engagement, acquisition, and stickiness. Capitalize on the missing puzzle piece of your analytics stack.</p>
                    </td>
                  </tr>
                  <tr>
                    <td><ExploreData color={styles.colorPrimaryPurple} /></td>
                    <td>
                      <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mb-1')}>Turn data into insights. <br/>Explore your data seamlessly</h5>
                      <p className={classnames(styles.p2, 'mb-3')}>Access years of engagement data and get answers to complex questions about your product with powerful SQL queries. By transforming data sets into business intelligence, teams can discover the user engagement insight that matters most.</p>
                    </td>
                  </tr>
                  <tr>
                    <td><StarSupport color={styles.colorPrimaryPurple} /></td>
                    <td>
                      <h5 className={classnames(styles.h5, styles.ColorPrimaryPurple, 'mb-1')}>Improve your product’s customer <br/>support experience</h5>
                      <p className={classnames(styles.p2)}>Pinpoint delivery and engagement events with comprehensive user profiles. Support teams can respond to tickets even faster with easy troubleshooting, sending user sentiment soaring.</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='col-lg-6 px-lg-4'>
              <Product className={classnames(styles.Image)} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
