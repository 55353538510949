import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { onScroll } from './Table/Helpers/Base';

import Search from './Table/Search';
import Head from './Table/Head';
import Body from './Table/Body';

import tableAPI from 'models/TableAPI';

import styles from 'styles/Dashboard/Table';

@observer
export default class Table extends Component {
  constructor(props) {
    super(props);
    this.tableAPI = new tableAPI({ path: props.path });
    this.tableAPI.fetch();

    window.addEventListener('scroll', () => onScroll(this.tableAPI));
  }

  render() {
    const { headers, hrefKey, hrefBase, ...params } = this.props;

    return (
      <div className={classnames(styles.Card2, styles.Container)}>
        <div className={classnames(styles.Header)}>
          <div className='row'>
            <div className='col-lg-6'>
              <Search tableAPI={this.tableAPI} />
            </div>

            <div className='col-lg-6'>
              {this.props.children}
            </div>
          </div>
        </div>

        <table>
          <Head tableAPI={this.tableAPI} headers={headers} />
          <Body tableAPI={this.tableAPI} headers={headers} hrefBase={hrefBase} hrefKey={hrefKey} />
        </table>

        { !this.tableAPI.loaded && (
          <div className='py-2'>
            <div className={classnames(styles.ColorPrimaryPurple, 'd-block', 'mx-auto', 'spinner-border')} role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
