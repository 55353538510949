import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

import Logo from 'components/Util/Misc/Logo';
import Plane from 'components/Util/Misc/Plane';

import MediumPrimaryButton from 'components/Util/Buttons/DarkBackground/MediumPrimaryButton';

import styles from 'styles/Marketing/Header';

@observer
export default class Header extends Component {
  topNav = observable.object({ should_expand: true, mobile_nav_expanded: false });

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  }

  onScroll = (e) => {
    const topNav = this.topNav;
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(function() {
      topNav.should_expand = window.scrollY == 0;
    });
  }

  onClick = (e) => {
    this.topNav.mobile_nav_expanded = !this.topNav.mobile_nav_expanded;
  }

  getStarted = (e) => {
    e.preventDefault();

    const cta = document.getElementById('js-amply-cta');

    if (cta) {
      cta.scrollIntoView();
    }
    else {
      window.location = '/?getstarted'
    }
  }

  render() {
    const navBarClasses = [styles.Navbar];

    if (this.topNav.should_expand) {
      navBarClasses.push('py-2');
        ['py-2']
    }
    else {
      navBarClasses.push(styles.Shrink, 'py-1', 'shrink');
    }

    if (this.topNav.mobile_nav_expanded) {
      navBarClasses.push(styles.DarkBg);
    }

    return (
      <nav id='navbar' className={classnames('navbar', 'navbar-expand-lg', 'fixed-top', navBarClasses)}>
        <div className={classnames(styles.MaxWidth, 'container-fluid', 'position-relative', 'px-0', 'mx-auto')}>

          <div className={classnames(styles.Logo, 'position-relative')}>
            <a href='/'>
            { this.topNav.should_expand && <Logo className='w-100 my-2' color={styles.colorPrimaryWhite} /> }
            { !this.topNav.should_expand && <Plane className='w-100' color={styles.colorPrimaryWhite} /> }
            </a>
          </div>

          <button
            className={classnames(styles.Toggle, 'navbar-toggler')}
            type="button"
            onClick={this.onClick}
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">

              <span className='navbar-toggler-icon'></span>
          </button>

          <div className={classnames(styles.Expanded, 'collapse', 'navbar-collapse', 'p-0')} id='navbarSupportedContent'>
            <ul className={classnames(styles.Center, 'navbar-nav')}>
              <li className='nav-item'>
                <a href='/platform'>Platform</a>
              </li>
              <li className='nav-item'>
                <a href='/pricing'>Pricing</a>
              </li>
              <li className='nav-item'>
                <a href="https://docs.sendamply.com" target='_blank'>Resources</a>
              </li>
              <li className='nav-item'>
                <a href='/contact'>Contact</a>
              </li>
            </ul>

            <ul className={classnames(styles.Right, 'navbar-nav', 'ms-auto')}>
              <li className='nav-item'>
                <a href="/organizations/sign_in">Sign In</a>
              </li>
              <li className='nav-item'>
                <a onClick={this.getStarted}>
                  <MediumPrimaryButton className='d-none d-lg-inline'>Get Started</MediumPrimaryButton>
                  <span className={classnames(styles.ColorPrimaryWhite, 'd-inline-block', 'd-lg-none', 'cursor-pointer', 'mb-1')}>Get Started</span>
                </a>
              </li>
            </ul>
          </div>

        </div>
      </nav>
    );
  }
}
