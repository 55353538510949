import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertButtonCommand extends Command {
  execute(options = {}) {
    this.editor.model.change(writer => {
      this.editor.model.insertContent(createButton(writer, options));
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const element = selection.getSelectedElement();
    const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'button');

    this.isEnabled = allowedIn !== null;

    if (!element || !element.hasAttribute('buttonData')) {
      this.value = null;
    }

    else {
      this.value = {
        buttonData: element.getAttribute( 'buttonData' ),
      };
    }
  }
}

function createButton(writer, options) {
  const button = writer.createElement('button', { buttonData: options });
  writer.insertText(options.text, button);
  return button;
}
