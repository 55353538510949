import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Plus extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<g style="mix-blend-mode:overlay">
<path d="M6.00165 0V5.612H0.389648V7.015H6.00165V12.627H7.40365V7.015H13.0166V5.612H7.40365V0H6.00165Z" fill="${color}"/>
</g>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
