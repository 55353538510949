import React, { Component } from 'react';
import classnames from 'classnames';

import Django from './Images/Section8/Django';
import Node from './Images/Section8/Node';
import Rails from './Images/Section8/Rails';
import Wordpress from './Images/Section8/Wordpress';
import Net from './Images/Section8/Net';

import styles from 'styles/Marketing/Root/Section8';

export default class Section8 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'py-5')}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4')}>
          <div className='row'>
            <div className='col-lg-4'>
              <h3 className={classnames(styles.h3, styles.ColorPrimaryWhite)}>Easy integration with:</h3>
            </div>

            <div className='col-lg-8'>
              <Django className='mb-2' />
              <Node className='mb-2' />
              <Rails className='mb-2' />
              <Wordpress className='mb-2' />
              <Net className='mb-2' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
