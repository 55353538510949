import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
  
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';

import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';

import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import BalloonPanelView from '@ckeditor/ckeditor5-ui/src/panel/balloon/balloonpanelview';

import HtmlButtonView from './ui/htmlbuttonview';

const VISUAL_SELECTION_MARKER_NAME = 'button-ui';

const buttonIcon = '<svg id="svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400" height="167.4418604651163" viewBox="0, 0, 400,167.4418604651163"><g id="svgg"><path id="path0" d="M21.307 1.904 C 14.464 4.329,5.977 12.212,2.751 19.142 L 0.023 25.000 0.023 83.721 L 0.023 142.442 2.751 148.300 C 6.085 155.461,14.526 163.137,21.783 165.610 C 29.971 168.400,370.029 168.400,378.217 165.610 C 385.474 163.137,393.915 155.461,397.249 148.300 L 399.977 142.442 399.977 83.721 L 399.977 25.000 397.249 19.142 C 393.915 11.981,385.474 4.304,378.217 1.832 C 370.318 -0.860,28.912 -0.790,21.307 1.904 M125.328 61.108 C 142.159 68.139,145.867 89.974,132.259 101.922 C 111.913 119.786,81.808 94.343,96.045 71.315 C 102.221 61.325,115.101 56.836,125.328 61.108 M207.423 60.732 C 214.047 62.712,221.257 70.187,223.186 77.076 C 229.480 99.555,203.127 117.083,184.966 102.497 C 164.248 85.860,181.865 53.096,207.423 60.732 M295.731 63.194 C 311.317 72.328,311.372 95.082,295.831 104.189 C 286.131 109.874,275.895 109.081,267.855 102.022 C 245.680 82.552,270.285 48.281,295.731 63.194 " stroke="none" fill="#333333" fill-rule="evenodd"></path></g></svg>';

export default class ButtonUI extends Plugin {
  init() {
    const editor = this.editor;

    this._balloon = editor.plugins.get(ContextualBalloon);
    this.formView = this._createFormView();

    this._enableUserBalloonInteractions();


    editor.ui.componentFactory.add('button', locale => {
      const view = new ButtonView( locale );

      view.set({
          label: 'Insert button',
          icon: buttonIcon,
          tooltip: true
      });

      this.listenTo(view, 'execute', () => this._showUI());

      return view;
    });
  }

  _createFormView() {
    const editor = this.editor;
    const view = new HtmlButtonView(editor.locale);

    this.listenTo(view, 'render', () => {
      view.buttonTextView.inputView.element.onkeyup = () => { updateButton(editor, view) };
      view.buttonHrefView.inputView.element.onkeyup = () => { updateButton(editor, view) };
      view.buttonAlignView.selectView.element.onchange = () => { updateButton(editor, view) };
      view.buttonColorView.inputView.element.onkeyup = () => { updateButton(editor, view) };
      view.buttonBackgroundView.inputView.element.onkeyup = () => { updateButton(editor, view) };
      view.buttonBorderView.inputView.element.onkeyup = () => { updateButton(editor, view) };
      view.buttonBorderRadiusView.inputView.element.onkeyup = () => { updateButton(editor, view) };
      view.buttonCustomStylesView.inputView.element.onkeyup = () => { updateButton(editor, view) };
    });

    return view;
  }

  _showUI() {
    this._addFormView();
    this._balloon.showStack('main');

    const buttonData = this.editor.commands.get('insertButton').value?.buttonData;
    const textElement = this.formView.buttonTextView.inputView.element;

    textElement.value = buttonData?.text || '';
    this.formView.buttonHrefView.inputView.element.value = buttonData?.href || '';
    this.formView.buttonAlignView.selectView.element.value = buttonData?.align || 'center';
    this.formView.buttonColorView.inputView.element.value = buttonData?.color || '#ffffff';
    this.formView.buttonBackgroundView.inputView.element.value = buttonData?.background || '#545aea';
    this.formView.buttonBorderView.inputView.element.value = buttonData?.border || 'none';
    this.formView.buttonBorderRadiusView.inputView.element.value = buttonData?.borderRadius || '5px';
    this.formView.buttonCustomStylesView.inputView.element.value = buttonData?.customStyles || 'padding: 8px';

    if (textElement.value === '') {
      textElement.focus();
    }

		// Begin responding to ui#update once the UI is added.
		this._startUpdatingUI();
    
  }

	_hideUI() {
		if (!this._isFormInPanel) {
			return;
		}

		const editor = this.editor;

		this.stopListening( editor.ui, 'update' );
		this.stopListening( this._balloon, 'change:visibleView' );

		// Make sure the focus always gets back to the editable _before_ removing the focused form view.
		// Doing otherwise causes issues in some browsers. See https://github.com/ckeditor/ckeditor5-link/issues/193.
		editor.editing.view.focus();

		this._balloon.remove(this.formView);
	}

	_startUpdatingUI() {
		const editor = this.editor;
		const viewDocument = editor.editing.view.document;

		let prevSelectedButton = this._getSelectedButtonElement();
		let prevSelectionParent = getSelectionParent();

		const update = () => {
			const selectedButton = this._getSelectedButtonElement();
			const selectionParent = getSelectionParent();

			// Hide the panel if:
			//
			// * the selection went out of the EXISTING link element. E.g. user moved the caret out
			//   of the link,
			if ( prevSelectedButton && !selectedButton ) {
				this._hideUI();
			}
			// Update the position of the panel when:
			//  * link panel is in the visible stack
			//  * the selection remains in the original link element,
			//  * there was no link element in the first place, i.e. creating a new link
			else if ( this._isFormInPanel ) {
				// If still in a link element, simply update the position of the balloon.
				// If there was no link (e.g. inserting one), the balloon must be moved
				// to the new position in the editing view (a new native DOM range).
				this._balloon.updatePosition( this._getBalloonPositionData() );
			}

			prevSelectedButton = selectedButton;
			prevSelectionParent = selectionParent;
		};

		function getSelectionParent() {
			return viewDocument.selection.focus.getAncestors()
				.reverse()
				.find( node => node.is( 'element' ) );
		}

		this.listenTo( editor.ui, 'update', update );
		this.listenTo( this._balloon, 'change:visibleView', update );
	}

	_enableUserBalloonInteractions() {
		const viewDocument = this.editor.editing.view.document;

		// Handle click on view document and show panel when selection is placed inside the link element.
		// Keep panel open until selection will be inside the same link element.
		this.listenTo( viewDocument, 'click', () => {
			const parentButton = this._getSelectedButtonElement();

			if (parentButton ) {
				// Then show panel but keep focus inside editor editable.
				this._showUI();
			}
		} );

		// Close the panel on the Esc key press when the editable has focus and the balloon is visible.
		this.editor.keystrokes.set('Esc', (data, cancel) => {
			if (this._isFormInPanel) {
				this._hideUI();
				cancel();
			}
		});

		// Close on click outside of balloon panel element.
		clickOutsideHandler( {
			emitter: this.formView,
			activator: () => this._isFormInPanel,
			contextElements: [this._balloon.view.element],
			callback: () => this._hideUI()
		});
	}

	_addFormView() {
		if ( this._areActionsInPanel ) {
			return;
		}

		this._balloon.add( {
			view: this.formView,
			position: this._getBalloonPositionData()
		} );
	}

	get _areActionsInPanel() {
		return this._balloon.hasView(this.formView);
	}

	_getBalloonPositionData() {
    const editingView = this.editor.editing.view;
    const selection = editingView.document.selection;
    const defaultPositions = BalloonPanelView.defaultPositions;
    const selectedElement = selection.getSelectedElement();

    let target;

    if (selectedElement) {
      target = editingView.domConverter.viewToDom(selectedElement);
    }
    else {
      const range = selection.getFirstRange();
      target = editingView.domConverter.viewRangeToDom(range);
    }

    return {
      target: target,
      positions: [
        defaultPositions.northArrowSouth,
        defaultPositions.northArrowSouthWest,
        defaultPositions.northArrowSouthEast,
        defaultPositions.southArrowNorth,
        defaultPositions.southArrowNorthWest,
        defaultPositions.southArrowNorthEast
      ]
    };
	}

	_getSelectedButtonElement() {
    const selectedElement = this.editor.model.document.selection.getSelectedElement();

    if (selectedElement?.name === 'button') {
      return selectedElement;
    }

    return null;
	}

  get _isFormInPanel() {
		return this._balloon.hasView(this.formView);
	}
}

function updateButton(editor, view) {
  const text = view.buttonTextView.inputView.element.value;
  const href = view.buttonHrefView.inputView.element.value;
  const align = view.buttonAlignView.template.children[1].element.value;
  const color = view.buttonColorView.inputView.element.value;
  const background = view.buttonBackgroundView.inputView.element.value;
  const border = view.buttonBorderView.inputView.element.value;
  const borderRadius = view.buttonBorderRadiusView.inputView.element.value;
  const customStyles = view.buttonCustomStylesView.inputView.element.value;

    editor.execute('insertButton', {
      text: text,
      href: href,
      align: align,
      color: color,
      background: background,
      border: border,
      borderRadius: borderRadius,
      customStyles: customStyles,
    });
}
