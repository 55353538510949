import React, { Component } from 'react';
import classnames from 'classnames';

import LargePrimaryButton from 'components/Util/Buttons/DarkBackground/LargePrimaryButton';

import styles from 'styles/Errors/Error404';

export default class Error extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'text-center')}>
        <div>
          <h1 className={classnames(styles.h1)} style={{ fontSize: '200px', marginBottom: '72px' }}>{window.errorCode || '404'}</h1>
          <p className={classnames(styles.h3, 'mb-3')}>Looks like you're flying out of orbit.</p>
          <a href='/'><LargePrimaryButton>Go back home</LargePrimaryButton></a>
        </div>
      </div>
    );
  }
}
