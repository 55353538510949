import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { formatCell } from '../Table/Helpers/Body';
import { onScroll } from '../Table/Helpers/Base';
import EventData from './EventData';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import tableAPI from 'models/TableAPI';

import styles from 'styles/Dashboard/Table';

@observer
export default class Feed extends Component {
  ref = React.createRef();

  constructor(props) {
    super(props);
    window.addEventListener('scroll', () => onScroll(props.tableAPI));
  }

  onMouseDown = (e) => {
    this.clickedAt = window.performance.now();
  }

  onClick = (e) => {
    const row = e.target.closest('tr');
    this.toggle(row);
  }

  onExpandClick = (e) => {
    const row = e.target.closest('tr').previousSibling;
    this.toggle(row);
  }

  toggle = (row) => {
    if (window.performance.now() - this.clickedAt > 350) {
      return;
    }

    const expandClass = styles.Expanded;
    const uncollapsedClass = styles.Uncollapsed;

    if (row.classList.contains(expandClass)) {
      row.classList.remove(expandClass);
      row.classList.remove(uncollapsedClass);
      row.nextSibling.classList.add(uncollapsedClass);
    }
    else {
      this.ensureWidth(row.nextSibling);
      row.classList.add(expandClass);
      row.classList.add(uncollapsedClass);
      row.nextSibling.classList.add(uncollapsedClass);
    }
  }

  ensureWidth = (row) => {
    const tableWidth = this.ref.current.getBoundingClientRect().width;
    const el = row.querySelector(`.${styles.PushCol}`);
    el.style.width = `${tableWidth}px`;
  }

  render() {
    const { tableAPI, type } = this.props;

    return (
      <div className={classnames(styles.Card2, styles.Container)}>
        <div className={classnames(styles.Header)} />

        <table className={classnames(styles.Feed)} ref={this.ref}>
          <thead>
            { type === 'user' && (
              <tr>
                <th width={140}>Event</th>
                <th>Subject</th>
                <th width={250}>Link</th>
                <th width={250} className='text-end'>Created at</th>
              </tr>
            )}
            { type === 'search' && (
              <tr>
                <th width={140}>Event</th>
                <th width={250}>Recipient</th>
                <th>Subject</th>
                <th width={250} className='text-end'>Created at</th>
              </tr>
            )}
          </thead>

          <tbody>
            { tableAPI?.results?.map((row, rowIndex) =>
              <React.Fragment key={rowIndex}>
                { type === 'user' && (
                  <tr onMouseDown={this.onMouseDown} onClick={this.onClick}>
                    <td><p>{row['activity_type_id']}</p></td>
                    <td><p>{row['subject']}</p></td>
                    <td><p>{row['href']}</p></td>
                    <td><p className='text-end'>{formatCell(row, 'created_at')}</p></td>
                  </tr>
                )}
                { type === 'search' && (
                  <tr onMouseDown={this.onMouseDown} onClick={this.onClick}>
                    <td><p>{row['activity_type_id']}</p></td>
                    <td><p>{row['recipient']}</p></td>
                    <td><p>{row['subject']}</p></td>
                    <td><p className='text-end'>{formatCell(row, 'created_at')}</p></td>
                  </tr>
                )}
                <tr onMouseDown={this.onMouseDown} onClick={this.onExpandClick}>
                  <td colSpan={4} width='100%'>
                    <div className={classnames(styles.PushCol)} />
                    <EventData type={type} row={row} />
                  </td>
                </tr>
              </React.Fragment>
            )}

            { tableAPI?.results?.length === 0 && tableAPI?.loaded && (
              <tr className={classnames(styles.NoResults)}>
                <td colSpan={4}>No events...</td>
              </tr>
            )}
          </tbody>
        </table>

        { !tableAPI?.loaded && (
          <div className={classnames(tableAPI.results.length === 0 ? 'py-2' : 'pb-2')}>
            <div className={classnames(styles.ColorPrimaryPurple, 'd-block', 'mx-auto', 'spinner-border')} role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
