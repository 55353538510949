import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import VerifiedDomainNew from 'components/Dashboard/Settings/VerifiedDomains/New';

import VerifiedDomain from 'models/VerifiedDomain';

@observer
export default class Show extends Component {
  verifiedDomain = new VerifiedDomain();

  render() {
    return (
      <Content>
        <VerifiedDomainNew verifiedDomain={this.verifiedDomain} />
      </Content>
    );
  }
}
