import { toJS, observable, computed, action } from 'mobx';

export default class IpPool {
  @observable id;
  @observable name;
  @observable uuid;
  @observable status;
  @observable ip_addresses;
  @observable created_at;

  @observable all_ip_addresses;

  assign(params) {
    this.name = params.name || this.name;
    this.uuid = params.uuid || this.uuid;
    this.status = params.status || this.status;
    this.ip_addresses = params.ip_addresses || this.ip_addresses;
    this.created_at = params.created_at || this.created_at;

    this.id = params.id || this.id;
    this.all_ip_addresses = params.all_ip_addresses || this.all_ip_addresses;
  }

  get statusFormatted() {
    return this.status?.charAt(0)?.toUpperCase() + this.status?.slice(1);
  }

  get loaded() {
    return this.id !== undefined && this.all_ip_addresses !== undefined;
  }
}
