import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class CheckmarkNeutral extends Component {
  render() {
    const src = `
<svg width="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 7.5C15 3.35795 11.642 0 7.5 0C3.35795 0 0 3.35795 0 7.5C0 11.642 3.35795 15 7.5 15C11.642 15 15 11.642 15 7.5Z" fill="#FD9B51"/>
<path d="M6.96396 8.52619L5.34464 6.83869L4.56055 7.60574L6.96396 9.9921L10.8333 6.15687L10.0662 5.45801L6.96396 8.52619Z" fill="#FFFFFF"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
