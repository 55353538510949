import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import MonitorsShow from 'components/Dashboard/Monitors/Show';

import Monitor from 'models/Monitor';
import api from 'models/API';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    const id = this.props.match.params.id;
    this.monitor = new Monitor();

    api.get(`/api/v1/monitors/${id}`).then(data => {
      this.monitor.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <MonitorsShow monitor={this.monitor} />
      </Content>
    );
  }
}
