import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';

import Config from './Config';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class New extends Component {
  store = new Store();

  render() {
    const { accessToken } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/access_tokens' className='me-1'>
            <BackButton />
          </a>

          New Access Token
        </h3>

        <LoadableCard className='mb-2' loadState={true}>
          <Config method='post' action='/api/v1/settings/access_tokens' store={this.store} accessToken={accessToken} />
        </LoadableCard>
      </div>
    );
  }
}
