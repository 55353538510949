import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import IpAddressShow from 'components/Dashboard/Settings/IpAddresses/Show';

import api from 'models/API';
import IpAddress from 'models/IpAddress';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.ipAddress = new IpAddress();

    const id = props.match.params.id;
    api.get(`/api/v1/settings/ip_addresses/${id}`).then(data => {
      this.ipAddress.assign(data);
    });

    api.get(`/api/v1/settings/verified_domains`).then(data => {
      this.ipAddress.assign({ verified_domains: data });
    });

      api.get(`/home/settings/ip_addresses/${id}/default_rdns`).then(data => {
        this.ipAddress.assign(data);
      })
  }

  render() {
    return (
      <Content>
        <IpAddressShow ipAddress={this.ipAddress} />
      </Content>
    );
  }
}
