import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Base';

import api from 'models/API';

@observer
export default class TestEvent extends Component {
  sendTestEvent = (e) => {
    e.preventDefault();

    const { webhook, store } = this.props;

    if (webhook.isSendingTestRequest) {
      return;
    }

    const postData = {
      authenticity_token: document.querySelector('[name=csrf-token]').content,
      type: store.get('type')?.value || webhook.type,
      subdomain: store.get('subdomain')?.value,
      verified_domain_id: store.get('verified_domain_id')?.value,
      destination_url: store.get('destination_url')?.value,
      sending_method: store.get('sending_method')?.value,
    };

    webhook.isSendingTestRequest = true;

    api.post('/home/settings/webhooks/test', postData).then(data => {
      store.clearErrors();
      store.clearSuccesses();
      webhook.isSendingTestRequest = false;

      for (const error of data['errors']) {
        store.addError(error['field'], error['message']);
      }

      if (data['errors'].length === 0) {
        store.addSuccess('id', 'successfully sent a test event to your webhook');
        setTimeout(function() {
          store.clearSuccesses();
        }, 2000);
      }
    });
  }

  render() {
    const { webhook } = this.props;

    return (
      <React.Fragment>
        <a
          onClick={this.sendTestEvent}
          className='my-1 d-inline-block cursor-pointer'
          style={{ fontSize: '13px' }}>

          Send test event
        </a>
        { webhook.isSendingTestRequest && (
          <div
            className={classnames(styles.ColorPrimaryPurple, 'd-inline-block spinner-border spinner-border-md ms-1 position-relative')}
            style={{ width: '16px', height: '16px', top: '2px' }}
            role='status'>

            <span className='visually-hidden'>Loading...</span>
          </div>
        )}
      </React.Fragment>
    );
  }
}
