import React, { Component } from 'react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import SearchIcon from 'components/Util/Icons/Search';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Table';

export default class Search extends Component {
  dummyStore = new Store;

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = this.setTimeout(event.target.value);
    }
  }

  onChange = (event) => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = this.setTimeout(event.target.value);
  }

  setTimeout = (value) => {
    const { tableAPI } = this.props;
    return setTimeout(function() {
      tableAPI.params.filters['search'] = value;
      tableAPI.results.clear();
      tableAPI.fetch();
    }, 300);
  }

  render() {
    return (
      <div className={classnames(styles.Search)}>
        <SearchIcon color={styles.tint4} />
        <InputM
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          placeholder='Search...'
          store={this.dummyStore}
          className='w-100'
          style={{ maxWidth: '400px' }} />
      </div>
    );
  }
}
