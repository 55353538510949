import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import SelectM from 'components/Util/Inputs/SelectM';

import styles from 'styles/Dashboard/Base';

@observer
export default class Logic extends Component {
  onLogicChange = (values, index) => {
    if (values.length > 0) {
      this.props.monitor.alert_conditions[index].logic = values[0];
    }
  }

  render() {
    const { index } = { ...this.props };

    return (
      <SelectM
        { ...this.props }
        onChange={(values) => this.onLogicChange(values, index)}>

        <option value='?>'>Greater than</option>
        <option value='?>='>Greater than or equal to</option>
        <option value='?<'>Less than</option>
        <option value='?<='>Less than or equal to</option>
      </SelectM>
    );
  }
}
