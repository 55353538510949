import { observable, computed, action } from 'mobx';

export default class Promo {
  @observable code;
  @observable promo_type;
  @observable amount;
  @observable start_date;
  @observable end_date;
  @observable created_at;
  @observable updated_at;

  constructor(args={}) {
    this.assign(args);
  }

  assign(params) {
    this.promo_type = params.promo_type;
    this.amount = params.amount;
    this.start_date = params.start_date;
    this.end_date = params.end_date;
    this.created_at = params.created_at;
    this.updated_at = params.updated_at;
    this.code = params.code;
  }

  @computed
  get formattedAmount() {
    if (this.promo_type === 'dollar') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      return formatter.format(this.amount / 100.0);
    }
    else {
      // percent
      return `${parseInt(this.amount)}%`;
    }
  }

  get period() {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const start_date = new Date(this.start_date).toLocaleDateString('en-us', options);
    const end_date = new Date(this.end_date).toLocaleDateString('en-us', options);
    return `${start_date} to ${end_date}`
  }

  get loaded() {
    return this.code !== undefined;
  }
}
