import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class ButtonIcon extends Component {
  render() {
    const color = this.props.color;

    const src = `
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.20857 18L6.22803 17.0195L15.8467 7.40078H7.0218V6H18.228V17.2062H16.8272V8.38132L7.20857 18Z" fill="${color}"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
