import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Tick from 'components/Util/Icons/Tick';

import CheckboxL from './CheckboxL';

import styles from 'styles/Base';

@observer
export default class CheckboxM extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
      <CheckboxL {...this.props} className={classnames(className, styles.CheckboxM)}>{children}</CheckboxL>
    );
  }
}
