import React, { Component } from 'react';

import ShowError from 'components/Errors/Show';

export default class Show extends Component {
  render() {
    return (
      <ShowError />
    );
  }
}
