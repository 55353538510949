import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';

import Table from '../../Table';

import VerifiedDomain from 'models/VerifiedDomain';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  domainStatus = (row) => {
    const value = row['status'];

    let text, klass;
    const verifiedDomain = new VerifiedDomain();
    verifiedDomain.assign({ status: value });

    switch (value) {
      case 'pending_verification':
        klass = styles.Unhealthy;
        break;
      case 'healthy':
        klass = styles.Healthy;
        break;
      case 'unhealthy':
        klass = styles.Unhealthy;
        break;
    }

    return (
      <span className={classnames(klass)}>{verifiedDomain.statusFormatted}</span>
    );
  }

  render() {
    const headers = [
      {
        key: 'name',
        value: 'Name',
      },
      { 
        key: 'status',
        value: 'Status',
        width: '170',
        className: 'text-end',
        callback: this.domainStatus,
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: '220',
        className: 'text-end',
      }
    ]

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Verified Domains</h3>
        <Table path='/api/v1/settings/verified_domains' hrefBase='/home/settings/verified_domains' hrefKey='id' headers={headers}>
          <div className='text-end'>
            <a href='/home/settings/verified_domains/new'><Button><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button></a>
          </div>
        </Table>
      </div>
    );
  }
}
