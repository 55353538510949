import React, { Component } from 'react';
import classnames from 'classnames';

import { formatCell } from './Helpers/Body';

import styles from 'styles/Dashboard/Table';

export default class Body extends Component {
  generateHref = (row) => {
    const { hrefBase, hrefKey, tableAPI } = this.props;

    if (typeof hrefBase === 'function') {
      return hrefBase(row);
    }

    return `${hrefBase}/${encodeURIComponent(row[hrefKey])}`;
  }

  render() {
    const { hrefBase, hrefKey, headers, tableAPI } = this.props;

    return (
      <tbody>
        { tableAPI.results?.map((row, rowIndex) =>
          <tr key={rowIndex}>
            { headers.map((cellKey, cellIndex) =>
              <td key={cellIndex}>
                <a href={this.generateHref(row)} className={cellKey['className']}>
                  { formatCell(row, cellKey['key'], cellKey['callback']) }
                </a>
              </td>
            )}
          </tr>
        )}

        { tableAPI.results.length === 0 && tableAPI.loaded && (
          <tr className={classnames(styles.NoResults)}>
            <td>No results...</td>
          </tr>
        )}
      </tbody>
    );
  }
}
