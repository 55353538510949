import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LoadableCard from 'components/Dashboard/LoadableCard';

import Navigation from './Navigation';
import DangerZone from './Plan/DangerZone';
import Details from './Plan/Details';

import styles from 'styles/Dashboard/Base';

@observer
export default class Plan extends Component {
  render() {
    const { plan, organizationAccount } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold', 'mb-1')}>Account Details</h3>

        <Navigation organizationAccount={organizationAccount} />

        <div className='row'>
          <div className='col-lg-6 px-1'>
            <LoadableCard className='mb-2' loadState={plan.loaded}>
              <Details plan={plan} organizationAccount={organizationAccount} />
            </LoadableCard>
          </div>
          <div className='col-lg-6 px-1'>
            <LoadableCard className='mb-2' loadState={plan.loaded}>
              <DangerZone plan={plan} organizationAccount={organizationAccount} />
            </LoadableCard>
          </div>
        </div>
      </div>
    );
  }
}
