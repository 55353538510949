import { observable, computed, action } from 'mobx';

export default class Role {
  @observable id;
  @observable name;
  @observable permissions = [];

  constructor(params={}) {
    this.assign(params);
  }

  assign(params) {
    this.id = params.id;
    this.name = params.name;
    this.permissions = params.permissions;
  }

  @computed

  get display_name() {
    return this.name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
