import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Base';

@observer
export default class Invoices extends Component {
  render() {
    const { charges } = this.props;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Invoices</h5>

        { charges.isEmpty ? (
          <p className={classnames(styles.p2, styles.Tint5, 'mb-0')}>No invoices found.</p>
        ) : (
          <table className={classnames(styles.BasicTable, 'w-100')} style={{ maxWidth: '500px' }}>
            <thead>
              <tr>
                <th>Status</th>
                <th>Amount</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              { charges.objects.map((charge, index) =>
                <tr key={index}>
                  <td><span className={classnames(styles.Healthy)} style={{ fontSize: '11px' }}>Succeeded</span></td>
                  <td>{charge.amount}</td>
                  <td>{charge.description}</td>
                  <td>{charge.date}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}
