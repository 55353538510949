import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import AccessTokenNew from 'components/Dashboard/Settings/AccessTokens/New';

import AccessToken from 'models/AccessToken';

@observer
export default class New extends Component {
  accessToken = new AccessToken();

  render() {
    return (
      <Content>
        <AccessTokenNew accessToken={this.accessToken} />
      </Content>
    );
  }
}
