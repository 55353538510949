import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from "mobx-react"
import classnames from 'classnames';

import { borderColor, borderWidth } from '../variables';

import DropdownOpen from 'components/Util/Icons/KeyboardArrowDown';
import DropdownClose from 'components/Util/Icons/ChevronRight';
import Email from 'components/Util/Icons/Dialogs';
import Row from 'components/Util/Icons/Splitscreen';
import Column from 'components/Util/Icons/ViewColumn';

import styles from 'styles/Dashboard/Editor';

@observer
export default class LayoutNode extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired, // TODO use shape
  }

  onMouseOver = (event) => {
    event.stopPropagation();
    this.props.node.hover();
  }

  onMouseOut = (event) => {
    event.stopPropagation();
    this.props.node.blur();
  }

  toggle = (event) => {
    event.preventDefault();
    this.props.node.toggle();
  }

  select = (event) => {
    event.preventDefault();
    this.props.node.editor.setSelectedNode(this.props.node);
  }

  render() {
    const { node } = this.props;

    const classes = [styles.LayoutNode];
    const nodeStyles = {};

    if (!node.isSelected && node.isHovered) {
      nodeStyles.border = `${borderWidth} dashed ${borderColor}`;
    }

    if (node.isSelected) {
      classes.push(styles.LayoutNodeSelected);
    }

    let Icon = Email;
    if (node.type === 'Column') {
      Icon = Column;
      classes.push(styles.Column);
    } else if (node.type === 'Row') {
      Icon = Row;
      classes.push(styles.Row);
    }

    return (
      <div className={classnames(classes)} style={nodeStyles} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
        { node.type !== 'Column' && (
          <a href="" onClick={this.toggle}>
            { node.toggled ? <DropdownOpen className={classnames(styles.Dropdown)} /> : <DropdownClose className={classnames(styles.Dropdown)} /> }
          </a>
        )}
        <Icon className={classnames(styles.Icon)} />
        <a href="" onClick={this.select}>
          {node.title}
        </a>

        <div className="ml-1" style={{display: node.toggled ? 'block' : 'none'}}>
          { node.children.map(child => child.type !== 'Text' && (
            <LayoutNode key={child.id} node={child} />
          )) }
        </div>
      </div>
    );
  }
}
