import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class CCPA extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="47" height="50" viewBox="0 0 47 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.6668 4H33.3335C34.0699 4 34.6668 4.59695 34.6668 5.33333V13.5531C34.6668 23.3952 26.3013 27.1354 24.3871 27.8624C24.1335 27.9588 23.8669 27.9588 23.6132 27.8624C21.699 27.1354 13.3335 23.3952 13.3335 13.5531V5.33333C13.3335 4.59695 13.9304 4 14.6668 4ZM31.3378 12.1747C31.9145 11.5799 31.8999 10.6303 31.3052 10.0536C30.7105 9.47688 29.7608 9.49149 29.1841 10.0862L22.8407 16.6279L20.2074 13.9123C19.6307 13.3176 18.6811 13.303 18.0863 13.8797C17.4916 14.4564 17.477 15.406 18.0537 16.0007L21.7638 19.8268C22.0464 20.1182 22.4349 20.2826 22.8407 20.2826C23.2465 20.2826 23.635 20.1182 23.9175 19.8268L31.3378 12.1747Z" fill="${color}"></path>
<path d="M11.8735 40.9572H9.05166C9.00012 40.5802 8.89489 40.2452 8.73598 39.9525C8.57706 39.6553 8.37305 39.4024 8.12394 39.1939C7.87483 38.9854 7.58707 38.8257 7.26065 38.7148C6.93853 38.6039 6.58849 38.5485 6.21053 38.5485C5.52763 38.5485 4.93278 38.7237 4.42597 39.0741C3.91916 39.4202 3.52617 39.9259 3.247 40.5913C2.96783 41.2522 2.82824 42.0551 2.82824 43C2.82824 43.9715 2.96783 44.7877 3.247 45.4487C3.53047 46.1096 3.92561 46.6087 4.43241 46.9458C4.93922 47.283 5.52548 47.4515 6.1912 47.4515C6.56487 47.4515 6.91061 47.4005 7.22844 47.2985C7.55056 47.1965 7.83618 47.0478 8.08529 46.8527C8.3344 46.653 8.54056 46.4113 8.70376 46.1274C8.87127 45.8435 8.98723 45.5196 9.05166 45.1559L11.8735 45.1692C11.8004 45.7947 11.6179 46.398 11.3258 46.9791C11.0381 47.5558 10.6494 48.0726 10.1598 48.5295C9.67443 48.9819 9.09461 49.3413 8.4203 49.6074C7.75028 49.8691 6.99222 50 6.14611 50C4.96928 50 3.91702 49.725 2.9893 49.1749C2.06588 48.6248 1.33574 47.8286 0.798865 46.7861C0.266288 45.7437 0 44.4816 0 43C0 41.5139 0.270583 40.2497 0.81175 39.2072C1.35292 38.1648 2.08736 37.3707 3.01507 36.8251C3.94279 36.275 4.98646 36 6.14611 36C6.91061 36 7.61928 36.1109 8.27212 36.3327C8.92925 36.5545 9.51122 36.8783 10.018 37.3042C10.5248 37.7256 10.9372 38.2424 11.255 38.8546C11.5771 39.4667 11.7833 40.1676 11.8735 40.9572Z" fill="${color}"></path>
<path d="M24.6083 40.9572H21.7865C21.7349 40.5802 21.6297 40.2452 21.4708 39.9525C21.3119 39.6553 21.1079 39.4024 20.8588 39.1939C20.6096 38.9854 20.3219 38.8257 19.9955 38.7148C19.6733 38.6039 19.3233 38.5485 18.9453 38.5485C18.2624 38.5485 17.6676 38.7237 17.1608 39.0741C16.654 39.4202 16.261 39.9259 15.9818 40.5913C15.7026 41.2522 15.5631 42.0551 15.5631 43C15.5631 43.9715 15.7026 44.7877 15.9818 45.4487C16.2653 46.1096 16.6604 46.6087 17.1672 46.9458C17.674 47.283 18.2603 47.4515 18.926 47.4515C19.2997 47.4515 19.6454 47.4005 19.9633 47.2985C20.2854 47.1965 20.571 47.0478 20.8201 46.8527C21.0692 46.653 21.2754 46.4113 21.4386 46.1274C21.6061 45.8435 21.722 45.5196 21.7865 45.1559L24.6083 45.1692C24.5352 45.7947 24.3527 46.398 24.0607 46.9791C23.7729 47.5558 23.3842 48.0726 22.8946 48.5295C22.4092 48.9819 21.8294 49.3413 21.1551 49.6074C20.4851 49.8691 19.727 50 18.8809 50C17.7041 50 16.6518 49.725 15.7241 49.1749C14.8007 48.6248 14.0705 47.8286 13.5337 46.7861C13.0011 45.7437 12.7348 44.4816 12.7348 43C12.7348 41.5139 13.0054 40.2497 13.5466 39.2072C14.0877 38.1648 14.8222 37.3707 15.7499 36.8251C16.6776 36.275 17.7213 36 18.8809 36C19.6454 36 20.3541 36.1109 21.0069 36.3327C21.6641 36.5545 22.246 36.8783 22.7528 37.3042C23.2596 37.7256 23.672 38.2424 23.9898 38.8546C24.3119 39.4667 24.5181 40.1676 24.6083 40.9572Z" fill="${color}"></path>
<path d="M25.7016 49.8137V36.1863H30.9071C31.9078 36.1863 32.7603 36.3837 33.4647 36.7785C34.1691 37.1689 34.706 37.7123 35.0753 38.4087C35.449 39.1008 35.6358 39.8992 35.6358 40.8042C35.6358 41.7091 35.4468 42.5076 35.0689 43.1996C34.6909 43.8916 34.1433 44.4306 33.4261 44.8165C32.7131 45.2025 31.8498 45.3954 30.8362 45.3954H27.5183V43.0865H30.3852C30.9221 43.0865 31.3645 42.9911 31.7124 42.8004C32.0646 42.6052 32.3265 42.3368 32.4983 41.9952C32.6744 41.6492 32.7625 41.2522 32.7625 40.8042C32.7625 40.3517 32.6744 39.9569 32.4983 39.6198C32.3265 39.2782 32.0646 39.0143 31.7124 38.8279C31.3602 38.6372 30.9135 38.5418 30.3723 38.5418H28.4911V49.8137H25.7016Z" fill="${color}"></path>
<path d="M37.2912 49.8137H34.3019L38.8567 36.1863H42.4516L47 49.8137H44.0107L40.7057 39.3004H40.6026L37.2912 49.8137ZM37.1044 44.4572H44.1653V46.7063H37.1044V44.4572Z" fill="${color}"></path>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
