import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import ContactShow from 'components/Dashboard/Contact/Show';

@observer
export default class Show extends Component {
  render() {
    return (
      <Content>
        <ContactShow />
      </Content>
    );
  }
}
