import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import api from 'models/API';

import alertStore from 'models/Alert';

import styles from 'styles/Dashboard/Base';

@observer
export default class Form extends Component {
  ref = React.createRef();

  constructor(props) {
    super(props);
    const name = this.props.name;

    if (name !== undefined) {
      this.formElement = props.store?.addFormElement({name: this.props.name, ref: this.ref });
    }

    // for confirmations
    const closeAlert = this.closeAlert;
    const submit     = this.submit;
    const store      = props.store;

    window.addEventListener('click', function(event) {
      switch (event.target.id) {
        case 'cancel_confirmation_ref':
          closeAlert();
          break;
        case 'submit_confirmation_ref':
          if (!store.isDisabled) {
            submit();
            closeAlert();
          }
          break;
      }
    })

  }

  closeAlert = () => {
    alertStore.setDisplay(false);
  }

  displayConfirmation = (msg) => {
    const displayMsg = typeof msg === 'function' ? msg() : msg;

    alertStore.setContents(
      <div>
        <h4 className={classnames(styles.h4, 'text-center')}>Confirmation</h4>

        <div className={classnames(styles.p2, 'text-center', 'mb-2')}>{displayMsg}</div>

        <p className={classnames(styles.p2, 'mb-0', 'text-center')}>
          <span
            id='cancel_confirmation_ref'
            className={classnames(styles.p2, 'fw-bold', 'text-decoration-underline', 'cursor-pointer', 'me-2')}>

            Cancel
          </span>

          <Button id='submit_confirmation_ref'>Submit</Button>
        </p>
      </div>
    );

    alertStore.setDisplay(true);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.persist();

    const confirmation = this.props.confirmation;
    this.submitEvent   = event;

    if ((typeof confirmation === 'function' && confirmation()) || confirmation === true) {
      return this.displayConfirmation(this.props.confirmationMessage);
    }

    this.submit();
  }

  submit = () => {
    if (typeof this.submitEvent === 'undefined') {
      return;
    }

    if (typeof this.props.onSubmit !== 'undefined') {
      return this.props.onSubmit(this.submitEvent, this.defaultSubmit);
    }

    return this.defaultSubmit(this.submitEvent.target);
  }

  defaultSubmit = (target) => {
    this.props.store.disableForm();

    let formData = {
      authenticity_token: document.querySelector('[name=csrf-token]').content
    };

    target.elements.forEach(element => {
      this.addFormElement(formData, element);
    });

    switch (this.props.method) {
      case 'get':
        api.get(this.props.action, formData).then(data => {
          this.handleResponse(data);
        }).catch(error => {
          this.handleResponse(error);
        });
        break;
      case 'post':
        api.post(this.props.action, formData).then(data => {
          this.handleResponse(data);
        }).catch(error => {
          this.handleResponse(error);
        });
        break;
      case 'put':
        api.put(this.props.action, formData).then(data => {
          this.handleResponse(data);
        }).catch(error => {
          this.handleResponse(error);
        });
        break;
      case 'delete':
        api.del(this.props.action, formData).then(data => {
          this.handleResponse(data);
        }).catch(error => {
          this.handleResponse(error);
        });
        break;
    }
  }

  parseErrors = (data, keys) => {
    const errors = [];
    const parseErrors = this.parseErrors;

    if (!data) {
      // undefined
      return errors;
    }

    switch (data.constructor) {
      case Array:
        for (const [index, element] of data.entries()) {
          errors.push(parseErrors(element, keys.concat(index)));
        }
        break;
      case Object:
        for (const [key, value] of Object.entries(data)) {
          if (key === 'errors') {
            let prepend= '';

            if (keys.length > 1) {
              const first = keys.shift();
              const leftover = keys.map(key => { return `[${key}]`}).join('');
              prepend = `${first}${leftover}`
            }

            // an array of Objects { field => '', message => '' }
            for (const error of value) {
              if (prepend === '') {
                errors.push({ field: error['field'], message: error['message'] });
              }
              else {
                errors.push({ field: `${prepend}[${error['field']}]`, message: error['message'] });
              }
            }
          }
          else {
            errors.push(parseErrors(value, keys.concat(key)));
          }
        }
        break;
    }

    return errors.flat(Infinity);
  }

  handleResponse = (data) => {
    const errors = this.parseErrors(data, []);
    this.props.store.clearErrors();
    this.props.store.clearSuccesses();

    if (errors.length > 0) {
      this.props.store.enableForm();

      // add errors
      for (const error of errors) {
        const name    = error['field'];
        const message = error['message'];

        this.props.store.addError(name, message);
      }

      if (this.props.onError !== undefined) {
        this.props.onError(data);
      }
    }
    else {
      // handle success
      this.props.onSuccess(data);
    }
  }

  addFormElement = (formData, element) => {
    if (element.name === undefined || element.name.length <= 0) {
      return;
    }

    let nextObj = formData;
    const matches = element.name.match(/([\w\-]+)|(\[\])/g)

    matches.forEach((name, index) => {
      if (index < (matches.length - 1)) {
        // build our object (if nested)
        if (matches[index + 1] === '[]' || matches[index + 1].match(/\d+/)) {
          nextObj[name] = nextObj[name] || [];
        }
        else {
          nextObj[name] = nextObj[name] || {};
        }
        nextObj = nextObj[name];
      }
      else {
        // we are in the final child
        const value = this.valueFromFormElement(element);
        if (name === '[]') {
          // this is an array
          if (value !== undefined && value !== "") {
            nextObj.push(value);
          }
        }

        else if (value !== undefined) {
          nextObj[name] = value;
        }
      }
    });
  }

  valueFromFormElement(element) {
    switch(element.tagName) {
      case 'INPUT':
        if (element.type == 'checkbox') {
          return element.checked ? (element.value || true) : false;
        }
        else if (element.type == 'radio') {
          return element.checked ? element.value : undefined;
        }
        else {
          return element.value;
        }
        break;
      case 'SELECT':
        return element.options[element.selectedIndex].value;
        break;
      case 'TEXTAREA':
        return element.value;
        break;
      case 'BUTTON':
        // ignore for now
        break;
    }

    return undefined;
  }

  render() {
    return (
      <form action={this.props.action} onSubmit={this.handleSubmit} ref={this.ref}>
        <fieldset disabled={this.props.store.isDisabled}>
          {this.props.children}
        </fieldset>
      </form>
    );
  }
}
