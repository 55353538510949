import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import SSOConnectionsNew from 'components/Dashboard/SSOConnections/New';

import SSOConnection from 'models/SSOConnection';

@observer
export default class New extends Component {
  ssoConnection = new SSOConnection();

  render() {
    return (
      <Content>
        <SSOConnectionsNew ssoConnection={this.ssoConnection} />
      </Content>
    );
  }
}
