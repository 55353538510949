import React, { Component } from 'react';
import classnames from 'classnames';

import Devops from './Images/Section5/Devops';
import Product from './Images/Section5/Product';
import Security from './Images/Section5/Security';

import styles from 'styles/Marketing/Root/Section5';

export default class Section5 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'position-relative')}>
        <h4 className={classnames(styles.h4, styles.ColorPrimaryWhite, 'mb-4', 'text-center')}>API-powered features you'll <br />only find in Amply's data platform:</h4>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4')}>

          <div className={classnames(styles.Rows, 'pt-3')}>
            <div className={classnames('row', 'd-flex', 'flex-lg-row-reverse')}>
              <div className='col-lg-5 pt-lg-4'>
                <div className={classnames('d-flex', 'flex-column', 'justify-content-center')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>DEVOPS</h4>
                  <h4 className={classnames(styles.h4, 'mb-2')}>Scale Better with Highly <br className='d-none d-lg-inline' />Available Email</h4>
                  <p className={classnames(styles.p2)}>Become email experts with intelligent routing and infrastructure monitoring. Achieve optimal deliverability and gain complete visibility into the status of key delivery events to run your app at peak performance.</p>
                  <p className={classnames(styles.p2, 'fst-italic')}>Proactively manage your email with failover and alerts. Spend less time dealing with unresolved delivery problems and unexpected outages and more time innovating.</p>
                </div>
              </div>
              <div className='col-lg-7'>
                <img src={Devops} />
              </div>
            </div>

            <div className={classnames('row', styles.Margin)}>
              <div className='col-lg-5 pt-lg-4'>
                <div className={classnames('d-flex', 'flex-column', 'justify-content-center')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>PRODUCT</h4>
                  <h4 className={classnames(styles.h4, 'mb-2')}>Gain Key Insights That Drive <br className='d-none d-lg-inline' />Customer Engagement</h4>
                  <p className={classnames(styles.p2)}>More than just email, dive deep into important events of your buyer’s journey including sign-ups, payments, and more. Discover which product features drive user growth. Assess user stickiness. Even get to know your daily power users. And if you’ve still got questions, find deeper answers with our powerful SQL interface.</p>
                  <p className={classnames(styles.p2, 'fst-italic')}>Channel your inner data scientist. With 2 years of event data, your team can analyze and understand product engagement and drive higher product adoption.</p>
                </div>
              </div>
              <div className='col-lg-7'>
                <img src={Product} />
              </div>
            </div>

            <div className={classnames('row', 'd-flex', 'flex-lg-row-reverse')}>
              <div className='col-lg-5 pt-lg-4'>
                <div className={classnames('d-flex', 'flex-column', 'justify-content-center')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>SECURITY [COMING SOON]</h4>
                  <h4 className={classnames(styles.h4, 'mb-2')}>Protect Your Customers <br className='d-none d-lg-inline' />With the Only Email-based <br className='d-none d-lg-inline' />IDS (EIDS)</h4>
                  <p className={classnames(styles.p2)}>Integrate a new layer of security to your communication stack with unprecedented visibility and real-time threat detection. Proactively identify compromised users and stop account takeover  attacks before damage occurs.</p>
                  <p className={classnames(styles.p2, 'fst-italic')}>Empower your security team to quickly investigate and respond to threats. EIDS is always working to protect your organization and your customers from financial and reputational loss.</p>
                </div>
              </div>

              <div className='col-lg-7'>
                <img src={Security} />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
