import { observable, computed, action } from 'mobx';

export default class Pricing {
  @observable num_emails;
  @observable plan;

  constructor(params={}) {
    this.assign(params);
  }

  assign(params) {
    this.num_emails = params.num_emails;
    this.plan = params.plan;
  }

  currency_formatter(d) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: d,
      maximumFractionDigits: d,
    });
  }

  compact_number_formatter(n, d) {
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
    });
  }

  calculate_total() {
    if (this.num_emails === undefined || this.plan.base_price === undefined) {
      return '';
    }

    let total = this.plan.base_price;
    const num_emails = Math.max(this.num_emails, this.plan.tier_volume_base);
    const num_emails_ceil = Math.ceil(num_emails / 1000000) * 1000000

    const remainder = (parseInt(num_emails_ceil) - parseInt(this.plan.tier_volume_base)) / parseInt(this.plan.tier_volume_interval);

    total += remainder * this.plan.tier_volume_price;

    return total;
  }

  calculate_total_with_vas() {
    return this.calculate_total();
  }

  @computed
  get num_emails_formatted() {
    return this.num_emails?.toLocaleString();
  }

  get num_emails_short() {
    return this.compact_number_formatter().format(this.num_emails, 0);
  }

  get price_without_vas_short() {
    const total = this.calculate_total();
    return this.currency_formatter(0).format(total);
  }

  get price() {
    return this.currency_formatter(2).format(this.calculate_total_with_vas());
  }
}
