import React, { Component } from 'react';
import classnames from 'classnames';

import Alert from 'components/Util/Misc/Alert';
import TooltipCore from 'components/Util/Misc/TooltipCore';
import Header from './Header'
import Footer from './Footer'

export default class Content extends Component {
  render() {
    return (
      <React.Fragment>
        <Alert />
        <TooltipCore />
        <Header />
        {this.props.children}
        <Footer setBorder={this.props.setFooterBorder} />
      </React.Fragment>
    );
  }
}
