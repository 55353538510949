import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Three extends Component {
  render() {
    const src = `
<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 15C0 6.71573 6.71573 0 15 0H47C55.2843 0 62 6.71573 62 15V47C62 55.2843 55.2843 62 47 62H15C6.71573 62 0 55.2843 0 47V15Z" fill="url(#paint0_linear_1_1732)"/>
<path d="M1 16C1 7.71573 7.71573 1 16 1H46C54.2843 1 61 7.71573 61 16V46C61 54.2843 54.2843 61 46 61H16C7.71573 61 1 54.2843 1 46V16Z" fill="url(#paint1_linear_1_1732)"/>
<path d="M24.8 38.6001C24.9067 40.4134 25.46 41.8001 26.46 42.7601C27.46 43.7201 28.9067 44.2001 30.8 44.2001C32.7467 44.2001 34.2334 43.7134 35.26 42.7401C36.2867 41.7668 36.8 40.4534 36.8 38.8001C36.8 35.6001 34.6 34.0001 30.2 34.0001H28.2V30.6001H30.2C32.1734 30.6001 33.7 30.1801 34.78 29.3401C35.86 28.5001 36.4 27.3201 36.4 25.8001C36.4 24.2801 35.92 23.1001 34.96 22.2601C34 21.4201 32.64 21.0001 30.88 21.0001C29.1734 21.0001 27.8267 21.4201 26.84 22.2601C25.8534 23.1001 25.3067 24.2801 25.2 25.8001H21.2C21.28 24.2001 21.72 22.7801 22.52 21.5401C23.32 20.3001 24.4334 19.3334 25.86 18.6401C27.2867 17.9468 28.96 17.6001 30.88 17.6001C32.8267 17.6001 34.52 17.9268 35.96 18.5801C37.4 19.2334 38.5 20.1468 39.26 21.3201C40.0201 22.4934 40.4 23.8534 40.4 25.4001C40.4 28.5734 38.8134 30.6668 35.64 31.6801C35.48 31.7334 35.3734 31.7868 35.32 31.8401C35.2667 31.8934 35.24 31.9868 35.24 32.1201C35.24 32.2534 35.2667 32.3468 35.32 32.4001C35.3734 32.4534 35.48 32.5068 35.64 32.5601C37.2934 33.0934 38.5667 33.9334 39.4601 35.0801C40.3534 36.2268 40.8001 37.6001 40.8001 39.2001C40.8001 40.8268 40.4067 42.2734 39.6201 43.5401C38.8334 44.8068 37.6867 45.8001 36.18 46.5201C34.6734 47.2401 32.88 47.6001 30.8 47.6001C27.7334 47.6001 25.3267 46.8068 23.58 45.2201C21.8334 43.6334 20.9067 41.4268 20.8 38.6001H24.8Z" fill="url(#paint2_linear_1_1732)"/>
<defs>
<linearGradient id="paint0_linear_1_1732" x1="28.4258" y1="66.3438" x2="31" y2="66.3438" gradientUnits="userSpaceOnUse">
<stop stop-color="#4141FF"/>
<stop stop-color="#6834FF"/>
<stop offset="0.536946" stop-color="#5535FE"/>
<stop offset="1" stop-color="#C400FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1_1732" x1="3.57617" y1="4.54492" x2="58.2793" y2="4.54492" gradientUnits="userSpaceOnUse">
<stop stop-color="#0B151E"/>
<stop offset="1" stop-color="#303A57"/>
</linearGradient>
<linearGradient id="paint2_linear_1_1732" x1="25.8" y1="25.1001" x2="25.8" y2="25.1001" gradientUnits="userSpaceOnUse">
<stop stop-color="#036EFF"/>
<stop stop-color="#12DBFF"/>
<stop offset="0.423645" stop-color="#10A1FF"/>
<stop offset="1" stop-color="#48FFC0"/>
</linearGradient>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
