import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import SelectM from 'components/Util/Inputs/SelectM';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';

import DnsConfiguration from './DnsConfiguration';

import Share from 'components/Util/Icons/Share';
import Favorite from 'components/Util/Icons/Favorite';
import ErrorOutline from 'components/Util/Icons/ErrorOutline';
import Cloud from 'components/Util/Icons/Cloud';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store;

  onSuccess = () => {
    window.location.reload();
  }

  afterDelete = () => {
    window.location = '/home/settings/verified_domains';
  }

  blocklistList = () => {
    const { verifiedDomain } = this.props;
    const links = [];

    if (verifiedDomain.blocklists.length == 0) {
      return 'No blocklists detected';
    }

    verifiedDomain.blocklists.forEach((blocklist, index) => {
      links.push(<a key={index} href={blocklist.url}>{blocklist.name}</a>);
    })

    return links.reduce((prev, curr) => [prev, ', ', curr]);
  }

  render() {
    const { verifiedDomain } = this.props;
    const action = `/api/v1/settings/verified_domains/${verifiedDomain.id}`;
    const deliverabilityStrategy = this.store.get('deliverability_strategy')?.value;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/verified_domains' className='me-1'>
            <BackButton />
          </a>

          Update Verified Domain

          <Delete action={action} store={this.store} afterDelete={this.afterDelete} className='float-end mb-2'>Delete</Delete>
        </h3>

        <LoadableCard className='mb-2' loadState={verifiedDomain.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Verified Domain</h5>

          <Attribute icon={Share} name='Domain Name' value={verifiedDomain.name} className='mb-1' />
          <Attribute icon={Cloud} name='High Availability' value={verifiedDomain.isHighlyAvailable} className='mb-1' />
          <Attribute icon={Favorite} name='Status' value={verifiedDomain.statusFormatted} className='mb-1' />
          <Attribute icon={ErrorOutline} name='Blocklists' value={this.blocklistList()} className='mb-0' />
        </LoadableCard>

        <DnsConfiguration verifiedDomain={verifiedDomain} />

        <LoadableCard loadState={verifiedDomain.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Intelligent Routing</h5>

          <Form method='put' action={action} onSuccess={this.onSuccess} store={this.store}>
            <div className='row'>
              <div className='col-lg-6'>
                <label className={classnames(styles.Label)}>
                  <p>Deliverability Strategy</p>
                  <p className={classnames(styles.InputDescription, 'mb-1')}>Amply routes your email in real-time to maximize placement in your users' primary inbox. An auto strategy supports failover between dedicated and shared IP addresses.</p>
                  <SelectM
                    className={classnames(styles.InputWidth, 'mb-2')}
                    defaultValue={verifiedDomain.deliverability_strategy}
                    name='deliverability_strategy'
                    store={this.store}>

                    <option value='auto'>Auto</option>
                    <option value='dedicated'>Dedicated</option>
                    <option value='shared'>Shared</option>
                    <option value='custom'>Custom</option>
                  </SelectM>
                </label>

                <div>
                  <Button>Update</Button>
                  <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={verifiedDomain.id} store={this.store} />
                </div>
              </div>

              <div className='col-lg-6'>
                { deliverabilityStrategy === 'custom' && (
                  <React.Fragment>
                    <div>
                      <label className={classnames(styles.Label, 'mb-2', 'd-inline-block', 'me-lg-1')}>
                        <p>SMTP Host</p>
                        <InputM
                          name='custom_deliverability_strategy_smtp_host'
                          placeholder='Host'
                          defaultValue={verifiedDomain.custom_deliverability_strategy_smtp_host}
                          store={this.store} />
                      </label>

                      <label className={classnames(styles.Label, 'mb-2', 'd-inline-block')}>
                        <p>SMTP Port</p>
                        <InputM
                          name='custom_deliverability_strategy_smtp_port'
                          placeholder='Port'
                          defaultValue={verifiedDomain.custom_deliverability_strategy_smtp_port}
                          store={this.store} />
                      </label>
                    </div>

                    <div>
                      <label className={classnames(styles.Label, 'mb-2', 'd-inline-block', 'me-lg-1')}>
                        <p>Username</p>
                        <InputM
                          name='custom_deliverability_strategy_username'
                          placeholder='Username'
                          defaultValue={verifiedDomain.custom_deliverability_strategy_username}
                          store={this.store} />
                      </label>

                      <label className={classnames(styles.Label, 'mb-2', 'd-inline-block')}>
                        <p>Password</p>
                        <InputM
                          name='custom_deliverability_strategy_password'
                          type='password'
                          placeholder='Password'
                          defaultValue={verifiedDomain.decrypted_custom_deliverability_strategy_password}
                          store={this.store} />
                      </label>
                    </div>

                    <div>
                      <label className={classnames(styles.Label, 'mb-2', 'd-inline-block')}>
                        <p>Load Percent</p>
                        <div className={classnames(styles.InputDescription)}>
                          <span className={classnames(styles.ColorPrimaryBlack)}>I want to send</span>
                          <span className={classnames(styles.ColorPrimaryBlack, 'mx-1')}>
                            <InputM
                              name='custom_deliverability_strategy_percent'
                              placeholder='Load Percent'
                              defaultValue={verifiedDomain.custom_deliverability_strategy_percent || 50}
                              style={{maxWidth: '125px'}}
                              store={this.store} />%
                            </span>

                          <span className={classnames(styles.ColorPrimaryBlack)}>of my domain's email through this host.</span>
                        </div>
                      </label>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>

          </Form>
        </LoadableCard>
      </div>
    );
  }
}
