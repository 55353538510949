import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Config from './Config';

@observer
export default class New extends Component {
  render() {
    const { sql, ...params } = this.props;

    return (
      <Config {...params} sql={sql} action={`/api/v1/searches/sql/${sql.id}`} method='put' />
    );
  }
}
