import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { setIframeYDimensions } from './Helpers';

import Iphone from 'components/Util/Misc/Iphone';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Mobile extends Component {
  desktopRef = React.createRef();
  iframeRef = React.createRef();
  svgID = 'js-amply-preview-svg';

  onResize = (e) => {
    this.updateView();
  }

  updateView = () => {
    const iframe  = this.iframeRef.current;
    const desktop = this.desktopRef.current;
    const svg     = document.getElementById(this.svgID);

    const svgRect = svg.getBoundingClientRect();
    const width = svgRect.width * 0.885;
    iframe.style.width = `${width}px`;

    setIframeYDimensions(desktop, svg, iframe, 0.123, -56);
  }

  componentDidMount() {
    const { template } = this.props;
    window.addEventListener('resize', this.onResize);

    template.generatePreview().then(data => {
      this.updateView();
    });
  }

  render() {
    const { template } = this.props;

    return (
      <div ref={this.desktopRef} className={classnames(styles.Mobile)}>
        <Iphone id={this.svgID} />
        <iframe ref={this.iframeRef} srcDoc={template.previewHTML} />
      </div>
    );
  }
}
