import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Navigation from './Navigation';
import Alert from 'components/Util/Misc/Alert';
import TooltipCore from 'components/Util/Misc/TooltipCore';

import styles from 'styles/Dashboard/Content';

@observer
export default class Content extends Component {
  render() {
    const { className, disableMaxWidth, ...params } = this.props;

    return (
      <React.Fragment>
        <Alert />
        <Navigation />
        <TooltipCore />

        <div {...params} className={classnames(styles.Container, className)}>
          <div className={classnames(disableMaxWidth && styles.DisableMaxWidth)}>
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
