import React, { Component } from 'react';
import classnames from 'classnames';

import Base from '../Base';
import Links from '../Links';

import Form from 'components/Util/Inputs/Form';
import InputL from 'components/Util/Inputs/InputL';
import Button from 'components/Util/Buttons/LightBackground/LargePrimarySquareButton';

import Store from 'models/Form';
import alertStore from 'models/Alert';

import styles from 'styles/Marketing/Organizations/Sessions/New';

export default class New extends Component {
  store = new Store;

  componentDidMount() {
    if (this.props.tokenExpired) {
      alertStore.setContents(
        <React.Fragment>
          <h3 className={classnames(styles.h3, 'text-center')}>Expired Token</h3>
          <p className={classnames(styles.p2, 'text-center', 'mb-0')}>Your confirmation link has expired. Please use the form to request a new one.</p>
        </React.Fragment>
      );

      alertStore.setDisplay(true);
    }
  }

  onSuccess = () => {
   this.store.addSuccess('organization_account[email]', 'If your account exists, you will receive a confirmation link at your email.');
   this.store.enableForm();
  }

  render() {
    return (
      <Base>
        <h3 className={classnames(styles.h3, 'text-center')}>Resend Confirmation</h3>
        <Form method='post' action='/organizations/confirmation' onSuccess={this.onSuccess} store={this.store}>
          <InputL className='mb-2 w-100' name='organization_account[email]' placeholder='Email*' store={this.store} />
          <Button className='w-100 mb-3'>Resend Confirmation</Button>
        </Form>

        <div className='text-center'>
          <Links links={['sign_up', 'confirmation']} />
        </div>
      </Base>
    );
  }
}
