export function hasErrors(store, sql) {
  let errors = false;

  const chartType = sql.chartSettings.type;
  const xAxis = sql.chartSettings.xAxis;
  const yAxis = sql.chartSettings.yAxis;

  store.clearErrors();

  if (chartType === undefined) {
    store.get('chartSettings[type]').setError('is empty');
    errors = true;
  }

  if (xAxis === undefined) {
    store.get('chartSettings[xAxis]').setError('is empty');
    errors = true;
  }

  if (yAxis === undefined) {
    store.get('chartSettings[yAxis]').setError('is empty');
    errors = true;
  }

  // ensure yAxis is numeric
  const index = sql.columns.indexOf(yAxis);
  if (index > -1) {
    sql.rows.every(row => {
      if (row[index] !== undefined && row[index] !== null) {
        const value = row[index].toString();
        if (!value.match(/^[\d\.\-]*$/)) { // good enough
          store.get('chartSettings[yAxis]').setError('is not numeric');
          errors = true;
          return false;
        }
      }
    });
  }

  return errors;
}
