import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import Tooltip from 'components/Util/Misc/Tooltip';
import Copy from 'components/Util/Icons/Copy';

import styles from 'styles/Dashboard/Attribute';

@observer
export default class Attribute extends Component {
  copyRef = React.createRef();
  clipboardTooltipRef = React.createRef();
  hoverTooltipRef = React.createRef();

  onClick = (e) => {
    const ref = this.clipboardTooltipRef.current;

    if (e.target.tagName === 'A') {
      return;
    }

    navigator.clipboard.writeText(this.copyRef.current.textContent);

    ReactTooltip.show(ref);

    setTimeout(() => {
      ReactTooltip.hide(ref);
    }, 750);
  }

  onMouseEnter = (e) => {
    clearTimeout(this.hoverTimeout);
    const ref = this.hoverTooltipRef.current;
    const el = this.copyRef.current;

    // overflow is less than two pixels
    if (Math.abs(el.scrollWidth - el.getBoundingClientRect().width) > 2) {
      this.hoverTimeout = setTimeout(function() {
        ReactTooltip.show(ref);
      }, 200);
    }
  }

  onMouseLeave = (e) => {
    clearTimeout(this.hoverTimeout);
    const ref = this.hoverTooltipRef.current;
    ReactTooltip.hide(ref);
  }

  render() {
    const Icon = this.props.icon;
    const { name, value, className, icon, ...params } = this.props;

    return (
      <div className={classnames('d-inline-flex', className)}>
        <Tooltip content='Copied to clipboard!' tooltipRef={this.clipboardTooltipRef} className={classnames(className)}>
          <Tooltip content={value} tooltipRef={this.hoverTooltipRef}>
            <div {...params} onClick={this.onClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} className={classnames(styles.Attribute)}>
              <span><Icon color={styles.colorPrimaryPurple} className={classnames(styles.AttributeIcon)} /></span>
              <div>
                <h5 ref={this.copyRef}>{value}<Copy className={classnames(styles.Copy)} /></h5>
                <p>{name}</p>
              </div>
            </div>
          </Tooltip>
        </Tooltip>
      </div>
    );
  }
}
