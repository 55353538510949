import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Input from 'components/Util/Inputs/InputM';

import PaddingTop from 'components/Util/Icons/PaddingTop';
import PaddingBottom from 'components/Util/Icons/PaddingBottom';
import PaddingLeft from 'components/Util/Icons/PaddingLeft';
import PaddingRight from 'components/Util/Icons/PaddingRight';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Editor';
import inputStyles from 'styles/Dashboard/Editor';

@observer
export default class Padding extends Component {
  store = new Store();

  onChange = (e, position) => {
    // top | right | bottom | left
    const map = {
      top: 0,
      right: 1,
      bottom: 2,
      left: 3,
    }

    const { node } = this.props;

    const nodePadding = node.padding.split(' ');
    nodePadding[map[position]] = `${parseInt(e.target.value) || 0}px`;
    node.setPadding(nodePadding.join(' '));
  }

  formatNumber = (number) => {
    const i = parseInt(number);
    return isNaN(i) ? 0 : i;
  }

  render() {
    const { node } = this.props;
    const min = '0', max = '600';
    const input = {
      width: 'calc(100% - 28px)',
    };

    const colPaddingStart = { paddingRight: '2px' }
    const colPaddingEnd = { paddingLeft: '2px' }

    return (
      <React.Fragment>
        <label className={classnames(styles.Label)}>
          <p className='mb-1'>Padding</p>
        </label>

        <div className='row'>
          <div className='col-6 mb-1' style={colPaddingStart}>
            <PaddingTop className={classnames(styles.SVG, styles.Fill)} />
            <Input
              type='number'
              min={min}
              max={max}
              className={classnames(inputStyles.IconInput)}
              value={this.formatNumber(node.paddingTop)}
              store={this.store}
              onChange={(e) => this.onChange(e, 'top')} />

          </div>
          <div className='col-6 mb-1' style={colPaddingEnd}>
            <PaddingBottom className={classnames(styles.SVG, styles.Fill)} />
            <Input
              type='number'
              min={min}
              max={max}
              className={classnames(inputStyles.IconInput)}
              value={this.formatNumber(node.paddingBottom)}
              store={this.store}
              onChange={(e) => this.onChange(e, 'bottom')} />
          </div>
          <div className='col-6' style={colPaddingStart}>
            <PaddingLeft className={classnames(styles.SVG, styles.Fill)} />
            <Input
              type='number'
              min={min}
              max={max}
              className={classnames(inputStyles.IconInput)}
              value={this.formatNumber(node.paddingLeft)}
              store={this.store}
              onChange={(e) => this.onChange(e, 'left')} />
          </div>
          <div className='col-6' style={colPaddingEnd}>
            <PaddingRight className={classnames(styles.SVG, styles.Fill)} />
            <Input
              type='number'
              min={min}
              max={max}
              className={classnames(inputStyles.IconInput)}
              value={this.formatNumber(node.paddingRight)}
              store={this.store}
              onChange={(e) => this.onChange(e, 'right')} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
