import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LoadableCard from 'components/Dashboard/LoadableCard';

import Navigation from './Navigation';
import Organization from './Account/Organization';
import Authentication from './Account/Authentication';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  render() {
    const { organizationAccount } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold', 'mb-1')}>Account Details</h3>

        <Navigation organizationAccount={organizationAccount} />

        <LoadableCard className='mb-2' loadState={organizationAccount.loaded}>
          <Organization organizationAccount={organizationAccount} />
        </LoadableCard>

        <LoadableCard loadState={organizationAccount.loaded}>
          <Authentication organizationAccount={organizationAccount} />
        </LoadableCard>
      </div>
    );
  }
}
