import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import classnames from 'classnames';

import { formatTime } from 'components/Dashboard/Table/Helpers/Body';

import Monitor from 'models/Monitor';

import styles from 'styles/Visualizations';
import dashboardStyles from 'styles/Dashboard/Base';

@observer
export default class Monitors extends Component {
  constructor(props) {
    super(props);
    const monitors = toJS(props.widget.data);
    this.monitors = monitors.map(data => new Monitor(data));
  }

  formatStatus(monitorStatus) {
    switch (monitorStatus) {
      case 'recovered':
        return <span className={classnames(styles.Recovered)}>recovered</span>;
      case 'triggered':
        return <span className={classnames(styles.Triggered)}>triggered</span>;
      default:
        return <span className={classnames(styles.None)}>n/a</span>;
    }
  }

  render() {
    const monitors = this.monitors || [];

    return (
      <div className={classnames(styles.Monitors)}>
            { monitors.length == 0 && (
              <p className={classnames(styles.p2, styles.Empty)}>No Monitors</p>
            )}

            { monitors.length > 0 && (
              <table className={classnames(dashboardStyles.BasicTable, styles.Table)}>
                <thead>
                  <tr>
                    <th width='20%'>Name</th>
                    <th>Type</th>
                    <th>Detection Type</th>
                    <th>Alerted By</th>
                    <th>Alert Status</th>
                    <th width='20%'>Alerted At</th>

                  </tr>
                </thead>
                <tbody>
                  { monitors.map((monitor, index) =>
                    <tr key={index}>
                      <td><span>{monitor.name}</span></td>
                      <td><span>{monitor.type_formatted}</span></td>
                      <td><span>{monitor.humanize_detection_type(monitor.detection_type)}</span></td>
                      <td><span>{monitor.last_triggered_object_name || 'n/a'}</span></td>
                      <td><span>{this.formatStatus(monitor.last_triggered_object_status)}</span></td>
                      <td><span>{formatTime(monitor.last_trigger_alerted_at) || 'n/a'}</span></td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
      </div>
    );
  }
}
