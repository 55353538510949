import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import { scrollTo } from './ScrollTo';

import Button from 'components/Util/Buttons/DarkBackground/LargeSecondaryButton';

import styles from 'styles/Cohorts';

export default class Section1 extends Component {
  render() {
    const orbs = [
      // PURPLE ORBS
      // top left
      {
        width: '500px',
        height: '500px',
        top: '-225px',
        left: 'calc(50% - 900px)',
        background: styles.colorPrimaryPurple,
      },
      // top center
      {
        width: '500px',
        height: '500px',
        top: '-200px',
        right: 'calc(50% - 290px)',
        background: styles.colorPrimaryPurple,
      },
      // bottom center
      {
        width: '300px',
        height: '300px',
        bottom: '-220px',
        right: 'calc(50% - 550px)',
        background: styles.colorPrimaryPurple,
      },
      // bottom right
      {
        width: '60px',
        height: '60px',
        bottom: '68px',
        right: 'calc(50% - 520px)',
        background: styles.colorSecondaryGreen,
      },
    ];

    return (
      <div className={classnames(styles.Section1, 'position-relative')}>
        <Orbs orbs={orbs} blurEffect={true}>
          <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4', 'pt-4', 'pb-5')}>
            <div className='row'>
              <div className='col-lg-6'>
                <h1 className={classnames(styles.h1, styles.ColorPrimaryWhite, 'mb-3')}>Amply Cohort</h1>
                <p
                  className={classnames(styles.h5Span, styles.ColorPrimaryWhite, 'mb-3', 'w-100', 'fw-normal')}
                  style={{ maxWidth: '500px', fontSize: '18px' }}>

                  Thank you for your interest in joining this upcoming cohort. This program features Amply's transactional email platform that uses data to help product development teams ship software faster than ever.
                </p>

                <Button onClick={() => scrollTo()} className='w-100' style={{ maxWidth: '180px' }}>Join Us</Button>
              </div>

              <div className={classnames(styles.Right, 'col-lg-6')} >
                <div className={classnames(styles.Card4, 'p-3')}>
                  <p style={{ fontSize: '15px' }} className={classnames(styles.h5Span, styles.ColorPrimaryWhite, 'mb-1', 'fw-normal')}>This cohort is expected to run for 4 weeks <br className='d-lg-block d-none' /> beginning on <b>October 9, 2023.</b></p>
                  <p style={{ fontSize: '15px' }} className={classnames(styles.h5Span, styles.ColorPrimaryWhite, 'mb-0', 'fw-normal')}>You'll get free, unlimited usage during <br className='d-lg-block d-none' /> the 1-month cohort period.</p>
                </div>
              </div>
            </div>
          </div>
        </Orbs>

        <div className={classnames(styles.Aura)} />
      </div>
    );
  }
}
