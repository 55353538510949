import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import Line3 from './Images/Section6/Line3';

import styles from 'styles/Marketing/Platform/Section6';

@observer
export default class Section6 extends Component {
  render() {
    const activeBreakpoint = this.props.activeBreakpoint;

    return (
      <div className={classnames(styles.Container, 'py-5')}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4', 'position-relative')}>
          <div className={classnames(styles.Line3)}>
            <Line3 />
            <div className={classnames(styles.Breakpoint6, activeBreakpoint?.index == 5 ? styles.Active : '')} />
            <div className={classnames(styles.Breakpoint7, activeBreakpoint?.index == 6 ? styles.Active : '')} />
          </div>

          <div className={classnames(styles.Content, 'mb-5')}>
            <h2 className={classnames(styles.h2, styles.ColorPrimaryWhite)}>Real-Time Threat Detection <br/><span className={classnames(styles.ColorPrimaryPurple)}>Elevating Your Security</span></h2>
            <p className={classnames(styles.h5, styles.ColorPrimaryWhite)}>Transactional email contains the most sensitive events in the user lifecycle including sign-ups, password resets, financial transactions, and more. Amply leverages these mission-critical interactions to identify threats based on sending patterns and user engagement. <span className={classnames(styles.Tint4)}> Our proactive solution gives security teams a full view of their threat landscape to protect against costly financial and data loss.</span></p>
          </div>

          <div className='row mx-lg-4 mx-0 mb-0'>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card4, 'h-100', 'p-2')}>
                <h5 className={classnames(styles.h5, styles.ColorPrimaryWhite)}>Defend against <br/>account takeover</h5>
                  <p className={classnames(styles.p2, styles.ColorPrimaryWhite)}>Amply’s Email-based Intrusion Detection System analyzes anomalies in user activity and sending patterns, including unusual and too-fast-to-travel locations, device attributes, timestamps, and more to detect compromised accounts.</p>
                  <p className={classnames(styles.p2, styles.ColorPrimaryWhite, 'mb-1')}>Amply instantly notifies you of abnormalities that indicate account takeover and even blocks lateral escalation attempts that originate from your user's email client. EIDS helps you lock down password resets and single sign-on links to reduce breach-related churn by 13%.</p>
              </div>
            </div>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card4, 'h-100', 'p-2')}>
                <h5 className={classnames(styles.h5, styles.ColorPrimaryWhite)}>Support & streamline <br/>easier investigations</h5>
                <p className={classnames(styles.p2, styles.ColorPrimaryWhite)}>Security teams are in the dark without a meaningful audit trail of user activity. Amply helps streamline investigations and brings security issues to light with event data from individual user profiles.</p>
              </div>
            </div>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card4, 'h-100', 'p-2')}>
                <h5 className={classnames(styles.h5, styles.ColorPrimaryWhite)}>Access security <br/>metrics in real-time</h5>
                <p className={classnames(styles.p2, styles.ColorPrimaryWhite)}>Stay informed of security-related events with a dashboard displaying sensitive account activity. With access to daily account and financial activity, easily see when things deviate from normal.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
