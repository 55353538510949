import React, { Component } from 'react';
import classnames from 'classnames';

import { scrollTo } from './ScrollTo';

import Button from 'components/Util/Buttons/LightBackground/LargePrimarySquareButton';

import styles from 'styles/Cohorts';

export default class Section5 extends Component {
  render() {
    return (
      <div className={classnames(styles.Section5)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2')}>
            <div className={classnames(styles.Row, 'row')}>
              <div className='col-lg-6 py-2'>
                <h5
                  style={{ fontSize: '16px' }}
                  className={classnames(styles.h5, styles.ColorPrimaryWhite, 'mb-3')}>

                  This is a great opportunity to shape a service <br className='d-none d-lg-block' /> that could help multiple critical teams within your <br className='d-none d-lg-block' /> organization.
                </h5>

                <h5 className={classnames(styles.h5, styles.ColorPrimaryWhite, 'mb-2')}>This cohorts ends November 3, 2023</h5>

                <Button onClick={() => scrollTo()} className='d-block'>Join Us</Button>
              </div>

              <div className='col-lg-6 py-2'>
              </div>
          </div>
        </div>
      </div>
    );
  }
}
