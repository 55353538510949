import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Analytics extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg xmlns="http://www.w3.org/2000/svg" width="13.033" height="13" viewBox="0 0 13.033 13">
  <path id="data_usage_FILL0_wght400_GRAD0_opsz48" d="M87.516,191a6.305,6.305,0,0,1-2.525-.513,6.68,6.68,0,0,1-2.077-1.393,6.512,6.512,0,0,1-1.4-2.069,6.487,6.487,0,0,1,5.531-9.009v1.662a4.841,4.841,0,0,0,.472,9.66,4.827,4.827,0,0,0,2.216-.529,5.14,5.14,0,0,0,1.776-1.491l1.434.831a6.613,6.613,0,0,1-2.37,2.1A6.468,6.468,0,0,1,87.516,191Zm5.9-3.731-1.434-.8a5.963,5.963,0,0,0,.293-1,5.1,5.1,0,0,0,.1-.986,4.733,4.733,0,0,0-1.246-3.274,4.573,4.573,0,0,0-3.1-1.548V178a6.423,6.423,0,0,1,6.011,6.419,6.737,6.737,0,0,1-.155,1.442A7.377,7.377,0,0,1,93.414,187.269Z" transform="translate(-81 -178)" fill="${color}"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
