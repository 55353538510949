import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import TextareaM from 'components/Util/Inputs/TextareaM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import styles from 'styles/Dashboard/Base';

@observer
export default class Config extends Component {
  onSuccess = (data) => {
    window.location = `/home/sso_connections/${data.id}`;
  }

  render() {
    const { ssoConnection, action, method, store } = this.props;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>

        <Form method={method} action={action} onSuccess={this.onSuccess} store={store}>
          <label className={classnames(styles.Label)}>
            <p>Name</p>
            <InputM
              defaultValue={ssoConnection.name}
              className={classnames(styles.InputWidth, 'mb-2')}
              name='name'
              placeholder='Connection Name'
              store={store} />
          </label>

          { ssoConnection.id > 0 && (
            <label className={classnames(styles.Label)}>
              <p>IDP Metadata</p>
              <p className={classnames(styles.InputDescription, 'mb-1')}>The XML document from your provider that contains information necessary to interact with SAML-enabled identity services.</p>
              <TextareaM
                defaultValue={ssoConnection.idp_metadata}
                style={{maxWidth: '500px'}}
                className='mb-2 w-100'
                name='idp_metadata'
                placeholder='IDP Metadata'
                store={store} />
            </label>
          )}

          <div>
            <Button>{ method === 'put' ? 'Update' : 'Create' }</Button>
            <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={ssoConnection.id} store={store} />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
