import React, { Component } from 'react';

import Content from 'components/Dashboard/Content';
import UnsubscribeGroupsIndex from 'components/Dashboard/Settings/UnsubscribeGroups/Index';

export default class Index extends Component {
  render() {
    return (
      <Content>
        <UnsubscribeGroupsIndex />
      </Content>
    );
  }
}
