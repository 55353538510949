import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Share from 'components/Util/Icons/Share';

import Config from './Config';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  afterDelete = () => {
    window.location = '/home/settings/webhooks';
  }

  action = () => {
    const { webhook } = this.props;

    switch(webhook.type) {
      case 'inbound_parse':
        return `/api/v1/settings/webhooks/inbound_parses/${webhook.id}`;
      case 'email_activity':
        return `/api/v1/settings/webhooks/email_activities/${webhook.id}`;
    }
  }

  render() {
    const { webhook } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/webhooks' className='me-1'>
            <BackButton />
          </a>

          Update Webhook

          <Delete action={this.action()} store={this.store} afterDelete={this.afterDelete} className='float-end mb-2'>Delete</Delete>
        </h3>

        <LoadableCard className='mb-2' loadState={webhook.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Webhook</h5>

          <Attribute icon={Share} name='Type' value={webhook.formattedType} className='mb-1' />
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={webhook.loaded}>
          <Config method='put' action={this.action()} store={this.store} webhook={webhook} />
        </LoadableCard>
      </div>
    );
  }
}
