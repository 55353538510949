import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';

import styles from 'styles/Base';

export default class Microsoft extends Component {
  render() {

  const src = `
<svg enable-background="new 0 0 2499.6 2500" viewBox="0 0 2499.6 2500" xmlns="http://www.w3.org/2000/svg"><path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b"/><path d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z" fill="#80cc28"/><path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef"/><path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09"/></svg>
`
  const encodedSrc = new Buffer(src).toString('base64');

    return (
      <a href={this.props.href} className={classnames('d-inline-block', this.props.className)}>
        <button className={classnames(styles.SSO, styles.p2, 'w-100')} style={{textTransform: 'none'}} type='button'>
          <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} width='18' className='mr-1' />
          Continue with Microsoft
        </button>
      </a>
    );
  }
}
