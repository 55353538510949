import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Line3 extends Component {
  render() {
    const src = `
<svg width="581" height="2444" viewBox="0 0 581 2444" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1088.07 2443.34L185.429 1576.12C118.453 1511.74 116.351 1405.25 180.734 1338.27C187.254 1331.49 194.331 1325.27 201.891 1319.67L428.399 1151.82C494.363 1102.96 515.437 1013.63 478.262 940.446L0.999878 1.00026" stroke="#4942FB"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
