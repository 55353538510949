import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Input from 'components/Util/Inputs/InputM';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Editor';

@observer
export default class LayerName extends Component {
  store = new Store();

  onChange = (event) => {
    this.props.node.setName(event.target.value);
  }

  render() {
    const { node } = this.props;
    return (
      <label className={classnames(styles.Label)}>
        <p>Layer Name</p>

        <Input
          placeholder={node.type}
          store={this.store}
          value={String(node.name || '')}
          onChange={this.onChange} />
      </label>
    );
  }
}



