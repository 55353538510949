import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Search extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="i"><circle id="Ellipse" cx="7" cy="7" r="6.2" stroke="${color}" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path id="Line" d="M14.0004 13.5858L11.6943 11.2797" stroke="${color}" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
