import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, toJS } from 'mobx';
import classnames from 'classnames';

import * as ChartGeo from 'chartjs-chart-geo';
import USATopology from 'us-atlas/states-10m.json'
import WorldTopology from 'world-atlas/countries-110m.json'

import Map from './Charts/Map';
import SensitiveEventLocations from './SensitiveEventLocations';

import styles from 'styles/Visualizations';

@observer
export default class SensitiveGeoEvents extends Component {
  view = observable.object({ map: 'usa' });

  constructor(props) {
    super(props);

    const { widget } = props;

    this.usaTopology = structuredClone(ChartGeo.topojson.feature(USATopology, USATopology.objects.states).features);
    this.usaData = {
      datasets: [toJS(widget.data.us_map.datasets[0])]
    };

    this.worldTopology = structuredClone(ChartGeo.topojson.feature(WorldTopology, WorldTopology.objects.countries).features);
    // rename to align what we call US in our database
    this.worldTopology.filter(a => a.properties.name == 'United States of America')[0].properties.name = 'United States';
    this.worldData = {
      datasets: [toJS(widget.data.world_map.datasets[0])]
    }
  }

  toggle = (e) => {
    if (this.view.map === 'usa') {
      this.view.map = 'world';
    }
    else {
      this.view.map = 'usa';
    }
  }

  render() {
    const { widget, options, chartRef } = this.props;

    return (
      <div className={classnames(styles.Map, 'row', 'w-100', 'h-100')}>
        <div className='col-5 d-flex'>
          <SensitiveEventLocations widget={widget} view={this.view.map} />
        </div>

        <div className={classnames(this.view.map === 'usa' ? styles.Usa : styles.World, 'col-7', 'd-flex')}>
          <div onClick={this.toggle} className={classnames(styles.Toggle)}>Toggle</div>

          <div className={classnames(this.view.map === 'world' && 'd-none', 'w-100', 'h-100')}>
            <Map
              id={`${widget.id}/usa`}
              data={structuredClone(this.usaData)}
              options={options}
              topology={structuredClone(this.usaTopology)}
              projection='albersUsa'
              chartRef={chartRef} />
          </div>

          <div className={classnames(this.view.map === 'usa' && 'd-none', 'w-100', 'h-100')}>
            <Map
              id={`${widget.id}/world`}
              data={structuredClone(this.worldData)}
              options={options}
              topology={structuredClone(this.worldTopology)}
              projection='equirectangular'
              chartRef={chartRef} />
          </div>
        </div>
      </div>
    );
  }
}
