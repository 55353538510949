import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Col } from 'react-bootstrap';
import classnames from 'classnames';
import Document from './Content/Document';

import styles from 'styles/Dashboard/Editor';
@observer
export default class Content extends Component {
  onMouseLeave = (event) => {
    if (this.props.store.draggingNode) {
      return;
    }

    this.props.store.clearHoveredNode();
  }

  onClick = (event) => {
    this.props.store.root.select();
  }

  render() {
    const { root } = this.props.store;

    return (
      <Col className={classnames('p-0', 'text-center', 'mt-2', styles.Content)} onMouseLeave={this.onMouseLeave} onClick={this.onClick}>
        <div className='mx-auto' style={{maxWidth: '600px'}}>
          <Document node={root} />
        </div>
      </Col>
    );
  }
}
