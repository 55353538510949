import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import BaseInput from './BaseInput';

import styles from 'styles/Base';

@observer
export default class TextareaL extends Component {
  render() {
    const { className, children, ...params } = this.props;
    return (
      <BaseInput {...params} tag='textarea' className={classnames(styles.TextareaL, className)} />
    );
  }
}
