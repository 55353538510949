import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import SelectM from 'components/Util/Inputs/SelectM';
import InputM from 'components/Util/Inputs/InputM';

import styles from 'styles/Dashboard/Base';

@observer
export default class InboundParse extends Component {
  render() {
    const { webhook, store } = this.props;
    const verifiedDomains = (webhook.verified_domains || []).filter(vd => vd.verified_at !== null);

    return (
      <React.Fragment>
        <label className={classnames(styles.Label)}>
          <p>Receiving Domain</p>
          <p className={classnames(styles.InputDescription, 'mb-1')}>The domain to receive incoming email on behalf of. The incoming email is programmatically parsed on Amply's servers and sent via POST request to your destination URL.</p>
        </label>

        <InputM
          className={classnames(styles.InputWidth, 'mb-2')}
          defaultValue={webhook.subdomain}
          placeholder='Subdomain (optional)'
          name='subdomain'
          store={store} />

        <span className='fw-bold position-relative' style={{ paddingLeft: '4px', paddingRight: '4px', top: '8px' }}>.</span>

        <SelectM
          className={classnames(styles.InputWidth, 'mb-2')}
          defaultValue={webhook.verified_domain_id}
          placeholder='Verified Domain'
          name='verified_domain_id'
          store={store}>

          { verifiedDomains.map((vd, index) =>
            <option key={index} value={vd.id}>{vd.name}</option>
          )}
        </SelectM>

        <label className={classnames(styles.Label)}>
          <p>Destination URL</p>
          <p className={classnames(styles.InputDescription, 'mb-1')}>The URL to receive the inbound message via POST request.</p>

          <InputM
            className={classnames(styles.InputWidth, styles.ShowInputMessage, 'mb-2')}
            defaultValue={webhook.destination_url}
            placeholder='Destination URL'
            name='destination_url'
            store={store} />
        </label>

        <label className={classnames(styles.Label)}>
          <p>Sending Method</p>
          <p className={classnames(styles.InputDescription, 'mb-1')}>The way email data is sent to your destination URL.</p>

          <SelectM
            className={classnames(styles.InputWidth)}
            defaultValue={webhook.sending_method}
            placeholder='Sending Method'
            name='sending_method'
            store={store}>

            <option value='default'>Default</option>
            <option value='raw'>Raw</option>
          </SelectM>
        </label>
      </React.Fragment>
    );
  }
}
