import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';
import Table from '../Table';

import Monitor from 'models/Monitor';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  monitorType = (row) => {
    const m = new Monitor({ type: row.type });
    return m.type_formatted;
  }

  render() {
    const headers = [
      {
        key: 'name',
        value: 'Name',
      },
      {
        key: 'type',
        value: 'Type',
        width: '250',
        callback: this.monitorType
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: '250',
        className: 'text-end',
      }
    ]

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Monitors</h3>
        <Table path='/api/v1/monitors' hrefBase='/home/monitors' hrefKey='id' headers={headers}>
          <div className='text-end'>
            <a href='/home/monitors/new'><Button><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button></a>
          </div>
        </Table>
      </div>
    );
  }
}
