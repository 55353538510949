import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class One extends Component {
  render() {
    const src = `
<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 15C0 6.71573 6.71573 0 15 0H47C55.2843 0 62 6.71573 62 15V47C62 55.2843 55.2843 62 47 62H15C6.71573 62 0 55.2843 0 47V15Z" fill="url(#paint0_linear_1_1728)"/>
<path d="M1 16C1 7.71573 7.71573 1 16 1H46C54.2843 1 61 7.71573 61 16V46C61 54.2843 54.2843 61 46 61H16C7.71573 61 1 54.2843 1 46V16Z" fill="url(#paint1_linear_1_1728)"/>
<path d="M23.8 25.0002L27.36 18.2002H35.2V47.0002H31.2V20.0802C31.2 19.8935 31.1067 19.8002 30.92 19.8002C30.7867 19.8002 30.68 19.8335 30.6 19.9002C30.52 19.9669 30.44 20.0802 30.36 20.2402L27.68 25.8002H23.8V25.0002Z" fill="url(#paint2_linear_1_1728)"/>
<defs>
<linearGradient id="paint0_linear_1_1728" x1="28.4258" y1="66.3438" x2="31" y2="66.3438" gradientUnits="userSpaceOnUse">
<stop stop-color="#3300DE"/>
<stop stop-color="#6834FF"/>
<stop offset="0.536946" stop-color="#5535FE"/>
<stop offset="1" stop-color="#0062FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1_1728" x1="3.57617" y1="4.54492" x2="58.2793" y2="4.54492" gradientUnits="userSpaceOnUse">
<stop stop-color="#0B151E"/>
<stop offset="1" stop-color="#303A57"/>
</linearGradient>
<linearGradient id="paint2_linear_1_1728" x1="35.2001" y1="50.9143" x2="29.5" y2="50.9143" gradientUnits="userSpaceOnUse">
<stop stop-color="#86B0FF"/>
<stop offset="1" stop-color="#48FFC0"/>
</linearGradient>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
