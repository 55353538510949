import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Config from './Config';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  afterDelete = () => {
    window.location = '/home/monitors';
  }

  render() {
    const { monitor } = this.props;
    const action = `/api/v1/monitors/${monitor.id}`;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/monitors' className='me-1'>
            <BackButton />
          </a>

          Update Monitor

          <Delete action={action} store={this.store} afterDelete={this.afterDelete} className='float-end mb-2'>Delete</Delete>
        </h3>

        <LoadableCard className='mb-2' loadState={monitor.loaded}>
          <Config method='put' action={action} store={this.store} monitor={monitor} />
        </LoadableCard>
      </div>
    );
  }
}
