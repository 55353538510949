import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import LayoutNode from './Layout/LayoutNode';

import Tooltip from 'components/Util/Misc/Tooltip';

import Add from 'components/Util/Icons/Add';
import TableChart from 'components/Util/Icons/TableChart';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Layout extends Component {
  onToggleNode(node, toggled) {
    node.setToggled(toggled);
  }

  render() {
    const { store } = this.props;

    return (
      <div className={classnames(styles.Layout)} onClick={this.onClick}>
        <p className={classnames(styles.Header, 'mx-2', 'mt-2', 'mb-1')}><TableChart />Structure</p>
        <LayoutNode node={store.root} />
      </div>
    );
  }
}
