import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Config from './Config';

@observer
export default class New extends Component {
  render() {
    const { ...params } = this.props;

    return (
      <Config {...params} action='/api/v1/searches/sql' method='post' />
    );
  }
}
