import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import UnsubscribeGroupsUsersNew from 'components/Dashboard/Settings/UnsubscribeGroups/Users/New';

import UnsubscribeGroup from 'models/UnsubscribeGroup';
import api from 'models/API';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    const unsubscribeGroupId = this.props.match.params.id;
    this.unsubscribeGroup = new UnsubscribeGroup(); 

    api.get(`/api/v1/settings/unsubscribe_groups/${unsubscribeGroupId}`).then(data => {
      this.unsubscribeGroup.assign(data);
    });
  }


  render() {
    return (
      <Content>
        <UnsubscribeGroupsUsersNew unsubscribeGroup={this.unsubscribeGroup} />
      </Content>
    );
  }
}
