import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class FormatLetterSpacing extends Component {
  render() {
    const src = `
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M226-80 80-226l146-145 42 42-73 73h569l-72-73 43-42 145 145L734-80l-42-42 74-74H195l73 74-42 42Zm61-360 164-440h59l164 440h-55l-43-122H385l-43 122h-55Zm114-166h160l-78-222h-4l-78 222Z"/></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
