import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Select from 'components/Util/Inputs/SelectM';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Editor';

@observer
export default class ColumnCount extends Component {
  store = new Store();

  onChange = (columnCount) => {
    this.props.node.setColumns(columnCount);
  }

  render() {
    const { node } = this.props;
    const values = Array(12).fill(0).map((_, i) => i + 1);

    return (
      <label className={classnames(styles.Label)}>
        <p>Columns</p>

        <Select
          className='w-100'
          placeholder={node.type}
          store={this.store}
          value={node.children.length}
          onChange={this.onChange}>

          { values.map((value, index) =>
            <option value={value} key={index}>{value}</option>
          )}
        </Select>
      </label>
    );
  }
}
