import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import sqlFormatter from '@sqltools/formatter';

import NewButton from 'components/Util/Buttons/DarkBackground/MediumPrimaryButton';
import SaveButton from 'components/Util/Buttons/DarkBackground/MediumSecondaryButton';
import Add from 'components/Util/Icons/Add';
import Save from 'components/Util/Icons/Save';
import Form from 'components/Util/Inputs/Form';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Tooltip from 'components/Util/Misc/Tooltip';

import Editor from '../../Editor';
import Queries from './Queries';
import Schema from './Schema';
import Console from './Console';

import RunQueryBtn from './RunQueryBtn';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Config extends Component {
  store = new Store();

  onSuccess = (data) => {
    const { sql } = this.props;
    sql.assign(data);
    window.location = `/home/analytics/searches/sql/${data.id}`
  }

  afterDelete = (data) => {
    // hacky, but it this is how we pass isStale check
    this.store.get('name').setValue(data.name);
    this.store.get('body[query]').setValue(data.body.query);

    window.location = '/home/analytics/searches/sql/new';
  }

  formatQuery = () => {
    const el = this.store.get('body[query]');
    if (el) {
      el.value = sqlFormatter.format(el.value);
    }
  }

  onError = (data) => {
    const { sql } = this.props;

    if (this.store.get('name').error?.length > 0) {
      sql.isEditingName = true;
    }
  }

  render() {
    const { action, method, queries, sql } = this.props;

    const navRight = (
      <div className='text-end'>
        { sql.id > 0 && (
          <Tooltip content='Delete'>
            <Delete
              action={`/api/v1/searches/sql/${sql.id}`}
              store={this.store}
              afterDelete={this.afterDelete}
              className={classnames(styles.Delete, 'align-middle', 'me-1')} />
          </Tooltip>
        )}

        <Tooltip content='Save'>
          <SaveButton
            className={classnames(styles.Save, 'me-1', 'align-middle')}>

            <Save /></SaveButton>
        </Tooltip>

        <Tooltip content='New'>
          <a href='/home/analytics/searches/sql/new'>
            <NewButton
              type='button'
              className={classnames(styles.New, 'position-relative', 'align-middle')}>

              <Add className='position-relative' />
            </NewButton>
          </a>
        </Tooltip>
      </div>
    );

    const navMiddle = (
      <div>
        <RunQueryBtn type='button' sql={sql} store={this.store} className='ms-1' />
        <button type='button' onClick={this.formatQuery} className={classnames(styles.Ghost, 'ms-1')}>Format SQL</button>
      </div>
    );

    return (
      <Form action={action} method={method} store={this.store} onSuccess={this.onSuccess} onError={this.onError}>
        <Editor
          className={classnames(styles.SqlEditor)}
          model={sql}
          queries={queries}
          store={this.store}
          navMiddle={navMiddle}
          navRight={navRight}
          editorLeft={<Queries queries={queries} sql={sql} />}
          editorRight={<Schema />}
          editorMiddle={<Console sql={sql} store={this.store} />} />
      </Form>
    );
  }
}
