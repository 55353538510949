import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Config from './Config';

@observer
export default class New extends Component {
  render() {
    const { template, ...params } = this.props;

    return (
      <Config {...params} template={template} action={`/api/v1/settings/templates/${template.id}`} method='put' />
    );
  }
}
