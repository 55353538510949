import { observable, computed, action } from 'mobx';
import { get, post, put, del } from 'helpers/apiHelper';

export class API {
  @observable jwe;
  @observable expiresAt;
  @observable loaded = observable.object({});

  constructor(params={}) {
    this.assign(params);
  }

  assign(params) {
    this.jwe = params.jwe;
    this.expiresAt = Date.parse(params.expires_at);
  }

  shouldLoadJwe() {
    const threshold = 30000; // 30 seconds
    return this.jwe === undefined || ((this.expiresAt - threshold) < new Date);
  }

  async get(path, params={}) {
    const api = this;
    api.loaded[path] = false;
    
    if (this.shouldLoadJwe()) { 
      await this.loadJwe();
    }

    params.jwe = this.jwe;
    const promise = get(path, params);
    promise.then(data => api.loaded[path] = true);
    return promise;
  }

  async post(path, params={}) {
    const api = this;
    api.loaded[path] = false;
    
    if (this.shouldLoadJwe()) { 
      await this.loadJwe();
    }

    params.jwe = this.jwe;
    const promise = post(path, params);
    promise.then(data => api.loaded[path] = true);
    return promise;
  }

  async put(path, params={}) {
    const api = this;
    api.loaded[path] = false;
   
    if (this.shouldLoadJwe()) {
      await this.loadJwe();
    }

    params.jwe = this.jwe;
    const promise = put(path, params);
    promise.then(data => api.loaded[path] = true);
    return promise;
  }

  async del(path) {
    const api = this;
    api.loaded[path] = false;
  
    if (this.shouldLoadJwe()) {
      await this.loadJwe();
    }

    const promise = del(`${path}?jwe=${this.jwe}`);
    promise.then(data => api.loaded[path] = true);
    return promise;
  }

  async loadJwe() {
    // TODO: improve this so that simultaneous API calls won't
    // load the jwe's simultaneously (just wait for one to load)
    const api = this;
    const promise = get('/home/jwe');
    promise.then(data => api.assign(data));
    return promise;
  }
}

const a = new API();
export default a;
