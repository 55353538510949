import React, { Component } from 'react';

import Content from 'components/Dashboard/Content';
import IpAddressIndex from 'components/Dashboard/Settings/IpAddresses/Index';

import GeneralSettings from 'models/GeneralSettings';
import api from 'models/API';

export default class Index extends Component {
  generalSettings = new GeneralSettings();

  constructor(props) {
    super(props);

    const generalSettings = this.generalSettings;

    api.get('/api/v1/settings/general').then(data => {
      generalSettings.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <IpAddressIndex generalSettings={this.generalSettings} />
      </Content>
    );
  }
}
