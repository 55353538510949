import { observable, computed, action } from 'mobx';
import { toJS } from 'mobx';
import ReactDOMServer from 'react-dom/server';

export class Alert {
  @observable id;
  @observable content_string;
  @observable content_id;
  @observable display = false;

  constructor(args={}) {
    this.assign(args);
  }

  assign(params) {
    this.id = params.id;
    this.ref = params.ref;
    this.content_string = params.content_string;
    this.content_id = params.content_id;
    this.display = params.display;
  }

  setContents(content_string) {
    this.content_id = null;
    this.content_string = ReactDOMServer.renderToStaticMarkup(content_string);
  }

  setContentsFromId(content_id) {
    this.content_string = null;
    this.content_id = content_id;
  }

  persistAlert() {
    sessionStorage.setItem('alert', JSON.stringify(toJS(this)));
  }

  loadAlertFromLocalStorage() {
    const jsonData = sessionStorage.getItem('alert');

    if (jsonData != null) {
      const data = JSON.parse(jsonData);
      data.display = false;
      this.assign(data);
      sessionStorage.removeItem('alert');
      return true;
    }

    return false;
  }

  setDisplay(display) {
    this.display = display;
  }
}

const a = new Alert;
export default a;
