import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import qs from 'qs';

import Content from 'components/Dashboard/Content';
import SQLNew from 'components/Dashboard/Analytics/SQL/New';

import Sql from 'models/SQL';
import Collection from 'models/TableAPI';

@observer
export default class New extends Component {
  sql = new Sql();
  queries = new Collection({ path: '/api/v1/searches/sql' });

  constructor(props) {
    super(props);
    const params = qs.parse(props.location.search.replace(/^\?/, ''));

    if (params.q !== undefined) {
      this.sql.assign({ body: { query: params.q }});
    }

    this.queries.fetch().then(() => {
      // must wait until initial queries have loaded
      this.queries.results.push(this.sql);
      this.sql.loaded = true;
    });
  }

  render() {
    return (
      <Content disableMaxWidth={true}>
        <SQLNew sql={this.sql} queries={this.queries} />
      </Content>
    );
  }
}
