import React, { Component } from 'react';
import { observable } from 'mobx';

import Content from 'components/Dashboard/Content';
import UsersIndex from 'components/Dashboard/Users/Index';

export default class Index extends Component {
  render() {
    return (
      <Content>
        <UsersIndex />
      </Content>
    );
  }
}
