import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import TemplatesShow from 'components/Dashboard/Settings/Templates/Show';

import Template from 'models/Template';
import api from 'models/API';

@observer
export default class New extends Component {
  constructor(props) {
    super(props);

    this.template = new Template(); 
    const id = props.match.params.id;

    api.get(`/api/v1/settings/templates/${id}`).then(data => {
      this.template.assign(data);
      this.template.editor.root.select();
    });

    api.get('/home/account').then(data => {
      this.template.previewEmail = data.email;
    });
  }


  render() {
    return (
      <Content disableMaxWidth={true}>
        <TemplatesShow template={this.template} />
      </Content>
    );
  }
}
