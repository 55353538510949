import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { del } from 'helpers/apiHelper';

import Dashboard from 'components/Util/Icons/Dashboard';
import Analytics from 'components/Util/Icons/Analytics';
import MailSettings from 'components/Util/Icons/MailSettings';
import Monitor from 'components/Util/Icons/Monitor';
import Documentation from 'components/Util/Icons/Documentation';
import Contact from 'components/Util/Icons/Contact';
import Person from 'components/Util/Icons/Person';

import ExpandMore from 'components/Util/Icons/ExpandMore';
import ExpandLess from 'components/Util/Icons/ExpandLess';

import Logo from 'components/Util/Misc/Logo';
import Plane from 'components/Util/Misc/Plane';

import OrganizationAccount from 'models/OrganizationAccount';

import styles from 'styles/Dashboard/Navigation';

@observer
export default class Navigation extends Component {
  navStatus = observable.object({ activated: undefined, expanded: undefined, analyticsMaxHeight: 0, mailSettingsMaxHeight: 0, accountMaxHeight: 0 });
  analyticsRef = React.createRef();
  mailSettingsRef = React.createRef();
  accountRef = React.createRef();

  constructor(props) {
    super(props);

    this.organizationAccount = new OrganizationAccount();
    this.organizationAccount.assign({ roles: window.amplyRoles });
  }


  componentDidMount() {
    const navStatus = this.navStatus;
    navStatus.analyticsMaxHeight = this.analyticsRef.current.scrollHeight;
    navStatus.mailSettingsMaxHeight = this.mailSettingsRef.current.scrollHeight;
    navStatus.accountMaxHeight = this.accountRef.current.scrollHeight;
    this.setActivated();
  }

  setActivated = () => {
    const path = window.location.pathname;

    if (path.match(/^\/home[^\/]*$/)) {
      this.navStatus.activated = 'home';
    }
    else if (path.match(/^\/home\/analytics\//)) {
      this.navStatus.activated = 'analytics';
      this.navStatus.expanded = 'analytics';
    }
    else if (path.match(/^\/home\/monitors[^\/]*/)) {
      this.navStatus.activated = 'monitors';
    }
    else if (path.match(/^\/home\/settings\//)) {
      this.navStatus.activated = 'mail_settings';
      this.navStatus.expanded = 'mail_settings';
    }
    else if (path.match(/^\/home\/contact[^\/]*$/)) {
      this.navStatus.activated = 'contact';
    }
    else if (path.match(/^\/home\/(account|users|sso_connections)\/*/)) {
      this.navStatus.activated = 'account';
      this.navStatus.expanded = 'account';
    }
  }

  shouldActivate = (category) => {
    const navStatus = this.navStatus;
    const nonExpandable = ['home', 'monitors', 'contact'];

    if (navStatus.activated === category) {
      if (navStatus.expanded === category || (navStatus.expanded === undefined && nonExpandable.includes(category))) {
        return styles.Active;
      }
    }

    return false;
  }

  shouldCollapse = (category) => {
    if (this.navStatus.expanded !== category) {
      return styles.Collapsed;
    }

    return styles.Expanded;
  }

  toggleExpand = (e, expand) => {
    const navStatus = this.navStatus;

    if (e.target.tagName === 'A') {
      return;
    }

    if (navStatus.expanded !== expand) {
      navStatus.expanded = expand;
    }
    else {
      navStatus.expanded = undefined;
    }
  }

  signOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const authenticityToken = document.querySelector('[name=csrf-token]').content;

    del(`/organizations/sign_out?authenticity_token=${encodeURIComponent(authenticityToken)}`).then(data => {
      window.location = '/';
    });
  }

  render() {
    const navStatus = this.navStatus;

    return (
      <nav className={classnames(styles.Container, 'navbar', 'navbar-expand-lg')}>
        <div className={classnames(styles.LogoContainer)}>
          <a href='/home' className='mb-lg-2'>
            <Logo className={classnames(styles.LogoExpanded)} color={styles.colorPrimaryWhite} />
            <Plane className={classnames(styles.LogoShrink)} color={styles.colorPrimaryWhite} />
          </a>
        </div>

        <button
          className={classnames(styles.Toggle, 'navbar-toggler')}
          type="button"
          onClick={this.onClick}
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">

            <span className='navbar-toggler-icon'></span>
        </button>

        <div className={classnames(styles.LinksContainer, 'collapse', 'navbar-collapse')} id='navbarSupportedContent'>
          <ul className={classnames('navbar-nav', 'w-100')}>
            <li className={classnames(this.shouldActivate('home'), 'nav-item')}>
              <div>
                <Dashboard className={classnames(styles.Icon)} />
                <a href='/home'>Dashboard</a>
              </div>
            </li>

            <li className={classnames(this.shouldActivate('analytics'), this.shouldCollapse('analytics'), 'nav-item')} onClick={(e) => this.toggleExpand(e, 'analytics')}>
              <div>
                <Analytics className={classnames(styles.Icon)} />
                <span>Analytics</span>

                <ExpandMore className={classnames(styles.ExpandMore, 'align-end')} />
                <ExpandLess className={classnames(styles.ExpandLess, 'align-end')} />
              </div>

                <ul
                  style={{ maxHeight: navStatus.analyticsMaxHeight }}
                  ref={this.analyticsRef}>

                  { this.organizationAccount.isAuthorized("external_user", "read") && <li><a href='/home/analytics/users'>Users</a></li> }
                  { this.organizationAccount.isAuthorized("search", "read") && <li><a href='/home/analytics/searches/basic/new'>Search</a></li> }
                  { this.organizationAccount.isAuthorized("search", "read") && <li><a href='/home/analytics/searches/sql/new'>SQL</a></li> }
                </ul>
            </li>

            { this.organizationAccount.isAuthorized("monitor", "read") &&  (
              <li className={classnames(this.shouldActivate('monitors'), 'nav-item')}>
                <div>
                  <Monitor className={classnames(styles.Icon)} />
                  <a href='/home/monitors'>Monitors</a>
                </div>
              </li>
            )}

            <li className={classnames(this.shouldActivate('mail_settings'), this.shouldCollapse('mail_settings'), 'nav-item')} onClick={(e) => this.toggleExpand(e, 'mail_settings') }>
              <div>
                <MailSettings className={classnames(styles.Icon)} />
                <span>Mail Settings</span>

                <ExpandMore className={classnames(styles.ExpandMore)} />
                <ExpandLess className={classnames(styles.ExpandLess)} />
              </div>

              <ul
                style={{ maxHeight: navStatus.mailSettingsMaxHeight }}
                ref={this.mailSettingsRef}>

                { this.organizationAccount.isAuthorized("general", "read") && <li><a href='/home/settings/general'>General</a></li> }
                { this.organizationAccount.isAuthorized("access_token", "read") && <li><a href='/home/settings/access_tokens'>Access Tokens</a></li> }
                { this.organizationAccount.isAuthorized("verified_domain", "read") && <li><a href='/home/settings/verified_domains'>Verified Domains</a></li> }
                { this.organizationAccount.isAuthorized("ip_address", "read") && <li><a href='/home/settings/ip_addresses'>IP Addresses</a></li> }
                { this.organizationAccount.isAuthorized("ip_pool", "read") && <li><a href='/home/settings/ip_pools'>IP Pools</a></li> }
                { this.organizationAccount.isAuthorized("template", "read") && <li><a href='/home/settings/templates'>Templates</a></li> }
                { this.organizationAccount.isAuthorized("unsubscribe_group", "read") && <li><a href='/home/settings/unsubscribe_groups'>Unsubscribe Groups</a></li> }
                { this.organizationAccount.isAuthorized("webhook", "read") && <li><a href='/home/settings/webhooks'>Webhooks</a></li> }
              </ul>
            </li>
          </ul>

          <ul className={classnames('navbar-nav', 'w-100', 'mt-auto')}>
            <li className={classnames('nav-item')}>
              <div>
                <Documentation className={classnames(styles.Icon)} />
                <a href='https://docs.sendamply.com' target='_blank'>Documentation</a>
              </div>
            </li>

            <li className={classnames(this.shouldActivate('contact'), 'nav-item')}>
              <div>
                <Contact className={classnames(styles.Icon)} />
                <a href='/home/contact'>Contact</a>
              </div>
            </li>

            <li className={classnames(this.shouldActivate('account'), this.shouldCollapse('account'), 'nav-item')} onClick={(e) => this.toggleExpand(e, 'account') }>
              <div className={classnames(!this.shouldCollapse('account') ? 'mb-2' : '')}>
                <Person className={classnames(styles.Icon)} />
                <span>Account</span>

                <ExpandMore className={classnames(styles.ExpandMore)} />
                <ExpandLess className={classnames(styles.ExpandLess)} />
              </div>

              <ul
                style={{ maxHeight: navStatus.accountMaxHeight }}
                className='mb-2'
                ref={this.accountRef}>

                <li><a href='/home/account'>Account Details</a></li>
                { this.organizationAccount.isAuthorized("sso_connection", "read") && <li><a href='/home/sso_connections'>SSO Connections</a></li> }
                { this.organizationAccount.isAuthorized("internal_user", "read") && <li><a href='/home/users'>Users</a></li> }
                <li><a href='/' onClick={this.signOut}>Sign Out</a></li>
              </ul>
            </li>

          </ul>
        </div>
      </nav>
    );
  }
}
