import { types, getSnapshot } from 'mobx-state-tree';

import Node from './Node';

export const Editor = types
  .model('Editor', {
    id: types.identifier,
    placeholders: types.array(types.string),
    root: types.maybe(Node),
    hoveredNode: types.maybe(types.reference(Node)),
    selectedNode: types.maybe(types.reference(Node)),
    draggingNode: types.maybe(types.reference(Node)),
    isLoaded: types.optional(types.boolean, false),
  })
  .actions(self => ({
    afterCreate() {
      self.root = Node.create({ id: `${self.id}/root`, type: 'Document', editor: self });
    },
    reset() {
      self.placeholders = [];
      self.hoveredNode = undefined;
      self.selectedNode = undefined;
      self.draggingNode = undefined;

      self.root.reset();
    },
    addRow(insertBefore) {
      const row = self.root.addChild('Row', {}, insertBefore);
      row.select();
    },
    addPlaceholders(...placeholders) {
      self.placeholders.push(...placeholders);
    },
    clearSelectedNode() {
      self.selectedNode = undefined;
    },
    load(json) {
      self.root.load(json);
      self.setIsLoaded(true);
    },
    setSelectedNode(node) {
      self.selectedNode = node;
    },
    clearHoveredNode() {
      self.hoveredNode = undefined;
    },
    setHoveredNode(node) {
      self.hoveredNode = node;
    },
    clearDraggingNode() {
      self.draggingNode = undefined;
    },
    setDraggingNode(node) {
      self.draggingNode = node;
    },
    setIsLoaded(value) {
      self.isLoaded = value;
    },
  }));

export default Editor;
