import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';
import Table from '../../Table';

import styles from 'styles/Dashboard/Base';

@observer
export default class Index extends Component {
  render() {
    const headers = [
      {
        key: 'token',
        value: 'Token',
        width: '300',
      },
      {
        key: 'description',
        value: 'Description',
      },
      {
        key: 'created_at',
        value: 'Created at',
        width: '250',
        className: 'text-end',
      }
    ]

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Access Tokens</h3>
        <Table path='/api/v1/settings/access_tokens' hrefBase='/home/settings/access_tokens' hrefKey='id' headers={headers}>
          <div className='text-end'>
            <a href='/home/settings/access_tokens/new'><Button><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button></a>
          </div>
        </Table>
      </div>
    );
  }
}
