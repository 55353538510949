import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { post } from 'helpers/apiHelper';

import styles from 'styles/Dashboard/Base';

@observer
export default class Test extends Component {
  sendTest = (event) => {
    event.preventDefault();

    const { store, monitor } = { ...this.props };
    const params = this.getParams();

    monitor.is_posting_test = true;

    post('/home/monitors/test', params).then(data => {
      store.clearErrors();
      store.clearSuccesses();
      monitor.is_posting_test = false;
      const errors = data['errors'] || [];

      for (const error of errors) {
        store.addError(error['field'], error['message']);
      }

      if (errors.length === 0) {
        store.addSuccess('notifications', 'successfully sent test notifications');
        setTimeout(function() {
          store.clearSuccesses();
        }, 2000);
      }
    });
  }

  getParams = () => {
    const { store, monitor } = { ...this.props };
    const csrfToken = document.querySelector('[name=csrf-token]').content;
    const name = store.getRef('name').current.value;
    const notifications = store.getRef('notifications').current.value;

    return {
      authenticity_token: csrfToken,
      name: name,
      id: monitor.id,
      type: monitor.type,
      detection_method: monitor.detection_method,
      detection_type: monitor.detection_type,
      alert_conditions: monitor.alert_conditions,
      notifications: notifications,
    };
  }

  render() {
    const { monitor } = { ...this.props };

    return (
      <React.Fragment>
        <a
          onClick={this.sendTest}
          className='mb-2 d-inline-block cursor-pointer mt-1'
          style={{ fontSize: '13px' }}>

          Send test event
        </a>
        { monitor.is_posting_test && (
          <div
            className={classnames(styles.ColorPrimaryPurple, 'd-inline-block spinner-border spinner-border-md ms-1 position-relative')}
            style={{ width: '16px', height: '16px', top: '2px' }}
            role='status'>

            <span className='visually-hidden'>Loading...</span>
          </div>
        )}
      </React.Fragment>
    );
  }
}
