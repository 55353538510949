import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Marketing/Platform/Section4';

export default class Section4 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container, 'pt-5', 'mb-5')}>
        <div className={classnames(styles.MaxWidth, 'mx-auto')}>
          <div className={classnames(styles.Background, 'text-center', 'position-relative', 'px-2', 'px-lg-4', 'mx-2', 'mx-lg-4')}>
            <h2 className={classnames(styles.h2, styles.ColorPrimaryWhite)}>Customer-First Insights <br /> <span className={classnames(styles.ColorPrimaryPurple)}>Driving Product Development</span></h2>
            <p className={classnames(styles.p1, styles.ColorPrimaryWhite, 'mb-0')}>The engagement data around your transactional emails is a treasure trove of insight. <b>Amply processes email data and transforms it into key product metrics, helping your product team make meaningful improvements to the customer journey.</b></p>
          </div>
        </div>
      </div>
    );
  }
}
