import React, { Component } from 'react';
import classnames from 'classnames';

import Divider from '../Divider';

import DevOps from './Images/Section3/DevOps';
import Product from './Images/Section3/Product';
import Security from './Images/Section3/Security';

import styles from 'styles/Marketing/Root/Section3';

export default class Section3 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-2', 'px-lg-4', 'position-relative')} style={{zIndex: '13'}}>
          <div className={classnames('px-lg-5', 'pt-5', 'px-0')}>
            <h3 className={classnames(styles.h3, 'mb-3')}>Agile teams can now shift from <br className='d-lg-block d-none'/>emergencies to opportunities.</h3>

            <div className={classnames(styles.Personas, 'row')}>
              <div className='col-lg-4'>
                <div className={classnames(styles.Card1, 'p-3')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>DEVOPS</h4>
                  <div className='text-center'>
                    <DevOps className='mb-2' />
                  </div>
                  <h5 className={classnames(styles.h5Span, 'mb-2')}>Speed up your<br/>enterprise metabolism</h5>
                  <p className={classnames(styles.p2, 'mb-0')}>Embrace a data-driven infrastructure that helps you anticipate issues, eliminate technical debt, and speed deployment of products customers love.</p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className={classnames(styles.Card1, 'p-3')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>PRODUCT</h4>
                  <div className='text-center'>
                    <Product className='mb-2' />
                  </div>
                  <h5 className={classnames(styles.h5Span, 'mb-2')}>Open a powerful lens<br/>into customer data</h5>
                  <p className={classnames(styles.p2, 'mb-0')}>Launch better products with insights derived from real-time customer engagement and transactional data.</p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className={classnames(styles.Card1, 'p-3')}>
                  <h4 className={classnames(styles.callout, styles.ColorPrimaryPurple, 'mb-2')}>SECURITY</h4>
                  <div className='text-center'>
                    <Security className='mb-2' />
                  </div>
                  <h5 className={classnames(styles.h5Span, 'mb-2')}>Build your immunity<br/>to threats</h5>
                  <p className={classnames(styles.p2, 'mb-0')}>Detect and mitigate account takeover with real-time visibility into user attributes that signal an account is at risk.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider style={{bottom: '116px'}} />
      </div>
    );
  }
}
