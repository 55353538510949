import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { setIframeYDimensions } from './Helpers';

import Gmail from 'components/Util/Misc/Gmail';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Desktop extends Component {
  desktopRef = React.createRef();
  iframeRef = React.createRef();
  svgID = 'js-amply-preview-svg';

  onResize = (e) => {
    this.updateView();
  }

  updateView = () => {
    const iframe  = this.iframeRef.current;
    const desktop = this.desktopRef.current;
    const svg     = document.getElementById(this.svgID);

    setIframeYDimensions(desktop, svg, iframe, 0.35, 16);
  }

  componentDidMount() {
    const { template } = this.props;
    window.addEventListener('resize', this.onResize);

    template.generatePreview().then(data => {
      this.updateView();
    });
  }

  render() {
    const { template } = this.props;

    return (
      <div ref={this.desktopRef} className={classnames(styles.Desktop)}>
        <Gmail id={this.svgID} />
        <iframe ref={this.iframeRef} srcDoc={template.previewHTML} />
      </div>
    );
  }
}
