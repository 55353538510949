import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import SelectM from 'components/Util/Inputs/SelectM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class QuarantineConfig extends Component {
  render() {
    const { unsubscribeGroup, store } = this.props;
    const maxWidth = '720px';
    const quarantineEnabled = store.get('quarantine[enabled]')?.value;

    return (
      <React.Fragment>
        <div className={classnames(styles.Label)}>
          <p>Quarantine</p>
          <p className={classnames(styles.InputDescription, 'mb-1')}>
            Help reduce legitimate users from being added to your unsubscribe group. Your quarantine will throttle the number of emails a recently bounced user can send before adding them to your unsubscribe group. This may affect your bounce rate as emails are retried.
          </p>
          <SelectM
            store={store}
            className={classnames(styles.InputWidth, 'mb-2')}
            defaultValue={unsubscribeGroup.quarantine.enabled}
            name='quarantine[enabled]'>

            <option value={true}>Enabled</option>
            <option value={false}>Disabled</option>
            </SelectM>
        </div>

        { quarantineEnabled && (
          <div className={classnames(styles.Label, 'mb-2')}>
            <p>Quarantine Settings</p>
            <p className={classnames(styles.InputDescription, 'mb-1')}>
              If an email is successfully delivered within the period, the user will be removed from your quarantine and stay out of your unsubscribe group. Otherwise, the user will be removed from your quarantine and added to your unsubscribe group.
            </p>
            <div className={classnames(styles.p2)} style={{ maxWidth: maxWidth }}>
              Limit emails for recently bounced users to 1 email per

              <SelectM
                store={store}
                style={{width: '124px'}}
                className='mx-1'
                defaultValue={unsubscribeGroup.quarantine.rate}
                name='quarantine[rate]'>

                <option value={3600}>1 hour</option>
                <option value={7200}>2 hours</option>
                <option value={14400}>4 hours</option>
                <option value={28800}>8 hours</option>
                <option value={43200}>12 hours</option>
              </SelectM>

              for up to

              <SelectM
                store={store}
                style={{width: '124px'}}
                className='mx-1'
                defaultValue={unsubscribeGroup.quarantine.period}
                name='quarantine[period]'>

                <option value={14400}>4 hours</option>
                <option value={28800}>8 hours</option>
                <option value={43200}>12 hours</option>
                <option value={86400}>24 hours</option>
                <option value={172800}>48 hours</option>
              </SelectM>

              before adding them to your unsubscribe group.
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
