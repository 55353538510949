import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import LoadableCard from 'components/Dashboard/LoadableCard';

import Resubscribe from './Resubscribe';
import Unsubscribe from './Unsubscribe';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  onSuccess = (data) => {
    window.location.reload();
  }

  render() {
    const { subscription } = this.props;

    return (
      <div className={classnames('m-3', 'mx-auto', 'px-3')} style={{ maxWidth: '750px' }}>
        <h3 className={classnames(styles.h3, 'fw-bold', 'text-center', 'mb-2')}>Manage Your Subscription</h3>

        <LoadableCard className='mb-2' loadState={subscription.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Subscription Status</h5>

          <p className={classnames(styles.p2)}>Hi {subscription.user?.email},</p>
          <Form action='' method='post' store={this.store} onSuccess={this.onSuccess}>
            { subscription.is_unsubscribed ?
              <Resubscribe subscription={subscription} store={this.store} /> :
              <Unsubscribe subscription={subscription} store={this.store} />
            }
          </Form>
        </LoadableCard>
      </div>
    );
  }
}
