import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import InputM from 'components/Util/Inputs/InputM';
import SelectM from 'components/Util/Inputs/SelectM';

import Delete from './Delete';
import Duration from './Duration';
import Label from './Label';
import Logic from './Logic';

import styles from 'styles/Dashboard/Base';

@observer
export default class Threshold extends Component {
  onValueChange = (e, index) => {
    this.props.monitor.alert_conditions[index].value = e.target.value;
  }

  render() {
    const { monitor, store } = { ...this.props };

    return (
      <div className='mb-2'>
        <Label monitor={monitor} />

        <ol className={classnames(styles.p2, 'position-relative')} style={{ left: '-16px' }}>
          { monitor.alert_conditions.map((alertCondition, index) =>
            <li key={index} className='mb-1'>
              { monitor.detection_type == 'bounce_rate' && (
                <span>Trigger an alert when the bounce rate is</span>
              )}

              { monitor.detection_type == 'spam_rate' && (
                <span>Trigger an alert when the spam rate is</span>
              )}

              { monitor.detection_type == 'unsubscribe_rate' && (
                <span>Trigger an alert when the unsubscribe rate is</span>
              )}

              <Logic
                monitor={monitor}
                index={index}
                value={alertCondition.logic}
                className='mx-1'
                name={`alert_conditions[${index}][logic]`}
                store={store} />

              the value

              <InputM
                value={alertCondition.value}
                onChange={(e) => this.onValueChange(e, index)}
                className='mx-1'
                style={{ width: '88px' }}
                name={`alert_conditions[${index}][value]`}
                store={store} />

              ({monitor.decimal_to_percent(alertCondition.value)}%) for at least

              <Duration
                monitor={monitor}
                index={index}
                value={alertCondition.trigger_duration}
                className='mx-1'
                store={store} />

              <div className={classnames(styles.Error, 'd-inline', 'mr-1')}>
                <p className='d-inline'>{store.get(`alert_conditions[${index}][value]`)?.errorMessage}</p>
              </div>

              <Delete monitor={monitor} index={index} />
            </li>
          )}
        </ol>
      </div>
    );
  }
}
