export default function AmplyOverrides(editor) {
    editor.conversion.for('dataDowncast').elementToElement({
        model: 'rawHtml',
        view: (model, { writer: viewWriter }) => {
          const modelVal = model.getAttribute('value') || '';
          const encodedVal = btoa(unescape(encodeURIComponent(modelVal)));;
          return viewWriter.createRawElement('div', { 'data-value': encodedVal, class: 'raw-html-embed' });
        },
        converterPriority: 10,
    });

		editor.conversion.for( 'upcast' ).elementToElement( {
			view: {
				name: 'div',
				classes: 'raw-html-embed'
			},
			model: ( viewElement, { writer } ) => {
				// The div.raw-html-embed is registered as a raw content element,
				// so all it's content is available in a custom property.
        const viewVal = viewElement.getAttribute('data-value') || '';
        const decodedVal = decodeURIComponent(escape(atob(viewVal)));

				return writer.createElement('rawHtml', {
					value: decodedVal
				});
			}
		});

    editor.conversion.for( 'downcast' ).add( dispatcher => {
      // update styles/classes when p is created
      dispatcher.on( 'insert:paragraph', (evt, data, conversionApi) => {
        const viewWriter = conversionApi.writer;
        const viewSelection = viewWriter.document.selection;
        const p = conversionApi.mapper.toViewElement(data.item);

        viewWriter.setStyle('font-size', 'inherit', p);
        viewWriter.setStyle('line-height', 'inherit', p);
        viewWriter.setStyle('margin', '0', p);
      }, { priority: 'low' });

      // update styles/classes when image src changes
      dispatcher.on( 'attribute:src:image', ( evt, data, conversionApi ) => {
        const viewWriter = conversionApi.writer;
        const viewSelection = viewWriter.document.selection;

        const figure = conversionApi.mapper.toViewElement(data.item);
        const image = figure.getChild(0);

        // figure
        viewWriter.setStyle('margin-top', '1em', figure);
        viewWriter.setStyle('margin-bottom', '1em', figure);
        viewWriter.setStyle('margin-left', 'auto', figure);
        viewWriter.setStyle('margin-right', 'auto', figure);
        viewWriter.setStyle('display', 'block', figure);
        viewWriter.removeStyle('margin', figure);
        viewWriter.removeClass('image', figure);

        // image
        viewWriter.setStyle('max-width', '100%', image);
        viewWriter.setStyle('vertical-align', 'middle', image);
        viewWriter.setStyle('border-style', 'none', image);
      }, { priority: 'low' });

      // update styles/classes for resized images
      dispatcher.on( 'attribute:width:image', ( evt, data, conversionApi ) => {
        const viewWriter = conversionApi.writer;
        const viewSelection = viewWriter.document.selection;
        const figure = conversionApi.mapper.toViewElement(data.item);

        viewWriter.setStyle('display', 'block', figure);
        viewWriter.setStyle('max-width', '100%', figure);
        viewWriter.setStyle('box-sizing', 'border-box', figure);

        viewWriter.removeClass('image_resized', figure);
      }, { priority: 'low' });


      // remove imagestyle floats and add margin settings inline
      dispatcher.on( 'attribute:imageStyle:image', ( evt, data, conversionApi ) => {
        const viewWriter = conversionApi.writer;
        const viewSelection = viewWriter.document.selection;
        const figure = conversionApi.mapper.toViewElement(data.item);

        const align = data.attributeNewValue;

        viewWriter.removeClass('image-style-align-left', figure);
        viewWriter.removeClass('image-style-align-center', figure);
        viewWriter.removeClass('image-style-align-right', figure);

        viewWriter.setStyle('margin-left', 'auto', figure);
        viewWriter.setStyle('margin-right', 'auto', figure);

        if (align === 'alignLeft') {
          viewWriter.setStyle('margin-left', '0', figure);
        }
        else if (align ==='alignRight') {
          viewWriter.setStyle('margin-right', '0', figure);
        }
      }, { priority: 'low' });
    });
}
