import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LineBar from './Charts/LineBar';

import styles from 'styles/Visualizations';

const createGradient = (chart, colorStart, colorEnd) => {
  const ctx = chart.ctx;
  const area = chart.chartArea;

  const gradient = ctx.createLinearGradient(0, 0, 0, area.height * 2);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

export function Daily(props) {
  const { widget, options } = props;

  options.plugins.tooltip.itemSort = (a, b) => {
    return a.datasetIndex - b.datasetIndex
  }

  const chartData = {
    ...widget.data,
    datasets: widget.data.datasets.map(dataset => ({
      ...dataset,
    })),
  };

  useEffect(() => {
    const chart = props.chartRef.current;

    if (chart) {
      if (!widget.data.datasets[0].borderColor) {
        const gradient = [
          createGradient(chart, '#7c1ffc', '#2a22f0'),
        ];

        const lineStyles = {
          borderColor: styles.colorPrimaryPurple,
          borderWidth: 1.5,
          pointRadius: 1.5,
          pointHoverRadius: 2.5,
          pointBackgroundColor: styles.colorPrimaryPurple,
          tension: 0,
        }

        widget.data.datasets[0] = {
          ...widget.data.datasets[0],
          ...lineStyles,
        };
      }
    }
  });

  return (
    <div className={classnames(styles.DailyEvents, 'row', 'h-100')}>
      <div className='col-12 d-flex'>
        <LineBar id={widget.id} data={chartData} options={options} chartRef={props.chartRef} />
      </div>
    </div>
  );
}

export default observer(Daily);
