import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimarySquareButton';
import Plus from 'components/Util/Icons/Plus';

import alertStore from 'models/Alert';
import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Create extends Component {
  store = new Store;

  onClick = (e) => {
    alertStore.setContentsFromId('create-ip-address');
    alertStore.setDisplay(true);
  }

  onSucccess = () => {
    alertStore.setDisplay(false);
    window.location.reload();
  }

  render() {
    return (
      <React.Fragment>
        <div className='d-none'>
          <div id='create-ip-address'>
            <Form action='/api/v1/settings/ip_addresses' method='post' store={this.store}>
              <h4 className={classnames(styles.h4, 'text-center')}>New IP Address</h4>

              <p className={classnames(styles.p2, styles.Tint5, 'mb-2', 'text-center')}>
                Provision a new IP address to send email.
              </p>

              <p className={classnames(styles.p2, 'mb-0', 'text-center')}>
                <span
                  onClick={() => alertStore.setDisplay(false)}
                  className={classnames(styles.p2, 'fw-bold', 'text-decoration-underline', 'cursor-pointer', 'me-2')}>

                  Cancel
                </span>

                <button
                  id='new_ip_address_create'
                  style={{ padding: '4px 8px' }}
                  className={classnames(styles.LightBackgroundMediumPrimarySquare)}>

                  Create
                </button>

              </p>

              <div className={classnames(this.store.hasErrors ? 'd-inline-block w-100' : 'd-none')}>
                <InputM name='id' className='w-100 h-auto mx-auto text-center mb-1' type='hidden' store={this.store} />
              </div>
            </Form>
          </div>
        </div>

        <div className='text-end'>
          <Button onClick={this.onClick}><Plus color={styles.colorPrimaryWhite} className='me-1' />Add New</Button>
        </div>
      </React.Fragment>
    );
  }
}
