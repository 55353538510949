import React, { Component } from 'react';
import classnames from 'classnames';

import One from 'components/Marketing/Pricing/Images/Section3/One';
import Two from 'components/Marketing/Pricing/Images/Section3/Two';
import Three from 'components/Marketing/Pricing/Images/Section3/Three';

import styles from 'styles/Cohorts';

export default class Section2 extends Component {
  render() {
    return (
      <div className={classnames(styles.Section2)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2', 'text-center')}>
          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple, 'mb-3')}>It will help technical users:</h2>

          <div className={classnames('row', 'px-0', 'px-lg-5')}>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card2, 'px-3', 'py-5', 'h-100')}>
                <One className='mb-2' />
                <p className={classnames(styles.p1, 'mb-0')}>Understand sending infrastructure health in a new light</p>
              </div>
            </div>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card2, 'px-3', 'py-5', 'h-100')}>
                <Two className='mb-2' />
                <p className={classnames(styles.p1)}>Explore data-driven product opportunities in the customer journey</p>
              </div>
            </div>

            <div className='col-lg-4 mb-2'>
              <div className={classnames(styles.Card2, 'px-3', 'py-5', 'h-100')}>
                <Three className='mb-2' />
                <p className={classnames(styles.p1)}>Feel more secure with real-time updates on sensitive account activity</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
