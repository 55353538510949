import { toJS, observable, computed, action } from 'mobx';

export default class OrganizationAccount {
  @observable id;
  @observable email;
  @observable is_owner;
  @observable has_only_sso_login;

  @observable password_authentication_enabled;
  @observable oauth_authentication_enabled;
  @observable saml_authentication_enabled;
  @observable override_authentication_strategy;

  @observable settings; // global settings
  @observable roles = [];

  @observable current_organization;
  @observable hijackable_organizations;

  @observable created_at;

  assign(params) {
    this.email = params.email || this.email;

    this.is_owner = params.is_owner || this.is_owner;
    this.has_only_sso_login = params.has_only_sso_login || this.has_only_sso_login;

    this.password_authentication_enabled = params.password_authentication_enabled !== undefined ? params.password_authentication_enabled : this.password_authentication_enabled;
    this.oauth_authentication_enabled = params.oauth_authentication_enabled !== undefined ? params.oauth_authentication_enabled : this.oauth_authentication_enabled;
    this.saml_authentication_enabled = params.saml_authentication_enabled !== undefined ? params.saml_authentication_enabled : this.saml_authentication_enabled;
    this.override_authentication_strategy = params.override_authentication_strategy !== undefined ? params.override_authentication_strategy : this.override_authentication_strategy;

    this.current_organization = params.current_organization || this.current_organization;
    this.hijackable_organizations = params.hijackable_organizations || this.hijackable_organizations;

    this.settings = params.settings || this.settings;
    this.roles = params.roles || this.roles;

    this.created_at = params.created_at || this.created_at;
    this.id = params.id || this.id;
  }

  isAuthorized(resource, operation) {
    if (window.amplyDisplayAllNavigationLinks) {
      // internal or account owner
      return true;
    }

    const permissions = this.roles.map(r => r.permissions).flat();

    if (permissions.filter(p => p.resource == resource && p.operation == operation).length > 0) {
      return true;
    }

    return false;
  }

  @computed
  get passwordAuthenticationStatus() {
    if (this.override_authentication_strategy) {
      return this.password_authentication_enabled;
    }
    else {
      return this.settings?.password_authentication_enabled;
    }
  }

  get oauthAuthenticationStatus() {
    if (this.override_authentication_strategy) {
      return this.oauth_authentication_enabled;
    }
    else {
      return this.settings?.oauth_authentication_enabled;
    }
  }

  get samlAuthenticationStatus() {
    if (this.override_authentication_strategy) {
      return this.saml_authentication_enabled;
    }
    else {
      return this.settings?.saml_authentication_enabled;
    }
  }

  get isDefaultAdmin() {
    return this.is_owner || this.email?.match(/\@sendamply\.com$/i);
  }

  get isSandboxMode() {
    return this.current_organization?.mode === 'sandbox';
  }

  get isProductionMode() {
    return this.current_organization?.mode === 'production';
  }

  get loaded() {
    return this.id !== undefined && this.settings !== undefined;
  }
}
