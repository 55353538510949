import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';
import Attribute from 'components/Dashboard/Attribute';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Share from 'components/Util/Icons/Share';
import Link from 'components/Util/Icons/Link';

import Config from './Config';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  afterDelete = () => {
    window.location = '/home/sso_connections';
  }

  render() {
    const { ssoConnection } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/sso_connections' className='me-1'>
            <BackButton />
          </a>

          Update SSO Connection

          <Delete action={`/api/v1/sso_connections/${ssoConnection.id}`} store={this.store} afterDelete={this.afterDelete} className='float-end mb-2'>Delete</Delete>
        </h3>

        <LoadableCard className='mb-2' loadState={ssoConnection.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>SSO Connection</h5>

          <Attribute icon={Share} name='Name' value={ssoConnection.name} className='mb-1' />
          <Attribute icon={Link} name='SSO URL' value={ssoConnection.sso_url} className='mb-1' style={{maxWidth: '300px'}} />
          <Attribute icon={Link} name='Audience URI' value={ssoConnection.audience_uri} className='mb-1' style={{maxWidth: '300px'}} />
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={ssoConnection.loaded}>
          <Config method='put' action={`/api/v1/sso_connections/${ssoConnection.id}`} store={this.store} ssoConnection={ssoConnection} />
        </LoadableCard>
      </div>
    );
  }
}
