import React from 'react';
import { observer } from 'mobx-react';
import { Container } from 'react-bootstrap';
import classnames from 'classnames';
import styles from 'styles/Dashboard/Editor';

import { borderColor, borderWidth } from '../variables';

import Node from './Node';
import Row from './Row';
import InsertPlaceholder from './InsertPlaceholder';
import Controls from './Controls';

@observer
export default class Document extends Node {
  renderChild(child) {
    if (child.type === 'Row') {
      return (
        <div key={child.id}>
          <InsertPlaceholder node={this.props.node} insertBefore={child} />
          <Row node={child} />
        </div>
      )
    } else {
      throw `Don't know how to render child ${child.type} of ${this.type}`
    }
  }

  render() {
    const { node } = this.props;

    let classes = [];

    if (node.isSelected) {
      classes.push(styles.selectedNode);
    }

    const nodeStyles = {
      background: node.background,
      padding: node.padding,
    };

    if (!node.isSelected && node.isHovered) {
      nodeStyles.border = `${borderWidth} dashed ${borderColor}`;
    }
    else {
      nodeStyles.border = `${borderWidth} dashed transparent`;
    }

    return (
      <Container
        className={classnames(classes)}
        style={nodeStyles}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={this.onClick}>

        { node.children.map(child => this.renderChild(child))}
        <InsertPlaceholder node={node} />
      </Container>
    );
  }
}
