import React, { Component } from 'react';

import Content from 'components/Dashboard/Content';
import SearchNew from 'components/Dashboard/Analytics/Search/New';

export default class New extends Component {
  render() {
    return (
      <Content>
        <SearchNew />
      </Content>
    );
  }
}
