import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import styles from 'styles/Dashboard/Base';

@observer
export default class Label extends Component {
  addAlertCondition = () => {
    this.props.monitor.add_default_alert_condition();
  }

  render() {
    const monitor = this.props.monitor;
    const style = {
      borderRadius: '4px',
      height: '20px',
      width: '20px',
      background: styles.tint15,
      border: 'none',
      fontSize: '12px',
      color: styles.tint6,
    };

    return (
      <label className={classnames(styles.Label)}>
        <p>
          Alert Conditions

        { monitor.alert_conditions.length < monitor.max_alert_conditions &&
          <button
            type='button'
            className='mx-1'
            style={style}
            onClick={this.addAlertCondition}>
              +
          </button>
        }
        </p>
      </label>
    );
  }
}

