import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LoadableCard from '../LoadableCard';

import styles from 'styles/Dashboard/Root/Show';

@observer
export default class DevOps extends Component {
  dailyEventsRef = React.createRef();
  domainRef = React.createRef();
  ipAddressRef = React.createRef();
  ipPoolRef = React.createRef();
  domainBounceRateRef = React.createRef();

  render() {
    const { dashboardCollection } = this.props;
    const search = window.location.search;

    return (
      <div className='row'>
        <div className='col-lg-12 mb-2 px-1'>
          <LoadableCard iframeRef={this.dailyEventsRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-1', 'fw-bold')}>Daily Events</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/daily_events${search}`} ref={this.dailyEventsRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-4 mb-2 px-1'>
          <LoadableCard iframeRef={this.domainRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-0', 'fw-bold')}>Domain Status</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/domain_health${search}`} ref={this.domainRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-4 mb-2 px-1'>
          <LoadableCard iframeRef={this.ipAddressRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-0', 'fw-bold')}>IP Address Status</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/ip_address_health${search}`} ref={this.ipAddressRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-4 mb-2 px-1'>
          <LoadableCard iframeRef={this.ipPoolRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-0', 'fw-bold')}>IP Pool Status</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/ip_pool_health${search}`} ref={this.ipPoolRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-12 px-1'>
          <LoadableCard iframeRef={this.domainBounceRateRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-1', 'fw-bold')}>Bounce & Spam Rate</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/bounce_spam_rate${search}`} ref={this.domainBounceRateRef} />
          </LoadableCard>
        </div>
      </div>
    );
  }
}
