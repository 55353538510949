import React, { Component } from 'react';

import Content from 'components/Marketing/Content';
import ShowRoot from 'components/Marketing/Root/Show';

export default class Show extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (window.location.href.match('getstarted')) {
        document.getElementById('js-amply-cta').scrollIntoView();
      }
    }, 1000);
  }

  render() {
    return (
        <Content>
          <ShowRoot />
        </Content>
    );
  }
}
