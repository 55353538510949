import React, { Component } from 'react';

import Content from 'components/Marketing/Content';
import ShowPlatform from 'components/Marketing/Platform/Show';

export default class Show extends Component {
  render() {
    return (
        <Content>
          <ShowPlatform />
        </Content>
    );
  }
}
