import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Webhook extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="${color}"><path d="M270-120q-78 0-134-56T80-310q0-67 39-117t101-66v63q-35 14-57.5 46.5T140-310q0 54 38 92t92 38q54 0 92-38t38-92v-30h249q8-9 19-14.5t22-5.5q21 0 35.5 14.5T740-310q0 20-14.5 35T690-260q-11 0-22-5.5T649-280H458q-14 69-66.5 114.5T270-120Zm420 0q-48 0-88.5-22T535-200h86q15 10 32.5 15t36.5 5q54 0 92-38t38-92q0-54-38-92t-92-38q-19 0-35 4.5T624-422L495-640q-20-4-32.5-17.5T450-690q0-21 15-35.5t35-14.5q21 0 35.5 14.5T550-690q0 4-.5 7.5T547-672l103 176q11-2 21.5-3t18.5-1q79 0 134.5 55.5T880-310q0 78-55.5 134T690-120ZM270-260q-20 0-35-15t-15-35q0-18 12.5-31.5T264-360l111-187q-30-29-47.5-65.5T310-690q0-79 56-134.5T500-880q76 0 131 52.5T690-700h-60q-3-51-40.5-85.5T500-820q-54 0-92 38t-38 92q0 42 24.5 75.5T457-567L316-329q2 6 3 10.5t1 8.5q0 20-14.5 35T270-260Z"/></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
