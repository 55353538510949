import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Card from 'components/Util/Inputs/Card';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Store from 'models/Form';
import alertStore from 'models/Alert';

import styles from 'styles/Dashboard/Base';

@observer
export default class Promos extends Component {
  store = new Store;

  onSuccess = () => {
    window.location.reload();
  }

  afterDelete = () => {
    window.location.reload();
  }

  addPromo = () => {
    alertStore.setContentsFromId('add-promo');
    alertStore.setDisplay(true);
  }

  render() {
    const { promos } = this.props;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>
          Promos
          <Button className='float-end' onClick={this.addPromo} store={this.store}>Add Promo</Button>
        </h5>

        { promos.isEmpty ? (
          <p className={classnames(styles.p2, styles.Tint5)}>No active promos found.</p>
        ) : (
          <table className={classnames(styles.BasicTable, 'w-100')} style={{ maxWidth: '500px' }}>
            <thead>
              <tr>
                <th>Code</th>
                <th>Amount</th>
                <th>Period</th>
              </tr>
            </thead>
            <tbody>
              { promos.objects.map((promo, index) =>
                <tr key={index}>
                  <td>{promo.code}</td>
                  <td>{promo.formattedAmount}</td>
                  <td>{promo.period}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <div className='d-none' id='add-promo'>
          <Form action='/home/account/promos' method='post' store={this.store} onSuccess={this.onSuccess}>
            <h4 className={classnames(styles.h4)}>Add Promo</h4>
            <label className={classnames(styles.Label)}>
              <p>Promo code</p>

              <InputM
                name='code'
                placeholder='Code'
                store={this.store}
                className='mb-2'
                style={{ background: 'transparent' }}
                className={classnames(styles.InputM, styles.InputWidth, 'mb-2')} />
            </label>

            <Button text='Submit' store={this.store}>Submit</Button>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}
