import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Editor';

import Base from './Attributes/Base';
import Col from './Attributes/Col';
import Row from './Attributes/Row';

const ATTRIBUTE_TYPES = {
  Column: Col,
  Document: Base,
  Row: Row
}

@observer
export default class Attributes extends Component {
  render() {
    const { selectedNode } = this.props.store;

    const AttributesClass = selectedNode && ATTRIBUTE_TYPES[selectedNode.type];

    return (
      <div className={classnames(styles.Attributes)} onClick={this.onClick}>
        {selectedNode && <AttributesClass node={selectedNode} />}
      </div>
    );
  }
}
