import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from 'styles/Dashboard/Editor';

@observer
export default class DragHandle extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired,
  }

  state = {
    originalX: 0,
    translateX: 0,
  };

  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = ({ clientX, clientY }) => {
    const { node } = this.props;
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    if (this.props.onDragStart) {
      this.props.onDragStart();
    }

    node.editor.setDraggingNode(node);

    this.setState({
      originalX: clientX,
      originalWidth: node.width,
      nextNodeOriginalWidth: node.parent.children[node.parent.children.indexOf(node) + 1].width
    });
  };

  handleMouseMove = ({ clientX }) => {
    const { node } = this.props;

    const isDragging = node.editor.draggingNode === node;

    if (!isDragging) {
      return;
    }

    const index = node.parent.children.indexOf(node);
    const next = node.parent.children[index + 1];

    // Find the containing row and its bounds
    const thisEl = this.elementRef;
    const nextEl = next.elementRef;
    const handle = thisEl.current;

    const row = handle.closest('.row');
    const gridWidth = row.clientWidth / 12;

    const minTranslateX = (node.width - 1) * gridWidth * -1;
    const maxTranslateX = (next.width - 1) * gridWidth;

    let translateX = clientX - this.state.originalX;

    if (translateX < minTranslateX) {
      translateX = minTranslateX;
    } else if (translateX > maxTranslateX) {
      translateX = maxTranslateX;
    }

    this.setState({ translateX });

    const change = Math.ceil(translateX / gridWidth);

    if (Math.abs(translateX) > gridWidth / 2 + 10) {
      next.setWidth(this.state.nextNodeOriginalWidth - change);
      node.setWidth(this.state.originalWidth + change);
    }
  };

  handleMouseUp = (event) => {
    event.stopPropagation();
    const { node } = this.props;
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    node.editor.clearDraggingNode();

    this.setState({
      originalX: 0,
      translateX: 0,
      originalWidth: node.width,
      nextNodeOriginalWidth: node.parent.children[node.parent.children.indexOf(node) + 1].width,
    });
  };

  render() {
    const { node, children } = this.props;
    const { translateX } = this.state;

    const divStyles = {
      transform: `translate(${translateX}px, 0)`
    }

    const isDragging = node.editor.draggingNode === node;

    if (isDragging) {
      divStyles.opacity = 0.8;
      divStyles.cursor = 'grabbing';
    }

    return (
      <div
        ref={this.elementRef}
        className={styles.DragHandle}
        onMouseDown={this.handleMouseDown}
        onClick={event => event.stopPropagation()}
        style={divStyles}
      >
        {children}
      </div>
    );
  }
}
