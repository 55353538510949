import React from 'react';
import { observer } from 'mobx-react';
import { types, getRoot } from 'mobx-state-tree';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from 'styles/Dashboard/Editor';

import { borderColor, borderWidth } from '../variables';

import InsertPlaceholder from './InsertPlaceholder';
import DragHandle from './DragHandle';
import Node from './Node';
import Col from './Col'
import Controls from './Controls';

@observer
export default class Row extends Node {
  static propTypes = {
    ...Node.propTypes
  }

  renderChild = child => {
    return <Col key={child.id} node={child} />;
  }

  render() {
    const { node } = this.props;

    let classes = ['py-2', 'no-gutters', node.isSelected && styles.selectedNode];

    const nodeStyles = { background: node.background};

    if (!node.isSelected && node.isHovered) {
      nodeStyles.border = `${borderWidth} dashed ${borderColor}`;
    }
    else {
      nodeStyles.border = `${borderWidth} dashed transparent`;
    }

    return (
      <div
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={this.onClick}
        className={classnames(classes)}
        style={nodeStyles}>

        { node.children.map((child, i) => this.renderChild(child))}
      </div>
    );
  }
}
