import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import UnsubscribedUser from './UnsubscribedUser';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class New extends Component {
  store = new Store();

  onSuccess = (data) => {
    const { unsubscribeGroup } = this.props;
    const email = encodeURIComponent(data.email);
    window.location = `/home/settings/unsubscribe_groups/${unsubscribeGroup.id}/users/${email}`;
  }

  render() {
    const { unsubscribeGroup } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href={`/home/settings/unsubscribe_groups/${unsubscribeGroup.id}/users`} className='me-1'>
            <BackButton />
          </a>

          New Unsubscribed User
        </h3>

        <LoadableCard className='mb-2' loadState={unsubscribeGroup.loaded}>
          <UnsubscribedUser unsubscribeGroup={unsubscribeGroup} />
        </LoadableCard>

        <LoadableCard className='mb-2' loadState={unsubscribeGroup.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>
          <Form method='post' action={`/api/v1/settings/unsubscribe_groups/${unsubscribeGroup.id}/users`} onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label)}>
              <p>Name</p>
              <InputM
                className={classnames(styles.InputWidth, 'mb-2')}
                name='email'
                placeholder='Email'
                store={this.store} />
            </label>

            <div>
              <Button>Create</Button>
              <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={unsubscribeGroup.id} store={this.store} />
            </div>
          </Form>
        </LoadableCard>
      </div>
    );
  }
}
