import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import SelectM from 'components/Util/Inputs/SelectM';
import InputM from 'components/Util/Inputs/InputM';

import styles from 'styles/Dashboard/Base';

@observer
export default class Duration extends Component {
  onDurationChange = (values, index) => {
    if (values.length > 0) {
      this.props.monitor.alert_conditions[index].trigger_duration = values[0];
      this.props.monitor.alert_conditions[index].recovery_duration = values[0];
    }
  }

  render() {
    const { index, store } = { ...this.props };

    return (
      <React.Fragment>
        <SelectM
          store={store}
          style={{width: '124px'}}
          { ...this.props }
          onChange={(values) => this.onDurationChange(values, index)}
          name={`alert_conditions[${index}][trigger_duration]`}>

          <option value={300}>5 minutes</option>
          <option value={900}>15 minutes</option>
          <option value={1800}>30 minutes</option>
          <option value={3600}>1 hour</option>
          <option value={7200}>2 hours</option>
          <option value={14400}>4 hours</option>
          <option value={28800}>8 hours</option>
          <option value={43200}>12 hours</option>
          <option value={86400}>24 hours</option>
        </SelectM>

        <InputM
          store={store}
          type='hidden'
          value={this.props.value}
          className='align-middle'
          name={`alert_conditions[${index}][recovery_duration]`} />
      </React.Fragment>
    );
  }
}
