import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class BugReport extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill="${color}"><path d="M480-120q-65 0-121-31t-83-89H160v-60h92q-7-26-7-52.5V-406h-86v-60h86q0-29 .5-57.5T254-580h-94v-60h120q14-28 37-49t51-35l-77-76 40-40 94 94q28-10 56.5-10t56.5 10l94-94 40 40-76 76q28 14 49.5 35.5T683-640h118v60h-95q9 28 8.5 56.5T714-466h87v60h-87q0 27 .5 53.5T708-300h93v60H685q-26 59-82.5 89.5T480-120Zm0-60q72 0 123-50.5T654-353v-167q0-72-51-122.5T480-693q-72 0-123 50.5T306-520v167q0 72 51 122.5T480-180Zm-80-140h160v-60H400v60Zm0-173h160v-60H400v60Zm80 57h.5-.5.5-.5.5-.5.5-.5Z"/></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
