import React, { Component } from 'react';
import classnames from 'classnames';

import Button from 'components/Util/Buttons/DarkBackground/LargePrimaryIconButton';
import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Marketing/Pricing/Section5';

export default class Section5 extends Component {
  render() {
    const orbs = [
      // PURPLE ORBS
      // right
      {
        width: '600px',
        height: '600px',
        top: '-200px',
        right: 'calc(50% - 800px)',
        background: styles.colorPrimaryPurple,
      },
      // ORANGE ORB
      // left
      {
        width: '550px',
        height: '550px',
        bottom: '-400px',
        left: 'calc(50% - 500px)',
        background: styles.colorSecondaryOrange,
      },
      // LIGHT BLUE ORB
      {
        width: '100px',
        height: '100px',
        top: '200px',
        left: '50px',
        background: '#18cefe'
      },
    ];

    return (
      <div className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'px-2', 'px-lg-4', 'pb-5', 'mx-auto')}>
          <Orbs orbs={orbs} className={classnames(styles.Orbs, 'mx-auto', 'py-3')} blurEffect={false}>
            <div className='text-center py-3'>
              <h2 className={classnames(styles.h2, styles.ColorPrimaryWhite)}>See the difference today.</h2>
              <p className={classnames(styles.h5, styles.ColorPrimaryWhite, 'mb-3')}>Spend less time fussing with email setup. <br/>Grab the code and get started now!</p>
              <div className={classnames(styles.p2)}><a href='/?getstarted'><Button> Try Amply for Free</Button></a></div>
            </div>
          </Orbs>
        </div>
      </div>
    );
  }
}
