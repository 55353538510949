import { observable, computed, action } from 'mobx';

export default class FormElement {
  @observable name;
  @observable placeholder;
  @observable active = false;
  @observable value;
  @observable error;
  @observable success;
  ref = undefined;

  constructor(args) {
    this.name = args.name;
    this.placeholder = args.placeholder;
    this.error = args.error;
    this.success = args.success;
    this.ref = args.ref;
    this.value = args.value;
  }

  get hasError() {
    return (this.error?.length || 0) > 0;
  }

  get hasSuccess() {
    return (this.success?.length || 0) > 0;
  }

  @action
  setError(error) {
    this.error = error;
  }

  setSuccess(success) {
    this.success = success;
  }

  setValue(value) {
    this.value = value;
    this.ref.current.value = value;
  }

  setChecked(value) {
    this.ref.current.checked = value;
  }
}
