import React, { Component } from 'react';

import Content from 'components/Marketing/Content';
import Cohort from 'components/Cohorts/Index';

export default class Show extends Component {
  render() {
    return (
        <Content>
          <Cohort />
        </Content>
    );
  }
}
