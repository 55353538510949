import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Events extends Component {
  render() {
    const src = `
<svg width="151" height="151" viewBox="0 0 151 151" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.7661 0.956055H0.551086V28.1711H27.7661V0.956055Z" fill="url(#paint0_linear_1_1832)"/>
<path d="M150.938 0.956055H123.723V28.1711H150.938V0.956055Z" fill="url(#paint1_linear_1_1832)"/>
<path d="M89.3521 0.956055H62.1371V28.1711H89.3521V0.956055Z" fill="url(#paint2_linear_1_1832)"/>
<path d="M27.7661 123.741H0.551086V150.956H27.7661V123.741Z" fill="url(#paint3_linear_1_1832)"/>
<path d="M150.938 123.741H123.723V150.956H150.938V123.741Z" fill="url(#paint4_linear_1_1832)"/>
<path d="M89.3521 123.741H62.1371V150.956H89.3521V123.741Z" fill="url(#paint5_linear_1_1832)"/>
<path d="M58.7161 31.751H31.5011V58.966H58.7161V31.751Z" fill="url(#paint6_linear_1_1832)"/>
<path d="M120.145 31.751H92.9301V58.966H120.145V31.751Z" fill="url(#paint7_linear_1_1832)"/>
<path d="M27.7661 62.3691H0.551086V89.5841H27.7661V62.3691Z" fill="url(#paint8_linear_1_1832)"/>
<path d="M150.938 62.3691H123.723V89.5841H150.938V62.3691Z" fill="url(#paint9_linear_1_1832)"/>
<path d="M89.3521 62.3682H62.1371V89.5832H89.3521V62.3682Z" fill="url(#paint10_linear_1_1832)"/>
<path d="M58.7161 93.1641H31.5011V120.379H58.7161V93.1641Z" fill="url(#paint11_linear_1_1832)"/>
<path d="M120.145 93.1641H92.9301V120.379H120.145V93.1641Z" fill="url(#paint12_linear_1_1832)"/>
<defs>
<linearGradient id="paint0_linear_1_1832" x1="14.1586" y1="0.956055" x2="14.1586" y2="28.1711" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1_1832" x1="137.331" y1="0.956055" x2="137.331" y2="28.1711" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint2_linear_1_1832" x1="75.7446" y1="0.956055" x2="75.7446" y2="28.1711" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint3_linear_1_1832" x1="14.1586" y1="123.741" x2="14.1586" y2="150.956" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint4_linear_1_1832" x1="137.331" y1="123.741" x2="137.331" y2="150.956" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint5_linear_1_1832" x1="75.7446" y1="123.741" x2="75.7446" y2="150.956" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint6_linear_1_1832" x1="45.1086" y1="31.751" x2="45.1086" y2="58.966" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint7_linear_1_1832" x1="106.538" y1="31.751" x2="106.538" y2="58.966" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint8_linear_1_1832" x1="14.1586" y1="62.3691" x2="14.1586" y2="89.5841" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint9_linear_1_1832" x1="137.331" y1="62.3691" x2="137.331" y2="89.5841" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint10_linear_1_1832" x1="75.7446" y1="62.3682" x2="75.7446" y2="89.5832" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint11_linear_1_1832" x1="45.1086" y1="93.1641" x2="45.1086" y2="120.379" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
<linearGradient id="paint12_linear_1_1832" x1="106.538" y1="93.1641" x2="106.538" y2="120.379" gradientUnits="userSpaceOnUse">
<stop stop-color="#6834FF"/>
<stop offset="1" stop-color="#4B34FF"/>
</linearGradient>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
