import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';

import UnsubscribedUser from './UnsubscribedUser';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  afterDelete = () => {
    const { unsubscribeGroup } = this.props;
    window.location = `/home/settings/unsubscribe_groups/${unsubscribeGroup.id}/users`;
  }

  render() {
    const { unsubscribeGroup, organizationUser } = this.props;
    const deleteAction = `/api/v1/settings/unsubscribe_groups/${unsubscribeGroup.id}/users/${encodeURIComponent(organizationUser.email)}`;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href={`/home/settings/unsubscribe_groups/${unsubscribeGroup.id}/quarantined_users`} className='me-1'>
            <BackButton />
          </a>

          Quarantined User
        </h3>

        <LoadableCard className='mb-2' loadState={unsubscribeGroup.loaded && organizationUser.loaded}>
          <UnsubscribedUser unsubscribeGroup={unsubscribeGroup} organizationUser={organizationUser} />
        </LoadableCard>
      </div>
    );
  }
}
