import React, { Component } from 'react';
import classnames from 'classnames';

import CTAForm from 'components/Marketing/CTAForm';

import styles from 'styles/Cohorts';

export default class Section3 extends Component {
  render() {
    return (
      <div className={classnames(styles.Section3)}>
        <div className={classnames(styles.MaxWidth, 'mx-auto', 'px-lg-4', 'px-2')}>
            <div className={classnames(styles.Row, 'row')}>
              <div className='col-lg-6 p-3 pb-lg-3 pb-0 text-center text-lg-end'>
                <h2 className={classnames(styles.h2, styles.ColorPrimaryWhite)}>Join our cohort. <br/> Coffee is on us.</h2>
              </div>

              <div className='col-lg-6 p-3'>
                <div id='js-amply-cta'>
                  <CTAForm
                    action='/cohorts'
                    cta='Join Us' />
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}
