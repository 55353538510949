import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Iphone extends Component {
  render() {
    const src = `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 363 730" enable-background="new 0 0 363 730" xml:space="preserve"><filter height="1.1646278" width="1.2175593" style="color-interpolation-filters: srgb;" id="filter5347__J5lRGhs9" x="-0.10877967" y="-0.082313925"><feGaussianBlur stdDeviation="0.030983682" id="feGaussianBlur5349__J5lRGhs9"></feGaussianBlur></filter><filter height="1.164929" width="1.3845524" style="color-interpolation-filters: srgb;" id="filter5343__J5lRGhs9" x="-0.19227619" y="-0.082464501"><feGaussianBlur stdDeviation="0.030983682" id="feGaussianBlur5345__J5lRGhs9"></feGaussianBlur></filter><filter height="1.0064174" width="1.0132177" style="color-interpolation-filters: srgb;" id="filter2409__J5lRGhs9" x="-0.0066088708" y="-0.0032087157"><feGaussianBlur stdDeviation="0.96852657" id="feGaussianBlur2411__J5lRGhs9"></feGaussianBlur></filter><filter height="6.1331673" width="1.1994606" style="color-interpolation-filters: srgb;" id="filter1214__J5lRGhs9" x="-0.09973029" y="-2.5665836"><feGaussianBlur stdDeviation="0.49125426" id="feGaussianBlur1216__J5lRGhs9"></feGaussianBlur></filter><pattern x="172" y="375.5" width="363" height="730" patternUnits="userSpaceOnUse" id="rect5261_1___J5lRGhs9" viewBox="0 -730 363 730" overflow="visible"><g><polygon fill="none" points="0,0 363,0 363,-730 0,-730 		"></polygon><g><polygon fill="none" points="0,-730 363,-730 363,0 0,0 			"></polygon></g></g></pattern><g id="svg8__J5lRGhs9" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"><path id="path2044__J5lRGhs9" fill="none" stroke="#FFFFFF" stroke-width="0.0626" stroke-linecap="round" d="M163.003,27.447
		c-2.114,0-3.815,1.214-3.815,2.723c0,1.509,1.701,2.723,3.815,2.723h35.835c2.114,0,3.814-1.214,3.814-2.723
		c0-1.509-1.7-2.723-3.814-2.723H163.003z"></path><path id="path1064__J5lRGhs9" fill="none" stroke="#FFFFFF" stroke-width="0.0529" stroke-linecap="round" d="M161.562,27.447
		c-1.509,0-2.723,1.214-2.723,2.723c0,1.509,1.214,2.723,2.723,2.723h37.5c1.508,0,2.723-1.214,2.723-2.723
		c0-1.509-1.215-2.723-2.723-2.723H161.562z"></path><path id="rect2415-6-8__J5lRGhs9" d="M353.273,187.92h8.467c0.451,0,0.813,0.363,0.813,0.814v84.292c0,0.451-0.362,0.814-0.813,0.814h-8.467
		c-0.45,0-0.813-0.363-0.813-0.814v-84.292C352.46,188.283,352.823,187.92,353.273,187.92z"></path><path id="rect2415-6__J5lRGhs9" d="M1.26,169.396h8.466c0.451,0,0.814,0.363,0.814,0.814v52.056c0,0.451-0.363,0.814-0.814,0.814H1.26
		c-0.451,0-0.814-0.363-0.814-0.814V170.21C0.445,169.759,0.809,169.396,1.26,169.396z"></path><path id="rect2415-6-0__J5lRGhs9" d="M1.26,239.433h8.466c0.451,0,0.814,0.363,0.814,0.814v52.057c0,0.451-0.363,0.814-0.814,0.814H1.26
		c-0.451,0-0.814-0.363-0.814-0.814v-52.057C0.445,239.796,0.809,239.433,1.26,239.433z"></path><path id="rect2415__J5lRGhs9" d="M1.26,116.226h8.466c0.45,0,0.814,0.364,0.814,0.814v26.268c0,0.45-0.364,0.814-0.814,0.814H1.26
		c-0.45,0-0.814-0.365-0.814-0.814V117.04C0.445,116.59,0.81,116.226,1.26,116.226z"></path><path id="rect2163__J5lRGhs9" stroke="#12284A" stroke-width="0.2646" stroke-linecap="round" stroke-linejoin="round" d="M59.333,0.837
		h244.334c30.729,0,55.642,24.911,55.642,55.641v617.043c0,30.729-24.911,55.642-55.642,55.642H59.333
		c-30.73,0-55.642-24.912-55.642-55.642V56.479C3.691,25.749,28.603,0.837,59.333,0.837z"></path><g><g><defs><path id="SVGID_1___J5lRGhs9" d="M59.333,0.837h244.334c30.729,0,55.642,24.911,55.642,55.641v617.043
					c0,30.729-24.911,55.642-55.642,55.642H59.333c-30.73,0-55.642-24.912-55.642-55.642V56.479
					C3.691,25.749,28.603,0.837,59.333,0.837z"></path></defs><clipPath id="SVGID_2___J5lRGhs9"><use xlink:href="#SVGID_1___J5lRGhs9" overflow="visible"></use></clipPath><g id="rect2163-1__J5lRGhs9" clip-path="url(#SVGID_2___J5lRGhs9)" filter="url(#filter2409__J5lRGhs9)"><path d="M59.33,2.789c-29.779,0-53.689,23.911-53.689,53.69v617.045c0,29.777,23.911,53.688,53.689,53.688h244.34
					c29.778,0,53.689-23.909,53.689-53.688V56.479c0-29.779-23.911-53.689-53.689-53.689L59.33,2.789L59.33,2.789z"></path></g></g></g><path id="rect3540__J5lRGhs9" stroke="#434343" stroke-width="0.1323" d="M60.269,5.426H302.73c28.97,0,52.453,23.484,52.453,52.453v614.242
		c0,28.969-23.483,52.453-52.453,52.453H60.269c-28.969,0-52.454-23.484-52.454-52.453V57.879C7.815,28.91,31.3,5.426,60.269,5.426z
		"></path><g id="g2170__J5lRGhs9" transform="translate(104.02232,1.0583265)"><circle id="path5263__J5lRGhs9" fill="#161616" cx="113.146" cy="25.541" r="6.602"></circle><circle id="path5265__J5lRGhs9" fill="#0A0D13" cx="113.146" cy="25.541" r="4.072"></circle><circle id="path5267__J5lRGhs9" fill="#091427" cx="113.146" cy="25.541" r="3.103"></circle><g id="path5271__J5lRGhs9" filter="url(#filter5343__J5lRGhs9)"><path opacity="0.5561" fill="#235A91" enable-background="new    " d="M111.01,23.466c-0.368,0.039-0.892,0.6-0.93,1.704
				s0.444,1.704,0.716,1.704C111.068,26.873,112.191,25.266,111.01,23.466L111.01,23.466z"></path></g><g id="path5273__J5lRGhs9" filter="url(#filter5347__J5lRGhs9)"><path opacity="0.5561" fill="#235A91" enable-background="new    " d="M113.652,23.079c-0.58,0.179-0.69,1.116-0.668,1.652
				c0.021,0.536,0.78,1.942,1.695,1.629s1.071-1.473,0.691-2.254C114.991,23.324,114.3,22.766,113.652,23.079z"></path></g></g><g id="g2051__J5lRGhs9" transform="matrix(0.98624005,0,0,0.98624005,1.1090123,-1.4667031)"><pattern id="rect5261_2___J5lRGhs9" xlink:href="#rect5261_1___J5lRGhs9" patternTransform="matrix(1.2288 0 0 1.2288 3376.9307 19840.4629)"></pattern><path id="rect5261__J5lRGhs9" fill="url(#rect5261_2___J5lRGhs9)" d="M167.857,24.695h32.286c1.899,0,3.44,1.54,3.44,3.44l0,0
			c0,1.9-1.541,3.44-3.44,3.44h-32.286c-1.9,0-3.44-1.54-3.44-3.44l0,0C164.417,26.235,165.957,24.695,167.857,24.695z"></path><path id="rect5261-0__J5lRGhs9" fill="none" stroke="#292929" stroke-width="0.0668" stroke-linecap="round" d="M167.857,24.696
			c-1.906,0-3.439,1.534-3.439,3.439c0,1.906,1.533,3.439,3.439,3.439h32.31c1.907,0,3.44-1.533,3.44-3.439
			c0-1.906-1.533-3.439-3.44-3.439H167.857z"></path><defs><filter id="Adobe_OpacityMaskFilter__J5lRGhs9" filterUnits="userSpaceOnUse" x="163.321" y="27.038" width="40.285" height="2.193"><feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"></feColorMatrix></filter></defs><mask maskUnits="userSpaceOnUse" x="163.321" y="27.038" width="40.285" height="2.193" id="mask2042-2__J5lRGhs9"><path id="path2044-8__J5lRGhs9" fill="none" filter="url(#Adobe_OpacityMaskFilter__J5lRGhs9)" stroke="#FFFFFF" stroke-width="0.0626" stroke-linecap="round" d="
				M164.153,29.317c-2.143,0-3.868,1.231-3.868,2.761c0,1.53,1.725,2.761,3.868,2.761h36.334c2.145,0,3.87-1.231,3.87-2.761
				c0-1.53-1.725-2.761-3.87-2.761H164.153z"></path></mask><g id="g1041__J5lRGhs9" transform="matrix(0.90162812,0,0,1.2632814,4.6999299,-1.5462883)" mask="url(#mask2042-2__J5lRGhs9)" filter="url(#filter1214__J5lRGhs9)"><path id="path999_2___J5lRGhs9" fill="#FFFFFF" d="M177.664,23.495c0,0.48-0.389,0.868-0.868,0.868l0,0c-0.479,0-0.868-0.388-0.868-0.868
				c0-0.479,0.389-0.868,0.868-0.868C177.275,22.627,177.664,23.016,177.664,23.495L177.664,23.495z"></path><path id="path999-2_2___J5lRGhs9" fill="#FFFFFF" d="M220.608,23.495c0,0.48-0.389,0.868-0.868,0.868c-0.481,0-0.869-0.388-0.869-0.868l0,0
				c0-0.479,0.388-0.868,0.869-0.868l0,0C220.22,22.627,220.608,23.016,220.608,23.495z"></path></g></g><path id="path998__J5lRGhs9" fill="#FBFBFB" d="M58.871,16.966c-21.037,0-38.091,17.053-38.092,38.09v619.887
		c0,21.038,17.054,38.093,38.092,38.092h245.258c21.038,0.001,38.093-17.055,38.092-38.092V55.056
		c0-21.037-17.055-38.09-38.092-38.09h-29.066c-4.451,0-6.754,0.46-6.754,7.521s-4.375,18.959-20.033,18.959h-133.55
		c-15.658,0-20.033-11.898-20.033-18.959s-2.302-7.521-6.754-7.521H58.871z"></path></g><g id="Layer_2__J5lRGhs9"><g><path fill="#ECF2F9" d="M314.084,34.504c0,2.201-1.602,4.002-3.559,4.002h-24.687c-1.957,0-3.559-1.801-3.559-4.002v-3.001
			c0-2.201,1.602-4.002,3.559-4.002h24.687c1.957,0,3.559,1.801,3.559,4.002V34.504z"></path></g><g><path fill="#ECF2F9" d="M80.027,33.503c0,2.201-1.601,4.002-3.559,4.002H51.782c-1.957,0-3.558-1.801-3.558-4.002v-3.001
			c0-2.201,1.601-4.002,3.558-4.002h24.687c1.958,0,3.559,1.801,3.559,4.002V33.503z"></path></g><g><path fill="#ECF2F9" d="M314.337,60.392c0,2.202-1.76,4.004-3.911,4.004H52.913c-2.152,0-3.913-1.802-3.913-4.004V55.85
			c0-2.202,1.76-4.004,3.913-4.004h257.513c2.151,0,3.911,1.802,3.911,4.004V60.392z"></path><g><path fill="#ECF2F9" d="M294.913,690.361c0,2.202-1.475,4.004-3.279,4.004H75.734c-1.804,0-3.28-1.802-3.28-4.004v-4.542
				c0-2.202,1.476-4.004,3.28-4.004h215.899c1.805,0,3.279,1.802,3.279,4.004V690.361z"></path></g></g></g><line fill="#F4F4F4" stroke="#ECF2F9" stroke-miterlimit="10" x1="20.75" y1="74.5" x2="342" y2="74.5"></line><line fill="#F4F4F4" stroke="#ECF2F9" stroke-miterlimit="10" x1="21" y1="664.499" x2="342.333" y2="664.499"></line></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
