import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Line1 extends Component {
  render() {
    const src = `
<svg width="1400" height="1088" viewBox="0 0 1400 1088" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2172.88 1087.54L1305.66 184.898C1241.27 117.922 1134.79 115.819 1067.81 180.203C1061.03 186.722 1054.81 193.799 1049.21 201.36L881.361 427.868C832.502 493.832 743.172 514.906 669.985 477.731L-269.461 0.46875" stroke="#4942FB"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
