import React, { Component } from 'react';

import Content from 'components/Dashboard/Content';
import WebhooksIndex from 'components/Dashboard/Settings/Webhooks/Index';

export default class Index extends Component {
  render() {
    return (
      <Content>
        <WebhooksIndex />
      </Content>
    );
  }
}
