import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import Modal from 'react-modal';

import Close from 'components/Util/Icons/Close';

import styles from 'styles/Base';

import store from 'models/Alert';

@observer
export default class Alert extends Component {
  constructor(props) {
    super(props);
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentDidMount = () => {
    if (store.loadAlertFromLocalStorage()) {
      const configureAlertContent = this.configureAlertContent;

      if (store.content_id) {
        this.waitForContent(store.content_id).then(el => {
          store.setDisplay(true);
          configureAlertContent();
        });
      }
      else {
        store.setDisplay(true);
        configureAlertContent();
      }
    }
  }

  componentDidUpdate() {
    this.configureAlertContent();
  }

  waitForContent(id) {
    return new Promise(resolve => {
      if (document.getElementById(id)) {
        return resolve(document.getElementById(id));
      }

      const observer = new MutationObserver(mutations => {
        if (document.getElementById(id)) {
          resolve(document.getElementById(id));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  }

  configureAlertContent = () => {
    if (store.display){
      // Show Modal
      document.body.style.overflow = 'hidden';
      const container = document.getElementById('amply-alert-content-id');

      if (store.content_id) {
        const node = document.getElementById(store.content_id)?.firstChild;
        if (container && node) {
          container.appendChild(node);
        }
        container.classList.remove('d-none');
      }
      else {
        container.classList.add('d-none');
      }
    }
    else {
      // Hide Modal
      document.body.style.overflow = 'unset';
      if (store.content_id) {
        const container = document.getElementById(store.content_id);
        const node = document.getElementById('amply-alert-content-id').firstChild;
        if (container && node) {
          container.appendChild(node);
        }
      }
    }
  }

  onKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.onClose();
    }
  }

  onCloseOverlay = (event) => {
    if (event.target.id === 'amply-alert') {
      this.onClose(event);
    }
  }

  onClose = (event) => {
    store.setDisplay(false);
  }

  render() {
    return (
      <div onClick={this.onCloseOverlay} id='amply-alert' className={classnames(styles.Alert, store.display ? 'd-block' : 'd-none')}>
        <div>
          <div className={classnames(styles.Close)} onClick={this.onClose}><Close /></div>
          <div className={classnames(styles.CloseBorder)} />
          <div className={classnames(styles.Content)}>
            <div id='amply-alert-content-id' className='d-none' />
            { store.content_string && <div id='amply-alert-content-string' dangerouslySetInnerHTML={{ __html: store.content_string }} /> }
          </div>
        </div>
      </div>
    );
  }
}
