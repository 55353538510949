import { toJS, observable, computed, action } from 'mobx';

export default class UnsubscribeGroup {
  @observable id;
  @observable name;
  @observable uuid;
  @observable total_users;
  @observable quarantine = observable.object({});
  @observable last_unsubscribed_at;
  @observable created_at;

  assign(params) {
    this.name = params.name || this.name;
    this.uuid = params.uuid || this.uuid;
    this.total_users = params.total_users || this.total_users || 0;
    this.last_unsubscribed_at = params.last_unsubscribed_at || this.last_unsubscribed_at;
    this.last_quarantined_at = params.last_quarantined_at || this.last_quarantined_at;
    this.created_at = params.created_at || this.created_at;

    if (params.quarantine) {
      this.quarantine.enabled = params.quarantine.enabled !== undefined ? params.quarantine.enabled : this.quarantine.enabled;
      this.quarantine.period = params.quarantine.period || this.quarantine.period;
      this.quarantine.rate = params.quarantine.rate || this.quarantine.rate;
      this.quarantine.total_users = params.quarantine.total_users || this.quarantine.total_users || 0;
      this.quarantine.last_quarantined_at = params.quarantine.last_quarantined_at || this.quarantine.last_quarantined_at;
    }

    this.id = params.id || this.id;
  }

  get isGlobalUnsubscribe() {
    return this.name === 'Global';
  }

  get isQuarantineEnabled() {
    return this.quarantine.enabled === true;
  }

  get loaded() {
    return this.id !== undefined;
  }
}
