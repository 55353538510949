import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Product extends Component {
  render() {
    const src = `
<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M74.81 149.62C116.126 149.62 149.62 116.126 149.62 74.81C149.62 33.4936 116.126 0 74.81 0C33.4936 0 0 33.4936 0 74.81C0 116.126 33.4936 149.62 74.81 149.62Z" fill="url(#paint0_linear_124_761)"/>
<g filter="url(#filter0_d_124_761)">
<path d="M74.81 97.31C87.2364 97.31 97.31 87.2364 97.31 74.81C97.31 62.3836 87.2364 52.31 74.81 52.31C62.3836 52.31 52.31 62.3836 52.31 74.81C52.31 87.2364 62.3836 97.31 74.81 97.31Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_124_761" x="32.31" y="32.31" width="85" height="85" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.478431 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_124_761"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_124_761" result="shape"/>
</filter>
<linearGradient id="paint0_linear_124_761" x1="3.61115" y1="97.7816" x2="146.005" y2="51.8446" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
