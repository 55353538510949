import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import BackButton from 'components/Util/Buttons/LightBackground/BackButton';

import LoadableCard from 'components/Dashboard/LoadableCard';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store;

  onSuccess = (data) => {
    window.location = `/home/settings/verified_domains/${data.id}`;
  }

  render() {
    const { verifiedDomain } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/settings/verified_domains' className='me-1'>
            <BackButton />
          </a>

          New Verified Domain
        </h3>

        <LoadableCard className='mb-2' loadState={true}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>

          <Form method='post' action='/api/v1/settings/verified_domains' onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label)}>
              <p>Domain Name</p>
              <p className={classnames(styles.InputDescription, 'mb-1')}>Verifying a domain will also verify all of its subdomains. For example, verifying <span className='fw-bold'>example.com</span> will also verify <span className='fw-bold'>a.example.com</span> and <span className='fw-bold'>a.b.example.com</span>.</p>
              <InputM
                className={classnames(styles.InputWidth, 'mb-2')}
                name='name'
                placeholder={verifiedDomain.namePlaceholder}
                store={this.store} />
            </label>

            <div>
              <Button>Create</Button>
            </div>
          </Form>
        </LoadableCard>
      </div>
    );
  }
}
