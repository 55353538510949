import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Contact extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="m515 976-10-110h-40q-143 0-244-101T120 521q0-143 101.5-244T467 176q71 0 131.5 25.5T704 273q45 46 70.5 108.5T800 518q0 66-19.5 132T724 776q-37 60-90 111t-119 89Zm50-108q82-69 128.5-162.5T740 518q0-124-77.5-203T467 236q-120 0-203.5 83.5T180 521q0 118 83.5 201.5T465 806h100v62Zm-98-115q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm-27-136h50q0-25 8.5-41.5T534 532q27-27 38-49.5t11-48.5q0-45-30.5-74T471 331q-42 0-75 22t-49 60l46 19q11-26 30.5-38.5T468 381q30 0 47 14.5t17 38.5q0 19-11 38.5T482 521q-27 28-34.5 45t-7.5 51Zm20-65Z"/></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
