import React, { Component } from 'react';
import classnames from 'classnames';

import Orbs from 'components/Util/Misc/Orbs';

import styles from 'styles/Base';

export default class New extends Component {
  render() {
    const orbs = [
      // ORANGE ORB
      // right
      {
        width: '200px',
        height: '225px',
        top: '150px',
        right: 'calc(50% - 325px)',
        background: styles.colorSecondaryOrange,
      },
      // PURPLE ORBS
      // top left
      {
        width: '600px',
        height: '600px',
        top: '-150px',
        left: 'calc(50% - 850px)',
        background: styles.colorPrimaryPurple,
      },
    ];

    return (
      <Orbs orbs={orbs} blurEffect={false}>
        <div className={classnames(styles.Container, 'py-5', 'my-3')}>
          <div className={classnames(styles.Card1, 'mx-auto', 'p-3')} style={{ maxWidth: '450px' }}>
            {this.props.children}
          </div>
        </div>
      </Orbs>
    );
  }
}
