import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import tooltip from 'models/Tooltip';

@observer
export default class Tooltip extends Component {
  tooltipID = 'amply-tooltip';

  constructor(props) {
    super(props);
    this.ref = this.props.tooltipRef || React.createRef();
  }

  onMouseEnter = () => {
    const { tooltipRef, place } = this.props;

    tooltip.prevPlace = tooltip.place;
    tooltip.place = place;

    if (tooltipRef === undefined) {
      setTimeout(() => {
        ReactTooltip.rebuild();
        ReactTooltip.show(this.ref.current)
      });
    }
  }

  onMouseLeave = () => {
    const { tooltipRef, place } = this.props;

    if (tooltipRef === undefined) {
      ReactTooltip.hide(this.ref.current)
    }

    tooltip.place = tooltip.prevPlace;
    tooltip.prevPlace = place;
  }

  render() {
    const { className, tooltipRef, content, ...params } = this.props;

    return (
      <div
        { ...params}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        data-tip={content}
        data-for={this.tooltipID}
        ref={this.ref}
        className={classnames(className, 'd-inline-block', 'position-relative')}>


        {this.props.children}
      </div>
    );
  }
}
