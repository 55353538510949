import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import LoadableCard from 'components/Dashboard/LoadableCard';

import Form from 'components/Util/Inputs/Form';
import SelectM from 'components/Util/Inputs/SelectM';
import CheckboxM from 'components/Util/Inputs/CheckboxM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  onSuccess = () => {
    window.location = '/home/users/settings';
  }

  render() {
    const { organizationAccount, roles } = this.props;

    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>
          <a href='/home/users' className='me-1'>
            <BackButton />
          </a>

          Update Global User Settings
        </h3>

        <LoadableCard className='mb-2' loadState={organizationAccount.loaded}>
          <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>

          <Form method='put' action='/api/v1/users/settings' onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label)}>
              <p>Default User Role</p>
              <p className={classnames(styles.InputDescription, 'mb-1')}>Update what resources your team has access to by default.</p>

              <SelectM
                defaultValue={organizationAccount.settings?.default_organization_account_role?.id}
                className={classnames(styles.InputWidth, 'mb-2')}
                name='default_organization_account_role_id'
                placeholder='Default Role'
                store={this.store}>

                { roles.map((role, index) =>
                  <option key={index} value={parseInt(role.id)}>{role.display_name}</option>
                )}

              </SelectM>
            </label>

            <label className={classnames(styles.Label)}>
              <p>Authentication Strategies</p>
              <p className={classnames(styles.InputDescription, 'mb-1')}>Change the way your team is authorized to sign into Amply.</p>

              <CheckboxM
                className='me-1'
                defaultChecked={organizationAccount.settings?.password_authentication_enabled}
                name='password_authentication_enabled'
                store={this.store}>

                Password Authentication
              </CheckboxM>

              <CheckboxM
                className='me-1'
                defaultChecked={organizationAccount.settings?.oauth_authentication_enabled}
                name='oauth_authentication_enabled'
                store={this.store}>

                OAuth Authentication
              </CheckboxM>

              <CheckboxM
                className='me-1'
                defaultChecked={organizationAccount.settings?.saml_authentication_enabled}
                name='saml_authentication_enabled'
                store={this.store}>

                SAML Authentication
              </CheckboxM>
            </label>

            <div className='mt-2'>
              <Button>Update</Button>
            </div>
          </Form>
        </LoadableCard>
      </div>
    );
  }
}
