import React, { Component } from 'react';

import Content from 'components/Dashboard/Content';
import UnsubscribeGroupsUsersIndex from 'components/Dashboard/Settings/UnsubscribeGroups/Users/Index';

import UnsubscribeGroup from 'models/UnsubscribeGroup';
import api from 'models/API';

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.unsubscribeGroup = new UnsubscribeGroup();
    this.unsubscribeGroupId = this.props.match.params.id;

    api.get(`/api/v1/settings/unsubscribe_groups/${this.unsubscribeGroupId}`).then(data => {
      this.unsubscribeGroup.assign(data);
    });
  }

  render() {
    return (
      <Content>
        <UnsubscribeGroupsUsersIndex unsubscribeGroupId={this.unsubscribeGroupId} unsubscribeGroup={this.unsubscribeGroup} />
      </Content>
    );
  }
}
