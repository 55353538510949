import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class DevOps extends Component {
  render() {
    const src = `
<svg width="146" height="148" viewBox="0 0 146 148" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="ICONS">
<g id="Group">
<path id="Vector" d="M72.17 147.02C84.5964 147.02 94.67 136.946 94.67 124.52C94.67 112.094 84.5964 102.02 72.17 102.02C59.7436 102.02 49.67 112.094 49.67 124.52C49.67 136.946 59.7436 147.02 72.17 147.02Z" fill="url(#paint0_linear_124_750)"/>
<path id="Vector_2" d="M119.32 121.44C130.366 121.44 139.32 112.486 139.32 101.44C139.32 90.3943 130.366 81.44 119.32 81.44C108.274 81.44 99.32 90.3943 99.32 101.44C99.32 112.486 108.274 121.44 119.32 121.44Z" fill="url(#paint1_linear_124_750)"/>
<path id="Vector_3" d="M126.93 73.36C137.147 73.36 145.43 65.0773 145.43 54.86C145.43 44.6427 137.147 36.36 126.93 36.36C116.713 36.36 108.43 44.6427 108.43 54.86C108.43 65.0773 116.713 73.36 126.93 73.36Z" fill="url(#paint2_linear_124_750)"/>
<path id="Vector_4" d="M99.42 39.7C109.361 39.7 117.42 31.6411 117.42 21.7C117.42 11.7589 109.361 3.7 99.42 3.7C89.4789 3.7 81.42 11.7589 81.42 21.7C81.42 31.6411 89.4789 39.7 99.42 39.7Z" fill="url(#paint3_linear_124_750)"/>
<path id="Vector_5" d="M55.35 34C64.7388 34 72.35 26.3888 72.35 17C72.35 7.61116 64.7388 0 55.35 0C45.9612 0 38.35 7.61116 38.35 17C38.35 26.3888 45.9612 34 55.35 34Z" fill="url(#paint4_linear_124_750)"/>
<path id="Vector_6" d="M22.41 55.66C30.142 55.66 36.41 49.392 36.41 41.66C36.41 33.928 30.142 27.66 22.41 27.66C14.678 27.66 8.41 33.928 8.41 41.66C8.41 49.392 14.678 55.66 22.41 55.66Z" fill="url(#paint5_linear_124_750)"/>
<path id="Vector_7" d="M12.5 90.21C19.4036 90.21 25 84.6136 25 77.71C25 70.8064 19.4036 65.21 12.5 65.21C5.59644 65.21 0 70.8064 0 77.71C0 84.6136 5.59644 90.21 12.5 90.21Z" fill="url(#paint6_linear_124_750)"/>
<path id="Vector_8" d="M25.11 122.89C31.1851 122.89 36.11 117.965 36.11 111.89C36.11 105.815 31.1851 100.89 25.11 100.89C19.0349 100.89 14.11 105.815 14.11 111.89C14.11 117.965 19.0349 122.89 25.11 122.89Z" fill="url(#paint7_linear_124_750)"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_124_750" x1="50.7517" y1="131.423" x2="93.5783" y2="117.607" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint1_linear_124_750" x1="110.216" y1="119.252" x2="128.45" y2="83.6498" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint2_linear_124_750" x1="132.632" y1="72.4725" x2="121.272" y2="37.2595" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint3_linear_124_750" x1="115.47" y1="29.8988" x2="83.4281" y2="13.488" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint4_linear_124_750" x1="71.5496" y1="11.7663" x2="39.1917" y2="22.2051" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint5_linear_124_750" x1="28.8056" y1="29.1889" x2="16.0416" y2="54.1104" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint6_linear_124_750" x1="8.67319" y1="65.7975" x2="16.3488" y2="89.59" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
<linearGradient id="paint7_linear_124_750" x1="15.3146" y1="106.874" x2="34.8958" y2="116.903" gradientUnits="userSpaceOnUse">
<stop stop-color="#6633FD"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
