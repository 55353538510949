import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Attribute from 'components/Dashboard/Attribute';
import Share from 'components/Util/Icons/Share';
import Person from 'components/Util/Icons/Person';

import styles from 'styles/Dashboard/Base';

@observer
export default class UnsubscribedUser extends Component {
  render() {
    const { unsubscribeGroup, organizationUser } = this.props;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>User</h5>

        <Attribute icon={Share} name='Unsubscribe Group' value={unsubscribeGroup.name} className='mb-1' />

        { organizationUser?.loaded && (
          <Attribute icon={Person} name='User' value={organizationUser.email} className='mb-1' />
        )}
      </React.Fragment>
    );
  }
}
