import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import TextareaM from 'components/Util/Inputs/TextareaM';
import InputM from 'components/Util/Inputs/InputM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import styles from 'styles/Dashboard/Base';

@observer
export default class Config extends Component {
  onSuccess = (data) => {
    window.location = `/home/settings/access_tokens/${data.id}`;
  }

  render() {
    const { accessToken, action, method, store } = this.props;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Configuration</h5>

        <Form method={method} action={action} onSuccess={this.onSuccess} store={store}>
          <label className={classnames(styles.Label)}>
            <p>Description</p>
            <p className={classnames(styles.InputDescription, 'mb-1')}>A brief description of what you'll be using this access token for. We recommend creating a new access token for every application you integrate with.</p>
            <TextareaM
              defaultValue={accessToken.description}
              className={classnames(styles.InputWidth, 'mb-2')}
              name='description'
              placeholder='Description'
              style={{width: '350px'}}
              store={store} />
          </label>

          <div>
            <Button>{ method === 'put' ? 'Update' : 'Create' }</Button>
            <InputM className={classnames(styles.ShowInputMessage, 'float-start')} type='hidden' name='id' value={accessToken.id} store={store} />
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
