import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Table';

@observer
export default class Feed extends Component {
  format = (string) => {
    const value = string || '';

    if (value.length > 0) {
      return value;
    }

    return 'Unknown';
  }

  formatLocation = (city, state, country) => {
    city    = city || '';
    state   = state || '';
    country = country || '';

    let result = '';

    if (city.length > 0) {
      result += city;
    }

    if (state.length > 0) {
      result += `, ${state}`;
    }

    if (country.length > 0) {
      result += `, ${country}`;
    }

    if (result.length > 0) {
      return result;
    }

    return 'Unknown';
  }

  formatOutbound = (ipAddress) => {
    const value = ipAddress || '';

    if (value.length > 0) {
      return value;
    }

    return 'N/A (sent from shared IP or custom relay)';
  }

  render() {
    const { type, row } = this.props;

    return (
      <React.Fragment>
        { [':open', ':click'].includes(row.activity_type_id) && (
          <React.Fragment>
            <div>
              <p>
                <span>Email Client</span>
                <span>{this.format(row['email_client'])}</span>
              </p>
              <p>
                <span>Browser</span>
                <span>{this.format(row['browser'])}</span>
              </p>
              <p>
                <span>Platform</span>
                <span>{this.format(row['platform'])}</span>
              </p>
              { type === 'search' && row.activity_type_id === ':click' && (
                <p className='overflow-visible'>
                  <span>Link</span>
                  <span className='overflow-visible align-top' style={{ width: '1px' }}>{this.format(row['href'])}</span>
                </p>
              )}
            </div>
            <div>
              <p>
                <span>IP Address</span>
                <span>{this.format(row['ip_address'])}</span>
              </p>
              <p>
                <span>Location</span>
                <span>{this.formatLocation(row['city'], row['state'], row['country'])}</span>
              </p>
              <p>
                <span>Message ID</span>
                <span>{this.format(row['message_id'])}</span>
              </p>
            </div>
          </React.Fragment>
        )}

        { [':send'].includes(row.activity_type_id) && (
          <React.Fragment>
            <div>
              <p>
                <span style={{ width: '165px' }}>From</span>
                <span>{this.format(row['from'])}</span>
              </p>
              <p>
                <span style={{ width: '165px' }}>Outbound IP Address</span>
                <span>{this.formatOutbound(row['outbound_ip_address'])}</span>
              </p>
              <p>
                <span style={{ width: '165px' }}>Message ID</span>
                <span>{this.format(row['message_id'])}</span>
              </p>
            </div>
          </React.Fragment>
        )}

        { [':bounce'].includes(row.activity_type_id) && (
          <React.Fragment>
            <div>
              <p>
                <span style={{ width: '165px' }}>From</span>
                <span>{this.format(row['from'])}</span>
              </p>
              <p>
                <span style={{ width: '165px' }}>Outbound IP Address</span>
                <span>{this.formatOutbound(row['outbound_ip_address'])}</span>
              </p>
              <p>
                <span style={{ width: '165px' }}>Message ID</span>
                <span>{this.format(row['message_id'])}</span>
              </p>
              <p>
                <span style={{ width: '165px' }}>Error</span>
                <span style={{ maxWidth: '750px' }}>{this.format(row['error'])}</span>
              </p>
            </div>
          </React.Fragment>
        )}
        { [':spam_report', ':rejection'].includes(row.activity_type_id) && (
          <React.Fragment>
            <div>
              <p>
                <span>From</span>
                <span>{this.format(row['from'])}</span>
              </p>
              <p>
                <span>Message ID</span>
                <span>{this.format(row['message_id'])}</span>
              </p>
              <p>
                <span>Error</span>
                <span style={{ maxWidth: '750px' }}>{this.format(row['error'])}</span>
              </p>
            </div>
          </React.Fragment>
        )}
 
        { [':unsubscribe'].includes(row.activity_type_id) && (
          <React.Fragment>
            <div>
              <p>
                <span>Email Client</span>
                <span>{this.format(row['email_client'])}</span>
              </p>
              <p>
                <span>Browser</span>
                <span>{this.format(row['browser'])}</span>
              </p>
              <p className='mb-0'>
                <span>Platform</span>
                <span>{this.format(row['platform'])}</span>
              </p>
            </div>
            <div>
              <p>
                <span>IP Address</span>
                <span>{this.format(row['ip_address'])}</span>
              </p>
              <p>
                <span>Location</span>
                <span>{this.formatLocation(row['city'], row['state'], row['country'])}</span>
              </p>
              <p className='mb-0'>
                <span>Message ID</span>
                <span>{this.format(row['message_id'])}</span>
              </p>
            </div>
            <div className='d-block'>
              <p>
                <span>Error</span>
                <span style={{ maxWidth: '750px' }}>{this.format(row['error'])}</span>
              </p>
            </div>
          </React.Fragment>
        )}

        { [':inbound'].includes(row.activity_type_id) && (
          <React.Fragment>
            <div>
              <p>
                <span style={{ width: '150px' }}>Receiving Address</span>
                <span>{this.format(row['from'])}</span>
              </p>
              <p>
                <span style={{ width: '150px' }}>Message ID</span>
                <span>{this.format(row['message_id'])}</span>
              </p>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
