import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { DateRange as ReactDateRange } from 'react-date-range';
import queryString from 'query-string';
import moment from 'moment-timezone';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Calendar from 'components/Util/Icons/Calendar';
import Button from 'components/Util/Buttons/LightBackground/MediumSecondaryButton';

import calendar from 'models/Calendar';

import styles from 'styles/Dashboard/Root/DateRange';

@observer
export default class DateRange extends Component {
  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
  }

  onClick = (e) => {
    e.stopPropagation();
    calendar.display = !calendar.display;
  }

  onWindowClick = (e) => {
    calendar.display = false;
  }

  onChange = (dateRange) => {
    calendar.startDate = moment(dateRange.selection.startDate);
    calendar.endDate   = moment(dateRange.selection.endDate);
  }

  onApplyClick = () => {
    let query   = queryString.parse(window.location.search);
    query.start = calendar.startDate.startOf('day').toDate().toISOString();
    query.end   = calendar.endDate.endOf('day').toDate().toISOString();

    window.location = `${window.location.pathname}?${queryString.stringify(query)}`
  }

  render() {
    // react-date-range only supports local times, so we need to factor in the utc offset to the date range
    const utcOffset = moment().utcOffset();
    let startDateWithOffset, endDateWithOffset;

    if (utcOffset < 0) {
      startDateWithOffset = moment(calendar.startJS).utc().add(Math.abs(utcOffset) * 1000 * 60).toDate();
      endDateWithOffset = moment(calendar.endJS).utc().add(Math.abs(utcOffset) * 1000 * 60).toDate();
    }
    else {
      startDateWithOffset = moment(calendar.startJS).utc().subtract(Math.abs(utcOffset) * 1000 * 60).toDate();
      endDateWithOffset = moment(calendar.endJS).utc().subtract(Math.abs(utcOffset) * 1000 * 60).toDate();
    }

    const selectionRange = {
      startDate: calendar.startDate.toDate(),
      endDate: calendar.endDate.toDate(),
      color: styles.colorPrimaryPurple,
      key: 'selection',
    }

    return (
      <div className={classnames(styles.DateRange, 'd-flex', 'align-items-center', 'position-relative', 'h-100')}>
        <button onClick={this.onClick}>
          <Calendar />
          <span>{calendar.rangeFormatted}</span>
        </button>

        <div
          onClick={(e) => e.stopPropagation()}
          className={classnames(styles.Dropdown, 'text-center', calendar.display ? 'd-block' : 'd-none')}
          ref={this.calendarRef}>

          <ReactDateRange
            onChange={this.onChange}
            ranges={[selectionRange]}
            showDateDisplay={false}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            maxDate={moment().toDate()}
            onChange={this.onChange} />

          <Button
            className={classnames(styles.Apply, 'mb-2', 'w-50')}
            onClick={this.onApplyClick}>

            Apply
          </Button>
        </div>
      </div>
    );
  }
}
