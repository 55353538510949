import React, { Component } from 'react';

import Content from 'components/Marketing/Content';
import ShowPricing from 'components/Marketing/Pricing/Show';

import Pricing from 'models/Pricing';
import Plan from 'models/Plan';

export default class Show extends Component {
  constructor(props) {
    super(props);
    const plan = new Plan();
    plan.fetch_new_plan();
    this.pricing = new Pricing({ plan: plan });
  }

  render() {
    return (
        <Content setFooterBorder={true}>
          <ShowPricing pricing={this.pricing} />
        </Content>
    );
  }
}
