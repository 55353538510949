import { toJS, observable, computed, action } from 'mobx';
import queryString from 'query-string';
import moment from 'moment-timezone';

export class Calendar {
  @observable startDate = undefined;
  @observable endDate = undefined;
  @observable display = false;

  constructor() {
    const getParams = queryString.parse(location.search.slice(1));

    try {
      this.startDate = new Date(getParams.start);
      this.endDate = new Date(getParams.end);

      if (this.startDate.toString() == 'Invalid Date' || this.endDate.toString() == 'Invalid Date' || this.startDate > this.endDate) {
        throw 'date error';
      }
      else {
        this.startDate = moment(this.startDate);
        this.endDate = moment(this.endDate);
      }
    }
    catch(err) {
      this.endDate   = moment().endOf('day');
      this.startDate = this.endDate.clone().subtract(30,'day').startOf('day');
    }

    this.appliedStartDate = toJS(this.startDate);
    this.appliedEndDate = toJS(this.endDate);
  }

  get startJS() {
    return toJS(this.startDate);
  }

  get endJS() {
    return toJS(this.endDate);
  }

  get rangeFormatted() {
    if (this.appliedEndDate.isSame(new Date(), 'day')) {
      return `Last ${this.appliedEndDate.diff(this.appliedStartDate, 'days')} Days`;
    }

    const formattedStart = this.appliedStartDate.format('MM/DD/YYYY')
    const formattedEnd = this.appliedEndDate.format('MM/DD/YYYY')

    return `${formattedStart} - ${formattedEnd}`;
  }
}

const calendar = new Calendar();
export default calendar;
