import { observable, computed, action } from 'mobx';

export default class Widget {
  @observable id;
  @observable data;
  @observable ref;

  constructor(params) {
    this.id = params.id;
    this.data = params.data;
    this.ref = params.ref;
  }
}
