import React, { Component } from 'react';
import classnames from 'classnames';

import Tooltip from 'components/Util/Misc/Tooltip';

import Button from 'components/Util/Buttons/LightBackground/LargePrimarySquareButton.js';

import Checkmark from './Images/Section4/Checkmark';
import CheckmarkWhiteBg from './Images/Section4/CheckmarkWhiteBg';
import CheckmarkNeutral from './Images/Section4/CheckmarkNeutral';
import Logo from 'components/Util/Misc/Logo'

import styles from 'styles/Marketing/Pricing/Section4';

export default class Section4 extends Component {
  render() {
    return (
      <div className={classnames(styles.Container)}>
        <div className={classnames(styles.MaxWidth, 'px-lg-4', 'px-2', 'mx-auto', 'text-center')}>
          <h2 className={classnames(styles.h2, styles.ColorPrimaryPurple, 'mb-3')}>Move beyond delivery to <br/>real-time customer data</h2>

          <div className='row mb-3'>
            <div className={classnames(styles.Col1, 'col-2', 'text-start', 'pe-0', 'mt-4')}>
              <div className={classnames('w-100', 'h-100', 'pe-0')}>
                <h5 className={classnames(styles.h5Span)}>Core</h5>
                <Tooltip content='Use our highly effective RESTful API or SMTP Relay to programmatically send your emails.'>
                  <p>Email API & SMTP</p>
                </Tooltip>

                <Tooltip content='Rely on our highly available, redundant infrastructure with best-in-class 99.99% uptime.'>
                  <p>99.99% Uptime</p>
                </Tooltip>

                <Tooltip content='Get answers fast from our knowledgeable support team.'>
                  <p>Technical Support</p>
                </Tooltip>

                <div className='mb-3'>
                  <Tooltip content='Integrate Amply with your Identity Provider to centrally manage user authentication.'>
                    <p className='mb-0'>SAML SSO</p>
                  </Tooltip>
                </div>

                <h5 className={classnames(styles.h5Span)}>DevOps</h5>
                <Tooltip content='Manage your infrastructure health with visualizations of delivery and engagement events, reputation status, and bounce & spam rates.'>
                  <p>DevOps Dashboard</p>
                </Tooltip>

                <Tooltip content='Load balance your email from the optimal IP address to maximize delivery. Take the guesswork out of email, gain flexibility and redundancy with your email infrastructure, and make switching to Amply easy with intelligent routing.'>
                  <p>Intelligent Routing</p>
                </Tooltip>

                <Tooltip content='Create custom alerts for critical events. Monitor domain and IP reputation, unsubscribe events, and blocklist events to stay ahead of issues before they happen.'>
                  <p>Monitors</p>
                </Tooltip>

                <div className='mb-3'>
                  <Tooltip content='Search event logs for any user you interact with and easily see the status of specific delivery events.'>
                    <p className='mb-0'>Event Logs</p>
                  </Tooltip>
                </div>

                <h5 className={classnames(styles.h5Span)}>Product</h5>

                <Tooltip content='Gain actionable insights into key product metrics. Visualize retention, acquisition, and stickiness over time from your user event data.'>
                  <p>Product Dashboard</p>
                </Tooltip>

                <Tooltip content='Query all of your data via SQL. Get answers to any questions you have about your users and their product usage.'>
                  <p>SQL Search</p>
                </Tooltip>

                <div className='mb-3'>
                  <Tooltip content='Get a granular view of each user you interact with. Dive into individual profiles to assess engagement and product usage.'>
                    <p className='mb-0'>User Profiles</p>
                  </Tooltip>
                </div>

                <h5 className={classnames(styles.h5Span)}>Security</h5>

                <Tooltip content='Monitor your security status and pinpoint anomalies with visualizations of your application’s most sensitive account and financial events.'>
                  <p>Security Dashboard</p>
                </Tooltip>

                <Tooltip content='Identify compromised users within your application and prevent lateral account takeover from compromised email accounts.'>
                  <p>Email-based IDS</p>
                </Tooltip>

                <Tooltip content='Investigate security issues with a centralized audit log of events to understand where attacks originate from.'>
                  <p>User Audit Logs</p>
                </Tooltip>
              </div>
            </div>

            <div className={classnames(styles.Col2, 'col-2', 'px-0')}>
              <div className={classnames('w-100', 'h-100')}>
                <Logo color={styles.colorPrimaryWhite} className={classnames(styles.Logo)} />
                <p><Checkmark /></p>
                <p><Checkmark /></p>
                <p><Checkmark /></p>
                <p className={classnames(styles.Spacer)}><Checkmark /></p>

                <p><Checkmark /></p>
                <p><Checkmark /></p>
                <p><Checkmark /></p>
                <p className={classnames(styles.Spacer)}><Checkmark /></p>

                <p><Checkmark /></p>
                <p><Checkmark /></p>
                <p className={classnames(styles.Spacer)}><Checkmark /></p>

                <p><Checkmark /></p>
                <p><Checkmark /></p>
                <p><Checkmark /></p>
              </div>
            </div>

            <div className={classnames(styles.Col3, 'col-2', 'pe-0')}>
              <div className={classnames(styles.Card1, 'w-100', 'h-100', 'pe-0')}>
                <h6 className={classnames(styles.p1)} style={{ paddingTop: '24px', marginBottom: '68px' }}>Sendgrid</h6>
                <p><CheckmarkWhiteBg /></p>
                <p><CheckmarkNeutral /></p>
                <p><CheckmarkNeutral /></p>
                <p className={classnames(styles.Spacer)}><CheckmarkNeutral /></p>

                <p><CheckmarkNeutral /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><CheckmarkNeutral /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><span className={classnames(styles.Dash)} /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
              </div>
            </div>

            <div className={classnames(styles.Col4, 'col-2', 'pe-0')}>
              <div className={classnames(styles.Card1, 'w-100', 'h-100')}>
                <h6 className={classnames(styles.p1)} style={{ paddingTop: '24px', marginBottom: '68px' }}>Mailgun</h6>
                <p><CheckmarkWhiteBg /></p>
                <p><CheckmarkWhiteBg /></p>
                <p><CheckmarkNeutral /></p>
                <p className={classnames(styles.Spacer)}><CheckmarkNeutral /></p>

                <p><CheckmarkNeutral /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><CheckmarkNeutral /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><span className={classnames(styles.Dash)} /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
              </div>
            </div>

            <div className={classnames(styles.Col5, 'col-2', 'pe-0')}>
              <div className={classnames(styles.Card1, 'w-100', 'h-100')}>
                <h6 className={classnames(styles.p1)} style={{ paddingTop: '24px', marginBottom: '68px' }}>Postmark</h6>
                <p><CheckmarkWhiteBg /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><CheckmarkNeutral /></p>
                <p className={classnames(styles.Spacer)}><span className={classnames(styles.Dash)} /></p>

                <p><CheckmarkNeutral /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><CheckmarkNeutral /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><span className={classnames(styles.Dash)} /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
              </div>

            </div>

            <div className={classnames(styles.Col6, 'col-2', 'pe-0')}>
              <div className={classnames(styles.Card1, 'w-100', 'h-100')}>
                <h6 className={classnames(styles.p1)} style={{ paddingTop: '24px', marginBottom: '68px' }}>Sparkpost</h6>
                <p><CheckmarkWhiteBg /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><CheckmarkNeutral /></p>

                <p><CheckmarkNeutral /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><CheckmarkNeutral /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p className={classnames(styles.Spacer)}><span className={classnames(styles.Dash)} /></p>

                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
                <p><span className={classnames(styles.Dash)} /></p>
              </div>

            </div>
          </div>

          <a className={classnames(styles.h5)} href='/Amply-Full-Feature-Breakdown.pdf' target='_blank'>
            <Button>Download Full Feature Breakdown</Button>
          </a>

        </div>
      </div>
    );
  }
}
