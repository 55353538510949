import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import SelectM from 'components/Util/Inputs/SelectM';

import styles from 'styles/Dashboard/Base';

@observer
export default class Anomaly extends Component {
  onChange = (values) => {
    this.props.monitor.update_detection_type(values[0]);
  }

  render() {
    const { monitor, store } = { ...this.props };

    return (
      <label className={classnames(styles.Label, 'mb-2')}>
        <p>Detection Type</p>
        <p className={classnames(styles.InputDescription, 'mb-1')}>
          { monitor.detection_type == 'bounce_rate' && (
            <React.Fragment>The bounce rate is the ratio of bounces to sends over a period of time. Amply recommends that you maintain a bounce rate within 0.1 (10%) of your average rate.</React.Fragment>
          )}
          { monitor.detection_type == 'spam_rate' && (
            <React.Fragment>The spam rate is the ratio of spam reports to sends over a period of time. Amply recommends that you maintain a spam rate within 0.1 (10%) of your average rate.</React.Fragment>
          )}
          { monitor.detection_type == 'send_rate' && (
            <React.Fragment>The send rate is the number of sends over a period of time.</React.Fragment>
          )}
          { monitor.detection_type == 'unsubscribe_rate' && (
            <React.Fragment>The unsubscribe rate is the number of unsubscribes over a period of time.</React.Fragment>
          )}
        </p>

        { monitor.type == 'DomainReputationMonitor' && (
          <SelectM
            value={monitor.detection_type}
            className={classnames(styles.InputWidth)}
            name='detection_type'
            placeholder='Detection Type'
            onChange={this.onChange}
            store={store}>

            <option value='bounce_rate'>Bounce Rate</option>
            <option value='spam_rate'>Spam Rate</option>
            <option value='send_rate'>Send Rate</option>
          </SelectM>
        )}

        { monitor.type == 'IpReputationMonitor' && (
          <SelectM
            value={monitor.detection_type}
            className={classnames(styles.InputWidth)}
            name='detection_type'
            placeholder='Detection Type'
            onChange={this.onChange}
            store={store}>

            <option value='bounce_rate'>Bounce Rate</option>
            <option value='spam_rate'>Spam Rate</option>
            <option value='send_rate'>Send Rate</option>
          </SelectM>
        )}

        { monitor.type == 'UnsubscribeMonitor' && (
          <SelectM
            value={monitor.detection_type}
            className={classnames(styles.InputWidth)}
            name='detection_type'
            placeholder='Detection Type'
            onChange={this.onChange}
            store={store}>

            <option value='unsubscribe_rate'>Unsubscribe Rate</option>
          </SelectM>
        )}
      </label>
    );
  }
}
