import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import LoadableCard from '../LoadableCard';

import styles from 'styles/Dashboard/Root/Show';

@observer
export default class Security extends Component {
  sensitiveGeoEventsRef = React.createRef();
  totalAccountEventsRef = React.createRef();
  dailyAccountEventsRef = React.createRef();
  totalFinancialEventsRef = React.createRef();
  dailyFinancialEventsRef = React.createRef();

  render() {
    const { dashboardCollection } = this.props;
    const search = window.location.search;

    return (
      <div className='row'>
        <div className='col-lg-12 mb-2 px-1'>
          <LoadableCard iframeRef={this.sensitiveGeoEventsRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-1', 'fw-bold')}>Sensitive Geographical Events</h5>
            <iframe style={{width: '100%', height: '281px' }} src={`/v/widgets/sensitive_geo_events${search}`} ref={this.sensitiveGeoEventsRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-3 mb-2 px-1'>
          <LoadableCard iframeRef={this.totalAccountEventsRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'text-center', 'mb-1', 'fw-bold')}>Total Account Events</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/total_account_events${search}`} ref={this.totalAccountEventsRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-9 mb-2 px-1'>
          <LoadableCard iframeRef={this.dailyAccountEventsRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-1', 'fw-bold')}>Daily Account Events</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/daily_account_events${search}`} ref={this.dailyAccountEventsRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-3 mb-lg-0 mb-2 px-1'>
          <LoadableCard iframeRef={this.totalFinancialEventsRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'text-center', 'mb-1', 'fw-bold')}>Total Financial Events</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/total_financial_events${search}`} ref={this.totalFinancialEventsRef} />
          </LoadableCard>
        </div>

        <div className='col-lg-9 px-1'>
          <LoadableCard iframeRef={this.dailyFinancialEventsRef} className='pb-1'>
            <h5 className={classnames(styles.p2, 'mb-1', 'fw-bold')}>Daily Financial Events</h5>
            <iframe style={{width: '100%', height: '160px' }} src={`/v/widgets/daily_financial_events${search}`} ref={this.dailyFinancialEventsRef} />
          </LoadableCard>
        </div>
      </div>
    );
  }
}
