import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Monitor extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill="${color}" d="M363 866q-16 0-29-9t-18-23l-97-228H48v-60h213l102 241 187-467q5-14 18-23t29-9q16 0 29 9t18 23l97 226h171v60H699L597 367 410 834q-5 14-18 23t-29 9Z"/></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
