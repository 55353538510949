import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import SelectM from 'components/Util/Inputs/SelectM';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Navigation extends Component {
  store = new Store();

  onSuccess = () => {
    window.location.reload();
  }

  isActive = (check) => {
    const path = window.location.pathname;

    if (path.match(check)) {
      return styles.ColorPrimaryPurple;
    }

    return [styles.Tint4, styles.HoverTint6];
  }

  onChange = (values) => {
    const store = this.store;
    const form = store.getRef('switch_accounts')?.current;

    // let UI re-render
    setTimeout(() => {
      form.requestSubmit();
    }, 100);
  }

  render() {
    const { organizationAccount } = this.props;

    const hijackableOrganizations = organizationAccount?.hijackable_organizations?.length || 0;
    const minHijackableOrganizations = 1;

    return (
      <div className='row mb-1'>
        <div className='col-lg-4'>
          <a className={classnames(styles.p2, this.isActive(/account\/*$/), 'fw-bold', 'me-1')} href='/home/account'>Account</a>
          <a className={classnames(styles.p2, this.isActive(/billing\/*$/), 'fw-bold', 'me-1')} href='/home/account/billing'>Billing</a>
          <a className={classnames(styles.p2, this.isActive(/plan\/*$/), 'fw-bold', 'me-1')} href='/home/account/plan'>Plan</a>
        </div>
        <div className='col-lg-8 text-end position-relative'>
          { hijackableOrganizations > minHijackableOrganizations && (
            <Form action='/home/account/switch' method='put' name='switch_accounts' store={this.store} onSuccess={this.onSuccess}>
              <SelectM
                name='organization_id'
                className={classnames(styles.InputWidth, 'text-start', 'position-absolute')}
                defaultValue={organizationAccount?.current_organization?.id}
                onChange={this.onChange}
                placeholder='Switch Accounts'
                style={{ right: '8px', bottom: '0px' }}
                store={this.store}>

                { organizationAccount.hijackable_organizations.map((organization, index) =>
                  <option value={organization.id} key={index}>{organization.name || organization.owner_email}</option>
                )}
              </SelectM>
            </Form>

          )}
        </div>
      </div>
    );
  }
}
