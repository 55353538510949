import { toJS, observable, computed, action } from 'mobx';

export default class OrganizationUser {
  @observable sends;
  @observable opens;
  @observable clicks;
  @observable bounces;
  @observable spam_reports;
  @observable rejections;
  @observable unsubscribes;
  @observable inbounds;

  @observable created_at;
  @observable email;

  assign(params) {
    this.sends = params.sends !== undefined ? params.sends : this.sends;
    this.opens = params.opens !== undefined ? params.opens : this.opens;
    this.clicks = params.clicks !== undefined ? params.clicks : this.clicks;
    this.bounces = params.bounces !== undefined ? params.bounces : this.bounces;
    this.spam_reports = params.spam_reports !== undefined ? params.spam_reports : this.spam_reports;
    this.rejections = params.rejections !== undefined ? params.rejections : this.rejections;
    this.unsusbcribes = params.unsusbcribes !== undefined ? params.unsusbcribes : this.unsusbcribes;
    this.inbounds = params.inbounds !== undefined ? params.inbounds : this.inbounds;

    this.created_at = params.created_at !== undefined ? params.created_at : this.created_at;
    this.email = params.email || this.email;
  }

  format_rate(rate) {
    const value = rate.toFixed(1);

    if (value === '0.0') {
      return 0;
    }

    return value;
  }

  @computed
  get open_rate() {
    if (this.sends === 0) {
      return 0;
    }

    const rate = this.opens / this.sends;
    return this.format_rate(rate);
  }

  get click_rate() {
    if (this.sends === 0) {
      return 0;
    }

    const rate = this.clicks / this.sends;
    return this.format_rate(rate);
  }

  get loaded() {
    return this.email !== undefined && this.created_at !== undefined;
  }
}

