import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
import { logWarning } from '@ckeditor/ckeditor5-utils/src/ckeditorerror';

export default class ImageUploadAdapter extends Plugin {
	static get requires() {
		return [FileRepository];
	}

	static get pluginName() {
		return 'ImageUploadAdapter';
	}

	init() {
		const options = this.editor.config.get('imageUpload');

		if ( !options ) {
			return;
		}

		if (!options.uploadUrl) {
			logWarning('amply-upload-adapter-missing-uploadurl');
			return;
		}
		this.editor.plugins.get(FileRepository).createUploadAdapter = loader => {
			return new Adapter(loader, options);
		};
	}
}

class Adapter {
	constructor(loader, options) {
		this.loader = loader;
		this.options = options;
	}

	upload() {
		return this.loader.file
			.then(file => new Promise((resolve, reject) => {
				this._initRequest();
				this._initListeners(resolve, reject, file);
				this._sendRequest(file);
			} ) );
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		const xhr = this.xhr = new XMLHttpRequest();

		xhr.open('POST', this.options.uploadUrl, true);
		xhr.responseType = 'json';
	}

	_initListeners(resolve, reject, file) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = `Couldn't upload file: ${ file.name }.`;

		xhr.addEventListener('error', () => reject(genericErrorText));
		xhr.addEventListener('abort', () => reject());
		xhr.addEventListener('load', () => {
			const response = xhr.response;

			if (!response || response.errors) {
				return reject(response && response.errors && response.errors[0].message ? response.errors[0].message.replace('Base64 encoded ', '') : genericErrorText);
			}

			resolve(response.url ? { default: response.url } : response.urls);
		} );

		// Upload progress when it is supported.
		if ( xhr.upload ) {
			xhr.upload.addEventListener( 'progress', evt => {
				if ( evt.lengthComputable ) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			} );
		}
	}

	async _sendRequest(file) {
		const headers = {
      'Content-Type': 'application/json',
    };

    const xhr = this.xhr;

    for (const [name, value] of Object.entries(headers)) {
      this.xhr.setRequestHeader(name, value);
    }

    const imageData = await getFileData(file);
    this.xhr.send(JSON.stringify({
      body: btoa(imageData),
      jwe: this.options.authorization,
    }));
	}
}

async function getFileData(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file)

        reader.onload = () => {
            resolve(reader.result);
        };
    });
}
