import React, { Component } from 'react';
import classnames from 'classnames';

import styles from 'styles/Dashboard/Editor';

export default class Loading extends Component {

  render() {
    return (
      <div className='py-1'>
        <div className={classnames(styles.ColorPrimaryPurple, 'd-block', 'mx-auto', 'spinner-border')} role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }
}
