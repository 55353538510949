import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import AmplyFontColorEditing from './amplyfontcolorediting';
import AmplyFontColorUI from './amplyfontcolorui';

export default class AmplyFontColor extends Plugin {
	static get requires() {
		return [AmplyFontColorEditing, AmplyFontColorUI];
	}

	static get pluginName() {
		return 'AmplyFontColor';
	}
}
