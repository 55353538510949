import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import InputM from 'components/Util/Inputs/InputM';
import Card from 'components/Util/Inputs/Card';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import Delete from 'components/Util/Buttons/LightBackground/MediumDeleteSquareButton';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Base';

@observer
export default class Payment extends Component {
  store = new Store;

  onSuccess = () => {
    window.location.reload();
  }

  afterDelete = () => {
    window.location.reload();
  }

  onSubmit = (event, defaultSubmit) => {
    event.preventDefault();

    const card = this.props.card;
    const target = event.target;
    const store = this.store;

    card.cardElement.update({ disabled: true } );
    card.is_submitting = true;
    store.disableForm();

    card.stripe.createToken(card.cardElement).then(function(stripeResponse) {
      store.clearErrors();

      if (typeof stripeResponse.error !== 'undefined') {
        const formattedError = stripeResponse.error.message.replace('.', '');
        store.addError('token', formattedError);
      }

      else {
        store.getRef('token').current.value = stripeResponse.token.id;
        return defaultSubmit(target);
      }

      store.enableForm();
      card.cardElement.update({ disabled: false } );
      card.is_submitting = false;
    });
  }


  render() {
    const { card } = this.props;
    const authenticityToken = document.querySelector('[name=csrf-token]').content;
    const delAction = `/home/account/card?authenticity_token=${encodeURIComponent(authenticityToken)}&ignore=`;

    return (
      <React.Fragment>
        <h5 className={classnames(styles.p2, 'fw-bold')}>Payment</h5>

        { card.last4 === null && (
          <Form method='post' action='/home/account/card' onSubmit={this.onSubmit} onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label, 'mb-2')}>
              <p>Card</p>
              <Card
                card={card}
                className={classnames(styles.InputWidth, styles.InputM)}
                store={this.store} />
            </label>

            <div>
              <Button>Add Card</Button>
            </div>
          </Form>
        )}

        { card.last4 !== undefined && card.last4 !== null && (
          <React.Fragment>
            <label className={classnames(styles.Label, 'mb-2')}>
              <p>Card</p>
              <InputM
                name='last4'
                defaultValue={`************ ${card.last4}`}
                disabled={true}
                store={this.store} />
            </label>

            <div style={{lineHeight: '35px'}}>
              <Delete
                action={delAction}
                store={this.store}
                afterDelete={this.afterDelete}
                style={{borderRadius: '25px' }}
                className='px-2'>

                Delete
              </Delete>
            </div>
          </React.Fragment>
        )}

        { card.last4 === undefined && (
          <p className={classnames(styles.p2, styles.Tint5, 'mb-0')}>No payment method found.</p>
        )}
      </React.Fragment>
    );
  }
}
