import { observable, computed, action } from 'mobx';
import api from './API';

export default class SQL {
  @observable id;
  @observable name = 'New Query';
  @observable query = "SELECT *\nFROM email_activities\nORDER BY created_at DESC\nLIMIT 20";

  @observable columns = [];
  @observable rows = [];
  @observable errors = [];

  @observable view = 'data';
  @observable isEditingName = false;
  @observable chartSettings = {
    // being explicit
    type: undefined,
    xAxis: undefined,
    yAxis: undefined,
    display: false,
  };

  @observable lastRunAt = undefined;
  @observable lastRunQuery = undefined;
  @observable loaded = false;
  @observable resultsLoaded = true;

  constructor(params={}) {
    this.assign(params);
  }

  assign(params) {
    this.id = params.id || this.id;
    this.name = params.name || this.name;
    this.query = params.body?.query || this.query;
    this.loaded = params.loaded !== undefined ? params.loaded : this.loaded;
  }

  @action
  setView(view) {
    this.view = view;
  }

  run(store) {
    const query = store.get('body[query]')?.value;

    if (!query || !this.loaded || !this.resultsLoaded) {
      return false;
    }

    const params = {
      body: {
        query: query
      }
    };

    this.resultsLoaded = false;

    this.lastRunAt = new Date();
    this.lastRunQuery = query;

    this.columns.clear();
    this.rows.clear();
    this.errors.clear();

    this.chartSettings.type = undefined;
    this.chartSettings.xAxis = undefined;
    this.chartSettings.yAxis = undefined;
    this.chartSettings.display = false;

    api.get('/api/v1/analytics/search/sql', params).then(data => {
      const columns = data.results?.columns || [];
      const rows = data.results?.rows || [];
      const errors = data.errors || [];

      this.columns.push(...columns);
      this.rows.push(...rows);
      this.errors.push(...errors);

      this.resultsLoaded = true;
    });
  }

  isStale(store) {
    if (this.name  !== store.get('name')?.value ||
        this.query !== store.get('body[query]')?.value) {

      return true;
    }

    return false;
  }

  @computed
  get isDataView() {
    return this.view === 'data';
  }

  get isChartView() {
    return this.view === 'chart';
  }

  get hasRun() {
    return this.lastRunAt !== undefined;
  }

  get hasErrors() {
    return this.errors.length > 0;
  }

  get queryErrors() {
    return this.errors.filter(error => error.field === 'body[query]');
  }

  get chartXValues() {
    const key = this.columns.indexOf(this.chartSettings.xAxis);

    if (key > -1) {
      return this.rows.map(row => {
        return row[key];
      });
    }

    return [];
  }

  get chartYValues() {
    const key = this.columns.indexOf(this.chartSettings.yAxis);

    if (key > -1) {
      return this.rows.map(row => {
        return row[key];
      });
    }

    return [];
  }

  get exportFilename() {
    const name = this.name.replace(' ', '-');
    const ext = this.view === 'data' ? 'csv' : 'png'
    return `${name}_${this.view}-${Date.now()}.${ext}`;

  }
}
