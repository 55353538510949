import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Tooltip from 'components/Util/Misc/Tooltip';

import styles from 'styles/Visualizations';

@observer
export default class SensitiveEventLocations extends Component {
  maxSpanWidth = 75;
  minSpanWidth = 10;

  formatData = (accountData, financialData, target) => {
    const accountSize = accountData.length;
    const financialSize = financialData.length;

    let accountTargetSize = accountSize >= target ? target : accountSize;
    let financialTargetSize = financialSize >= target ? target : financialSize;

    if (accountTargetSize < target) {
      // try to increase finance
      const remainder = target - accountTargetSize;

      if (financialSize > target) {
        const add = Math.min(target - accountTargetSize, financialSize - target);
        financialTargetSize += add;
      }
    }

    if (financialTargetSize < target) {
      // try to increase account
      const remainder = target - financialTargetSize;

      if (accountSize > target) {
        const add = Math.min(target - financialTargetSize, accountSize - target);
        accountTargetSize += add;
      }
    }

    // if we have 0 of one, but all of the other, we need to remove one to fit in the 250px iframe
    // this is because we display additional text "No account/financial data detected"
    const maxResults = target * 2;

    if (accountTargetSize === 0 && financialTargetSize === maxResults) {
      financialTargetSize--;
    }

    if (financialTargetSize === 0 && accountTargetSize === maxResults) {
      accountTargetSize--;
    }

    return [accountData.slice(0, accountTargetSize), financialData.slice(0, financialTargetSize)];
  }

  calcWidth = (value, maxValue) => {
    const multiplier = this.maxSpanWidth / maxValue;
    const computedValue = parseInt(value * multiplier) || 0;
    
    return `${Math.max(computedValue, this.minSpanWidth)}%`;
  }

  render() {
    const { widget, view } = this.props;
    let locations;

    if (view === 'usa') {
      locations = widget.data.us_locations;
    }
    else {
      locations = widget.data.world_locations;
    }

    const accountData = locations.datasets.filter(el => el.label === 'Account')[0].data;
    const financialData = locations.datasets.filter(el => el.label === 'Financial')[0].data;

    const [formattedAccountData, formattedFinancialData] = this.formatData(accountData, financialData, 4);

    const maxAccountCount = formattedAccountData.length > 0 ? formattedAccountData[0][1] : 0;
    const maxFinancialCount = formattedFinancialData.length > 0 ? formattedFinancialData[0][1] : 0;

    const dataSize = accountData.length + financialData.length;

    const klasses = [styles.Locations];

    if (dataSize === 0) {
      klasses.push(styles.Empty);
    }

    return (
      <div className={classnames(klasses)}>
        <p className={classnames(styles.Header)}><span>Top Account Events</span></p>
        { formattedAccountData.length > 0 ? (
          <ul className='mb-2'>
            { formattedAccountData.map((data, index) =>
              <li key={index}>
                <Tooltip style={{ width: this.calcWidth(data[1], maxAccountCount)}} content={data[0]}>
                  <span>{data[0]}</span>
                </Tooltip>

                {data[1].toLocaleString()}
              </li>
            )}
          </ul>
        ) : (
          <p className={classnames(styles.NoData, 'mb-2')}>No account data detected.</p>
        )}

        <p className={classnames(styles.Header)}><span>Top Financial Events</span></p>
        { formattedFinancialData.length > 0 ? (
          <ul className='mb-0'>
            { formattedFinancialData.map((data, index) =>
              <li key={index}>
                <Tooltip style={{ width: this.calcWidth(data[1], maxFinancialCount)}} content={data[0]}>
                  <span>{data[0]}</span>
                </Tooltip>

                {data[1].toLocaleString()}
              </li>
            )}
          </ul>
        ) : (
          <p className={classnames(styles.NoData, 'mb-0')}>No financial data detected.</p>
        )}

      </div>
    );
  }
}
