import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import InputM from 'components/Util/Inputs/InputM';

import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import Store from 'models/Form';
import api from 'models/API';

import styles from 'styles/Dashboard/Base';

@observer
export default class Preview extends Component {
  store = new Store();

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submit();
    }
  }

  submit = () => {
    const { template } = this.props;
    const store = this.store;

    if (template.isSendingPreview) {
      return;
    }

    const post = {
      authenticity_token: document.querySelector('[name=csrf-token]').content,
      email: store.get('email').value,
      body: template.editor.root.JSON,
    }

    store.clearErrors();
    store.clearSuccesses();
    template.isSendingPreview = true;

    api.post('/home/settings/templates/preview', post).then(data => {
      template.isSendingPreview = false;

      if (data.errors.length === 0) {
        store.addSuccess('email', 'a preview was sent');
      }
      else {
        this.store.addError('email', 'is invalid');
      }
    });
  }

  render() {
    const { id, template } = this.props;

    return (
      <div className='d-none' id={id}>
        <div>
          <h4 className={classnames(styles.h4)}>Preview Email</h4>
          <label className={classnames(styles.Label)}>
            <p>Send preview to</p>

            <InputM
              name='email'
              placeholder='Email'
              defaultValue={template.previewEmail}
              onKeyDown={this.onKeyDown}
              store={this.store} />

            <Button onClick={this.submit} className='ms-1' store={this.store}>Send</Button>
          </label>

        </div>
      </div>
    );
  }
}
