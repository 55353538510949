import React, { Component } from 'react';
import classnames from 'classnames';

import TableSort from 'components/Util/Icons/TableSort';

import styles from 'styles/Dashboard/Table';

export default class Head extends Component {
  onClick = (header) => {
    const tableAPI = this.props.tableAPI;
    let sortOrder = tableAPI.params?.sort?.order || 'asc';

    // flip the current sort order...
    if (sortOrder == 'asc') {
      sortOrder = 'desc';
    }
    else {
      sortOrder = 'asc'
    }

    tableAPI.params.sort = {
      name: header.key,
      order: sortOrder,
    }

    tableAPI.fetch();
  }

  render() {
    const { headers } = this.props;

    return (
      <thead>
        <tr>
          { headers.map(header =>
            <th
              onClick={() => this.onClick(header)}
              width={header.width}
              key={header.key}
              className={classnames(header.className, header.sortable !== false ? styles.Sortable : '')}>

              {header.value}
              { header.sortable !== false  && <TableSort className={classnames(styles.TableSort)} /> }
            </th>
          )}
        </tr>
      </thead>
    );
  }
}
