import { observable, computed, action } from 'mobx';

export default class Card {
  @observable name;
  @observable last4;
  @observable publishable_key;
  @observable is_submitting;
  @observable loaded = false;

  constructor(params={}) {
    this.assign(params);
  }

  assign(params) {
    this.name = params.name;
    this.publishable_key = params.publishable_key;
    this.last4 = params.last4;
  }
}
