import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import TopNav from './TopNav';
import DevOps from './DevOps';
import Product from './Product';
import Security from './Security';

import styles from 'styles/Dashboard/Root/Show';

import Store from 'models/Form';

@observer
export default class Show extends Component {
  render() {
    const { dashboardCollection } = this.props;

    return (
      <React.Fragment>
        <TopNav dashboardCollection={dashboardCollection} />

        <div className={classnames(styles.Container, 'p-3', 'pt-2')}>
          <h3 className={classnames(styles.h3, 'fw-bold')}>Dashboard</h3>
          { dashboardCollection.active === 'devops' && <DevOps dashboardCollection={dashboardCollection} /> }
          { dashboardCollection.active === 'product' && <Product dashboardCollection={dashboardCollection} /> }
          { dashboardCollection.active === 'security' && <Security dashboardCollection={dashboardCollection} /> }
        </div>
      </React.Fragment>
    );
  }
}
