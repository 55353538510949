import React, { Component } from 'react';
import queryString from 'query-string'

import Content from 'components/Marketing/Content';
import EditPassword from 'components/Marketing/Organizations/Passwords/Edit';

export default class New extends Component {
  render() {
    const token = queryString.parse(window.location.search)['reset_password_token'];

    return (
        <Content setFooterBorder={true}>
          <EditPassword token={token}/>
        </Content>
    );
  }
}
