import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class TableSort extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="i"><path id="Rectangle" d="M1 4L4 1L7 4" stroke="${color}" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path id="Rectangle_2" d="M1 12L4 15L7 12" stroke="${color}" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
