import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import InputM from 'components/Util/Inputs/InputM';

import styles from 'styles/Dashboard/Base';

@observer
export default class EmailActivity extends Component {
  render() {
    const { webhook, action, store, method } = this.props;

    return (
      <React.Fragment>
        <label className={classnames(styles.Label)}>
          <p>Destination URL</p>
          <p className={classnames(styles.InputDescription, 'mb-1')}>The URL to receive the activity event via POST request.</p>

          <InputM
            className={classnames(styles.InputWidth, styles.ShowInputMessage)}
            defaultValue={webhook.destination_url}
            placeholder='Destination URL'
            name='destination_url'
            store={store} />
        </label>
      </React.Fragment>
    );
  }
}
