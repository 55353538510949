import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';

import InputM from 'components/Util/Inputs/InputM';

import Edit from 'components/Util/Icons/Edit';

import styles from 'styles/Dashboard/Editor';

@observer
export default class TopNav extends Component {
  componentDidMount = () => {
    window.addEventListener('click', this.onWindowClick);
  }

  onWindowClick = (e) => {
    const { model } = this.props;

    if (!model.isEditingName) {
      return;
    }

    if (e.target.getAttribute('name') !== 'name') {
      model.isEditingName = false;
    }
  }

  editName = (e) => {
    e.stopPropagation();

    const { model, store } = this.props;

    if (model.isEditingName) {
      return;
    }

    model.isEditingName = true;
    const input = store.getRef('name').current;

    if (input) {
      setTimeout(() => {
        input.focus();
      });
    }
  }

  render() {
    const { model, store, middle, right } = this.props;
    const hasError = store.get('name')?.hasError;

    return (
      <div className={classnames(styles.TopNav, 'd-flex', 'justify-content-center', 'flex-column')}>
        <div className='row'>
          <div
            className={classnames(styles.EditName, 'col', 'd-flex', 'align-items-center', 'position-relative', 'px-0')}
            onClick={this.editName}
            style={{ left: '12px', maxWidth: styles.editorColumnWidth }}>

            <div className='ps-2 w-100'>
              <InputM
                name='name'
                className={classnames((model.isEditingName || hasError) ? '' : 'd-none')}
                defaultValue={model.name}
                placeholder='Name'
                store={store} />

              <div className={classnames((!model.isEditingName && !hasError) ? '' : 'd-none')}>
                <h3
                  className={classnames(styles.p1, styles.ColorPrimaryWhite, 'fw-bold', 'd-inline-block', 'mb-0', 'align-middle', 'overflow-ellipsis-hidden')}
                  style={{ maxWidth: 'calc(100% - 20px)'}}>

                  {store.get('name')?.value}
                </h3>

                <Edit className={classnames(styles.Icon)} />
              </div>
            </div>
          </div>

          <div className='col d-flex align-items-center'>
            {middle}
          </div>

          <div className='col d-flex align-items-center ps-0 pe-1 position-relative' style={{ right: '12px', maxWidth: styles.editorColumnWidth }}>
            <div className='w-100'>
              {right}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
