import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

export default class Plane extends Component {
  render() {
    const src = `
<svg width="286" height="315" viewBox="0 0 286 315" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 927">
<g id="Clip path group">
<mask id="mask0_127_693" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="286" height="315">
<g id="clip-path-3">
<path id="Rectangle 949" d="M286 0.947021H0.335999V314.523H286V0.947021Z" fill="white"/>
</g>
</mask>
<g mask="url(#mask0_127_693)">
<g id="Group 899">
<g id="Group 898">
<g id="Group 897">
<g id="Clip path group_2">
<mask id="mask1_127_693" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="286" height="315">
<g id="clip-path-4">
<path id="Rectangle 948" d="M286 0.947021H0.335999V314.523H286V0.947021Z" fill="white"/>
</g>
</mask>
<g mask="url(#mask1_127_693)">
<g id="Group 896">
<path id="Path 4094" d="M156.699 243.359L242.376 64.928C242.494 64.6805 242.521 64.399 242.452 64.1334C242.383 63.8679 242.222 63.6354 241.998 63.4771C241.774 63.3189 241.501 63.2452 241.227 63.2689C240.954 63.2927 240.698 63.4125 240.504 63.607L101.452 204.487L101.507 204.537L0.335999 133.333L286 0.947021L257.814 314.523L156.699 243.359Z" fill="url(#paint0_linear_127_693)"/>
</g>
</g>
</g>
</g>
</g>
</g>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_127_693" x1="286" y1="8.47285" x2="115.153" y2="195.356" gradientUnits="userSpaceOnUse">
<stop stop-color="#6434FD"/>
<stop offset="0.453" stop-color="#332385"/>
<stop offset="1" stop-color="#4836FF"/>
</linearGradient>
</defs>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
