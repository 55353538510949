import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Form from 'components/Util/Inputs/Form';
import TextareaM from 'components/Util/Inputs/TextareaM';

import BackButton from 'components/Util/Buttons/LightBackground/BackButton';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';
import LoadableCard from 'components/Dashboard/LoadableCard';

import Store from 'models/Form';
import alertStore from 'models/Alert';

import styles from 'styles/Dashboard/Base';

@observer
export default class Show extends Component {
  store = new Store();

  onSuccess = () => {
    alertStore.setContents(
      <React.Fragment>
        <h3 className={classnames(styles.h3, 'text-center')}>Your Message has been submitted!</h3>
        <p className={classnames(styles.p2, 'mb-0', 'text-center')}>Our team will be reaching out shortly. Thank you!</p>
      </React.Fragment>
    );

    alertStore.persistAlert();

    window.location = '/home';
  }

  render() {
    return (
      <div className='m-3'>
        <h3 className={classnames(styles.h3, 'fw-bold')}>Contact</h3>

        <LoadableCard className='mb-2' loadState={true}>
          <h5 className={classnames(styles.p2, 'fw-bold', 'mb-1')}>Open a Ticket</h5>

          <Form method='post' action='/contact' onSuccess={this.onSuccess} store={this.store}>
            <label className={classnames(styles.Label, 'w-100')}>
              <p>How can we help?</p>
              <TextareaM
                defaultValue=''
                placeholder='Message'
                className='mb-2 w-100'
                style={{maxWidth: '425px'}}
                name='message'
                store={this.store} />
            </label>

            <div>
              <Button>Submit</Button>
            </div>
          </Form>
        </LoadableCard>
      </div>
    );
  }
}
