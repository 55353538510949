import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Health from './Health';

@observer
export default class IpAddressHealth extends Component {
  render() {
    const { widget, options, chartRef } = this.props;
    const healthy = <p>Healthy IP<br/>Addresses</p>
    const unhealthy = <p>Unhealthy IP<br/>Addresses</p>

    return (
      <Health
        widget={widget}
        options={options}
        chartRef={chartRef}
        healthyText={healthy}
        unhealthyText={unhealthy} />
    );
  }
}
