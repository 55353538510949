import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Play from 'components/Util/Icons/Play';

import styles from 'styles/Dashboard/Editor';

@observer
export default class RunQueryBtn extends Component {
  onClick = (e) => {
    const { sql, store } = this.props;

    store.clearErrors();
    sql.run(store);
  }
  render() {
    const { className, ...params } = this.props;

    return (
      <button {...params} type='button' onClick={this.onClick} className={classnames(className, styles.CTA)}><Play />Run Query</button>
    );
  }
}
