export function deepMerge(obj1, obj2) {
  const result = {};

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (typeof obj2[key] === 'object' && obj1.hasOwnProperty(key) && typeof obj1[key] === 'object') {
        result[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        result[key] = obj2[key];
      }
    }
  }

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
      if (typeof obj1[key] === 'object') {
        result[key] = deepMerge(obj1[key], {});
      } else {
        result[key] = obj1[key];
      }
    }
  }

  return result;
}
