import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Change from './AlertConditions/Change';
import Threshold from './AlertConditions/Threshold';
import Anomaly from './AlertConditions/Anomaly';

@observer
export default class AlertConditions extends Component {
  render() {
    const { monitor, store } = { ...this.props }

    return (
      <React.Fragment>
        { monitor.detection_method === 'change' && <Change monitor={monitor} store={store} /> }
        { monitor.detection_method === 'threshold' && <Threshold monitor={monitor} store={store} /> }
        { monitor.detection_method === 'anomaly' && <Anomaly monitor={monitor} store={store} /> }
      </React.Fragment>
    );
  }
}
