import React from 'react';
import { observer } from 'mobx-react';
import Base from './Base';
import classnames from 'classnames';

import RowIcon from 'components/Util/Icons/Splitscreen';

import LayerName from './Elements/LayerName';
import ColumnCount from './Elements/ColumnCount';
import DeleteRow from './Elements/DeleteRow';
import Spacer from './Elements/Spacer';

@observer
export default class Row extends Base {
  Icon() {
    return <RowIcon />;
  }

  setName = (event) => {
    this.props.node.setName(event.target.value);
  }

  setColumns = (event) => {
    const { node } = this.props;
    const columns = event.target.value;

    node.setColumns(columns);
  }

  renderForm = () => {
    const { node } = this.props;

    return (
      <React.Fragment>
        <LayerName node={node} />
        <Spacer />
        <ColumnCount node={node} />
        <Spacer />
        <DeleteRow node={node} />
      </React.Fragment>
    );
  }
}
