import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classnames from 'classnames';
import { ChromePicker } from 'react-color';

import Input from 'components/Util/Inputs/InputM';

import Store from 'models/Form';

import styles from 'styles/Dashboard/Editor';

@observer
export default class Background extends Component {
  attribute = observable.object({ displayPicker: false });
  inputRef = React.createRef();
  store = new Store();

  componentDidMount() {
    window.addEventListener('click', this.onClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutside);
  }

  onClickOutside = (event) => {
    this.attribute.displayPicker = false;
  }

  onPickerChange = (color) => {
    this.inputRef.current.value = color.hex;
    this.props.node.setBackground(color.hex);
  }

  onChange = (e) => {
    const value = e.target.value;
    this.props.node.setBackground(value);
    this.store.get('background').value = value;
  }

  onClick = (event) => {
    this.attribute.displayPicker = true;
    event.stopPropagation();
  }

  render() {
    const { node } = this.props;
    const pickerStyles = {
      position: 'absolute',
      top: '44px',
      width: '100%',
    }

    const previewStyle = {
      position: 'absolute',
      width: '16px',
      height: '16px',
      background: node.background,
      borderRadius: '50%',
      border: `1px solid ${styles.tint2}`,
      position: 'absolute',
      right: '8px',
      bottom: '12px',
      zIndex: '1',
    };

    return (
      <React.Fragment>
        <div className='position-relative' onClick={this.onClick}>
          <div style={previewStyle} />
          <label className={classnames(styles.Label, 'w-100')}>
            <p>Background</p>
            <Input
              placeholder='Background'
              ref={this.inputRef}
              value={node.background}
              store={this.store}
              className='w-100'
              onChange={this.onChange} />

            { this.attribute.displayPicker &&
              <div style={pickerStyles}>
                <ChromePicker disableAlpha={true} color={node.background} onChange={this.onPickerChange} />
              </div>
            }
          </label>
        </div>

      </React.Fragment>
    );
  }
}



