import React, { Component } from 'react';

import Content from 'components/Marketing/Content';
import ShowContact from 'components/Marketing/Contact/Show';

export default class Show extends Component {
  render() {
    return (
        <Content>
          <ShowContact />
        </Content>
    );
  }
}
