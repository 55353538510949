import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import SelectM from 'components/Util/Inputs/SelectM';
import Button from 'components/Util/Buttons/LightBackground/MediumPrimaryButton';

import styles from 'styles/Dashboard/Base';

@observer
export default class Resubscribe extends Component {
  render() {
    const { subscription, store } = this.props;

    return (
      <div>
        <p className={classnames(styles.p2)}>You are <span className='fw-bold'>not</span> subscribed to the <span className={classnames(styles.ColorPrimaryPurple, 'fw-bold', 'fst-italic')}>{subscription.unsubscribe_group?.name}</span> mailing list.</p>
        <div><Button>Subscribe</Button></div>
      </div>
    );
  }
}
