import React, { Component } from 'react';
import classnames from 'classnames';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

export default class DoughnutChart extends Component {
  componentDidMount() {
    ChartJS.register(ArcElement, Tooltip, Legend);
  }

  render() {
    const { chartRef, ...params } = this.props;

    return (
      <Doughnut {...params} ref={chartRef} />
    );
  }
}
