import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import styles from 'styles/Base'

export default class Close extends Component {
  render() {
    const color = this.props.color || styles.colorPrimaryBlack;

    const src = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.79429 7.70749L15.7654 1.73637L14.2726 0.243586L8.30151 6.21471L2.33039 0.243586L0.837608 1.73637L6.80873 7.70749L0.837607 13.6786L2.33039 15.1714L8.30151 9.20027L14.2726 15.1714L15.7654 13.6786L9.79429 7.70749Z" fill="${color}"/>
</svg>
`;

    const encodedSrc = new Buffer(src).toString('base64');

    return (
      <SVG src={'data:text/plain;base64,' + encodedSrc} uniquifyIDs={true} {...this.props}/>
    );
  }
}
