import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Doughnut from './Charts/Doughnut';

import styles from 'styles/Visualizations';

const createGradient = (chart, colorStart, colorEnd) => {
  const ctx = chart.ctx;
  const area = chart.chartArea;

  const centerX = area.width / 2;
  const centerY = area.height / 2;
  const r = area.height * 0.666;

  const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

export function EmailEngagementRate(props) {
  const { widget, options, chartRef } = props;

  // outside, middle
  const sendColor = [styles.colorSecondaryGrey, styles.colorSecondaryGrey];
  const openColor = [styles.colorPrimaryPurple, styles.colorPrimaryPurple];
  const clickColor = [styles.colorSecondaryGreen, styles.colorSecondaryGreen];

  // remove tooltip since we display legend
  options.plugins.tooltip.enabled = false
  options.cutout = '66%';
  options.borderWidth = 0;
  options.hoverBorderWidth = 0;

  const chartData = {
    ...widget.data,
    datasets: widget.data.datasets.map(dataset => ({
      ...dataset,
    })),
  };

  const openData = {
    ...chartData,
    datasets: [chartData.datasets[0]]
  };

  const clickData = {
    ...chartData,
    datasets: [chartData.datasets[1]]
  };

  useEffect(() => {
    const chart = props.chartRef.current;
    const opens = widget.data.datasets[0];
    const clicks = widget.data.datasets[1];

    if (chart) {
      if (!opens.backgroundColor) {
        const openColors = [
          createGradient(chart, openColor[0], openColor[1]),
          createGradient(chart, sendColor[0], sendColor[1]),
        ];

        opens.backgroundColor = openColors;
        opens.borderColor = openColors;

        const clickColors = [
          createGradient(chart, clickColor[0], clickColor[1]),
          createGradient(chart, sendColor[0], sendColor[1]),
        ];

        clicks.backgroundColor = clickColors;
        clicks.borderColor = clickColors;
      }
    }
  });

  const openRate = openData.datasets[0].data[0];
  const clickRate = clickData.datasets[0].data[0];

  return (
    <div className={classnames(styles.EmailEngagementRate, 'row', 'h-100')}>
      <div className='col-6 d-flex position-relative'>
        <div className={classnames(styles.Stats)}>
          <p>{openRate}%</p>
          <p>Unique Open<br/>Rate</p>
        </div>

        <Doughnut id={widget.id} data={openData} options={options} chartRef={props.chartRef} />
      </div>
      <div className='col-6 d-flex position-relative'>
        <div className={classnames(styles.Stats)}>
          <p>{clickRate}%</p>
          <p>Unique Click<br/>Rate</p>
        </div>

        <Doughnut id={widget.id} data={clickData} options={options} chartRef={props.chartRef} />
      </div>
    </div>
  );
}

export default observer(EmailEngagementRate);
