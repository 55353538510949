import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Content from 'components/Dashboard/Content';
import IpPoolsShow from 'components/Dashboard/Settings/IpPools/Show';

import api from 'models/API';
import IpPool from 'models/IpPool';

@observer
export default class Show extends Component {
  constructor(props) {
    super(props);

    this.ipPool = new IpPool();
    const id = props.match.params.id;

    api.get(`/api/v1/settings/ip_pools/${id}`).then(data => {
      this.ipPool.assign(data);
    });

    // TODO: FIX TO ENSURE WE GET ALL IP'S
    api.get(`/api/v1/settings/ip_addresses`).then(data => {
      this.ipPool.assign({ all_ip_addresses: data });
    });
  }

  render() {
    return (
      <Content>
        <IpPoolsShow ipPool={this.ipPool} />
      </Content>
    );
  }
}
